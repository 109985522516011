import {
    fetchChatMessages,
    fetchChatPackages,
    fetchContacts,
    fetchUserChatMessages,
} from '../../services';
import * as ActionTypes from './types';

export const setActiveChat =
    ({ id, type }) =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.SET_ACTIVE_CHAT, payload: { id, type } });
        } catch (error) {
            console.log(error);
        }
    };

export const resetPackages =
    ({ projectId }) =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.RESET_PACKAGES, payload: { projectId } });
        } catch (error) {
            console.log(error);
        }
    };

export const getChatPackages =
    ({ project, projectId, page, pageLimit, search }) =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CHAT_PACKAGES, payload: { project, projectId } });
            const response = await fetchChatPackages({
                projectId,
                page,
                pageLimit,
                search,
            });
            dispatch({
                type: ActionTypes.GET_CHAT_PACKAGES_SUCCESS,
                payload: {
                    projectId,
                    page,
                    data: response?.data,
                    meta: response?.meta,
                },
            });
        } catch (error) {
            dispatch({ type: ActionTypes.GET_CHAT_PACKAGES_FAILED });
        }
    };

export const getAllChatMessages =
    ({ id, page, pageLimit, type }) =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CHAT_MESSAGES_INIT, payload: id });
            dispatch({ type: ActionTypes.GET_CHAT_MESSAGES });
            const response = await fetchChatMessages({ id, page, pageLimit, type });
            dispatch({
                type: ActionTypes.GET_CHAT_MESSAGES_SUCCESS,
                payload: { id, data: response?.data },
            });
        } catch (error) {
            dispatch({ type: ActionTypes.GET_CHAT_MESSAGES_FAILED });
        }
    };

export const getAllUserChatMessages =
    ({ id, page, pageLimit }) =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CHAT_MESSAGES_INIT, payload: id });
            dispatch({ type: ActionTypes.GET_CHAT_MESSAGES });
            const response = await fetchUserChatMessages({ id, page, pageLimit });
            dispatch({
                type: ActionTypes.GET_CHAT_MESSAGES_SUCCESS,
                payload: { id, data: response?.data },
            });
        } catch (error) {
            dispatch({ type: ActionTypes.GET_CHAT_MESSAGES_FAILED });
        }
    };

export const setChatMessage =
    ({ id, message, user }) =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.SET_CHAT_MESSAGE, payload: { id, message, user } });
        } catch (error) {
            dispatch({ type: ActionTypes.SET_CHAT_MESSAGE_FAILED });
        }
    };

export const updateInboxUnreadMessages = (payload) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.UPDATE_INBOX_MESSAGE, payload });
    } catch (error) {
        // dispatch({ type: FETCH_PROJECTS_FAILURE });
    }
};

export const updateInboxProjectUnreadMessages = (payload) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.UPDATE_PROJECT_INBOX_MESSAGE, payload });
    } catch (error) {
        // dispatch({ type: FETCH_PROJECTS_FAILURE });
    }
};

export const getContactList = (searchParam) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.GET_CONTACT_LIST });
        const response = await fetchContacts(searchParam);
        dispatch({
            type: ActionTypes.GET_CONTACT_LIST_SUCCESS,
            payload: {
                data: response?.data,
                meta: response?.meta,
                page: searchParam?.page || 1,
            },
        });
    } catch (error) {
        dispatch({ type: ActionTypes.GET_CONTACT_LIST_FAILED });
    }
};

export const activeContactRead = (payload) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.ACTIVE_CONTACT_READ, payload });
    } catch (error) {
        // dispatch({ type: FETCH_PROJECTS_FAILURE });
    }
};

export const activePackageRead = (payload) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.ACTIVE_PACKAGE_READ, payload });
    } catch (error) {
        // dispatch({ type: FETCH_PROJECTS_FAILURE });
    }
};

export const updateContactUnreadMessages = (payload) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.UPDATE_CONTACT_MESSAGE, payload });
    } catch (error) {
        // dispatch({ type: FETCH_PROJECTS_FAILURE });
    }
};
