import {
  Modal, notification, Select, Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { updateInActiveTags } from '../../../services';

const { Option } = Select;

function ToggleTagModal({
  title = '',
  visible = false,
  onOk,
  onCancel,
  getActiveTags,
  getInactiveTags,
  prjctId,
  tagsList = [],
}) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [activateLoader, setActivateLoader] = useState(false);

  const handleChange = (value) => {
    setSelectedTags(value);
  };

  useEffect(() => {
    if (visible) {
      getInactiveTags();
    }
  }, []);

  const handleTagActivation = async () => {
    if (selectedTags.length) {
      setActivateLoader(true);

      try {
        const res = await updateInActiveTags(prjctId, {
          is_active: true,
          tags: selectedTags,
        });

        if (res.success) {
          notification.success({
            message: `${selectedTags.length} activated successfully!`,
          });
          setActivateLoader(false);
          setSelectedTags([]);
          getActiveTags();
          onOk();
        } else {
          notification.error({
            message: 'Error Updating tags.',
          });
          setActivateLoader(false);
          setSelectedTags([]);
          onOk();
        }
      } catch {
        setActivateLoader(false);
        setSelectedTags([]);
      }
    } else {
      notification.warn({
        message: 'You have not selected anything to Activate',
      });
    }
  };

  return (
    <Modal
      title={title}
      centered
      visible={visible}
      onOk={handleTagActivation}
      onCancel={onCancel}
      okText="Activate"
      maskClosable={false}
    >
      <Spin spinning={activateLoader}>
        <div className="toggle-tag-content">
          <h4>Tags</h4>
          <div id="inActiveTagsSelect">
            <Select
              getPopupContainer={() => document.getElementById('inActiveTagsSelect')}
              mode="multiple"
              style={{
                width: '100%',
              }}
              placeholder="Select tags to activate"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              onChange={handleChange}
              value={selectedTags}
            >
              {tagsList.map((tag) => (
                <Option key={tag.id} value={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ToggleTagModal;
