import React from 'react';
import {
  Layout, Menu, Row, Col, Button,
} from 'antd';
import { useHistory } from 'react-router';
import Logo from '../../../assets/images/logo.png';
import phoneIcon from '../../../assets/images/phoneicon.png';
import './styles.css';

const { Header } = Layout;

export function NavHeader() {
  const history = useHistory();

  const menu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="#">
          Why Alectify
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="#">
          Overview
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="#">
          Prices
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="#">
          About Us
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="container">
      <Header className="header-container">
        <Row>
          <Col span={16} className="single-line-logo">
            <a href="#">
              <img src={Logo} />
            </a>
            <div className="nav">{menu}</div>
          </Col>

          <Col span={8} className="phone-number">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={phoneIcon} />
              <p>+49 (0)89 21540420</p>
            </a>
            <div>
              <Button
                type="link"
                className="login-btn"
                onClick={() => history.push('/login')}
              >
                Login
              </Button>
              <Button
                className="get-started-btn"
                onClick={() => history.push('/signup')}
              >
                Get started now
              </Button>
            </div>
          </Col>
        </Row>
      </Header>
    </div>
  );
}
