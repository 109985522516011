import {
  IMAGE_LIST,
  RESET_STATE,
  FETCH_CONSTRUCTION_DOCS_SUCCESS,
  FETCH_EQUIPMENT_DOCS_INITIATE,
  FETCH_EQUIPMENT_DOCS_SUCCESS,
  UPLOAD_EQUIPMENT_DOCUMENT_INITIATE,
} from './types';

const initialState = {
  loading: false,
  error: null,
  equipmentDocs: {
    list: [],
    constructionDrawings: [],
    images: [],
  },
};

export const equipmentDocsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_EQUIPMENT_DOCS_INITIATE:
    case UPLOAD_EQUIPMENT_DOCUMENT_INITIATE:
      return {
        ...state,
        loading: true,
      };

    case FETCH_EQUIPMENT_DOCS_SUCCESS:
      return {
        ...state,
        loading: false,
        equipmentDocs: { ...state.equipmentDocs, list: payload },
      };

    case FETCH_CONSTRUCTION_DOCS_SUCCESS:
      return {
        ...state,
        loading: false,
        equipmentDocs: { ...state.equipmentDocs, constructionDrawings: payload },
      };

    case IMAGE_LIST:
      return { ...state, equipmentDocs: { ...state.equipmentDocs, images: payload } };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
