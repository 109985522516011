import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Col, Row, Form, Input, Button, notification,
} from 'antd';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { requestResetPassword } from '../../../../app/services';
import authBanner from '../../../../app/assets/images/auth-banner.jpg';
import './style.scss';

function ForgotPassword({ history }) {
  const { user } = useSelector(({ auth }) => auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      history.push('/projects');
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await requestResetPassword(values);
      notification.success({
        message: 'Email sent',
        description: response?.data?.message,
        duration: 10000,
      });
    } catch (error) {
      notification.error({
        message: 'Invalid email',
        description: "User doesn't exist with your requested email",
        duration: 10000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="auth-container height-100vh" align="middle">
      <Col span={12}>
        <img src={authBanner} className="auth-image" />
      </Col>
      <Col span={12}>
        <div className="auth-form-container">
          <div className="auth-form-header">
            <h3>
              Welcome to
              {' '}
              <span className="color-primary">Alectify!</span>
            </h3>
            <p>Reset your account password</p>
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={() => {}}
            autoComplete="off"
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please enter your email',
                },
              ]}
            >
              <Input placeholder="Enter your email address here" />
            </Form.Item>

            <Form.Item className="mt-50">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-lg btn-primary-lg"
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          <div className="mt-50">
            <p style={{ fontSize: 15 }}>
              Back to login?
              {' '}
              <Link className="btn btn-outline-primary" to="/login">
                Click here
              </Link>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ForgotPassword;
