import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import { memo, useState, useEffect } from 'react';
import { AlectifyTable } from '../../../../../../components';
import { fetchCustomerPurchaseOrderDocuments } from '../../../../../../services';
import useColumns from './effects/useColumns';

const CustomerInvoices = memo(() => {
    const columns = useColumns();
    const { projectId } = useParams();
    const [documents, setDocuments] = useState({ data: null, fetching: false, total: 0 });

    const getCustomerPurchaseOrderDocuments = async (pagination) => {
        try {
            setDocuments({ ...documents, fetching: true });
            const params = {
                page: pagination?.page || 1,
                per_page: pagination?.per_page || 10,
                ordering: pagination?.orderingColumn || null,
            };
            if (pagination?.searchText && pagination?.searchedColumn) {
                params[pagination.searchedColumn] = pagination?.searchText;
            }
            const response = await fetchCustomerPurchaseOrderDocuments(projectId, params);
            setDocuments({
                ...documents,
                fetching: false,
                data: response?.data,
                total: response.meta.total_count,
            });
        } catch (error) {
            setDocuments({ ...documents, fetching: false });
        }
    };

    useEffect(() => {
        getCustomerPurchaseOrderDocuments();
    }, [projectId]);

    return (
        <Card title="Customer Invoices" bordered={false}>
            <AlectifyTable
                columns={columns}
                defaultPageSize={10}
                total={documents.total}
                dataSource={documents.data}
                loading={documents.fetching}
                onDataFetch={getCustomerPurchaseOrderDocuments}
                selectRows={false}
            />
        </Card>
    );
});

export default CustomerInvoices;
