import _, { orderBy } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Tooltip, Collapse, Table, Avatar,
} from 'antd';
import {
  APPROVAL_STATUSES,
  COMMENTS_VALUE,
  DISPLAY_DATETIME_FORMAT,
  makeAvatar,
} from '../../../../utils';
import './review-status.scss';

const { Panel } = Collapse;

const MARKUP_COLUMNS = [
  // { title: 'Item #', dataIndex: 'id', key: 'id' },
  {
    title: 'File Name',
    dataIndex: 'approval_file_name',
    key: 'approval_file_name',
    render: (_, record) => (
      <p className="approval-file-name" title={record.approval_file_name}>
        {record.approval_file_name}
      </p>
    ),
  },
  {
    title: 'Due Date',
    dataIndex: 'approval_due_time',
    render: (_, value) => {
      if (value.approval_due_time) {
        return <p>{moment(value.approval_due_time).format(DISPLAY_DATETIME_FORMAT)}</p>;
      }

      return <p>-</p>;
    },
  },
  {
    title: 'Released date',
    dataIndex: 'created_at',
    render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
  },
  {
    title: 'Approval Comments',
    dataIndex: 'notes',
    key: 'notes',
    render: (_, record) => <p>{record?.markup_comment ? COMMENTS_VALUE[record?.markup_comment] : '-'}</p>,
  },
  {
    title: 'Submitted by',
    key: 'Submitted_by',
    render: (_, record) => (
      <Tooltip title={`${record.account.first_name} ${record.account.last_name}`}>
        <Avatar
          className="circle-icon m-auto"
          src={
                        record?.account?.image
                        || makeAvatar(record?.account?.first_name[0], record?.account?.last_name[0])
                    }
        />
      </Tooltip>
    ),
  },
];

const APPROVAL_COLUMNS = [
  {
    title: 'Type',
    dataIndex: 'document_type',
    key: 'document_type',
    render: (_, record) => {
      if (record.document_type) {
        return <p>{record.document_type}</p>;
      }

      return <p>-</p>;
    },
  },
  {
    title: 'File Name',
    dataIndex: 'approval_file_name',
    key: 'approval_file_name',
    render: (_, record) => (
      <p className="approval-file-name" title={record.approval_file_name}>
        {record.approval_file_name}
      </p>
    ),
  },
  {
    title: 'Due Date',
    dataIndex: 'approval_due_time',
    render: (_, record) => {
      if (record.approval_due_time) {
        return <p>{moment(record.approval_due_time).format(DISPLAY_DATETIME_FORMAT)}</p>;
      }

      return <p>-</p>;
    },
  },
  {
    title: 'Submitted on',
    dataIndex: 'created_at',
    render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
  },
  {
    title: 'Submitted by',
    key: 'submittedBy',
    align: 'center',
    render: (_, record) => (
      <Tooltip title={`${record.account.first_name} ${record.account.last_name}`}>
        <Avatar
          className="circle-icon m-auto"
          src={
                        record?.account?.image
                        || makeAvatar(record?.account?.first_name[0], record?.account?.last_name[0])
                    }
        />
      </Tooltip>
    ),
  },
];

export const getSortedItems = (list, sortKey = ['']) => orderBy(list, [...sortKey], ['desc']);

function Status({ tags = [], pkgId }) {
  const { approvals, markups } = useSelector(({ approval }) => approval);

  const markupsWithoutResubmittals = markups.filter((item) => item.markup_comment !== 3);
  const markupsWithResubmittals = markups.filter((item) => item.markup_comment === 3);
  const filteredApprovals = approvals.filter(
    (approval) => approval.approval_status === APPROVAL_STATUSES.SEND_FOR_APPROVAL,
  );

  return (
    <div className="mb-30">
      <Collapse bordered={false} className="tagsBody" defaultActiveKey={['1', '2', '3']}>
        {/* <Panel
          className="site-collapse-custom-panel-grey"
          header={(
            <b>
              List of Equipments (
              {tags.length}
              )
            </b>
)}
          key="1"
        >
          <Table
            dataSource={[...tags]}
            columns={[
              { title: 'Tag Name', dataIndex: 'name' },
              { title: 'Description', dataIndex: 'description' },
              { title: 'Price', dataIndex: 'price' },
              { title: 'Quantity', dataIndex: 'quantity' },
            ]}
            pagination={tags.length > 10}
          />
        </Panel> */}

        <Panel
          className="site-collapse-custom-panel-grey"
          header={(
            <b>
              History of Approval Documents (
              {filteredApprovals.length}
              )
            </b>
)}
          key="2"
        >
          <Table
            dataSource={getSortedItems(filteredApprovals, ['created_at'])}
            columns={APPROVAL_COLUMNS}
            // title={() => `Approvals (${filteredApprovals?.length})`}
            pagination={filteredApprovals.length > 10}
          />
        </Panel>

        <Panel
          className="site-collapse-custom-panel-grey"
          header={(
            <b>
              History of Reviewed Documents (
              {markupsWithoutResubmittals.length}
              )
            </b>
)}
          key="3"
        >
          <Table
            dataSource={getSortedItems(markupsWithoutResubmittals, ['created_at'])}
            // title={() => `Markups (${markupsWithoutResubmittals?.length})`}
            rowKey="id"
            columns={MARKUP_COLUMNS}
            pagination={markupsWithoutResubmittals.length > 10}
          />
        </Panel>

        {/* <Panel
          className="site-collapse-custom-panel-warning"
          header={<b>History of Resubmittal Documents</b>}
          key="4"
        >
          <Table
            dataSource={getSortedItems(markupsWithResubmittals, ['created_at'])}
            // title={() => `Markups (${markupsWithResubmittals?.length})`}
            rowKey="id"
            columns={MARKUP_COLUMNS}
            pagination={markupsWithResubmittals.length > 10}
          />
        </Panel> */}
      </Collapse>
    </div>
  );
}

export default Status;
