import _ from 'lodash';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  Collapse, Space, Form, Button, Row, Col, Upload, Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getSignedUrl } from '../../../../services';
import { DocumentsListing, UploadNote } from '../../../../components';
import pdfIcon from '../../../../assets/images/pdf-icon.png';
import imageIcon from '../../../../assets/images/img-icon.png';
import textIcon from '../../../../assets/images/text-icon.png';
import '../../../../assets/styles/components/documents.scss';

const { Panel } = Collapse;

function Documents({
  goBack = () => {},
  onSubmit = () => {},
  documents = [],
  deleteDocument = () => {},
}) {
  const downloadDocument = async ({ file_path, file_name }) => {
    if (file_name && file_path) {
      const url = await getSignedUrl(file_path);
      await axios.get(url.data, { headers: { 'Content-Disposition': 'attachment' } });
      saveAs(url.data, file_name);
    }
  };

  const extractIcon = (fileName) => {
    const fileExt = fileName.split('.').pop();
    if (fileExt === 'pdf') {
      return pdfIcon;
    } if (['jpeg', 'png', 'jpg', 'svg'].includes(fileExt)) {
      return imageIcon;
    }
    return textIcon;
  };

  return (
    <>
      <h2 className="step-heading-inline mb-20">
        Upload Documentation
        <UploadNote />
      </h2>
      <Form onFinish={onSubmit}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {!_.isEmpty(documents)
                        && documents.map((project, index) => (
                          <div key={index}>
                            {project.dir_key === 'single_lines' && (
                            <h2 className="step-heading-inline mt-10 mb-10">
                              Bird's Eye View
                              <UploadNote />
                            </h2>
                            )}
                            <Collapse
                              expandIconPosition="right"
                              className="alectify-collapse document-collapse"
                              key={index}
                            >
                              <Panel
                                header={project.dir_value}
                                extra={(
                                  <Tooltip title={project.help}>
                                    <QuestionCircleOutlined className="cursor-pointer" />
                                  </Tooltip>
                                              )}
                              >
                                <Row gutter={15}>
                                  <Col>
                                    <Form.Item
                                      name={project.dir_key}
                                      valuePropName={project.dir_key}
                                    >
                                      <Upload
                                        beforeUpload={() => false}
                                        listType="picture"
                                        multiple
                                      >
                                        <Button type="primary">
                                          Upload Files
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <DocumentsListing
                                  documents={project?.dir_files}
                                  directoryName={project.dir_key}
                                  deleteDocument={deleteDocument}
                                  downloadDocument={downloadDocument}
                                />
                              </Panel>
                            </Collapse>
                          </div>
                        ))}
        </Space>
        <div className="text-right">
          <Space>
            <Button
              className="btn-lg btn-default save-btn mt-30"
              type="default"
              htmlType="button"
              onClick={goBack}
            >
              Previous
            </Button>
            <Button className="btn-lg save-btn mt-30" type="primary" htmlType="submit">
              Save & Finish
            </Button>
          </Space>
        </div>
      </Form>
    </>
  );
}

export default Documents;
