import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button, Card, Layout, message } from 'antd';
import { createContact, fetchContacts, removeContacts, updateContact } from '../../services';
import ContactList from './ContactList';
import CreateUpdateContact from './CreateUpdateContact';

const { Content } = Layout;

const Contacts = () => {
    const { user } = useSelector(({ auth }) => auth);
    const [submitting, setSubmitting] = useState(false);
    const [action, setAction] = useState({ visible: false, data: null });
    const [contacts, setContacts] = useState({
        data: [],
        total: 0,
        fetching: false,
    });
    const [pagination, setPagination] = useState({ page: 1, perPage: 10 });

    useEffect(() => {
        getContacts();
    }, []);

    const getContacts = async (params = {}) => {
        try {
            if (!isEmpty(params)) {
                setPagination({ page: params?.page, pageSize: params?.pageSize });
            }
            setContacts({ ...contacts, fetching: true });
            const response = await fetchContacts({
                ...params,
                page: params?.page || pagination.page,
                pageSize: params?.pageSize || pagination.pageSize,
            });
            setContacts({
                fetching: false,
                data: response.data,
                total: response.meta.total_count,
            });
        } catch (error) {
            console.log(error);
            setContacts({ ...contacts, fetching: false });
        }
    };

    const deleteContacts = async (contactId) => {
        try {
            setContacts({ ...contacts, fetching: true });
            const response = await removeContacts([contactId]);
            message.success(response.message);
            await getContacts();
        } catch (error) {
            console.log(error);
            setContacts({ ...contacts, fetching: false });
        }
    };

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            let response = null;
            if (action.data) {
                delete values.organization_name;
                delete values.branch;
                response = await updateContact(action.data.id, values);
            } else {
                values.append('branch', user.branch);
                response = await createContact(values);
            }
            message.success(response.message);
            setAction({ visible: false, data: null });
            setSubmitting(false);
            await getContacts();
        } catch (error) {
            setSubmitting(false);
            console.log(error);
        }
    };

    const actionHandler = async (visible = false, data = null, type = 'create') => {
        if (type === 'delete') {
            await deleteContacts(data.id);
        } else if (type === 'create') {
            setAction({ visible, data: null });
        } else {
            setAction({ visible, data });
        }
    };

    const cardAction = (
        <Button type="primary" onClick={() => actionHandler(true)}>
            Create New
        </Button>
    );

    return (
        <Content>
            <Card title="My Contacts" extra={cardAction}>
                <ContactList
                    contacts={contacts}
                    getContacts={getContacts}
                    actionHandler={actionHandler}
                />
            </Card>
            {action.visible && (
                <CreateUpdateContact
                    data={action.data}
                    onSubmit={onSubmit}
                    submitting={submitting}
                    visible={action.visible}
                    onCancel={() => setAction(false)}
                />
            )}
        </Content>
    );
};
export default Contacts;
