import _ from 'lodash';
import moment from 'moment';
import { Tooltip, Space, Switch, Tag } from 'antd';
import {
    ExpandOutlined,
    DeleteTwoTone,
    EditOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import {
    DISPLAY_DATE_FORMAT,
    DEFINITIONS,
    SERVER_DATE_FORMAT,
    DATE_STATUSES,
} from '../../../../../utils';

const renderDate = (value) => {
    if (value) {
        return moment(value, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    }
    return '-';
};

export const dateViewColumns = ({
    setModalType,
    toggleDeleteModal,
    setDeletingRecord,
    setRecord,
}) => [
    {
        title: 'Ref #',
        dataIndex: 'reference_number',
        width: 100,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Line #',
        dataIndex: 'line_number',
        width: 140,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: (
            <div>
                <Tooltip placement="bottom" title={DEFINITIONS.TAG_NAME}>
                    Tag Name*
                    <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
                </Tooltip>
            </div>
        ),
        dataIndex: 'name',
        render: (data, record) => (
            <div className="description-td-edit">
                <Tooltip placement="topLeft" title={data}>
                    <div className="description-td-edit">{data}</div>
                </Tooltip>
            </div>
        ),
        sorter: true,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Description',
        dataIndex: 'description',
        render: (data, record) => (
            <Tooltip placement="topLeft" title={record.description}>
                <div className="description-td-edit">{record.description}</div>
            </Tooltip>
        ),
        sorter: true,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        align: 'center',
        render: (data, record) => {
            if (record?.quantity) {
                return (
                    <Tooltip placement="topLeft" title={record.quantity}>
                        <div className="description-td-edit">{record.quantity}</div>
                    </Tooltip>
                );
            }
            return '-';
        },
    },
    // {
    //     title: 'Approval',
    //     dataIndex: 'approval_dwg',
    //     width: 100,
    //     align: 'center',
    //     render: (status) => ({
    //         props: {
    //             className: 'approval-design-td',
    //         },
    //         children: (
    //             <Tag
    //                 className="custom-tag"
    //                 style={!status ? { color: '#555' } : {}}
    //                 color={status ? '#1dbf73' : '#eee'}
    //             >
    //                 {status ? 'Yes' : 'No'}
    //             </Tag>
    //         ),
    //     }),
    // },
    {
        title: 'Manual Schedule',
        dataIndex: 'is_manual_tracking',
        width: 150,
        align: 'center',
        render: (status, record) => ({
            props: {
                className: 'approval-design-td',
            },
            children: (
                // <Switch
                //   checked={status}
                //   checkedChildren="Yes"
                //   unCheckedChildren="No"
                //   disabled
                // />
                <Tag
                    className="custom-tag"
                    style={!status ? { color: '#555' } : {}}
                    color={status ? '#1dbf73' : '#eee'}
                >
                    {status ? 'Yes' : 'No'}
                </Tag>
            ),
        }),
    },
    {
        title: 'Order Entry',
        dataIndex: 'order_date',
        render: renderDate,
    },
    {
        title: 'Planned Ship',
        dataIndex: 'ship_date',
        render: renderDate,
    },
    {
        title: 'Actual Ship',
        dataIndex: 'actual_ship_date',
        render: renderDate,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   render: (value, record) => {
    //     const filteredItem = DATE_STATUSES.filter((status) => status.value === record.status);

    //     return <p>{filteredItem.length ? filteredItem[0]?.label : 'NA'}</p>;
    //   },
    // },
    {
        title: 'Action',
        dataIndex: '',
        render: (_, record) => (
            <Space>
                <Tooltip title="View">
                    <ExpandOutlined
                        onClick={() => {
                            setModalType('view');
                            setRecord(record);
                        }}
                    />
                </Tooltip>
                <Tooltip title="Edit">
                    <EditOutlined
                        onClick={() => {
                            setModalType('update');
                            setRecord(record);
                        }}
                    />
                </Tooltip>
                <Tooltip title="Delete">
                    <DeleteTwoTone
                        twoToneColor="#f56666"
                        onClick={() => {
                            setModalType('delete');
                            setDeletingRecord(record);
                            toggleDeleteModal();
                        }}
                    />
                </Tooltip>
            </Space>
        ),
    },
];
