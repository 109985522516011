import moment from 'moment';

export const EQUIPMENT_PROGRESS_SERIES = [
  {
    name: 'Original Commitment',
    data: [
      { x: ['Original', 'Commitment'], y: ['2022-1-1', '2022-6-30'] },
      { x: 'Alectify Estimate', y: ['2022-1-1', '2022-6-15'] },
      { x: 'Actual', y: ['2022-1-1', '2022-4-30'] },
    ],
  },
  {
    name: 'Alectify Estimate',
    data: [{ x: 'Alectify Estimate', y: ['2022-1-1', '2022-6-15'] }],
  },
  {
    name: 'Actual',
    data: [{ x: 'Actual', y: ['2022-1-1', '2022-4-30'] }],
  },
  {
    name: 'Predicted',
    data: [{ x: 'Actual', y: ['2022-4-30', '2022-10-15'] }],
  },
];

export const EQUIPMENT_PROGRESS_OPTIONS = {
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '43%',
      rangeBarGroupRows: true,
    },
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1, 3, 5, 7, 9],
    formatter(val, val1, val2) {
      if (val1.seriesIndex === 3) {
        return 'Predicted';
      }
      const a = moment(val[0]);
      const b = moment(val[1]);
      const diff = b.diff(a, 'days');
      // return '+' + diff; //+ (diff > 1 ? ' days' : ' day')
      return ''; // + (diff > 1 ? ' days' : ' day')
    },
    textAnchor: 'middle',
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '12px',
      // fontFamily: 'Helvetica, Arial, sans-serif',
      // fontWeight: 600,
      colors: ['#ffffff'],
    },
    // background: {
    //   enabled: true,
    //   foreColor: '#fff',
    //   padding: 4,
    //   borderRadius: 2,
    //   borderWidth: 1,
    //   borderColor: '#fff',
    //   opacity: 0.9,
    //   dropShadow: {
    //     enabled: false,
    //     top: 1,
    //     left: 1,
    //     blur: 1,
    //     color: '#000',
    //     opacity: 0.45
    //   }
    // },
    // dropShadow: {
    //     enabled: false,
    //     top: 1,
    //     left: 1,
    //     blur: 1,
    //     color: '#000',
    //     opacity: 0.45,
    // },
  },

  // theme: {
  //     palette: 'palette1' // upto palette10
  // },
  colors: [
    '#008FFB',
    '#005799', // "#005799", //"#cce9ff",
    '#00E396',
    '#ef7800', // "#99ffdd",
    '#FEB019',
    '#b27701',
    '#FF4560',
    '#ff1a3c',
    '#775DD0',
    '#5a39c6',

    '#005582',
    '#0086ad',
    '#00c2c7',
    '#97ebdb',
    '#daf8e3',

    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#004c4c',
    '#006666',
    '#008080',
    '#66b2b2',
    '#b2d8d8',
    '#003f5c',
    '#00E396',
    '#008FFB',
    '#FEB019',
    '#bc5090',
    '#ff6361',
    '#ffa600',
    '#58508d',

    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#3F51B5',
    '#546E7A',
    '#D4526E',
    '#8D5B4C',
    '#F86624',
    '#D7263D',
    '#1B998B',
    '#2E294E',
    '#F46036',
    '#E2C044',
  ],
  // fill: {
  //     type: 'gradient',
  //     gradient: {
  //         shade: 'light',
  //         type: 'diagonal',
  //         shadeIntensity: 0.01,
  //         gradientToColors: undefined,
  //         inverseColors: false,
  //         opacityFrom: 0.7,
  //         opacityTo: 0.9,
  //         // stops: [50, 0, 100, 100]
  //     },
  //     // type: 'pattern',
  //     // pattern: {
  //     //   style: 'slantedLines',
  //     //   width: 6,
  //     //   height: 6,
  //     //   strokeWidth: 1
  //     // }
  // },
  dropShadow: {
    enabled: true,
    top: 0,
    left: 0,
    blur: 2,
    opacity: 0.5,
  },

  grid: {
    show: true,
    borderColor: '#DEE2E6', // '#90A4AE'
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: undefined,
      opacity: 0.1,
    },
    column: {
      colors: undefined,
      opacity: 0.1,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  xaxis: {
    type: 'datetime',
    min: undefined,
    labels: {
      show: true,
      rotate: -45,
      rotateAlways: false,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      format: undefined,
      formatter: undefined,
      datetimeUTC: true,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    },
    lines: {
      show: true,
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    axisBorder: {
      show: true,
      color: '#78909C',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0,
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 0.9,
      stroke: {
        color: '#b6b6b6',
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: 'solid',
        color: '#B1B9C4',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
    tooltip: {
      enabled: true,
      formatter: undefined,
      offsetY: 0,
      style: {
        fontSize: 0,
        fontFamily: 0,
      },
    },
  },
  yaxis: {
    lines: {
      show: true,
    },
    labels: {
      show: true,
      rotate: 0,
      rotateAlways: false,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      format: undefined,
      formatter: undefined,
      datetimeUTC: true,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    },
    title: {
      show: false,
      text: '',
      rotate: -90,
      offsetX: -1,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 600,
        cssClass: 'apexcharts-yaxis-title',
      },
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    axisBorder: {
      show: true,
      color: '#78909C',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0,
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 0.9,
      stroke: {
        color: '#b6b6b6',
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: 'solid',
        color: 'orange',
        // gradient: {
        //     colorFrom: '#D8E3F0',
        //     colorTo: '#BED1E6',
        //     stops: [0, 100],
        //     opacityFrom: 0.4,
        //     opacityTo: 0.5,
        // },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
  },
  annotations: {
    // yaxis: [{
    //     y: 30,
    //     borderColor: '#999',
    //     label: {
    //         show: true,
    //         text: 'Support',
    //         style: {
    //             color: "#fff",
    //             background: '#00E396'
    //         }
    //     }
    // }],
    xaxis: [
      {
        x: new Date().getTime(), // new Date().getTime()
        borderColor: '#999',
        yAxisIndex: 0,
        label: {
          show: true,
          text: "Today's Date",
          style: {
            color: '#fff',
            background: '#775DD0',
          },
        },
      },
    ],
  },

  // title: {
  //     text: 'Approval Process Gantt Chart',
  //     align: 'center',
  //     margin: 5,
  //     offsetX: 0,
  //     offsetY: 0,
  //     floating: false,
  //     style: {
  //         fontSize: '16px',
  //         fontWeight: 'bold',
  //         fontFamily: undefined,
  //         color: '#263238',
  //     },
  // },

  chart: {
    background: '#fff',
    // fontFamily: 'Helvetica, Arial, sans-serif',
    foreColor: '#373d3f',
  },

  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'bottom',
    horizontalAlign: 'center',
    floating: false,
    fontSize: '14px',
    color: '#555',
    // fontFamily: 'Helvetica, Arial',
    fontWeight: 400,
    formatter: undefined,
    inverseOrder: false,
    width: undefined,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: -15,
    offsetY: 0,
    labels: {
      colors: undefined,
      useSeriesColors: false,
    },
    markers: {
      width: 10,
      height: 10,
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: undefined,
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: -3,
      offsetY: 0,
    },
    itemMargin: {
      horizontal: 15,
      vertical: 4,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  // tooltip: {
  //     custom: function (opts) {
  //         const fromYear = new Date(opts.y1).getFullYear()
  //         const toYear = new Date(opts.y2).getFullYear()
  //         const values = opts.ctx.rangeBar.getTooltipValues(opts)

  //         return (
  //             ''
  //         )
  //     }
  // }
};

export const PROJECT_GANT_SERIES = [
  {
    name: 'Approval',
    popupHeading: 'Order Entry',
    data: [
      {
        x: 'MV-SWGR',
        // y: ['06-May-22', '01-Jun-22'],
        y: ['2022-05-06', '2022-06-01'],
        // orginal: '04-May-2022',
        orginal: '2022-05-04',
        // actual: '06-May-2022',
        actual: '2022-05-06',
      },
      {
        x: 'LV-SWGR',
        // y: ['01-May-22', '15-May-22'],
        y: ['2022-05-01', '2022-05-15'],
        // orginal: '01-May-2022',
        orginal: '2022-05-01',
        // actual: '01-May-2022',
        actual: '2022-05-01',
      },
      {
        x: 'TX-1',
        // y: ['07-May-22', '28-May-22'],
        y: ['2022-05-07', '2022-05-28'],
        // orginal: '04-May-2022',
        orginal: '2022-05-04',
        // actual: '07-May-2022',
        actual: '2022-05-07',
      },
    ],
  },
  {
    name: 'Review',
    popupHeading: 'Approval Drawings',
    data: [
      {
        x: 'MV-SWGR',
        // y: ['01-Jun-22', '17-Jun-22'],
        y: ['2022-06-01', '2022-06-17'],
        // orginal: '30-May-2022',
        orginal: '2022-05-30',
        // actual: '01-Jun-2022',
        actual: '2022-06-01',
      },
      {
        x: 'LV-SWGR',
        // y: ['15-May-22', '10-Jun-22'],
        y: ['2022-05-15', '2022-06-10'],
        // orginal: '15-May-2022',
        orginal: '2022-05-15',
        // actual: '15-May-2022',
        actual: '2022-05-15',
      },
      {
        x: 'TX-1',
        // y: ['28-May-22', '22-Jun-22'],
        y: ['2022-05-28', '2022-06-22'],
        // orginal: '25-May-2022',
        orginal: '2022-05-25',
        // actual: '28-May-2022',
        actual: '2022-05-28',
      },
    ],
  },
  {
    name: 'Manufacturing',
    popupHeading: 'Release for Manufacturing',
    data: [
      {
        x: 'MV-SWGR',
        // y: ['17-Jun-22', '02-Aug-22'],
        y: ['2022-06-17', '2022-08-02'],
        // orginal: '15-Jun-2022',
        orginal: '2022-06-15',
        // actual: '17-Jun-2022',
        actual: '2022-06-17',
      },
      {
        x: 'LV-SWGR',
        // y: ['10-Jun-22', '25-Jul-22'],
        y: ['2022-06-10', '2022-07-25'],
        // orginal: '10-Jun-2022',
        orginal: '2022-06-10',
        // actual: '10-Jun-2022',
        actual: '2022-06-10',
      },
      {
        x: 'TX-1',
        // y: ['22-Jun-22', '13-Aug-22'],
        y: ['2022-06-22', '2022-08-13'],
        // orginal: '19-Jun-2022',
        orginal: '2022-06-19',
        // actual: '22-Jun-2022',
        actual: '2022-06-22',
      },
    ],
  },
  {
    name: 'Shipment',
    popupHeading: 'Shipment',
    data: [
      {
        x: 'MV-SWGR',
        // y: ['02-Aug-22', '03-Aug-22'],
        y: ['2022-08-02', '2022-08-03'],
        // orginal: '31-Jul-2022',
        orginal: '2022-07-31',
        // actual: '02-Aug-2022',
        actual: '2022-08-02',
      },
      {
        x: 'LV-SWGR',
        // y: ['25-Jul-22', '26-Jul-22'],
        y: ['2022-07-25', '2022-07-26'],
        // orginal: '25-Jul-2022',
        orginal: '2022-07-25',
        // actual: '25-Jul-2022',
        actual: '2022-07-25',
      },
      {
        x: 'TX-1',
        // y: ['13-Aug-22', '14-Aug-22'],
        y: ['2022-08-13', '2022-08-14'],
        // orginal: '10-Aug-2022',
        orginal: '2022-08-10',
        // actual: '13-Aug-2022',
        actual: '2022-08-13',
      },
    ],
  },
];

const getGanttBarHeight = (items) => {
  if (items === 1) {
    return '8%';
  }

  if (items === 2) {
    return '20%';
  }

  if (items === 3) {
    return '30%';
  }

  if (items === 4) {
    return '40%';
  }

  if (items > 4) {
    return '60%';
  }
};

export const projectGantOptions = (gantSeries, gantSeriesNames, chartType) => ({
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: getGanttBarHeight(gantSeriesNames.length),
      rangeBarGroupRows: true,
    },
  },
  dataLabels: {
    enabled: true,
    // enabledOnSeries: [1, 3, 5, 7, 9],
    formatter(val, record) {
      const heading = PROJECT_GANT_SERIES[record.seriesIndex].name;
      const a = moment(val[0]);
      const b = moment(val[1]);
      const diff = b.diff(a, 'days');
      return `${heading} - ${diff} days`;
    },
    textAnchor: 'middle',

    distributed: false,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '12px',
      // fontFamily: 'Helvetica, Arial, sans-serif',
      // fontWeight: 600,
      colors: ['#ffffff'],
    },
    // background: {
    //   enabled: true,
    //   foreColor: '#fff',
    //   padding: 4,
    //   borderRadius: 2,
    //   borderWidth: 1,
    //   borderColor: '#fff',
    //   opacity: 0.9,
    //   dropShadow: {
    //     enabled: false,
    //     top: 1,
    //     left: 1,
    //     blur: 1,
    //     color: '#000',
    //     opacity: 0.45
    //   }
    // },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0.45,
    },
  },

  // theme: {
  //     palette: 'palette1' // upto palette10
  // },
  colors: ['#003566', '#fca311', '#119da4', '#FF4560'],
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'diagonal',
      shadeIntensity: 0.01,
      gradientToColors: undefined,
      inverseColors: false,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      // stops: [50, 0, 100, 100]
    },
    // type: 'pattern',
    // pattern: {
    //   style: 'slantedLines',
    //   width: 6,
    //   height: 6,
    //   strokeWidth: 1
    // }
  },
  dropShadow: {
    enabled: true,
    top: 0,
    left: 0,
    blur: 2,
    opacity: 0.5,
  },

  grid: {
    show: true,
    borderColor: '#DEE2E6', // '#90A4AE'
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: undefined,
      opacity: 0.1,
    },
    column: {
      colors: undefined,
      opacity: 0.1,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  xaxis: {
    type: 'datetime',
    min: undefined,
    labels: {
      show: true,
      rotate: -45,
      rotateAlways: false,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      format: undefined,
      formatter: undefined,
      datetimeUTC: true,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    },
    lines: {
      show: true,
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    axisBorder: {
      show: true,
      color: '#78909C',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0,
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 0.9,
      stroke: {
        color: '#b6b6b6',
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: 'solid',
        color: '#B1B9C4',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
    tooltip: {
      enabled: true,
      formatter: undefined,
      offsetY: 0,
      style: {
        fontSize: 0,
        fontFamily: 0,
      },
    },
  },
  yaxis: {
    lines: {
      show: true,
    },
    labels: {
      show: true,
      rotate: 0,
      rotateAlways: false,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      format: undefined,
      formatter: undefined,
      datetimeUTC: true,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    },
    title: {
      enabled: false,
      show: false,
      text: 'Equipments',
      rotate: -90,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#fff',
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 600,
        cssClass: 'apexcharts-yaxis-title',
      },
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    axisBorder: {
      show: true,
      color: '#78909C',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0,
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 0.9,
      stroke: {
        color: '#b6b6b6',
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: 'solid',
        color: '#B1B9C4',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
  },
  annotations: {
    // yaxis: [{
    //     y: 30,
    //     borderColor: '#999',
    //     label: {
    //         show: true,
    //         text: 'Support',
    //         style: {
    //             color: "#fff",
    //             background: '#00E396'
    //         }
    //     }
    // }],
    xaxis: [
      {
        x: new Date().getTime(),
        borderColor: '#999',
        yAxisIndex: 0,
        label: {
          show: true,
          text: "Today's Date",
          style: {
            color: '#fff',
            background: '#775DD0',
          },
        },
      },
    ],
  },

  // title: {
  //     text: 'Approval Process Gantt Chart',
  //     align: 'center',
  //     margin: 5,
  //     offsetX: 0,
  //     offsetY: 0,
  //     floating: false,
  //     style: {
  //         fontSize: '16px',
  //         fontWeight: 'bold',
  //         fontFamily: undefined,
  //         color: '#263238',
  //     },
  // },

  chart: {
    background: '#fff',
    group: 'estimations',
    // fontFamily: 'Helvetica, Arial, sans-serif',
    foreColor: '#373d3f',
  },

  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'bottom',
    horizontalAlign: 'center',
    floating: false,
    fontSize: '13px',
    // fontFamily: 'Helvetica, Arial',
    fontWeight: 400,
    formatter: undefined,
    inverseOrder: false,
    width: undefined,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: undefined,
      useSeriesColors: false,
    },
    markers: {
      width: 10,
      height: 10,
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: undefined,
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    itemMargin: {
      horizontal: 20,
      vertical: 4,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  tooltip: {
    custom(opts) {
      const actual = gantSeries[opts.seriesIndex].data[opts.dataPointIndex]?.y[1] || '';
      const original = gantSeries[opts.seriesIndex].data[opts.dataPointIndex]?.y[0] || '';
      const original_date = original ? moment(original) : '';
      const actual_date = actual ? moment(actual) : '';

      const diff = original_date && actual_date ? actual_date.diff(original_date, 'days') : '';
      let html = '';
      html += '<div class="gant-hover-container">';
      html += `<h4><b>${gantSeries[opts.seriesIndex].name}</b></h4>`;
      html += '<div class="label-container">';
      html += '<label>Start: </label>';
      html += `<span>${moment(original).format('MMM DD, YYYY')}</span>`;
      html += '</div>';
      html += '<div class="label-container">';
      html += '<label>End: </label>';
      // html +=
      //     '<label>' +
      //     (new Date(actual).getTime() > new Date().getTime() ? 'Estimate: ' : 'Actual') +
      //     '</label>';
      html += `<span>${moment(actual).format('MMM DD, YYYY')}</span>`;
      html += '</div>';

      if (chartType.toLowerCase() === 'actual') {
        html += '<div class="label-container">';
        if (diff) {
          html += '<label style="color:red">Status: </label>';
          html += `<span style="color:red">Delayed by ${diff} days</span>`;
        } else {
          html += '<label style="color:green">Status: </label>';
          html += '<span style="color:green">On time</span>';
        }
        html += '</div>';
      }

      html += '</div>';

      return html;
    },
  },
});

export const completeGantOptions = (gantSeries, gantSeriesNames) => ({
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: getGanttBarHeight(gantSeriesNames.length),
      rangeBarGroupRows: true,
    },
  },
  dataLabels: {
    enabled: true,
    // enabledOnSeries: [1, 3, 5, 7, 9],
    formatter(val, record) {
      const heading = PROJECT_GANT_SERIES[record.seriesIndex].name;
      const a = moment(val[0]);
      const b = moment(val[1]);
      const diff = b.diff(a, 'days');
      return `${heading} - ${diff} days`;
    },
    textAnchor: 'middle',

    distributed: false,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '12px',
      // fontFamily: 'Helvetica, Arial, sans-serif',
      // fontWeight: 600,
      colors: ['#ffffff'],
    },
    // background: {
    //   enabled: true,
    //   foreColor: '#fff',
    //   padding: 4,
    //   borderRadius: 2,
    //   borderWidth: 1,
    //   borderColor: '#fff',
    //   opacity: 0.9,
    //   dropShadow: {
    //     enabled: false,
    //     top: 1,
    //     left: 1,
    //     blur: 1,
    //     color: '#000',
    //     opacity: 0.45
    //   }
    // },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0.45,
    },
  },

  // theme: {
  //     palette: 'palette1' // upto palette10
  // },
  colors: ['#003566', '#fca311', '#119da4', '#FF4560'],
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'diagonal',
      shadeIntensity: 0.01,
      gradientToColors: undefined,
      inverseColors: false,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      // stops: [50, 0, 100, 100]
    },
    // type: 'pattern',
    // pattern: {
    //   style: 'slantedLines',
    //   width: 6,
    //   height: 6,
    //   strokeWidth: 1
    // }
  },
  dropShadow: {
    enabled: true,
    top: 0,
    left: 0,
    blur: 2,
    opacity: 0.5,
  },

  grid: {
    show: true,
    borderColor: '#DEE2E6', // '#90A4AE'
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: undefined,
      opacity: 0.1,
    },
    column: {
      colors: undefined,
      opacity: 0.1,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  xaxis: {
    type: 'datetime',
    min: undefined,
    labels: {
      show: true,
      rotate: -45,
      rotateAlways: false,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      format: undefined,
      formatter: undefined,
      datetimeUTC: true,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    },
    lines: {
      show: true,
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    axisBorder: {
      show: true,
      color: '#78909C',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0,
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 0.9,
      stroke: {
        color: '#b6b6b6',
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: 'solid',
        color: '#B1B9C4',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
    tooltip: {
      enabled: true,
      formatter: undefined,
      offsetY: 0,
      style: {
        fontSize: 0,
        fontFamily: 0,
      },
    },
  },
  yaxis: {
    lines: {
      show: true,
    },
    labels: {
      show: true,
      rotate: 0,
      rotateAlways: false,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      format: undefined,
      formatter: undefined,
      datetimeUTC: true,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    },
    title: {
      enabled: false,
      show: false,
      text: 'Equipments',
      rotate: -90,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#fff',
        fontSize: '12px',
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 600,
        cssClass: 'apexcharts-yaxis-title',
      },
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    axisBorder: {
      show: true,
      color: '#78909C',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0,
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 0.9,
      stroke: {
        color: '#b6b6b6',
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: 'solid',
        color: '#B1B9C4',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
  },
  annotations: {
    // yaxis: [{
    //     y: 30,
    //     borderColor: '#999',
    //     label: {
    //         show: true,
    //         text: 'Support',
    //         style: {
    //             color: "#fff",
    //             background: '#00E396'
    //         }
    //     }
    // }],
    xaxis: [
      {
        x: new Date().getTime(),
        borderColor: '#999',
        yAxisIndex: 0,
        label: {
          show: true,
          text: "Today's Date",
          style: {
            color: '#fff',
            background: '#775DD0',
          },
        },
      },
    ],
  },

  // title: {
  //     text: 'Approval Process Gantt Chart',
  //     align: 'center',
  //     margin: 5,
  //     offsetX: 0,
  //     offsetY: 0,
  //     floating: false,
  //     style: {
  //         fontSize: '16px',
  //         fontWeight: 'bold',
  //         fontFamily: undefined,
  //         color: '#263238',
  //     },
  // },

  chart: {
    background: '#fff',
    group: 'estimations',
    // fontFamily: 'Helvetica, Arial, sans-serif',
    foreColor: '#373d3f',
  },

  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'bottom',
    horizontalAlign: 'center',
    floating: false,
    fontSize: '13px',
    // fontFamily: 'Helvetica, Arial',
    fontWeight: 400,
    formatter: undefined,
    inverseOrder: false,
    width: undefined,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: undefined,
      useSeriesColors: false,
    },
    markers: {
      width: 10,
      height: 10,
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: undefined,
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    itemMargin: {
      horizontal: 20,
      vertical: 4,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  tooltip: {
    custom(opts) {
      const actual = gantSeries[opts.seriesIndex].data[opts.dataPointIndex]?.actual || '';
      const original = gantSeries[opts.seriesIndex].data[opts.dataPointIndex]?.orginal || '';
      const original_date = original ? moment(original) : '';
      const actual_date = actual ? moment(actual) : '';
      const diff = original_date && actual_date ? actual_date.diff(original_date, 'days') : '';
      let html = '';
      html += '<div class="gant-hover-container">';
      html += `<h4><b>${gantSeries[opts.seriesIndex].popupHeading}</b></h4>`;
      html += '<div class="label-container">';
      html += '<label>Original: </label>';
      html += `<span>${moment(original).format('MMM DD, YYYY')}</span>`;
      html += '</div>';
      html += '<div class="label-container">';
      html
                += `<label>${
          new Date(actual).getTime() > new Date().getTime() ? 'Estimate: ' : 'Actual'
        }</label>`;
      html += `<span>${moment(actual).format('MMM DD, YYYY')}</span>`;
      html += '</div>';
      html += '<div class="label-container">';
      if (diff) {
        html += '<label style="color:red">Status: </label>';
        html += `<span style="color:red">Delayed by ${diff} days</span>`;
      } else {
        html += '<label style="color:green">Status: </label>';
        html += '<span style="color:green">On time</span>';
      }
      html += '</div>';
      html += '</div>';

      return html;
    },
  },
});
