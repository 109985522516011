import { http } from '../utils/http';

export const fetchDashboardDetails = async (companyId) => {
  try {
    const response = await http.get(`/admin/companies/${companyId}/insights/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchSentiments = async (companyId, params) => {
  try {
    const response = await http.get(`chat/sentiments-count/company/${companyId}/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchSentimentsDetail = async (companyId, params) => {
  try {
    const response = await http.get(`chat/sentiments/company/${companyId}/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
