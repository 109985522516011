import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlectifyTable } from '../../../../../../components/table';
import { getProjectCosts, removeProjectCosts } from '../../../../../../store/billing';
import useColumns from './effects/useColumns';
import EditProjectCost from './EditProjectCost';
import ProjectCostDetail from './ProjectCostDetail';

const ProjectCostList = memo(() => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { projectCosts } = useSelector(({ billings }) => billings);
    const [projectCostAction, setProjectCostAction] = useState({
        data: null,
        edit: false,
        view: false,
    });

    const getAllProjectCosts = (page = 1, per_page = 10) => {
        const params = {
            page,
            per_page,
        };
        dispatch(getProjectCosts(projectId, params));
    };

    useEffect(() => {
        getAllProjectCosts();
    }, [projectId]);

    const onActionClick = (type, projectCost) => {
        setProjectCostAction({ ...projectCostAction, [type]: true, data: projectCost });
    };

    const columns = useColumns(onActionClick);

    return (
        <>
            <AlectifyTable
                columns={columns}
                defaultPageSize={10}
                total={projectCosts.total}
                multipleDeleteOption
                dataSource={projectCosts.data}
                loading={projectCosts.fetching}
                onMultipleDelete={(ids) => dispatch(removeProjectCosts(projectId, ids))}
                onDataFetch={({ page, per_page }) => getAllProjectCosts(page, per_page)}
            />
            {projectCostAction.edit && (
                <EditProjectCost
                    data={projectCostAction.data}
                    visible={projectCostAction.edit}
                    onCancel={() => setProjectCostAction({ edit: false, view: false, data: null })}
                />
            )}
            {projectCostAction.view && (
                <ProjectCostDetail
                    data={projectCostAction.data}
                    visible={projectCostAction.view}
                    onCancel={() => setProjectCostAction({ edit: false, view: false, data: null })}
                />
            )}
        </>
    );
});

export default ProjectCostList;
