import { Card, Row, Col, Button, message, Spin } from 'antd';
import VendorQuotesList from './VendorQuotesList';
import VendorsQuoteSummary from './VendorQuotesSummary';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    cloneContractorFinancialsFromProject,
    deleteContractorVendorEstimations,
    fetchContractorsVendorQuotes,
    fetchContractorsVendorsSummary,
} from '../../../../../../services/bidding-management';
import VendorQuotesAutoCreation from './VendorQuotesAutoCreation';
import ConfirmationPopup from './ConfirmationPopup';

const VendorQoutes = () => {
    const [loading, setLoader] = useState(false);
    const { contractorId } = useParams();
    const [summary, setSummary] = useState({ fetching: false, data: [] });
    const [vendorQuotes, setVendorQuotes] = useState({ fetching: false, data: [], total: 0 });
    const [autoCreateModal, setAutoCreateModal] = useState(false);
    const [confirmationPopup, setConfirmationPopup] = useState(false);

    const [action, setAction] = useState({
        data: null,
        visible: false,
        detail: false,
        edit: false,
    });

    const getVendorsSummary = async () => {
        try {
            setSummary({ ...summary, fetching: true });
            const response = await fetchContractorsVendorsSummary(contractorId);
            setSummary({ ...summary, fetching: false, data: response?.data });
        } catch (error) {
            setSummary({ ...summary, fetching: false });
        }
    };

    useEffect(() => {
        getVendorsSummary();
        getVendorslist();
    }, [contractorId]);

    const autoCreate = async () => {
        setLoader(true);
        try {
            await cloneContractorFinancialsFromProject(contractorId);
            message.success('Financials details has been created successfully.');
            getVendorsSummary();
            getVendorslist();
            setLoader(false);
            setConfirmationPopup(false);
        } catch (ex) {
            setLoader(false);
        }
    };

    const onActionClick = (action, vendorItem) => {
        setAction({ ...action, data: vendorItem, [action]: true });
    };

    const getVendorslist = async (pagination) => {
        try {
            const params = {
                page: pagination?.page || 1,
                per_page: pagination?.per_page || 10,
            };
            setVendorQuotes({ ...vendorQuotes, fetching: true });
            const response = await fetchContractorsVendorQuotes(contractorId, params);
            setVendorQuotes({
                ...vendorQuotes,
                fetching: false,
                data: response?.data,
                total: response?.meta?.total_count,
            });
        } catch (error) {
            setVendorQuotes({ ...vendorQuotes, fetching: false });
        }
    };

    const deleteEstimations = async (ids) => {
        setLoader(true);
        try {
            await deleteContractorVendorEstimations(ids, contractorId);
            setLoader(false);
            getVendorsSummary();
            getVendorslist();
            message.success('Selected vendor quotes have been deleted successfully');
        } catch (ex) {
            setLoader(false);
            message.success(ex.response?.data?.message || 'Something unexpected happened');
        }
    };

    const onAutoCreateModalClose = (changed) => {
        if (changed) {
            setAutoCreateModal(false);
            getVendorsSummary();
            getVendorslist();
            setConfirmationPopup(false);
        } else {
            setAutoCreateModal(false);
            setConfirmationPopup(false);
        }
    };
    return (
        <>
            <Spin spinning={loading}>
                <Row justify={'end'}>
                    <Col>
                        <Button
                            type="primary"
                            className="mb-10 justify-end"
                            onClick={() => setConfirmationPopup(true)}
                        >
                            Import Vendor Quotes from Master
                        </Button>
                    </Col>
                </Row>
                <Card title="Project Summary Tally Sheet" className="mb-30">
                    <VendorsQuoteSummary summary={summary} />
                    <VendorQuotesList
                        onActionClick={onActionClick}
                        setAction={setAction}
                        vendorQuotes={vendorQuotes}
                        action={action}
                        getVendorslist={getVendorslist}
                        deleteEstimations={deleteEstimations}
                        getVendorsSummary={getVendorsSummary}
                    />
                </Card>
            </Spin>
            <VendorQuotesAutoCreation visible={autoCreateModal} onCancel={onAutoCreateModalClose} />
            <ConfirmationPopup
                loader={loading}
                visible={confirmationPopup}
                onCancel={() => setConfirmationPopup(false)}
                onCopyPartially={() => setAutoCreateModal(true)}
                onCopyAll={autoCreate}
            />
        </>
    );
};

export default VendorQoutes;
