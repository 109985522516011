import { RESET_STATE } from '../common';
import * as Action from './types';

const initialState = {
  projectCosts: {
    data: null,
    total: 0,
    fetching: false,
  },
  customerPurchaseOrders: {
    data: null,
    total: 0,
    fetching: false,
  },
  projectCostsSummary: {
    data: null,
    fetching: false,
  },
  customerPurchaseOrdersSummary: {
    data: null,
    fetching: false,
  },
};

export const billingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Action.FETCH_PROJECT_COSTS_INITIATE:
    case Action.DELETE_PROJECT_COSTS_INITIATE:
      return { ...state, projectCosts: { ...state.projectCosts, fetching: true } };

    case Action.FETCH_PROJECT_COSTS_SUCCESS:
      return {
        ...state,
        projectCosts: {
          ...state.projectCosts,
          fetching: false,
          data: payload?.data,
          total: payload?.meta?.total_count,
        },
      };

    case Action.FETCH_PROJECT_COSTS_FAILURE:
      return {
        ...state,
        projectCosts: {
          ...state.projectCosts, fetching: false, data: null, total: 0,
        },
      };

    case Action.FETCH_CUSTOMER_PO_INITIATE:
    case Action.DELETE_CUSTOMER_PO_INITIATE:
      return {
        ...state,
        customerPurchaseOrders: { ...state.customerPurchaseOrders, fetching: true },
      };

    case Action.FETCH_CUSTOMER_PO_SUCCESS:
      return {
        ...state,
        customerPurchaseOrders: {
          ...state.customerPurchaseOrders,
          fetching: false,
          data: payload?.data,
          total: payload?.meta?.total_count,
        },
      };

    case Action.FETCH_CUSTOMER_PO_FAILURE:
      return {
        ...state,
        customerPurchaseOrders: {
          ...state.customerPurchaseOrders,
          fetching: false,
          data: null,
        },
      };

    case Action.FETCH_CUSTOMER_PO_SUMMARY_INITIATE:
      return {
        ...state,
        customerPurchaseOrdersSummary: {
          ...state.customerPurchaseOrdersSummary,
          fetching: true,
        },
      };

    case Action.FETCH_CUSTOMER_PO_SUMMARY_SUCCESS:
      return {
        ...state,
        customerPurchaseOrdersSummary: {
          ...state.customerPurchaseOrdersSummary,
          fetching: false,
          data: payload,
        },
      };

    case Action.FETCH_CUSTOMER_PO_SUMMARY_FAILURE:
      return {
        ...state,
        customerPurchaseOrdersSummary: {
          ...state.customerPurchaseOrdersSummary,
          fetching: false,
          data: null,
        },
      };

    case Action.FETCH_PROJECT_COSTS_SUMMARY_INITIATE:
      return {
        ...state,
        projectCostsSummary: {
          ...state.projectCostsSummary,
          fetching: true,
        },
      };

    case Action.FETCH_PROJECT_COSTS_SUMMARY_SUCCESS:
      return {
        ...state,
        projectCostsSummary: {
          ...state.projectCostsSummary,
          fetching: false,
          data: payload,
        },
      };

    case Action.FETCH_PROJECT_COSTS_SUMMARY_FAILURE:
      return {
        ...state,
        projectCostsSummary: {
          ...state.projectCostsSummary,
          fetching: false,
          data: null,
        },
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
