import React, { useState, useRef, useEffect } from 'react';
import { Col } from 'antd';

function DragNDrop({ data, getData = () => {} }) {
  const [list, setList] = useState(data);
  const [dragging, setDragging] = useState(false);
  const dragItem = useRef();
  const dragNode = useRef();
  const [selectedTags, setSelectedTags] = useState();

  const handleDragStart = (e, params) => {
    dragItem.current = params;
    dragNode.current = e.target;
    dragNode.current.addEventListener('dragend', handleDragEnd);
    setDragging(true);
  };

  useEffect(() => {
    if (selectedTags) {
      getData(selectedTags);
    }
  }, [selectedTags]);

  const handleDragEnter = (e, params) => {
    if (e.target !== dragNode.current) {
      setList((oldList) => {
        const newList = JSON.parse(JSON.stringify(oldList));
        newList[params.itemIndex].items.splice(
          params.tagIndex,
          0,
          newList[dragItem.current.itemIndex].items.splice(
            dragItem.current.tagIndex,
            1,
          )[0],
        );
        dragItem.current = params;
        setSelectedTags(newList[1].items);
        return newList;
      });
    }
  };

  const handleDragEnd = (e, params) => {
    setDragging(false);
    dragNode.current.removeEventListener('dragend', handleDragEnd);
    dragItem.current = null;
    dragNode.current = null;
  };

  const getStyels = (params) => {
    const currentItem = dragItem.current;
    if (
      currentItem.itemIndex === params.itemIndex
            && currentItem.tagIndex === params.tagIndex
    ) {
      return 'tag-dragging';
    }
    return 'tag';
  };

  return (
    <>
      {list.map((item, itemIndex) => (
        <Col span={12} key={item.title}>
          <div
            className="select"
            onDragEnter={
                                dragging && !item.items.length
                                  ? (e) => handleDragEnter(e, { itemIndex, tagIndex: 0 })
                                  : (e) => handleDragEnter(e, { itemIndex })
                            }
            key={item.title}
          >
            <h5>
              <span>
                Total Tags (
                {item.items.length}
                {' '}
                )
              </span>
              {item.title}
            </h5>
            <div
              className="scroll"
              style={{
                background:
                                        item.title === 'Selected' && dragging && 'aliceblue',
              }}
            >
              {item.items.length > 0 ? (
                item.items.map((tag, tagIndex) => (
                  <div
                    key={tag.id}
                    title={tag?.name}
                    className={
                                                    dragging
                                                      ? getStyels({ itemIndex, tagIndex })
                                                      : 'tag pkg-tag'
                                                }
                    draggable
                    onDragStart={(e) => handleDragStart(e, { itemIndex, tagIndex })}
                    onDragEnter={
                                                    dragging
                                                      ? (e) => handleDragEnter(e, {
                                                        itemIndex,
                                                        tagIndex,
                                                      })
                                                      : null
                                                }
                  >
                    {tag.name}
                  </div>
                ))
              ) : (
                <div className="empty-box">Empty</div>
              )}
            </div>
          </div>
        </Col>
      ))}
    </>
  );
}
export default DragNDrop;
