import { http } from '../utils/http';

export const fetchNotifications = async ({
  projectId, searchParam = '', sortingParam = '', page = 1,
}) => {
  const perPage = 18;
  try {
    let url = `projects/${projectId}/notifications/?page=${page}&per_page=${perPage}`;
    if (searchParam) url += `&search=${searchParam}`;
    if (sortingParam) url += `&ordering=${sortingParam}`;
    const response = await http.get(url);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchChatPackages = async ({
  projectId, search = '', sorting = '', page = 1, hasUnread = 0,
}) => {
  const perPage = 12;
  try {
    const response = await http.get(
      `chat/${projectId}/messages/?page=${page}&per_page=${perPage}&search=${search}&ordering=${sorting}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const markAsReadNotification = async (projectId, pkgId) => {
  try {
    const response = await http.patch(`projects/${projectId}/read/${pkgId}/notifications/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const getNotification = async (projectId, pkgId) => {
  try {
    const response = await http.get(`projects/${projectId}/${pkgId}/notifications/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchNotificationsCount = async (projectId) => {
  try {
    const response = await http.get(`projects/${projectId}/notifications-count/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchChatMessagesCount = async (projectId) => {
  try {
    const response = await http.get(`chat/${projectId}/messages-count/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const createNotification = async (projectId, body) => {
  try {
    const response = await http.post(`projects/${projectId}/notifications/`, body);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const helpEnquiry = async (body) => {
  try {
    const response = await http.post('contact-us/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const notificationSettings = async (body) => {
  try {
    const response = await http.post('projects/notifications-setting/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
