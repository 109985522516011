import _ from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { SubscriptionMessage } from '../../../components';

import Notifications from './notifications';

import '../../../assets/styles/components/task.scss';

function TaskInbox({ projectId, getCount, getAllProjects }) {
  const { user } = useSelector(({ auth }) => auth);

  useEffect(() => {
    getCount();
  }, []);

  return (
    <Row className="row-body">
      <Col span={24}>
        <div>
          <Notifications
            projectId={projectId}
            getNotificationsCount={getCount}
            getAllProjects={getAllProjects}
          />
        </div>
      </Col>
    </Row>
  );
}

export default TaskInbox;
