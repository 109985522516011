import { Modal, Button, Popconfirm, Spin } from 'antd';
import { useState } from 'react';

const ConfirmationPopup = ({ loader, visible, onCancel, onCopyAll, onCopyPartially }) => {
    return (
        <Modal
            title="Confirmation"
            onCancel={onCancel}
            loading={loader}
            footer={
                <Spin spinning={loader}>
                    <Button type="primary" onClick={onCopyAll}>
                        Copy All
                    </Button>
                    <Button style={{ marginLeft: '10px' }} onClick={onCopyPartially}>
                        Partially
                    </Button>
                </Spin>
            }
            centered
            closable={true}
            open={visible}
        >
            Please select how do you want to copy items.
        </Modal>
    );
};

export default ConfirmationPopup;
