import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { notification, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { DISPLAY_DATE_FORMAT, SERVER_DATE_FORMAT } from './constants';

export const isDatePast = (date1, date2) => {
    if (date1 && date2) {
        return moment(date1).isBefore(moment(date2));
    }
};

export const disabledDate = (current, end) => {
    if (end) {
        return current && current < end;
    }
};

export const conditionForVendorAndItem = {
    "vendor_estimate_associated_detail,vendor_estimate,vendor": ['vendor_name'],
    'vendor_estimate_associated_detail,vendor_estimate,item_detail': ['item_detail'],
  };

export const displayDate = (val, format) => (val ? moment(val).format(format) : '-');
export const displayValue = (value) => value || '-';

export const useInterval = (callback, delay) => {
    const savedCallback = React.useRef();

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export const makeTitle = (slug) => {
    if (slug) {
        const words = slug.split('_');

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }

        return words.join(' ');
    }
    return null;
};

export const camelcaseToTitle = (text) => {
    const result = text?.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult || text;
};

export const sanitizeText = (value) => value || 'N/A';

export const datePercentage = (dates) => {
    const start = new Date(dates.startDate).getTime();
    const end = new Date(dates.endDate).getTime();
    const today = new Date().getTime();
    const percentage = Math.round(((today - start) / (end - start)) * 100);
    return percentage;
};

export const makeAvatar = (firstChar, secondChar) =>
    `https://ui-avatars.com/api/?name=${firstChar}${secondChar}`; // background=random

export const checkPackageManufactures = (record, page) => {
    if (page === 'create_project') {
        const { team_members } = record;
        const manufacturers =
            team_members &&
            team_members.filter((member) => member.organization_type_name === 'Manufacturer');
        const different_manufacturers = _.unionBy(manufacturers, 'organization_name');
        if (different_manufacturers.length > 1) {
            return (
                <Tooltip title="This package has more than one manufacture">
                    <WarningOutlined style={{ color: 'red', fontSize: 18 }} />
                </Tooltip>
            );
        }
    } else {
        const { team_members } = record;
        const manufacturers = team_members
            .filter((member) => member?.organization_type?.name === 'Manufacturer')
            .map((member) => ({ ...member, organization: member?.organization?.name }));
        const different_manufacturers = _.unionBy(manufacturers, 'organization');
        if (different_manufacturers.length > 1) {
            return (
                <Tooltip title="This package has more than one manufacture">
                    <WarningOutlined style={{ color: 'red', fontSize: 18 }} />
                </Tooltip>
            );
        }
    }
};

export const showNotification = (message = '', type = 'success', placement = 'topRight') =>
    notification[type]({ message, placement });

export const filterProjectDocumentsData = (documents, dir_key) => {
    const filteredDocs = documents.filter((document) => {
        if (
            ['single_lines', 'site_layout'].includes(dir_key) &&
            (document.file_name.includes('.csv') || document.file_name.includes('.jpg'))
        ) {
            return;
        }
        return document;
    });

    return filteredDocs;
};

export const renderFrequency = (value) => {
    const frequency = parseInt(value);
    if (!frequency) {
        return '0 Month';
    }
    const frequencies = {
        1: 'Monthly',
        2: 'Bi-Monthly',
        4: 'Quaterly',
        6: 'Semi-Annual',
        12: 'Annual',
    };

    if (frequency in frequencies) {
        return frequencies[frequency];
    }
    if (frequency % 12 !== 0) {
        return `${frequency} Months`;
    }

    const years = Math.floor(frequency / 12);
    return `${years} Years`;
};

export const renderAmount = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value || 0);
};

export const calculateAvgMargin = (cost, sell) => {
    let margin = 0;
    if (cost && sell) {
        margin = ((sell - cost) / sell) * 100;
    }
    return margin?.toFixed(2) || 0;
};

export const calculateSell = (cost, avgMargin) => {
    const margin = avgMargin / 100;
    if (cost < 0 || margin < 0) {
        throw new Error('Invalid input. Cost and margin must be non-negative.');
    }

    if (margin === 1) {
        return cost * 2; // Selling at 100% margin means doubling the cost price
    }

    const sellPrice = cost / (1 - margin);
    return sellPrice;
};

export const ensureUuid = (value) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(value);
};

export const renderDate = (value) => {
    if (value) {
        return moment(value, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    }
    return '-';
};

export const capitalizeText = (value) => {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return '';
};

export const ensureEmail = (value) => {
    const regEx = /^\S+@\S+\.\S{2,}$/;
    return regEx.test(value);
};
