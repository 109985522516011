import { find, cloneDeep, unionBy, remove, orderBy, isEmpty, isNull } from 'lodash';
import { RESET_STATE } from '../common';
import * as ActionTypes from './types';

const initialState = {
    packages: [],
    activeChatId: null,
    packageLoader: false,
    messageData: [],
    contactList: {
        data: [],
        total: 0,
        fetching: false,
    },
    loading: false,
    error: null,
};

export const chatReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let value;
    switch (type) {
        case ActionTypes.GET_CHAT_MESSAGES_INIT:
            // Add to the messages key
            if (!find(state.messageData, ['id', payload])) {
                const data = {
                    id: payload,
                    masterId: payload?.project?.masterProject?.id,
                    messages: [],
                    meta: {},
                };
                return {
                    ...state,
                    loading: true,
                    messageData: [...state.messageData, data],
                };
            }
            return { ...state, loading: true };
        case ActionTypes.GET_CHAT_MESSAGES_SUCCESS:
            value = find(state.messageData, ['id', payload.id]);
            if (value) {
                // Id must exist
                value.meta = payload.data.meta;
                value.messages = orderBy(
                    unionBy(payload.data.data, [...value.messages], 'id'),
                    ['created_at'],
                    ['asc'],
                );
                const newMessageData = unionBy([value], state.messageData, 'id');
                return {
                    ...state,
                    loading: false,
                    messageData: newMessageData,
                };
            }
        case ActionTypes.SET_CHAT_MESSAGE:
            value = find(state.messageData, ['id', payload.id]);
            if (value) {
                value.messages = orderBy(
                    unionBy(
                        [
                            {
                                id: payload.message?.id,
                                text: payload.message?.text,
                                created_at: payload.message?.created_at || new Date().toISOString(),
                                updated_at: payload.message?.updated_at || new Date().toISOString(),
                                sent_by: {
                                    id: payload?.user?.id,
                                    first_name: payload?.user?.first_name,
                                    last_name: payload?.user?.last_name,
                                },
                                s3_url: payload.message?.s3_url,
                                file_name: payload.message?.file_name,
                                content_type: payload.message?.content_type,
                            },
                        ],
                        [...value.messages],
                        'id',
                    ),
                    ['created_at'],
                    ['asc'],
                );
                const newMessageData = unionBy([value], state.messageData, 'id');
                return {
                    ...state,
                    loading: false,
                    messageData: newMessageData,
                };
            }
        case ActionTypes.GET_CHAT_MESSAGES_FAILED:
            return { ...state, loading: false };
        case ActionTypes.RESET_PACKAGES:
            // remove(state.packages, ['id', payload?.projectId]);
            return { ...state, loading: true };
        case ActionTypes.GET_CHAT_PACKAGES:
            const project = find(state.packages, ['id', payload?.projectId]);
            let data;
            if (!project) {
                data = {
                    id: payload?.projectId,
                    masterId: payload?.project?.masterProject?.id || payload?.project?.id,
                    packages: [
                        {
                            description: 'Project Chat',
                            name: payload?.project?.name,
                            id: payload?.project?.id,
                            has_unread_messages: false,
                            unread_messages_count: 0,
                            project_chat: true,
                        },
                    ],
                    meta: {},
                    currPage: 1,
                };
            } else {
                // Check for projectChat
                let packages = project?.packages;
                const projectChat = find(packages, ['id', payload?.project?.id]);
                if (!projectChat) {
                    const chatPackage = {
                        description: 'Project Chat',
                        name: payload?.project?.name,
                        id: payload?.project?.id,
                        has_unread_messages: false,
                        unread_messages_count: 0,
                        project_chat: true,
                    };
                    packages = unionBy([chatPackage], packages, 'id');
                }
                data = { ...project, packages };
            }

            return {
                ...state,
                loading: false,
                packages: unionBy([data], state.packages, 'id'),
            };
        case ActionTypes.GET_CHAT_PACKAGES_SUCCESS:
            value = find(state.packages, ['id', payload?.projectId]);
            if (value) {
                value.packages = unionBy(payload?.data, [...value.packages], 'id');
                value.meta = payload?.meta;
                value.currPage = payload?.page;
                const newPackagesData = unionBy([value], state.packages, 'id');
                return {
                    ...state,
                    loading: false,
                    packages: newPackagesData,
                };
            }
        case ActionTypes.UPDATE_INBOX_MESSAGE:
            const unreadData = payload;
            let newPackagesData = cloneDeep(state.packages);
            for (let i = 0; i < unreadData?.length; i++) {
                const data = unreadData[i];
                const projectData = find(newPackagesData, ['id', data?.project_id]);

                if (projectData) {
                    let packageInfo = find(projectData?.packages, ['id', data?.package_id]);
                    if (packageInfo) {
                        packageInfo.unread_messages_count = data?.unread_messages_count;
                    } else {
                        packageInfo = {
                            unread_messages_count: data?.unread_messages_count,
                            id: data?.package_id,
                            name: data?.package_name,
                            description: data?.description,
                        };
                    }
                    projectData.packages = unionBy([packageInfo], projectData.packages, 'id');
                } else {
                    newPackagesData = unionBy(
                        [
                            {
                                id: data?.project_id,
                                masterId: data?.master_project_id,
                                packages: [
                                    {
                                        unread_messages_count: data?.unread_messages_count,
                                        id: data?.package_id,
                                        name: data?.package_name,
                                        description: data?.description,
                                    },
                                ],
                                meta: {},
                            },
                        ],
                        newPackagesData,
                        'id',
                    );
                }
            }

            return {
                ...state,
                packages: newPackagesData,
            };
        case ActionTypes.UPDATE_PROJECT_INBOX_MESSAGE:
            const unreadProjectData = payload;
            let prjPackagesData = cloneDeep(state.packages);
            for (let i = 0; i < unreadProjectData?.length; i++) {
                const data = unreadProjectData[i];
                const projectData = find(prjPackagesData, ['id', data?.project_id]);
                if (projectData) {
                    let packageInfo = find(projectData?.packages, ['id', data?.project_id]);
                    if (packageInfo) {
                        packageInfo.unread_messages_count = data?.unread_messages_count;
                    } else {
                        packageInfo = {
                            unread_messages_count: data?.unread_messages_count,
                            id: data?.project_id,
                            name: data?.project_name,
                            description:
                                isEmpty(data?.description) ||
                                isNull(data?.description) ||
                                'Project Chat',
                            has_unread_messages: !!data?.unread_messages_count,
                            project_chat: true,
                        };
                    }
                    projectData.packages = unionBy([packageInfo], projectData.packages, 'id');
                } else {
                    prjPackagesData = unionBy(
                        [
                            {
                                id: data?.project_id,
                                masterId: data?.master_project_id,
                                packages: [
                                    {
                                        unread_messages_count: data?.unread_messages_count,
                                        id: data?.project_id,
                                        name: data?.project_name,
                                        description:
                                            isEmpty(data?.description) ||
                                            isNull(data?.description) ||
                                            'Project Chat',
                                        project_chat: true,
                                    },
                                ],
                                meta: {},
                            },
                        ],
                        prjPackagesData,
                        'id',
                    );
                }
            }
            return {
                ...state,
                packages: prjPackagesData,
            };
        case ActionTypes.GET_CONTACT_LIST:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    fetching: true,
                },
            };
        case ActionTypes.GET_CONTACT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                contactList: {
                    fetching: false,
                    data: [...(payload.page === 1 ? [] : state.contactList.data), ...payload.data],
                    total: payload?.meta?.total_count,
                },
            };
        case ActionTypes.ACTIVE_CONTACT_READ:
            let newUnreadContactList = cloneDeep(state.contactList.data);
            const contact = find(newUnreadContactList, ['id', payload?.id]);
            if (contact) {
                contact.unread_chat_count = 0;
            }
            newUnreadContactList = unionBy([contact], newUnreadContactList, 'id');
            return {
                ...state,
                loading: false,
                contactList: {
                    ...state.contactList,
                    data: newUnreadContactList,
                },
            };
        case ActionTypes.ACTIVE_PACKAGE_READ:
            let newPackages = cloneDeep(state.packages);
            const packageActive = find(newPackages, ['id', payload?.id]); // Master
            if (packageActive) {
                // Find child package
                const cPkg = find(packageActive?.packages, ['id', payload?.id]);
                cPkg.unread_messages_count = 0;
            }
            newPackages = unionBy([packageActive], newPackages, 'id');
            console.log(newPackages);
            return {
                ...state,
                loading: false,
                packages: newPackages,
            };
        case ActionTypes.UPDATE_CONTACT_MESSAGE:
            const unreadContactData = payload;
            let newContactList = cloneDeep(state.contactList.data);
            for (let i = 0; i < unreadContactData?.length; i++) {
                const data = unreadContactData[i];
                const contact = find(newContactList, ['id', data?.sent_by_id]);

                if (contact) {
                    contact.unread_chat_count = data?.unread_messages_count;
                }
                newContactList = unionBy([contact], newContactList, 'id');
            }
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    data: newContactList,
                },
            };
        case ActionTypes.SET_ACTIVE_CHAT:
            return { ...state };
        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
