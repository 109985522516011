import { Button, Form, message } from 'antd';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateUpdateContactForm } from '../../../../components';
import { fetchUserDetails, updateUserProfile } from '../../../../services';
import { GET_AUTH_USER } from '../../../../store/auth';

const AccountInformation = memo(() => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const { user } = useSelector(({ auth }) => auth);

    const onUpdateProfile = async (values) => {
        try {
            setSubmitting(true);
            const response = await updateUserProfile(values);
            if (response.success) {
                const userDetails = await fetchUserDetails();
                dispatch({
                    type: GET_AUTH_USER,
                    payload: { ...userDetails.data, image_url: userDetails.data.image },
                });
                message.success('Profile Updated');
            }
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            message.error('Failed to update');
        }
    };

    return (
        <>
            <CreateUpdateContactForm
                form={form}
                user={user}
                hidePermissionInput
                onSubmit={onUpdateProfile}
            />
            <Button className="mt-10" type="primary" onClick={form.submit} loading={submitting}>
                Update Profile
            </Button>
        </>
    );
});

export default AccountInformation;
