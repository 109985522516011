import { http, fileHttp } from '../utils/http';

export const fetchProjectCosts = async (projectId, params) => {
  try {
    const response = await http.get(
      `/masterprojects/${projectId}/finance/distributor-purchase-orders/`,
      params,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchProjectCostsSummary = async (projectId) => {
  try {
    const response = await http.get(
      `/masterprojects/${projectId}/finance/project-cost-summary/`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createProjectCost = async (projectId, body) => {
  try {
    const response = await http.post(
      `/masterprojects/${projectId}/finance/distributor-purchase-orders/`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const updateProjectCost = async (projectId, projectCostId, body) => {
  try {
    const response = await http.patch(
      `/masterprojects/${projectId}/finance/distributor-purchase-orders/${projectCostId}`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteProjectCosts = async (projectId, projectCostIds) => {
  try {
    const response = await http.delete(
      `/masterprojects/${projectId}/finance/distributor-purchase-orders/`,
      { distributorPOIds: projectCostIds },
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const uploadProjectCostDocuments = async (projectId, projectCostId, body) => {
  try {
    const response = await fileHttp.post(
      `/masterprojects/${projectId}/distributor-purchase-orders/${projectCostId}/documents/`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchProjectCostDocuments = async (projectId, params) => {
  try {
    const response = await http.get(`/masterprojects/${projectId}/distributor-purchase-orders/documents/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchProjectCostInvoice = async (projectId, projectCostId, params) => {
  try {
    const response = await http.get(`/masterprojects/${projectId}/distributor-purchase-orders/${projectCostId}/documents/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const updateProjectCostInvoice = async (projectId, projectCostId, body) => {
  try {
    const response = await http.patch(`/masterprojects/${projectId}/distributor-purchase-orders/${projectCostId}/documents/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCustomerPurchaseOrders = async (projectId, params) => {
  try {
    const response = await http.get(
      `/masterprojects/${projectId}/finance/customer-purchase-orders/`,
      params,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCustomerPurchaseOrdersSummary = async (projectId) => {
  try {
    const response = await http.get(
      `/masterprojects/${projectId}/finance/customer-purchase-orders-summary/`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createCustomerPurchaseOrder = async (projectId, body) => {
  try {
    const response = await http.post(
      `/masterprojects/${projectId}/finance/customer-purchase-orders/`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const updateCustomerPurchaseOrder = async (projectId, customerPoId, body) => {
  try {
    const response = await http.patch(
      `/masterprojects/${projectId}/finance/customer-purchase-orders/${customerPoId}`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteCustomerPurchaseOrders = async (projectId, customerPoIds) => {
  try {
    const response = await http.delete(
      `/masterprojects/${projectId}/finance/customer-purchase-orders/`,
      { customerPOIds: customerPoIds },
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const uploadCustomerPurchaseOrderDocuments = async (projectId, customerPoId, body) => {
  try {
    const response = await fileHttp.post(
      `/masterprojects/${projectId}/customer-purchase-orders/${customerPoId}/documents/`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCustomerPurchaseOrderDocuments = async (projectId, params) => {
  try {
    const response = await http.get(`/masterprojects/${projectId}/customer-purchase-orders/documents/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCustomerPurchaseOrderInvoice = async (projectId, projectCostId, params) => {
  try {
    const response = await http.get(`/masterprojects/${projectId}/customer-purchase-orders/${projectCostId}/documents/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const sendCombinedPdf = async (projectId, body) => {
  try {
    const response = await http.post(`/masterprojects/${projectId}/finance/send-combined-pdf/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
