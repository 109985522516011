import { fetchEquipmentDocs, saveEquipmentDocs } from '../../services';
import {
  IMAGE_LIST,
  FETCH_EQUIPMENT_DOCS_INITIATE,
  FETCH_EQUIPMENT_DOCS_SUCCESS,
  FETCH_CONSTRUCTION_DOCS_SUCCESS,
  UPLOAD_EQUIPMENT_DOCUMENT_INITIATE,
} from './types';

export const getEquipmentDocuments = (projectId, pkgId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EQUIPMENT_DOCS_INITIATE });
    const response = await fetchEquipmentDocs(projectId, pkgId);
    dispatch({ type: FETCH_EQUIPMENT_DOCS_SUCCESS, payload: response?.data });
    dispatch({ type: FETCH_CONSTRUCTION_DOCS_SUCCESS, payload: response?.data });
  } catch (error) {
    console.log(error);
  }
};

export const uploadEquipmentDocuments = ({
  fileList, key, projectId, pkgId, dispatchEvent,
}) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_EQUIPMENT_DOCUMENT_INITIATE });
    const formData = new FormData();
    fileList.map((file) => formData.append('files', file));
    formData.append('is_package', true);
    formData.append('dir_key', key);
    formData.append('project_id', projectId);
    formData.append('id', pkgId);
    await saveEquipmentDocs(projectId, pkgId, formData);
    dispatchEvent(getEquipmentDocuments(projectId, pkgId));
  } catch (error) {}
};

export const setImageDocs = (data) => (dispatch) => {
  dispatch({ type: IMAGE_LIST, payload: data });
};
