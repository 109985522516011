import PropTypes from 'prop-types';
import { useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, message, Modal, Row } from 'antd';
import { exportOrPreviewBinder } from '../../../../../services';
import BinderPreview from './BinderPreview';
import BinderOptions from './BinderOptions';

const BinderGeneration = (props) => {
    const { projectId } = useParams();
    const [pdfData, setPdfData] = useState({ data: null, fetching: false });

    const onSubmit = async (type, options) => {
        try {
            options.is_preview = type === 'preview';
            setPdfData({ ...pdfData, fetching: true });
            const response = await exportOrPreviewBinder(projectId, options);
            const data = new Blob([response], { type: 'application/pdf' });
            if (type === 'export') {
                message.success('Digital Binder Exported');
                props.onCancel();
                props.getBindersHistory();
            } else {
                setPdfData({ fetching: false, data });
            }
        } catch (error) {}
    };

    return (
        <Modal
            title={null}
            open={props.visible}
            onCancel={props.onCancel}
            width={'100%'}
            className="full-width-modal binder-generation-modal"
            footer={null}
        >
            <Row gutter={15}>
                <Col span={5}>
                    <BinderOptions onSubmit={onSubmit} />
                </Col>
                <Col span={19}>
                    <BinderPreview {...pdfData} />
                </Col>
            </Row>
        </Modal>
    );
};

BinderGeneration.defaultProps = {
    visible: false,
    onCancel: () => {},
    getBindersHistory: () => {},
};

BinderGeneration.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    getBindersHistory: PropTypes.func,
};

export default memo(BinderGeneration);
