import React from 'react';
import { PACKAGES_TYPES } from '../../../utils';

export default () => React.useMemo(() => [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: false,
    sortDirections: ['ascend', 'descend'],
    width: 300,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    sorter: false,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Type',
    dataIndex: 'package_type',
    render: (package_type) => PACKAGES_TYPES[package_type],
  },
]);
