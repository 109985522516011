import { memo, useEffect } from 'react';
import { Modal, Form, Select } from 'antd';
import { DATE_STATUSES } from '../../utils';

const { Option } = Select;

function UpdateStatusModal({
  onSubmit = () => {},
  onCancel = () => {},
  visible = false,
  initialValues,
}) {
  const [form] = Form.useForm();

  const onSubmitHandler = (values) => {
    onSubmit(values);
    form.resetFields();
  };

  const cancleHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (initialValues?.status) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  return (
    <Modal
      title="Add/Update Ship Date"
      visible={visible}
      onOk={form.submit}
      onCancel={cancleHandler}
      okText="Save"
    >
      <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
        <Form.Item label="Status" name="status" required>
          <Select style={{ width: '100%' }}>
            {DATE_STATUSES.map((status) => (
              <Option key={status.value} value={status.value}>{status.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default memo(UpdateStatusModal);
