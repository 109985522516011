export const FETCH_EQUIPMENT_DOCS_INITIATE = 'FETCH_EQUIPMENT_DOCS_INITIATE';
export const FETCH_EQUIPMENT_DOCS_SUCCESS = 'FETCH_EQUIPMENT_DOCS_SUCCESS';

export const CONSTRUCTION_DOCS_LIST = 'CONSTRUCTION_DOCS_LIST';
export const FETCH_CONSTRUCTION_DOCS_SUCCESS = 'FETCH_CONSTRUCTION_DOCS_SUCCESS';

export const UPLOAD_EQUIPMENT_DOCUMENT_INITIATE = 'UPLOAD_EQUIPMENT_DOCUMENT_INITIATE';
export const UPLOAD_EQUIPMENT_DOCUMENT_SUCCESS = 'UPLOAD_EQUIPMENT_DOCUMENT_SUCCESS';

export const RESET_STATE = 'RESET_STATE';
export const IMAGE_LIST = 'IMAGE_LIST';
