import {
  notification,
} from 'antd';
import * as ActionTypes from './types';
import {
  fetchTagsByProject,
  fetchTagsBySline,
  updateTag,
  fetchShippingUpdatesByTag,
  updateShippingDetail,
  fetchTagsByProjectV2,
  fetchTagsByPackage,
} from '../../services';

export const setTag = (data) => (dispatch) => {
  dispatch({ type: ActionTypes.TAG_LIST, payload: data });
};

export const getTagsByPackage = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_MASTER_TAGS_LOADING });
    const response = await fetchTagsByPackage(data);
    dispatch({ type: ActionTypes.GET_MASTER_TAGS_SUCCESS, payload: response });
  } catch (error) {
    throw (error);
  }
};

export const getTagsByProject = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_MASTER_TAGS_LOADING });
    const response = await fetchTagsByProjectV2(data);
    dispatch({ type: ActionTypes.GET_MASTER_TAGS_SUCCESS, payload: response });
  } catch (error) {
    throw (error);
  }
};

export const getMasterTags = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_MASTER_TAGS_LOADING });
    const response = await fetchTagsByProject(data);
    dispatch({ type: ActionTypes.GET_MASTER_TAGS_SUCCESS, payload: response });
  } catch (error) {
    throw (error);
  }
};

export const getTagsBySline = (projectId, slineId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_MASTER_TAGS_LOADING });
    const response = await fetchTagsBySline(projectId, slineId);
    dispatch({ type: ActionTypes.GET_MASTER_TAGS_SUCCESS, payload: response });
  } catch (error) {
    throw (error);
  }
};

export const updateMasterTag = (data = {}, cb = () => { }) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_TAG_INITIATE });
    await updateTag(data?.projectId, data?.id, data);
    dispatch({ type: ActionTypes.UPDATE_TAG_SUCCESS });
    cb && cb();
  } catch (ex) {
    throw (ex);
  }
};

export const getShippingUpdateByTag = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_SHIPPING_UPDATE_LIST });
    const response = await fetchShippingUpdatesByTag(data);
    dispatch({ type: ActionTypes.GET_SHIPPING_UPDATE_LIST_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_SHIPPING_UPDATE_LIST_FAILURE });
    throw (error);
  }
};

export const updateShipping = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_SHIPPING });
    const response = await updateShippingDetail(data);
    dispatch({ type: ActionTypes.UPDATE_SHIPPING_SUCCESS, payload: response });
    notification.success({
      message: 'Shipping date Added',
    });
  } catch (error) {
    dispatch({ type: ActionTypes.UPDATE_SHIPPING_FAILURE });
    notification.success({
      message: 'Failed to add Shipping date',
    });
  }
};
