import { Card, Row, Col, Button, Upload, Space } from 'antd';
import { memo, useEffect, useState } from 'react';
import { AlectifyTable, UploadNote } from '../../../../../../components';
import useColumns from './effects/useColumns';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import FileUploadModal from './UploadContractorsDocument';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllContractorDocs } from '../../../../../../store/bidding-management';
import { debounce } from 'lodash';

const ContractorDcouments = () => {
    const columns = useColumns();
    const { contractorId } = useParams();
    const {
        documents: { contractorDocs },
        fetching,
    } = useSelector(({ bidding }) => bidding);
    const [uploadModal, setUploadModal] = useState(false);
    const [documentsToUpload, setDocumentsToUpload] = useState([]);
    const [fileList, setFileList] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        onGetAllDocs();
    }, [contractorId]);

    const onGetAllDocs = (pagination) => {
        const params = {
            page: pagination?.page || 1,
            per_page: pagination?.per_page || 10,
        };
        dispatch(getAllContractorDocs(contractorId, params));
    };

    const onUploadDocuments = (ev) => {
        setDocumentsToUpload([...documentsToUpload, ...ev.fileList]);
        setUploadModal(true);
    };

    return (
        <>
            <Card title="Contractor Documents" bordered>
                <Row justify={'end'}>
                    <Col className="mb-10">
                        <Upload
                            beforeUpload={() => false}
                            onChange={debounce(onUploadDocuments, 100)}
                            showUploadList={false}
                            multiple
                            fileList={[]}
                        >
                            <Button type="default" shape="round" className="main-upload-btn">
                                <span
                                    style={{
                                        margin: '0 8px 0 0',
                                    }}
                                >
                                    <PlusOutlined /> Add Documents
                                </span>
                                <UploadNote />
                            </Button>
                        </Upload>
                    </Col>
                </Row>
                <Space />
                <AlectifyTable
                    columns={columns}
                    defaultPageSize={10}
                    total={contractorDocs?.meta?.total_count}
                    dataSource={contractorDocs?.data || []}
                    loading={fetching}
                    onDataFetch={onGetAllDocs}
                    selectRows={false}
                    bordered
                />
            </Card>
            <FileUploadModal
                visible={uploadModal}
                onCancel={() => setUploadModal(false)}
                contractorId={contractorId}
                documentsToUpload={documentsToUpload}
                setDocumentsToUpload={setDocumentsToUpload}
                fileList={fileList}
                setFileList={setFileList}
            />
        </>
    );
};

export default memo(ContractorDcouments);
