import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import { fetchMasterAggregate } from '../../../../../services';
import { isPackagesActive } from '../../../../../utils';

const Insights = memo(() => {
    const { projectId } = useParams();
    const [insights, setInsights] = useState({ data: {}, fetching: false });

    const getMasterInsights = async () => {
        try {
            setInsights({ ...insights, fetching: true });
            const response = await fetchMasterAggregate(projectId);
            setInsights({ ...insights, fetching: false, data: response?.data });
        } catch (error) {
            setInsights({ ...insights, fetching: false });
        }
    };

    useEffect(() => {
        getMasterInsights();
    }, [projectId]);

    return (
        <div className="dashboard-container">
            <Row>
                <p className="card-heading summary-heading">Aggregated Project Overview</p>
            </Row>
            <Row gutter={15}>
                <>
                    {isPackagesActive() && (
                        <Col span={6}>
                            <div className="page-content-container dashboard-card colorful-card bg-light-purple cursor-pointer">
                                <h4 className="card-heading custom-card-heading">Packages</h4>
                                <h1 className="dashboard-card-text">
                                    {insights.data?.total_packages || 0}
                                </h1>
                            </div>
                        </Col>
                    )}
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-light-yellow cursor-pointer">
                            <h4 className="card-heading custom-card-heading">Equipments</h4>
                            <h1 className="dashboard-card-text">
                                {insights.data?.total_tags || 0}
                            </h1>
                        </div>
                    </Col>
                </>
            </Row>
        </div>
    );
});

export default Insights;
