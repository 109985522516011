import PropTypes from 'prop-types';
import { memo, useState, useEffect } from 'react';
import { Card, Space, Row, Switch, Col, Button } from 'antd';

const BinderOptions = (props) => {
    const [options, setOptions] = useState({
        vendor_estimate_summary: true,
        vendor_estimate_associated_details: false,
        vendor_estimate_detail_docs: false,
        project_finance_summary: true,
        project_finance_detail_docs: false,
        distributor_po_summary: true,
        // distributor_po_associated_details: false,
        distributor_po_docs: false,
        customer_po_summary: true,
        // customer_po_associated_details: false,
        customer_po_docs: false,
    });

    const onChange = (checked, state) => {
        setOptions({ ...options, [state]: checked });
    };

    const getValue = (key) => options[key];

    useEffect(() => {
        props.onSubmit('preview', options);
    }, []);

    return (
        <Card title="Binder Options">
            <h3>Vendor Quotes</h3>
            <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('vendor_estimate_summary')}
                        onChange={(checked) => onChange(checked, 'vendor_estimate_summary')}
                    />
                    Summary
                </Space>
            </div>
            <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('vendor_estimate_associated_details')}
                        onChange={(checked) =>
                            onChange(checked, 'vendor_estimate_associated_details')
                        }
                    />
                    Individual Breakdown
                </Space>
            </div>
            <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('vendor_estimate_detail_docs')}
                        onChange={(checked) => onChange(checked, 'vendor_estimate_detail_docs')}
                    />
                    Individual Attachments
                </Space>
            </div>
            <h3 className="mt-30 mb-15">Customer PO</h3>
            <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('project_finance_summary')}
                        onChange={(checked) => onChange(checked, 'project_finance_summary')}
                    />
                    Summary
                </Space>
            </div>
            <div className="mb-10">
                <Space>
                    <Switch
                        disabled
                        checked={getValue('project_finance_detail_docs')}
                        onChange={(checked) => onChange(checked, 'project_finance_detail_docs')}
                    />
                    Individual Attachments
                </Space>
            </div>
            <h3 className="mt-30 mb-15">Billing</h3>
            <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('distributor_po_summary')}
                        onChange={(checked) => onChange(checked, 'distributor_po_summary')}
                    />
                    Vendor Invoices
                </Space>
            </div>
            {/* <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('distributor_po_associated_details')}
                        onChange={(checked) =>
                            onChange(checked, 'distributor_po_associated_details')
                        }
                    />
                    Vendor Invoice Individual Breakdown
                </Space>
            </div> */}
            <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('distributor_po_docs')}
                        onChange={(checked) => onChange(checked, 'distributor_po_docs')}
                    />
                    Vendor Invoice Attachments
                </Space>
            </div>
            <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('customer_po_summary')}
                        onChange={(checked) => onChange(checked, 'customer_po_summary')}
                    />
                    Customer Invoices
                </Space>
            </div>
            {/* <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('customer_po_associated_details')}
                        onChange={(checked) => onChange(checked, 'customer_po_associated_details')}
                    />
                    Customer Invoice Individual Breakdown
                </Space>
            </div> */}
            <div className="mb-10">
                <Space>
                    <Switch
                        checked={getValue('customer_po_docs')}
                        onChange={(checked) => onChange(checked, 'customer_po_docs')}
                    />
                    Customer Invoice Attachments
                </Space>
            </div>
            <Row gutter={15} className="mt-30">
                <Col span={12}>
                    <Button
                        block
                        type="default"
                        loading={false}
                        onClick={() => props.onSubmit('preview', options)}
                    >
                        Update Preview
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        loading={false}
                        onClick={() => props.onSubmit('export', options)}
                    >
                        Export PDF
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

BinderOptions.defaultProps = {
    onSubmit: () => {},
};

BinderOptions.propTypes = {
    onSubmit: PropTypes.func,
};

export default memo(BinderOptions);
