import React from 'react';
import { Tabs, Collapse, Table } from 'antd';
import './user-right-data.scss';
import { useSelector } from 'react-redux';

const { Panel } = Collapse;

function UserRightData() {
  const { titleValue } = useSelector(({ common }) => common);

  const dataSource = [
    {
      name: 'File 1',
      status: 'Pending',
    },
    {
      name: 'File 2',
      status: 'Approved',
    },
  ];

  const columns = [
    {
      title: 'File name',
      dataIndex: 'name',
    },

    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  return (
    <div className="right-data-container">
      <div>
        <h2>{titleValue}</h2>
      </div>
      <Collapse defaultActiveKey={['1', '2', '3']}>
        <Panel header="Package 1" key="1">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </Panel>
        <Panel header="Package 2" key="2">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </Panel>
        <Panel header="Package 3" key="3">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </Panel>
      </Collapse>
    </div>
  );
}

export default UserRightData;
