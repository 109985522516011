import React, { useState } from 'react';

import { Tabs } from 'antd';

import Analytics from './analytics';
import Status from './status';
import { EquipmentDocs } from '../../equipment_docs';

const { TabPane } = Tabs;

export function ManufactureTab({
  projectId,
  detail,
  getPackageDetail,
  isLoading = false,
  equipmentDocs = [],
  uploadDocs = () => {},
  uploadForVideos = () => {},
  pkgId,
}) {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <Tabs tabPosition="left" defaultActiveKey={activeTab} onChange={setActiveTab}>
      <TabPane className="side_tabs" tab=" Status" key="1">
        <Status
          detail={detail}
          getPackageDetail={getPackageDetail}
          isLoading={isLoading}
          uploadEquipmentDocs={uploadDocs}
        />
      </TabPane>
      <TabPane className="side_tabs" tab="Witness Testing" key="2">
        <Analytics />
      </TabPane>

      <TabPane tab="Documents" key="4">
        <EquipmentDocs
          pkgId={pkgId}
          projectId={projectId}
          data={equipmentDocs}
          isLoading={isLoading}
          uploadDocs={uploadDocs}
          uploadForVideos={uploadForVideos}
        />
      </TabPane>
    </Tabs>
  );
}
