import _ from 'lodash';
import { Tooltip, Avatar, Space } from 'antd';
import { isPackagesActive, PACKAGES_TYPES } from '../../../../utils';

export const searchTableColumns = ({}) => [
    {
        title: 'Ref #',
        dataIndex: 'reference_number',
        width: 100,
    },
    {
        title: 'Line #',
        dataIndex: 'line_number',
        width: 140,
    },
    {
        title: 'Tag Name',
        dataIndex: 'name',
        width: 150,
        // ...getColumnSearchProps('name'),
        render: (text, record) => (
            <Space size="middle">
                <Avatar
                    src={
                        record.tag_image_url ||
                        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlBRzUn5IDV4IYZHfu5NUJ7OaY9WEMTTWOQQ&usqp=CAU'
                    }
                />
                <Tooltip placement="topLeft" title={text}>
                    <p className="mb-0 tagName-master-td" title={text}>
                        {text}
                    </p>
                </Tooltip>
            </Space>
        ),
    },
    {
        title: 'Description',
        dataIndex: 'description',
        width: 150,
        render: (text, record) => ({
            props: {
                className: 'description-td',
            },
            children: (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        }),
    },
    ...[
        isPackagesActive()
            ? {
                  title: 'Package Type',
                  dataIndex: 'package_type',
                  width: 150,
                  render: (package_type) => PACKAGES_TYPES[package_type],
              }
            : {},
    ],
];
