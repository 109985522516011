import moment from 'moment';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, message, Modal, Row, Spin } from 'antd';
import { updateTag } from '../../../../../services';
import { getTagsByProject } from '../../../../../store/tags';
import { DATES_KEYS, INPUT_DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../../../utils';
import { UpdateEquipmentDates } from '../../../../../components';

const EditManualSchedule = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [dates, setDates] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (values) => {
        try {
            const { id, project } = props.data;
            setSubmitting(true);
            Object.keys(values).forEach((key) => {
                if (DATES_KEYS.includes(key)) {
                    values[key] = values[key]
                        ? moment(values[key], INPUT_DATE_FORMAT).format(SERVER_DATE_FORMAT)
                        : null;
                }
            });
            values.id = id;
            if (values?.lead_time_weeks) {
                values.lead_time_weeks = parseInt(values?.lead_time_weeks);
            }
            await updateTag(project, id, values);
            message.success('Updated Successfully');
            setSubmitting(false);
            props.onCancel();
            props.getManualSchedule();
            dispatch(getTagsByProject({ id: project, pageSize: 10 }));
        } catch (error) {
            console.log(error);
            setSubmitting(false);
        }
    };

    useEffect(() => {
        const data = props.data;
        DATES_KEYS.forEach((key) => {
            if (!isEmpty(data[key])) {
                data[key] = moment(data[key], SERVER_DATE_FORMAT);
            }
        });
        form.setFieldsValue(data);
    }, []);

    return (
        <Modal
            className="update-equipment-modal"
            width={1100}
            style={{ top: 10 }}
            destroyOnClose
            okText="Update"
            onOk={form.submit}
            open={props.visible}
            title={`${props?.data?.name}: Edit Equipment Dates `}
            onCancel={props.onCancel}
            confirmLoading={submitting}
        >
            <div className="modal-content">
            <Spin spinning={submitting}>
                <Form layout="vertical" className="mb-20" onFinish={onSubmit} form={form}>
                    <UpdateEquipmentDates />
                </Form>
            </Spin>
            </div>
        </Modal>
    );
};

EditManualSchedule.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
    getManualSchedule: () => {},
};

EditManualSchedule.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    getManualSchedule: PropTypes.func,
};

export default EditManualSchedule;
