export const GET_BRANCH_PERMISSIONS = 'GET_BRANCH_PERMISSIONS';
export const GET_BRANCH_PERMISSIONS_SUCCESS = 'GET_BRANCH_PERMISSIONS_SUCCESS';
export const GET_BRANCH_PERMISSIONS_FAILED = 'GET_BRANCH_PERMISSIONS_FAILED';

export const GET_USERS_BY_PROJECT = 'GET_USERS_BY_PROJECT';
export const GET_USERS_BY_PROJECT_SUCCESS = 'GET_USERS_BY_PROJECT_SUCCESS';
export const GET_USERS_BY_PROJECT_FAILED = 'GET_USERS_BY_PROJECT_FAILED';

export const GET_PROJECTS_BY_ADMIN = 'GET_PROJECTS_BY_ADMIN';
export const GET_PROJECTS_BY_ADMIN_SUCCESS = 'GET_PROJECTS_BY_ADMIN_SUCCESS';
export const GET_PROJECTS_BY_ADMIN_FAILED = 'GET_PROJECTS_BY_ADMIN_FAILED';

export const GET_ROLE_TEMPLATES = 'GET_ROLE_TEMPLATES';
export const GET_ROLE_TEMPLATES_SUCCESS = 'GET_ROLE_TEMPLATES_SUCCESS';
export const GET_ROLE_TEMPLATES_FAILED = 'GET_ROLE_TEMPLATES_FAILED';

export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAILED = 'GET_USER_ROLES_FAILED';

export const GET_PERMISSIONS_LIST = 'GET_PERMISSIONS_LIST';
export const GET_PERMISSIONS_LIST_SUCCESS = 'GET_PERMISSIONS_LIST_SUCCESS';
export const GET_PERMISSIONS_LIST_FAILED = 'GET_PERMISSIONS_LIST_FAILED';

export const GET_GROUPS_LIST = 'GET_GROUPS_LIST';
export const GET_GROUPS_LIST_SUCCESS = 'GET_GROUPS_LIST_SUCCESS';
export const GET_GROUPS_LIST_FAILED = 'GET_GROUPS_LIST_FAILED';

export const CREATE_ROLE_TEMPLATE = 'CREATE_ROLE_TEMPLATE';
export const CREATE_ROLE_TEMPLATE_SUCCESS = 'CREATE_ROLE_TEMPLATE_SUCCESS';
export const CREATE_ROLE_TEMPLATE_FAILED = 'CREATE_ROLE_TEMPLATE_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const UPDATE_BRANCH_GROUP = 'UPDATE_BRANCH_GROUP';
export const UPDATE_BRANCH_GROUP_SUCCESS = 'UPDATE_BRANCH_GROUP_SUCCESS';
export const UPDATE_BRANCH_GROUP_FAILED = 'UPDATE_BRANCH_GROUP_FAILED';

export const UPDATE_USER_GROUP = 'UPDATE_BRANCH_GROUP';
export const UPDATE_USER_GROUP_SUCCESS = 'UPDATE_BRANCH_GROUP_SUCCESS';
export const UPDATE_USER_GROUP_FAILED = 'UPDATE_BRANCH_GROUP_FAILED';

export const ADD_PERMISSION_TO_GROUP = 'ADD_PERMISSION_TO_GROUP';
export const ADD_PERMISSION_TO_GROUP_SUCCESS = 'ADD_PERMISSION_TO_GROUP_SUCCESS';
export const ADD_PERMISSION_TO_GROUP_FAILED = 'ADD_PERMISSION_TO_GROUP_FAILED';

export const REMOVE_PERMISSION_FROM_GROUP = 'REMOVE_PERMISSION_FROM_GROUP';
export const REMOVE_PERMISSION_FROM_GROUP_SUCCESS = 'REMOVE_PERMISSION_FROM_GROUP_SUCCESS';
export const REMOVE_PERMISSION_FROM_GROUP_FAILED = 'REMOVE_PERMISSION_FROM_GROUP_FAILED';

export const ADD_PERMISSION_TO_PROJECT = 'ADD_PERMISSION_TO_PROJECT';
export const ADD_PERMISSION_TO_PROJECT_SUCCESS = 'ADD_PERMISSION_TO_PROJECT_SUCCESS';
export const ADD_PERMISSION_TO_PROJECT_FAILED = 'ADD_PERMISSION_TO_PROJECT_FAILED';

export const REMOVE_PERMISSION_FROM_PROJECT = 'REMOVE_PERMISSION_FROM_PROJECT';
export const REMOVE_PERMISSION_FROM_PROJECT_SUCCESS = 'REMOVE_PERMISSION_FROM_PROJECT_SUCCESS';
export const REMOVE_PERMISSION_FROM_PROJECT_FAILED = 'REMOVE_PERMISSION_FROM_PROJECT_FAILED';

export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const SET_RECORD = 'SET_RECORD';
