import * as ActionTypes from './types';
import { RESET_STATE } from '../common';
import { find } from 'lodash';

const initialState = {
  branch_permissions: {},
  branch_permissions_loading: false,
  role_templates: {},
  role_templates_loading: false,
  user_roles: {},
  user_roles_loading: false,
  permission_list: {},
  permission_list_loading: false,
  groups_list: {},
  groups_list_loading: false,
  record: {},
  record_loading: false,
  action_loading: false,
  admin_projects: {},
  projects_loading: false,
  project_users: {},
  project_users_loading: false,
};

export const adminReducer = (state = initialState, action) => {
  const { type, payload, data } = action;
  switch (type) {
    case ActionTypes.GET_BRANCH_PERMISSIONS:
      return { ...state, branch_permissions_loading: true };
    case ActionTypes.GET_BRANCH_PERMISSIONS_SUCCESS:
      return { ...state, branch_permissions_loading: false, branch_permissions: payload };
    case ActionTypes.GET_BRANCH_PERMISSIONS_FAILED:
      return { ...state, branch_permissions_loading: false };
    case ActionTypes.GET_ROLE_TEMPLATES:
      return { ...state, role_templates_loading: true };
    case ActionTypes.GET_ROLE_TEMPLATES_SUCCESS:
      return { ...state, role_templates_loading: false, role_templates: payload };
    case ActionTypes.GET_BRANCH_PERMISSIONS_FAILED:
      return { ...state, role_templates_loading: false };
    case ActionTypes.GET_USER_ROLES:
      return { ...state, user_roles_loading: true };
    case ActionTypes.GET_USER_ROLES_SUCCESS:
      return { ...state, user_roles_loading: false, user_roles: payload };
    case ActionTypes.GET_USER_ROLES_FAILED:
      return { ...state, user_roles_loading: false };
    case ActionTypes.GET_PERMISSIONS_LIST:
      return { ...state, permission_list_loading: true };
    case ActionTypes.GET_PERMISSIONS_LIST_SUCCESS:
      return { ...state, permission_list_loading: false, permission_list: payload };
    case ActionTypes.GET_PERMISSIONS_LIST_FAILED:
      return { ...state, permission_list_loading: false };
    case ActionTypes.GET_GROUPS_LIST:
      return { ...state, groups_list_loading: true };
    case ActionTypes.GET_GROUPS_LIST_SUCCESS:
      return { ...state, groups_list_loading: false, groups_list: payload };
    case ActionTypes.GET_PROJECTS_BY_ADMIN:
      return { ...state, projects_loading: true };
    case ActionTypes.GET_PROJECTS_BY_ADMIN_SUCCESS:
       return { ...state, projects_loading: false, admin_projects: payload };
    case ActionTypes.GET_PROJECTS_BY_ADMIN_FAILED:
      return { ...state, projects_loading: false };
    case ActionTypes.GET_USERS_BY_PROJECT:
      return { ...state, project_users_loading: true };
    case ActionTypes.GET_USERS_BY_PROJECT_SUCCESS:
      return { ...state, project_users_loading: false, project_users: payload };
    case ActionTypes.GET_USERS_BY_PROJECT_FAILED:
      return { ...state, project_users_loading: false };
    case ActionTypes.ADD_PERMISSION_TO_GROUP:
    case ActionTypes.REMOVE_PERMISSION_FROM_GROUP:
      return { ...state, record_loading: true };
    case ActionTypes.ADD_PERMISSION_TO_GROUP:
    case ActionTypes.REMOVE_PERMISSION_FROM_GROUP:
      return { ...state, record_loading: false };
    case ActionTypes.ADD_PERMISSION_TO_PROJECT_SUCCESS:
      return {...state};
    case ActionTypes.ADD_PERMISSION_TO_GROUP_SUCCESS:
    case ActionTypes.REMOVE_PERMISSION_FROM_GROUP_SUCCESS:
      if (state?.record?.type === 'branch') {
        const group = state?.record?.group;
        group.permissions = payload?.data?.permissions;
        return {
          ...state,
          record_loading: false,
          record: {
            ...state.record,
            group,
          },
        };
      } if (state?.record?.type === 'role') {
        return {
          ...state,
          record_loading: false,
          record: {
            ...state.record,
            permissions: payload?.data?.permissions,
          },
        };
      }
    case ActionTypes.UPDATE_USER_STATUS:
      const users = state?.user_roles?.data;
      const user = find(users, ['id', data?.userId]);
      user.is_active = data?.payload?.is_active;
      const new_users = users.map((item) => (item.id === user.id ? user : item));
      return { ...state, project_users: new_users };
    case ActionTypes.CHANGE_USER_STATUS:
      return { ...state };
    case ActionTypes.SET_RECORD:
      return { ...state, record: payload, record_loading: false };
    case ActionTypes.UPDATE_BRANCH_GROUP:
      return { ...state, action_loading: true };
    case ActionTypes.UPDATE_BRANCH_GROUP_SUCCESS:
    case ActionTypes.UPDATE_BRANCH_GROUP_FAILED:
      return { ...state, action_loading: false };
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
