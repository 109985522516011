import _ from 'lodash';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import { RightOutlined } from '@ant-design/icons';
import { memo, useEffect, useState } from 'react';
import { Button, Col, Empty, Row, Spin, Select, Divider, List, Skeleton, Tag } from 'antd';
import {
    fetchNotifications,
    getNotification,
    markAsReadNotification,
} from '../../../../../services';
import { PROJECTS } from '../../../../../utils';
import ActivityDetail from './ActivityDetail';

const { Option } = Select;

function Activites({ projectId, getNotificationsCount = () => {}, getAllProjects = () => {} }) {
    const history = useHistory();
    const [tags, setTags] = useState([]);

    const [activeTag, setActiveTag] = useState();
    const [tagsLoaders, setTagsLoader] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [searchParam, setSearchParam] = useState(null);
    const [sortingParam, setSortingParam] = useState('-has_unread_notification');
    const [page, setPage] = useState(1);
    const [totalTagsCount, setTotalTagsCount] = useState(0);

    useEffect(() => {
        getNotifications(page); // Reset page
    }, [searchParam, sortingParam, page]);

    useEffect(() => {
        if (totalTagsCount === 0) {
            setTags([]);
        }
    }, [totalTagsCount]);

    const getNotifications = async (page) => {
        try {
            setTagsLoader(true);
            const response = await fetchNotifications({
                projectId,
                searchParam,
                sortingParam,
                page,
            });

            if (page === 1) {
                setTags([...response?.data]);
                setTotalTagsCount(response?.meta?.total_count);
            } else {
                // setTags(tags.concat(response?.data));
                setTags([...tags, ...response?.data]);
            }

            setTotalTagsCount(response?.meta?.total_count);

            setTagsLoader(false);
        } catch (error) {
            setTagsLoader(false);
        }
    };

    const sortBy = (value) => {
        setPage(1);
        if (value === 'has_unread_notification') {
            setSortingParam('-has_unread_notification');
        } else {
            setSortingParam(value);
        }
    };

    const searchInbox = (value) => {
        setPage(1);
        if (value) {
            setSearchParam(value);
        } else {
            setSearchParam('');
        }
    };

    const notificationReadHandler = (tag) => (tag?.has_unread_notification ? 'unread' : 'read');

    const markedAsRead = async (pkgId) => {
        await markAsReadNotification(projectId, pkgId);
    };

    // @TODO: Add notifications views/detail functionality using separate API
    const onNotificationClick = async (pkg) => {
        const notification = await getNotification(projectId, pkg.id);
        if (!_.isEmpty(notification) && notification.data.some(({ unread }) => unread)) {
            await markedAsRead(pkg.id);
            const readTags = tags.map((tag) => {
                if (tag.id === pkg.id) {
                    return {
                        ...tag,
                        has_unread_notification: false,
                    };
                }
                return tag;
            });
            getAllProjects();
            setTags([...readTags]);
            getNotificationsCount();
        }

        setActiveTag(pkg);
        setNotifications([...notification.data]); // @TODO: set notifications of specific tag in this state
    };

    const goToPackage = () => {
        const url = `${PROJECTS}/${projectId}/package/${activeTag?.id}`;
        history.push(url);
    };

    return (
        <Row>
            <Col span={6}>
                <Spin spinning={tagsLoaders}>
                    <div className="page-content-container mb-0">
                        <div className="task-inbox-actions">
                            <Row>
                                <Col span={16}>
                                    <input
                                        type="search"
                                        className="inbox-search"
                                        placeholder="Search ..."
                                        onChange={({ target }) => searchInbox(target.value)}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Select
                                        defaultValue="has_unread_notification"
                                        style={{ width: '100%' }}
                                        onChange={(value) => sortBy(value)}
                                    >
                                        <Option value="has_unread_notification">Unread</Option>
                                        <Option value="name">Alphabetical</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </div>

                        <div id="scrollableDiv" className="task-inbox-list custom-scroll-dark">
                            <ul>
                                {!_.isEmpty(tags) ? (
                                    <InfiniteScroll
                                        key="activity-sidebar"
                                        dataLength={tags?.length}
                                        next={() => {
                                            setPage(page + 1);
                                        }}
                                        hasMore={tags?.length < totalTagsCount}
                                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                                        endMessage={
                                            <Divider plain>It is all, nothing more.</Divider>
                                        }
                                        scrollableTarget="scrollableDiv"
                                    >
                                        <List
                                            dataSource={tags}
                                            renderItem={(tag, index) => (
                                                <List.Item
                                                    key={`${tag.name}-${tag.id}-${index}`}
                                                    className={`task-notification ${
                                                        tag.id === activeTag?.id
                                                            ? 'task-active'
                                                            : ''
                                                    }`}
                                                >
                                                    <article
                                                        onClick={() => onNotificationClick(tag)}
                                                        id={
                                                            activeTag?.id === tag.id ? 'active' : ''
                                                        }
                                                        className={notificationReadHandler(tag)}
                                                    >
                                                        <div>
                                                            <div className="task-inbox-header">
                                                                <h4>{tag?.name}</h4>
                                                            </div>
                                                            <p
                                                                className="task-inbox-desc"
                                                                title={tag?.description}
                                                            >
                                                                {tag?.description}
                                                            </p>
                                                        </div>
                                                        <div className="actions">
                                                            {notificationReadHandler(tag) ===
                                                                'unread' && (
                                                                <Tag color="volcano">Unread</Tag>
                                                            )}
                                                        </div>
                                                    </article>
                                                </List.Item>
                                            )}
                                        />
                                    </InfiniteScroll>
                                ) : !tagsLoaders && _.isEmpty(tags) ? (
                                    <Empty
                                        description="You don't have any packages"
                                        className="mt-30"
                                    />
                                ) : null}
                            </ul>
                        </div>
                    </div>
                </Spin>
            </Col>
            <Col span={18}>
                <ActivityDetail
                    tags={tags}
                    activeTag={activeTag}
                    tagsLoaders={tagsLoaders}
                    notifications={notifications}
                    goToPackage={goToPackage}
                />
            </Col>
        </Row>
    );
}

export default memo(Activites);
