import moment from 'moment';
import {
  memo, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  notification,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { AlectifyTable } from '../index';

import {
  PROJECTS,
  DATES_KEYS,
  SERVER_DATE_FORMAT,
  INPUT_DATE_FORMAT,
} from '../../utils';
import { progressViewColumns } from './progressViewColumns';
import { dateViewColumns } from './dateViewColumns';
import {
  getMasterTags, getTagsByProject, getTagsBySline, updateMasterTag,
} from '../../store/tags';
import TagDetailModal from './tagDetail';
import ShipDateModal from './shipDateModal';
import UpdateStatusModal from './updateStatusModal';
import ApprovalDatesModal from './approvalDatesModal';
import ViewModal from './ViewModal';
import ShipmentsModal from './ShipmentsModal';

function MemorizedTagListView({ slineId, masterTagView = false, datesView = false }) {
  const dispatch = useDispatch();
  const { masterTags, masterTagsLoading } = useSelector(({ tags }) => tags);
  const history = useHistory();
  const { projectId, subProjectId } = useParams();
  const { permissions } = useSelector(({ permission }) => permission);
  const [detailModal, setDetailModal] = useState(false);
  const [approvalDrawingModal, setApprovalDrawingModal] = useState(false);
  const [activeTag, setActiveTag] = useState(null);
  const [shipDateModal, setShipDateModal] = useState(false);
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [record, setRecord] = useState(null);
  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    if (masterTagView) {
    } else {
      dispatch(getTagsBySline(projectId, slineId));
    }
  }, [slineId]);

  const updateTagCallback = () => {
    notification.success({
      message: 'Successfully updated.',
      placement: 'topRight',
    });
    if (masterTagView) {
      dispatch(getMasterTags({ slineId: projectId || subProjectId }));
    } else {
      dispatch(getTagsBySline(projectId, slineId));
    }
  };

  const onTagUpdate = async (data, cb) => {
    Object.keys(data).forEach((key) => {
      if (DATES_KEYS.includes(key) && !_.isEmpty(data[key])) {
        data[key] = moment(moment(data[key], INPUT_DATE_FORMAT)).format(SERVER_DATE_FORMAT);
      }
    });
    dispatch(
      updateMasterTag(data, () => {
        updateTagCallback();
        cb && cb();
      }),
    );
  };

  const handleApprovalDrawing = (checked, record) => {
    onTagUpdate({ id: record.id, approval_dwg: checked, projectId: record.project }, () => {
      if (checked) {
        setApprovalDrawingModal(true);
        setActiveTag({ ...record });
      }
    });
  };

  const detailModalHandler = () => {
    setDetailModal(!detailModal);
  };

  const dateSubmissionHandler = (values) => {
    onTagUpdate({ id: activeTag.id, ...values, projectId: activeTag?.project });
    setApprovalDrawingModal(false);
    setActiveTag(null);
  };

  const statusUpdateHandler = (values) => {
    onTagUpdate({ id: activeTag.id, ...values, projectId: activeTag?.project });
    setUpdateStatusModal(false);
    setActiveTag(null);
  };

  const handleShipDateValue = (values) => {
    onTagUpdate({ id: activeTag.id, ...values, projectId: activeTag?.project });
    setShipDateModal(false);
    setActiveTag(null);
  };

  const onTagClickHandler = (record) => {
    const url = `${PROJECTS}/${record?.project}/package/${record?.packageroom?.[0].id}?tag_id=${record?.id}`;
    history.push(url);
  };

  useEffect(() => {
    dispatch(getTagsByProject({ id: projectId || subProjectId, pageSize: 10 }));
  }, []);

  return (
    <>
      <Form component={false}>
        <AlectifyTable
          size="small"
          onDataFetch={(data) => dispatch(getTagsByProject(data))}
          columns={
            datesView
              ? dateViewColumns({
                permissions,
                handleApprovalDrawing,
                setModalType,
                setRecord,
              })
              : progressViewColumns({
                permissions,
                handleApprovalDrawing,
                setModalType,
                setRecord,
              })
          }
          isDownloadZip={false}
          isEmailSharing={false}
          id={projectId || subProjectId}
          packageId={null}
          dataSource={masterTags?.data}
          loading={masterTagsLoading}
          selectRows={false}
          // onRow={(record, rowIndex) => onTagClickHandler(record)}
          onRowSelectionAction={() => { }}
          total={masterTags?.meta?.total_count}
          key={`${projectId || subProjectId}-review-files`}
          defaultPageSize={10}
        />
      </Form>

      <TagDetailModal
        title={activeTag?.name || 'N/A'}
        visible={detailModal}
        onSubmit={detailModalHandler}
        detail={activeTag}
        onCancel={() => {
          setActiveTag(null);
          setDetailModal(false);
        }}
      />

      <ApprovalDatesModal
        onCancel={() => setApprovalDrawingModal(false)}
        visible={approvalDrawingModal}
        onSubmit={dateSubmissionHandler}
        initialValues={{ ...activeTag }}
      />

      <ShipDateModal
        onCancel={() => {
          setActiveTag(null);
          setShipDateModal(false);
        }}
        visible={shipDateModal}
        initialValues={{ ...activeTag }}
        onSubmit={handleShipDateValue}
      />

      <UpdateStatusModal
        onCancel={() => setUpdateStatusModal(false)}
        visible={updateStatusModal}
        onSubmit={statusUpdateHandler}
        initialValues={{ ...activeTag }}
      />

      <ViewModal
        key={record?.id}
        visible={modalType === 'view'}
        record={record}
        onOk={() => {
          setRecord(null);
          setModalType(null);
        }}
        onCancel={() => {
          setRecord(null);
          setModalType(null);
        }}
      />
      {modalType === 'shipment' && (
        <ShipmentsModal
          visible={modalType === 'shipment'}
          data={record}
          onCancel={() => {
            setRecord(null);
            setModalType(null);
          }}
        />
      )}
    </>
  );
}

export const TagListView = memo(MemorizedTagListView);
