import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';
import { fetchAllDeadlines } from '../../services';

const DeadlinesModal = (props) => {
    const [deadlines, setDeadlines] = useState({ fetching: false, data: '' });

    const getDeadlines = async () => {
        try {
            setDeadlines({ ...deadlines, fetching: true });
            const response = await fetchAllDeadlines();
            setDeadlines({ fetching: false, data: response?.data?.email_body });
        } catch (error) {
            setDeadlines({ fetching: false, data: '' });
        }
    };

    useEffect(() => {
        getDeadlines();
    }, []);

    return (
        <Modal
            width={690}
            footer={null}
            open={props.visible}
            onCancel={props.onCancel}
            title="Deadlines For All Projects"
        >
            <Spin spinning={deadlines.fetching}>
                {deadlines.data ? (
                    <div dangerouslySetInnerHTML={{ __html: deadlines.data }} />
                ) : (
                    !deadlines.fetching && (
                        <p className="deadline-message-text">You are all caught up!</p>
                    )
                )}
            </Spin>
        </Modal>
    );
};

DeadlinesModal.defaultProps = {
    visible: false,
    onCancel: () => {},
};

DeadlinesModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default memo(DeadlinesModal);
