import { RESET_STATE } from '../common';
import * as ActionTypes from './types';

const initialState = {
    vendorSummary: {
        fetching: false,
        data: null,
    },
    summarySheet: {
        fetching: false,
        data: null,
        meta: {},
    },
    vendors: {
        meta: {},
        data: null,
        fetching: false,
    },
    vendorAssociates: {
        meta: {},
        data: null,
        fetching: false,
    },
    vendorItemDetail: {
        data: null,
        fetching: false,
    },
    verticalType: 'contruction',
};

export const financialReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // Finanical Summary
        case ActionTypes.FETCH_SUMMARY_INITATE:
            return { ...state, summarySheet: { ...state.summarySheet, fetching: true } };

        case ActionTypes.FETCH_SUMMARY_SUCCESS:
            return {
                ...state,
                summarySheet: {
                    ...state.summarySheet,
                    fetching: false,
                    data: payload.data,
                    meta: payload.meta,
                },
            };

        case ActionTypes.FETCH_SUMMARY_FAILURE:
            return {
                ...state,
                summarySheet: { ...state.summarySheet, fetching: false, data: null },
            };

        // Vendor Quotes Summary
        case ActionTypes.FETCH_VENDOR_QUOTES_SUMMARY_INITATE:
            return { ...state, vendorSummary: { ...state.vendorSummary, fetching: true } };

        case ActionTypes.FETCH_VENDOR_QUOTES_SUMMARY_SUCCESS:
            return {
                ...state,
                vendorSummary: {
                    ...state.vendorSummary,
                    fetching: false,
                    data: payload.data,
                },
            };

        case ActionTypes.FETCH_VENDOR_QUOTES_SUMMARY_FAILURE:
            return {
                ...state,
                vendorSummary: { ...state.vendorSummary, fetching: false, data: null },
            };

        // Vendor Estimations/Items
        case ActionTypes.FETCH_VENDOR_ESTIMATIONS_INITIATE:
        case ActionTypes.DELETE_VENDOR_ESTIMATIONS_INITIATE:
            return { ...state, vendors: { ...state.vendors, fetching: true } };

        case ActionTypes.FETCH_VENDOR_ESTIMATIONS_SUCCESS:
            return {
                ...state,
                vendors: {
                    ...state.vendors,
                    fetching: false,
                    data: payload.data,
                    meta: payload.meta,
                },
            };

        case ActionTypes.FETCH_VENDOR_ESTIMATIONS_FAILURE:
            return {
                ...state,
                vendors: { ...state.vendors, fetching: false, data: null },
            };

        // Vendor Estimations/Items Details
        case ActionTypes.FETCH_VENDOR_ESTIMATION_DETAIL_INITIATE:
        case ActionTypes.DELETE_VENDOR_ESTIMATIONS_INITIATE:
            return { ...state, vendorItemDetail: { ...state.vendorItemDetail, fetching: true } };

        case ActionTypes.FETCH_VENDOR_ESTIMATION_DETAIL_SUCCESS:
            return {
                ...state,
                vendorItemDetail: {
                    ...state.vendorItemDetail,
                    fetching: false,
                    data: payload.data,
                },
            };

        case ActionTypes.FETCH_VENDOR_ESTIMATION_DETAIL_FAILURE:
            return {
                ...state,
                vendorItemDetail: { ...state.vendorItemDetail, fetching: false, data: null },
            };

        // Vendor Associates
        case ActionTypes.FETCH_VENDOR_ASSOCIATES_INITIATE:
        case ActionTypes.DELETE_VENDOR_ASSOCIATES_INITIATE:
            return { ...state, vendorAssociates: { ...state.vendorAssociates, fetching: true } };

        case ActionTypes.FETCH_VENDOR_ASSOCIATES_SUCCESS:
            return {
                ...state,
                vendorAssociates: {
                    ...state.vendorAssociates,
                    fetching: false,
                    data: payload.data,
                    meta: payload.meta,
                },
            };

        case ActionTypes.FETCH_VENDOR_ASSOCIATES_FAILURE:
            return {
                ...state,
                vendorAssociates: { ...state.vendorAssociates, fetching: false, data: null },
            };
        case ActionTypes.SET_FINANCIAL_VERTICAL_TYPE:
            return {
                ...state,
                verticalType: payload,
            };
        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
