import { memo, useEffect, useState } from 'react';
import {
  Card, Col, Layout, Progress, Row, Select, Space, Table,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import GoogleMapReact from 'google-map-react';
import { getDashboardDetails } from '../../store/dashboard';
import { fetchSentiments, fetchSentimentsDetail } from '../../services';
import { SENTIMENTS } from '../../utils';
import {
  REVENUE_PERFORMANCE_SERIES,
  MARKET_INDICATOR_OPTIONS,
  MARKET_INDICATOR_SERIES,
  CONTRACTORS_SERIES,
  CONTRACTORS_OPTIONS,
  MANUFACTURE_RATING_OPTIONS,
  MANUFACTURE_RATING_SERIES,
  REVENUE_PERFORMANCE_OPTIONS,
} from './helper';
import { CompanyInsightsModal } from './CompanyInsightsModal';
import useColumns from './effects/useColumns';
import './styles.scss';

const { Content } = Layout;
const { Option } = Select;

const PROJECT_STATUSES = {
  all: null,
  ongoing: 'False',
  finished: 'True',
};

function CardLoader({ height }) {
  return (
    <>
      <Col span={6}>
        <Card className="mb-30" style={{ height }} loading />
      </Col>
      <Col span={6}>
        <Card className="mb-30" style={{ height }} loading />
      </Col>
      <Col span={6}>
        <Card className="mb-30" style={{ height }} loading />
      </Col>
      <Col span={6}>
        <Card className="mb-30" style={{ height }} loading />
      </Col>
    </>
  );
}

const Dashboard = memo(() => {
  const columns = useColumns();
  const [companyInsights, setCompanyInsights] = useState({
    visible: false,
    variant: null,
  });
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const { details, loading } = useSelector(({ dashboard }) => dashboard);
  const [sentiments, setSentiments] = useState({
    data: {},
    fetching: false,
    projectStatus: 'ongoing',
  });
  const [sentimentsDetail, setSentimentsDetail] = useState({
    data: [],
    total: 0,
    current: 1,
    pageSize: 10,
    fetching: false,
    type: 'Positive',
    projectStatus: 'ongoing',
  });

  const getSentiments = async () => {
    try {
      setSentiments({ ...sentiments, fetching: true });
      const params = {
        is_finished: PROJECT_STATUSES[sentiments.projectStatus],
      };
      const response = await fetchSentiments(user?.company?.id, params);
      setSentiments({
        ...sentiments,
        fetching: false,
        data: response?.data,
      });
    } catch (error) {
      setSentiments({ ...sentiments, fetching: false });
    }
  };

  const getSentimentDetail = async () => {
    try {
      setSentimentsDetail({ ...sentimentsDetail, fetching: true });
      const params = {
        page: sentimentsDetail.current,
        per_page: sentimentsDetail.pageSize,
        sentiment: sentimentsDetail.type.toLowerCase(),
        finished: PROJECT_STATUSES[sentimentsDetail.projectStatus],
      };
      const response = await fetchSentimentsDetail(user?.company?.id, params);
      setSentimentsDetail({
        ...sentimentsDetail,
        fetching: false,
        data: [...response?.data],
        total: response.meta.total_count,
      });
    } catch (error) {
      setSentimentsDetail({ ...sentimentsDetail, fetching: false });
    }
  };

  useEffect(() => {
    dispatch(getDashboardDetails(user?.company?.id));
  }, []);

  useEffect(() => {
    getSentiments();
  }, [sentiments.projectStatus]);

  useEffect(() => {
    getSentimentDetail();
  }, [
    sentimentsDetail.type,
    sentimentsDetail.current,
    sentimentsDetail.pageSize,
    sentimentsDetail.projectStatus,
  ]);

  const onPaginationChange = ({ current, pageSize }) => {
    setSentimentsDetail({ ...sentimentsDetail, current, pageSize });
  };

  const onChangeSentimentType = (type) => {
    setSentimentsDetail({ ...sentimentsDetail, type, current: 1 });
  };

  const onChangeProjectStatus = (projectStatus, type) => {
    if (type === 'sentiments') {
      setSentiments({ ...sentiments, projectStatus });
      return;
    }
    setSentimentsDetail({ ...sentimentsDetail, projectStatus, current: 1 });
  };

  const onClickDashboardCard = (variant) => {
    setCompanyInsights({ visible: true, variant });
  };

  const mapOptions = {
    center: { lat: 45, lng: -100 }, // Center coordinates (approximate center of North America)
    zoom: 4,
  };

  return (
    <Content className="fixed-layout">
      <div className="dashboard-container main-dashboard">
        <div className="dashboard-lg-card">
          <h2 className="heading">
            {user?.company?.name}
            {' '}
            Statistics
          </h2>
          <Row gutter={30}>
            {loading ? (
              <CardLoader height={134} />
            ) : (
              <>
                <Col span={6}>
                  <div
                    className="page-content-container dashboard-card colorful-card bg-light-purple mb-0 cursor-pointer"
                    onClick={() => onClickDashboardCard('total')}
                  >
                    <h4 className="card-heading custom-card-heading">
                      Total Projects
                    </h4>
                    <h1 className="dashboard-card-text">
                      {details?.no_of_projects || 0}
                    </h1>
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    className="page-content-container dashboard-card colorful-card bg-light-grey mb-0 cursor-pointer"
                    onClick={() => onClickDashboardCard('active')}
                  >
                    <h4 className="card-heading custom-card-heading">
                      Active Projects
                    </h4>
                    <h1 className="dashboard-card-text">
                      {details?.no_of_active_projects || 0}
                    </h1>
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    className="page-content-container dashboard-card colorful-card bg-light-pinkish mb-0 cursor-pointer"
                    onClick={() => onClickDashboardCard('completed')}
                  >
                    <h4 className="card-heading custom-card-heading">
                      Completed Projects
                    </h4>
                    <h1 className="dashboard-card-text">
                      {details?.no_of_completed_projects || 0}
                    </h1>
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    className="page-content-container dashboard-card colorful-card bg-light-greenish mb-0 cursor-pointer"
                    onClick={() => onClickDashboardCard('users')}
                  >
                    <h4 className="card-heading custom-card-heading">
                      Total Users
                    </h4>
                    <h1 className="dashboard-card-text">
                      {details?.no_of_total_users || 0}
                    </h1>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
        <div className="page-content-container dashboard-lg-card">
          <h4 className="heading">
            Sentiments Analysis
            <Select
              style={{ width: 180 }}
              value={sentiments.projectStatus}
              onChange={(val) => onChangeProjectStatus(val, 'sentiments')}
            >
              <Option value="all">All Projects</Option>
              <Option value="ongoing">On-going Projects</Option>
              <Option value="finished">Finished Projects</Option>
            </Select>
          </h4>
          <div className="sentiments">
            <Row gutter={30}>
              {sentiments.fetching ? (
                <CardLoader height={200} />
              ) : (
                <>
                  <Col span={6}>
                    <div
                      className="sentiment-wrapper happy"
                      onClick={() => onChangeSentimentType('Positive')}
                    >
                      <span className="sentiment happy" />
                      <h1>Positive</h1>
                      <h2>{sentiments?.data?.positive || 0}</h2>
                      <div className="sentiment-progress">
                        <Progress
                          percent={
                            Math.round(
                              sentiments?.data?.positive_percentage,
                            ) || 0
                          }
                          strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      className="sentiment-wrapper sad"
                      onClick={() => onChangeSentimentType('Negative')}
                    >
                      <span className="sentiment" />
                      <h1>Negative</h1>
                      <h2>{sentiments?.data?.negative || 0}</h2>
                      <div className="sentiment-progress">
                        <Progress
                          percent={
                            Math.round(
                              sentiments?.data?.negative_percentage,
                            ) || 0
                          }
                          strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      className="sentiment-wrapper neutral"
                      onClick={() => onChangeSentimentType('Neutral')}
                    >
                      <span className="sentiment" />
                      <h1>Neutral</h1>
                      <h2>{sentiments?.data?.neutral || 0}</h2>
                      <div className="sentiment-progress">
                        <Progress
                          percent={
                            Math.round(
                              sentiments?.data?.neutral_percentage,
                            ) || 0
                          }
                          strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      className="sentiment-wrapper mixed"
                      onClick={() => onChangeSentimentType('Mixed')}
                    >
                      <span className="sentiment" />
                      <h1>Mixed</h1>
                      <h2>{sentiments?.data?.mixed || 0}</h2>
                      <div className="sentiment-progress">
                        <Progress
                          percent={
                            Math.round(
                              sentiments?.data?.mixed_percentage,
                            ) || 0
                          }
                          strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>
        <div className="page-content-container dashboard-lg-card">
          <h4 className="heading">
            Project Sentiments
            <div>
              <Space>
                <Select
                  style={{ width: 180 }}
                  value={sentimentsDetail.projectStatus}
                  onChange={onChangeProjectStatus}
                >
                  <Option value="all">All Projects</Option>
                  <Option value="ongoing">On-going Projects</Option>
                  <Option value="finished">Finished Projects</Option>
                </Select>
                <Select
                  value={sentimentsDetail.type}
                  style={{ width: 150 }}
                  onChange={onChangeSentimentType}
                >
                  {SENTIMENTS.map((sentiment) => (
                    <Option value={sentiment} key={sentiment}>
                      {sentiment}
                    </Option>
                  ))}
                </Select>
              </Space>
            </div>
          </h4>
          <Table
            rowKey={(item, idx) => `${item.master_project_name}-${idx}`}
            columns={columns}
            loading={sentimentsDetail.fetching}
            dataSource={sentimentsDetail?.data || []}
            pagination={{
              showSizeChanger: true,
              total: sentimentsDetail.total,
              current: sentimentsDetail.current,
              pageSize: sentimentsDetail?.pageSize,
            }}
            onChange={onPaginationChange}
          />
        </div>
      </div>

      <Row className="mt-20" gutter={20}>
        <Col span={10}>
          <div className="page-content-container mb-15">
            <div className="chart-wrapper">
              <div>
                <ReactApexChart
                  options={REVENUE_PERFORMANCE_OPTIONS}
                  series={REVENUE_PERFORMANCE_SERIES}
                  type="line"
                  height={500}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col span={14}>
          <div className="page-content-container mb-15">
            <div className="chart-wrapper">
              <div>
                <ReactApexChart
                  options={MARKET_INDICATOR_OPTIONS}
                  series={MARKET_INDICATOR_SERIES}
                  type="area"
                  height={500}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={14}>
          <div className="page-content-container mb-15">
            <h4 className="card-heading">Growth Areas</h4>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                libraries: ['places', 'geometry'],
              }}
              defaultCenter={mapOptions.center}
              defaultZoom={mapOptions.zoom}
            />
          </div>
        </Col>
        <Col span={10}>
          <div className="page-content-container mb-15">
            <div className="chart-wrapper">
              <div>
                <ReactApexChart
                  options={MANUFACTURE_RATING_OPTIONS}
                  series={MANUFACTURE_RATING_SERIES}
                  type="line"
                  height={483}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={15} className="m-20">
        <Col span={6}>
          <div className="page-content-container dashboard-card colorful-card bg-light-yellowish">
            <h4 className="card-heading custom-card-heading">Revenue</h4>
            <h1 className="dashboard-card-text">$765 M</h1>
          </div>
        </Col>
        <Col span={6}>
          <div className="page-content-container dashboard-card colorful-card bg-light-pinkis">
            <h4 className="card-heading custom-card-heading">Disputes</h4>
            <h1 className="dashboard-card-text">2,567</h1>
          </div>
        </Col>
        <Col span={6}>
          <div className="page-content-container dashboard-card colorful-card bg-light-purple ">
            <h4 className="card-heading custom-card-heading">Change Orders</h4>
            <h1 className="dashboard-card-text">5,003</h1>
          </div>
        </Col>
        <Col span={6}>
          <div className="page-content-container dashboard-card colorful-card bg-light-greenish">
            <h4 className="card-heading custom-card-heading">Repeat Orders</h4>
            <h1 className="dashboard-card-text">73</h1>
          </div>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={14}>
          <div className="page-content-container">
            <div className="chart-wrapper">
              <div>
                <ReactApexChart
                  options={CONTRACTORS_OPTIONS}
                  series={CONTRACTORS_SERIES}
                  type="bar"
                  height={500}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col span={10}>
          <div
            className="page-content-container dashboard-card dashboard-down-cards"
            style={{ height: 276 }}
          >
            <h4 className="card-heading custom-card-heading">Projects Performance</h4>
            <ul className="dashboard-list">
              <li>
                <div className="left-side">On-time</div>
                <p>312</p>
              </li>
              <li>
                <div className="left-side">Early</div>
                <p>44</p>
              </li>
              <li>
                <div className="left-side">Delayed</div>
                <p>101</p>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      {companyInsights.visible && (
        <CompanyInsightsModal
          {...companyInsights}
          onCancel={() => setCompanyInsights({ visible: false, variant: null })}
        />
      )}
    </Content>
  );
});

export default Dashboard;
