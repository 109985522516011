import { RESET_STATE } from '../common';
import * as ActionTypes from './types';

const initialState = {
  loading: false,
  error: null,
  roomPackages: [],
  individualPackages: [],
  allPackages: [],
  approvals: [],
  markups: [],
  masterPackages: [],
  masterPackagesLoading: false,
};

export const packageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_PACKAGES_INITIATE:
      return { ...state, loading: true };

    case ActionTypes.GET_MASTER_PACKAGES_LOADING:
      return { ...state, masterPackagesLoading: true };

    case ActionTypes.GET_MASTER_PACKAGES_SUCCESS:
      return { ...state, masterPackages: payload, masterPackagesLoading: false };

    case ActionTypes.GET_ALL_PACKAGES_SUCCESS:
      return {
        ...state,
        allPackages: payload, // Todo check this once.
        loading: false,
      };

    case ActionTypes.PACKAGE_LIST:
      return {
        ...state,
        roomPackages: payload.room_packages,
        individualPackages: payload.individual_packages,
      };

    case ActionTypes.ADD_PACKAGE_TO_LIST:
      return {
        ...state,
        allPackages: [...state.allPackages, payload],
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
