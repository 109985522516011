import PropTypes from 'prop-types';
import { memo } from 'react';
import { Card, Modal } from 'antd';
import { ASSOCIATE_VENDOR_ITEMS_REASONS } from '../../../../../../../utils';
import VendorItemInfo from './VendorItemInfo';
import VendorAssociateList from './VendorAssociateList';
import CreateVendorAssociate from './CreateVendorAssociate';

const AdderDeductVendorItem = memo((props) => {
    const showCreateForm = () => {
        return Object.keys(ASSOCIATE_VENDOR_ITEMS_REASONS).includes(props.variant);
    };

    return (
        <Modal
            centered
            width={900}
            footer={null}
            open={props.visible}
            onCancel={props.onCancel}
            className="equipment-detail-modal"
        >
            <VendorItemInfo {...props.data} />
            <Card title="Price Summary">
                {showCreateForm() && <CreateVendorAssociate {...props} />}
                <VendorAssociateList {...props.data} />
            </Card>
        </Modal>
    );
});

AdderDeductVendorItem.defaultProps = {
    data: null,
    visible: false,
    variant: 'add',
    onCancel: () => {},
};

AdderDeductVendorItem.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    variant: PropTypes.string,
};

export default AdderDeductVendorItem;
