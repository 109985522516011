import * as ActionTypes from './types';
import { fetchAllPackages, fetchFilteredPackages2 } from '../../services';

export const setPackage = (data) => (dispatch) => {
  dispatch({ type: ActionTypes.PACKAGE_LIST, payload: data });
};

export const getPackagesByProject = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_MASTER_PACKAGES_LOADING });
    const response = await fetchFilteredPackages2(data);
    dispatch({ type: ActionTypes.GET_MASTER_PACKAGES_SUCCESS, payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getAllPackages = (projectId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_ALL_PACKAGES_INITIATE });
    const response = await fetchAllPackages(projectId, 'dropdown');
    dispatch({ type: ActionTypes.GET_ALL_PACKAGES_SUCCESS, payload: response?.data });
  } catch (error) {
    console.log(error);
  }
};

export const addPackageToList = (payload) => async (dispatch) => {
  dispatch({ type: ActionTypes.ADD_PACKAGE_TO_LIST, payload });
};
