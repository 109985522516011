export const UPDATE_APPROVAL = 'UPDATE_APPROVAL';
export const REMOVE_MARKUP_FILE = 'REMOVE_MARKUP_FILE';

export const GET_APPROVALS_INITIATE = 'GET_APPROVALS_INITIATE';
export const GET_APPROVALS_SUCCESS = 'GET_APPROVALS_SUCCESS';
export const GET_APPROVALS_FAILURE = 'GET_APPROVALS_FAILURE';

export const GET_MARKUPS_INITIATE = 'GET_MARKUPS_INITIATE';
export const GET_MARKUPS_SUCCESS = 'GET_MARKUPS_SUCCESS';
export const GET_MARKUPS_FAILURE = 'GET_MARKUPS_FAILURE';

export const UPLOADING_MARKUP = 'UPLOADING_MARKUP';

export const SEND_FOR_RELEASE_INITIATE = 'SEND_FOR_RELEASE_INITIATE';
export const SEND_FOR_RELEASE_SUCCESS = 'SEND_FOR_RELEASE_SUCCESS';
export const SEND_FOR_RELEASE_FAILURE = 'SEND_FOR_RELEASE_FAILURE';
