import { memo } from 'react';
import ProjectCosts from './project-costs';
import BillingSummary from './billing-summary';
import CustomerPurchaseOrders from './customer-purchase-order';
import SideBySideView from './side-by-side';

const Billing = memo(() => (
  <>
    <BillingSummary />
    <SideBySideView />
    <ProjectCosts />
    <CustomerPurchaseOrders />
  </>
));

export default Billing;
