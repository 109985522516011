import { Breadcrumb, Layout } from 'antd';
import ProfileSettingTabs from './tabs';

const { Content } = Layout;

const ProfileSettings = () => {
    return (
        <Content>
            <Breadcrumb separator=">">
                <Breadcrumb.Item>Alectify</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span className="cursor-pointer">Profile Settings</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <ProfileSettingTabs />
        </Content>
    );
};

export default ProfileSettings;
