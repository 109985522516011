const {
  REACT_APP_STAGE,
  REACT_APP_DEV_BASE_DOMAIN,
  REACT_APP_DEV_BASE_URL,
  REACT_APP_DEV_WS_URL,
  REACT_APP_PROD_BASE_DOMAIN,
  REACT_APP_PROD_BASE_URL,
  REACT_APP_PROD_WS_URL,
  REACT_APP_STAGE_BASE_URL,
  REACT_APP_STAGE_BUCKET_NAME,
  REACT_APP_PROD_BUCKET_NAME,
  REACT_APP_DEV_BUCKET_NAME,
  REACT_APP_IS_ACTIVITY_ACTIVE,
  REACT_APP_IS_INBOX_ACTIVE,
  REACT_APP_IS_BIRDSEYE_ACTIVE,
  REACT_APP_IS_PACKAGES_ACTIVE,
  REACT_APP_IS_TASKS_ACTIVE,
  REACT_APP_IS_FILES_ACTIVE,
  REACT_APP_IS_INSIGHTS_ACTIVE,
  REACT_APP_IS_MANUAL_SCHEUDLE_ACTIVE,
  REACT_APP_IS_MAIN_DASHBOARD_ACTIVE,
  REACT_APP_ONE_TO_ONE_CHAT_ACTIVE,
  REACT_APP_ASSET_DETAIL_REVIEW_ACTIVE,
} = process.env;

export const getWebSocketBaseUrl = () => {
  switch (REACT_APP_STAGE) {
    case 'stage':
      return REACT_APP_DEV_WS_URL;
    case 'production':
      return REACT_APP_PROD_WS_URL;
    default:
      return REACT_APP_DEV_WS_URL;
  }
};

export const getBaseUrlDomain = () => {
  switch (REACT_APP_STAGE) {
    case 'stage':
      return REACT_APP_DEV_BASE_DOMAIN;
    case 'production':
      return REACT_APP_PROD_BASE_DOMAIN;
    default:
      return REACT_APP_DEV_BASE_DOMAIN;
  }
};

export const getBaseUrl = () => {
  switch (REACT_APP_STAGE) {
    case 'stage':
      return REACT_APP_STAGE_BASE_URL;
    case 'production':
      return REACT_APP_PROD_BASE_URL;
    default:
      return REACT_APP_DEV_BASE_URL;
  }
};

export const getBucketName = () => {
  switch (REACT_APP_STAGE) {
    case 'stage':
      return REACT_APP_STAGE_BUCKET_NAME;
    case 'production':
      return REACT_APP_PROD_BUCKET_NAME;
    default:
      return REACT_APP_DEV_BUCKET_NAME;
  }
};

export const isInsightsActive = () => JSON.parse(REACT_APP_IS_INSIGHTS_ACTIVE);

export const isActivityActive = () => JSON.parse(REACT_APP_IS_ACTIVITY_ACTIVE);

export const isInboxActive = () => JSON.parse(REACT_APP_IS_INBOX_ACTIVE);

export const isTasksActive = () => JSON.parse(REACT_APP_IS_TASKS_ACTIVE);

export const isPackagesActive = () => JSON.parse(REACT_APP_IS_PACKAGES_ACTIVE);

export const isBirdseyeActive = () => JSON.parse(REACT_APP_IS_BIRDSEYE_ACTIVE);

export const isFilesActive = () => JSON.parse(REACT_APP_IS_FILES_ACTIVE);

export const isManualScheduleActive = () => JSON.parse(REACT_APP_IS_MANUAL_SCHEUDLE_ACTIVE);

export const isMainDashboardActive = () => JSON.parse(REACT_APP_IS_MAIN_DASHBOARD_ACTIVE);

export const isOneToOneChatActive = () => JSON.parse(REACT_APP_ONE_TO_ONE_CHAT_ACTIVE);

export const isAssetDetailReviewActive = () => JSON.parse(REACT_APP_ASSET_DETAIL_REVIEW_ACTIVE);
