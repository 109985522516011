import { message } from 'antd';
import {
  patchPermissionToGroup,
  deletePermissionFromGroup,
  postBranchGroup,
  postUserGroup,
  postCreateRoleTemplate,
  fetchBranchPermissions,
  fetchPermissionsList,
  fetchRoleTemplates,
  fetchUserRoles,
  fetchLicenseGroupList,
  updateUser,
  fetchProjectsbyAdmin,
  fetchUsersByProject,
  addUserPermissionToProject,
  removeUserPermissionFromProject,
  toggleUserStatus,
} from '../../services';
import * as ActionTypes from './types';

export const updateUserInfo = (data) => async (dispatch) => {
  try {
    await updateUser(data);
    dispatch({ type: ActionTypes.SET_RECORD, payload: null });
    dispatch({ type: ActionTypes.UPDATE_USER_SUCCESS, payload: null });
  } catch (error) {
    dispatch({ type: ActionTypes.UPDATE_USER_FAILED, payload: error });
  }
};

export const setAdminRecord = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.SET_RECORD, payload: data });
  } catch (error) {
    // Error catch
  }
};

export const changeUserStatus = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CHANGE_USER_STATUS, payload: data });
    dispatch({ type: ActionTypes.UPDATE_USER_STATUS, data });
    const response = await toggleUserStatus(data);
    message.success('User Status changed');
  } catch (error) {
    // Error catch
    message.success('Failed to change user status');
  }
};

export const createRoleTemplate = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CREATE_ROLE_TEMPLATE });
    const response = await postCreateRoleTemplate(data);
    dispatch({ type: ActionTypes.CREATE_ROLE_TEMPLATE_SUCCESS, payload: response });
    dispatch({ type: ActionTypes.SET_RECORD, payload: null });
    message.success('New Role template added');
    dispatch(getRoleTemplates());
  } catch (error) {
    dispatch({ type: ActionTypes.CREATE_ROLE_TEMPLATE_FAILED, payload: error });
    message.success('Failed to add role template');
  }
};

export const updateBranchGroup = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_BRANCH_GROUP });
    const response = await postBranchGroup(data);
    dispatch({ type: ActionTypes.UPDATE_BRANCH_GROUP_SUCCESS, payload: response });
    dispatch({ type: ActionTypes.SET_RECORD, payload: null });
  } catch (error) {
    dispatch({ type: ActionTypes.UPDATE_BRANCH_GROUP_FAILED, payload: error });
  }
};

export const updateUserGroup = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_USER_GROUP });
    const response = await postUserGroup(data);
    dispatch({ type: ActionTypes.UPDATE_USER_GROUP_SUCCESS, payload: response });
    dispatch({ type: ActionTypes.SET_RECORD, payload: null });
  } catch (error) {
    dispatch({ type: ActionTypes.UPDATE_USER_GROUP_FAILED, payload: error });
  }
};

export const addPermissionToGroup = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.ADD_PERMISSION_TO_GROUP });
    const response = await patchPermissionToGroup(data);
    dispatch({ type: ActionTypes.ADD_PERMISSION_TO_GROUP_SUCCESS, payload: response });
    message.success('Permission added');
    dispatch(getRoleTemplates());
  } catch (error) {
    dispatch({ type: ActionTypes.ADD_PERMISSION_TO_GROUP_FAILED, payload: error });
    message.error('Failed to modify permissions');
  }
};

export const removePermissionFromGroup = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.REMOVE_PERMISSION_FROM_GROUP });
    const response = await deletePermissionFromGroup(data);
    dispatch({ type: ActionTypes.REMOVE_PERMISSION_FROM_GROUP_SUCCESS, payload: response });
    message.success('Permission removed');
    dispatch(getRoleTemplates());
  } catch (error) {
    dispatch({ type: ActionTypes.REMOVE_PERMISSION_FROM_GROUP_FAILED, payload: error });
    message.error('Failed to modify permissions');
  }
};

export const addPermissionToRole = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.ADD_PERMISSION_TO_GROUP });
    const response = await patchPermissionToGroup(data);
    dispatch({ type: ActionTypes.ADD_PERMISSION_TO_GROUP_SUCCESS, payload: response });
    dispatch(getRoleTemplates());
  } catch (error) {
    dispatch({ type: ActionTypes.ADD_PERMISSION_TO_GROUP_FAILED, payload: error });
  }
};

export const removePermissionFromRole = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.REMOVE_PERMISSION_FROM_GROUP });
    const response = await deletePermissionFromGroup(data);
    dispatch({ type: ActionTypes.REMOVE_PERMISSION_FROM_GROUP_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.REMOVE_PERMISSION_FROM_GROUP_FAILED, payload: error });
  }
};

export const getGroupsList = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_GROUPS_LIST });
    const response = await fetchLicenseGroupList(data);
    dispatch({ type: ActionTypes.GET_GROUPS_LIST_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_GROUPS_LIST_FAILED, payload: error });
  }
};

export const getPermissionsList = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_PERMISSIONS_LIST });
    const response = await fetchPermissionsList(data);
    dispatch({ type: ActionTypes.GET_PERMISSIONS_LIST_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_PERMISSIONS_LIST_FAILED, payload: error });
  }
};

export const getBranchPermissions = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_BRANCH_PERMISSIONS });
    const response = await fetchBranchPermissions(data);
    dispatch({ type: ActionTypes.GET_BRANCH_PERMISSIONS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_BRANCH_PERMISSIONS_FAILED, payload: error });
  }
};

export const getRoleTemplates = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_ROLE_TEMPLATES });
    const response = await fetchRoleTemplates();
    dispatch({ type: ActionTypes.GET_ROLE_TEMPLATES_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_ROLE_TEMPLATES_FAILED, payload: error });
  }
};

export const getAdminUsers = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_USER_ROLES });
    const response = await fetchUserRoles(data);
    dispatch({ type: ActionTypes.GET_USER_ROLES_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_USER_ROLES_FAILED, payload: error });
  }
};

export const getProjectsByAdmin = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_PROJECTS_BY_ADMIN });
    const response = await fetchProjectsbyAdmin(data);
    dispatch({ type: ActionTypes.GET_PROJECTS_BY_ADMIN_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_PROJECTS_BY_ADMIN_FAILED, payload: error });
  }
};

export const getProjectUsers = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_USERS_BY_PROJECT });
    const response = await fetchUsersByProject(data);
    dispatch({ type: ActionTypes.GET_USERS_BY_PROJECT_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_USERS_BY_PROJECT_FAILED, payload: error });
  }
};

export const addPermissionToProject = (data) => async (dispatch) => {
  try {
    const response = await addUserPermissionToProject(data);
    dispatch({ type: ActionTypes.ADD_PERMISSION_TO_PROJECT_SUCCESS, payload: response });
    message.success('Permission added');
  } catch (error) {
    dispatch({ type: ActionTypes.ADD_PERMISSION_TO_PROJECT_FAILED, payload: error });
  }
};

export const removePermissionFromProject = (data) => async (dispatch) => {
  try {
    const response = await removeUserPermissionFromProject(data);
    dispatch({ type: ActionTypes.REMOVE_PERMISSION_FROM_PROJECT_SUCCESS, payload: response });
    message.success('Permission removed');
  } catch (error) {
    dispatch({ type: ActionTypes.REMOVE_PERMISSION_FROM_PROJECT_FAILED, payload: error });
  }
};
