import { memo } from 'react';
import PurchaseOrder from './purchase-order';
import Vendors from './vendors';

const Financials = memo(() => (
  <div className="financials">
    {/* <PurchaseOrder /> */}
    <Vendors />
  </div>
));

export default Financials;
