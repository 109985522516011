export function ProgressBar({
  bgcolor = '', progress = 0, height = 5, text = '',
}) {
  const Parentdiv = {
    height,
    width: '100%',
    backgroundColor: 'whitesmoke',
    backgroundColor: '#DCDFE1',
    borderRadius: 40,
    position: 'relative',
  };

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    maxWidth: '100%',
    backgroundColor: bgcolor,
    backgroundImage:
            bgcolor || 'linear-gradient(to right, rgb(16, 142, 233) 0%, rgb(135, 208, 104) 100%)',
    borderRadius: 40,
    textAlign: 'right',
  };

  const progresstext = {
    paddingRight: 15,
    display: 'inline-block',
    paddingTop: 1,
    fontSize: 12,
    verticalAlign: 'top',
    color: '#fff',
    fontWeight: 900,
  };

  const textStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#201f1f',
    fontSize: 13,
    top: '2px',
    fontWeight: 600,
  };

  const percentageText = {
    color: '#555',
    marginLeft: 10,
    minWidth: 40,
    width: 40,
    textAlign: 'right',
  };

  const container = {
    display: 'flex',
  };

  return (
    <div style={container}>
      <div style={Parentdiv}>
        <span style={textStyle}>{`${text}`}</span>
        <div style={Childdiv}>
          <span style={progresstext} />
        </div>
      </div>
      <span style={percentageText}>
        {progress}
        %
      </span>
    </div>
  );
}
