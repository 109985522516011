import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import {
  Row, Col, Tag, Spin, Breadcrumb, Layout, Button, Select, Space, notification,
} from 'antd';
import {
  RotateLeftOutlined,
  RotateRightOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import { SinglelineViewer } from '../../../components';
import {
  fetchCalibratedDetailsBySlines,
  fetchSinglelineDetails,
  fetchSlinesByProject,
  rotateSlineCalibrated,
  updateTagsCalibrated,
  fetchProjectSearchResults,
  deleteTag,
  addTag,
} from '../../../services';
import './styles.scss';
import { PROJECTS, SLINES } from '../../../utils';
import { setActiveProjectTab } from '../../../store/common';

const { Content } = Layout;
const { Option, OptGroup } = Select;

function SinglelineDetail({ history }) {
  const { slineId, projectId } = useParams();
  const { activeProject } = useSelector(({ common }) => common);
  const params = useLocation();
  const [activeTab, setActiveTab] = useState('1');
  const [datesView, setDatesView] = useState(false);
  const [detail, setDetail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [slinesLoader, setSlinesLoader] = useState(false);
  const [slines, setSlines] = useState([]);
  const [slinesUrls, setSlinesUrls] = useState([]);
  const [markersDraggable, setMarkersDraggable] = useState(false);
  const [btnText, setBtnText] = useState('Edit');
  const [tagSaveLoader, setTagSaveLoader] = useState(false);
  const [coords, setCoords] = useState([]);
  const [fourCorners, setFourCorners] = useState({});
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [activeSline, setActiveSline] = useState();
  const [displayedTags, setDisplayedTags] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getSlines();
    getCalibratedDetailsBySlines(activeSline || slineId);
  }, [slineId]);

  const getCalibratedDetailsBySlines = async (slineID) => {
    setLoader(true);
    const response = await fetchCalibratedDetailsBySlines(projectId, slineID, 'construction');
    setDetail({ ...response });
    setLoader(false);
  };

  const getSlines = async () => {
    setSlinesLoader(true);
    const response = await fetchSlinesByProject(projectId, 1);

    const slineUrl = response?.data.map(
      (res) => `${PROJECTS}/${activeProject.id}${SLINES}/${res.id}`,
    );

    setSlinesUrls([...slineUrl]);
    setSlines([...response?.data]);
    setSlinesLoader(false);
  };

  const onSlineSelect = (slineID) => {
    setActiveSline(slineID);
    getCalibratedDetailsBySlines(slineID);
  };

  const updateTags = async () => {
    setTagSaveLoader(true);
    const coordsArr = coords.map(({ coords }) => ({
      id: coords.id,
      x_axis: coords.x_axis,
      y_axis: coords.y_axis,
    }));

    const fourCornersData = {
      ...fourCorners,
      image: '',
    };

    await updateTagsCalibrated(activeProject.id, slineId, {
      ...fourCornersData,
      coords: coordsArr,
    });
    setTagSaveLoader(false);
  };

  const rotateSline = async (rotationDegree) => {
    setTagSaveLoader(true);

    await rotateSlineCalibrated(activeProject.id, slineId, {
      rotation_degree: rotationDegree,
    });

    await getSlines();
    await getCalibratedDetailsBySlines(activeSline || slineId);

    setTagSaveLoader(false);
  };

  const editAndSaveTagHandler = (e) => {
    if (btnText === 'Edit') {
      setBtnText('Save');
    } else {
      updateTags();
      setBtnText('Edit');
    }
    setMarkersDraggable(!markersDraggable);
  };

  /**
     * Handle Project level Search
     */

  useEffect(() => {
    if (searchQuery) {
      getProjectSearchResults(searchQuery);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const getProjectSearchResults = async (query) => {
    setSearchLoading(true);
    const res = await fetchProjectSearchResults(activeProject?.id, {
      search_str: query,
    });

    if (res.success) {
      setSearchLoading(false);
      setSearchResults(res.data);
    } else {
      setSearchResults([]);
      setSearchLoading(false);
    }
  };

  const handleProjectSearch = (value) => {
    if (value) {
      setSearchQuery(value);
    } else {
      setSearchQuery(null);
    }
  };

  const handleProjectChange = (id) => {
  };

  const clearProjectSearch = () => {
    setSearchQuery(null);
    setSearchResults([]);
  };

  const deleteTagAndReload = async (data) => {
    const resp = await deleteTag(data);
    // if(resp) {
    //     notification.success({
    //         message: `Tag deleted susccessfully`
    //     })
    // } else {
    //     notification.error({
    //         message: `Failed to delete Tag`
    //     })
    // }
    await getCalibratedDetailsBySlines(activeSline || slineId);
  };

  const onClickAddTag = async (item) => {
    // Get this date from the viewer if possible
    await addTag({
      projectId,
      slineId,
      data: {
        image: '',
        dst_ne: {
          x: 1387,
          y: 0,
        },
        dst_nw: {
          x: 373,
          y: 0,
        },
        dst_se: {
          x: 1387,
          y: 716,
        },
        dst_sw: {
          x: 373,
          y: 716,
        },
        coords: [
          {
            tag_id: item?.id,
            x_axis: 500,
            y_axis: 350,
          },
        ],
      },
    });
    await getCalibratedDetailsBySlines(activeSline || slineId);
  };

  return (
    <Content className="content-width-sidebar">
      <Row>
        <Col span={24}>
          <div
            className="mt-10"
            style={{
              float: 'right',
            }}
          >
            {slinesUrls.findIndex((sline) => sline === params.pathname) + 1}
            /
            {slinesUrls.length}
          </div>
          <Row className="mb-10 mt-10">
            <Col span={6}>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push(PROJECTS)}>
                  <span className="cursor-pointer">{params?.state}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(setActiveProjectTab('activity'));
                    history.push(PROJECTS);
                  }}
                >
                  Sline
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">{detail?.sline?.name}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="project-search-wrapper">
                <Select
                  style={{
                    width: 400,
                    borderColor: '#16aaff',
                    borderRadius: 12,
                    marginRight: 2,
                  }}
                  size="large"
                  loading={isSearchLoading}
                  defaultValue={searchQuery}
                  showSearch
                  allowClear
                  showArrow={false}
                  onBlur={clearProjectSearch}
                  onSearch={handleProjectSearch}
                  filterOption={false}
                  onChange={handleProjectChange}
                  notFoundContent={null}
                  placeholder="Type and search for Tags"
                >
                  {searchResults?.tags && searchResults?.tags.length && (
                  <OptGroup label="Tags">
                    {searchResults.tags.map((item) => {
                      const displayAdd = !_.find(displayedTags, ['id', item.id]);
                      return (
                        <Option
                        value={item.packageroom[0].id}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {item?.name}
                            { displayAdd && <Button size="small" onClick={(e) => onClickAddTag(item)}>Add Tag</Button>}
                          </div>
                        </Option>
                      );
                    })}
                  </OptGroup>
                  )}
                </Select>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex', justifyContent: 'end', paddingLeft: 20, paddingRight: 20,
              }}
            >
              {detail && (
              <>
                {/* <Tag color="success">Success</Tag>  */}
                <Button
                  size="large"
                  type="primary"
                  loading={tagSaveLoader}
                  onClick={editAndSaveTagHandler}
                  style={{ marginRight: 20 }}
                >
                  {btnText}
                </Button>
                <Button
                  shape="circle"
                  size="large"
                  disabled={tagSaveLoader}
                  style={{ marginRight: 10 }}
                  onClick={() => rotateSline(-90)}
                >
                  <RotateLeftOutlined />
                </Button>
                <Button
                  shape="circle"
                  size="large"
                  disabled={tagSaveLoader}
                  onClick={() => rotateSline(90)}
                >
                  <RotateRightOutlined />
                </Button>
              </>
              )}
            </Col>
          </Row>

          {/* {detail ? (
                        <div
                            className="page-content-container container-height"
                            style={{
                                height: 'calc(100vh - 150px)',
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                        > */}

          {/* <Tabs
                            defaultActiveKey={activeTab}
                            onChange={setActiveTab}
                            className="project-detail-tabs"
                            tabBarExtraContent={{
                                right:
                                    activeTab === '2' ? (
                                        <>
                                            <span style={{ color: '#7b7b7b' }}>Progress View</span>
                                            <Switch
                                                style={{ margin: '0 10px' }}
                                                onChange={setDatesView}
                                            />
                                            <span style={{ color: '#7b7b7b' }}>Dates View</span>
                                        </>
                                    ) : null,
                            }}
                        > */}
          {/* <TabPane tab="Single Line View" key="1"> */}
          {/* <Spin spinning={loader}> */}

          {/* </Spin> */}
          {/* </TabPane> */}
          {/* <TabPane tab="Tag List View" key="2">
                                <TagListView
                                    slineId={activeSline || slineId}
                                    datesView={datesView}
                                />
                            </TabPane> */}
          {/* </Tabs> */}
          {/* </div>
                    ) : (
                        <div
                            className="page-content-container container-height"
                            style={{
                                height: 'calc(100vh - 150px)',
                                display: 'flex',
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                        >
                            <div
                                style={{
                                    margin: 'auto',
                                }}
                            >
                                <Empty />
                            </div>
                        </div>
                    )} */}
          <Spin spinning={false}>
            <SinglelineViewer
              deleteTag={deleteTagAndReload}
              images={slines}
              tags={detail?.tags}
              slineId={slineId}
              projectId={projectId}
              stlineImage={detail?.sline?.image_url}
              imageWidth={detail?.sline?.src_se_x}
              imageHeight={detail?.sline?.src_se_y}
              setLoader={setLoader}
              slines={slinesUrls}
              markersDraggable={markersDraggable}
              coords={coords}
              setCoords={setCoords}
              setFourCorners={setFourCorners}
              fourCorners={fourCorners}
              displayedTags={displayedTags}
              setDisplayedTags={setDisplayedTags}
              key={`${projectId}-${slineId}-${coords.length}`}
              mode={btnText}
            />
          </Spin>
        </Col>
      </Row>
    </Content>
  );
}

export default SinglelineDetail;
