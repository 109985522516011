import _ from 'lodash';
import moment from 'moment';
import {
  Tooltip, Avatar, Space, Button, Popconfirm,
} from 'antd';
import { DeleteTwoTone, DownloadOutlined, ContainerOutlined } from '@ant-design/icons';
import { DISPLAY_DATETIME_FORMAT, makeAvatar, makeTitle } from '../../utils';

export const packageColumns = ({
  type = '',
  downloadDocument = () => {},
  setRecord = () => {},
}) => [
  {
    title: 'Master Project',
    dataIndex: 'master_project_name',
    isSearchable: false,
  },
  {
    title: 'Sub Project',
    dataIndex: 'project_name',
    isSearchable: true,
  },
  {
    title: 'Equipment',
    dataIndex: 'package_room_name',
    isSearchable: true,
    render: (_, record) => record?.package_name,
  },
  {
    title: 'Folder',
    dataIndex: 'dir_key',
    isSearchable: true,
    render: makeTitle,
  },
  {
    title: 'File Name',
    dataIndex: 'file_name',
    isSearchable: true,
    render: (_, record) => (
      <Space>
        <Tooltip title="Download">
          <Button
            style={{
              border: 'none',
            }}
            data-key="download"
            type="ghost"
            onClick={() => downloadDocument(record)}
          >
            {record?.file_name}
            {' '}
            <DownloadOutlined style={{ fontSize: 18, cursor: 'pointer' }} />
          </Button>
        </Tooltip>
      </Space>
    ),
  },
  // {
  //   title: 'Submitted at',
  //   dataIndex: 'updated_at',
  //   sorter: true,
  //   sortDirections: ['ascend', 'descend'],
  //   render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
  // },
  // {
  //   title: 'Submitted by',
  //   align: 'center',
  //   dataIndex: 'account',
  //   render: (account) => (!_.isEmpty(account) ? (
  //     <Tooltip title={`${account?.first_name} ${account?.last_name}`}>
  //       <Avatar
  //         size="small"
  //         src={makeAvatar(account?.first_name?.[0], account?.last_name?.[0])}
  //       />
  //     </Tooltip>
  //   ) : (
  //     ''
  //   )),
  // },
  {
    title: 'Shared By',
    dataIndex: 'shared_history',
    render: (_, record) => (
      <div
        key={record?.id}
      >
        <Tooltip
          key={record?.id}
          title="View History"
        >
          <Button
            key={record?.id}
            type="text"
            onClick={() => {
              record.type = type;
              setRecord(record);
            }}
            icon={<ContainerOutlined />}
          />
        </Tooltip>
      </div>
    ),
  },
];
