import _, { find } from 'lodash';
import {
  Empty, Space, Collapse, Tooltip, Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CheckOutlined, CloseOutlined, ContainerOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { SidebarListItem } from '../../../components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PROJECTS, PROJECT_PERMISSIONS, isActivityActive, isInboxActive } from '../../../utils';
import {
  getAllProjects,
  PROJECT_SETUP,
  setActiveProject,
  setActiveProjectTab,
} from '../../../store/common';
import * as Permissions from '../../../utils/permissions';
import { useState } from 'react';
import { setFinancialVerticalType } from '../../../store/financials';

const { Panel } = Collapse;

function ProjectsAccordianList({
  list,
  listMeta,
  onProjectClickHandler,
  activeProject,
  onProjectDBClickHandler,
  active,
  setActive,
  filtered,
  totalCount = 0,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(({ auth }) => auth);
  const { packages } = useSelector(({ chat }) => chat);

  const onEditHandler = (ev, project, masterProject) => {
    ev.stopPropagation();
    dispatch(
      setActiveProject({
        id: project?.id,
        name: project?.name,
        masterProject,
        permissions: project?.permissions,
      }),
    );
    dispatch({ type: PROJECT_SETUP, payload: false });
    history.push(`${PROJECTS}/${masterProject?.id}/${project?.id}/edit?activeTab=1`);
  };

  const genExtra = (project) => {
    return (
      <Space>
        {
          Permissions.can_add_project(user?.platform_permissions)
          && (
            <>
              <Tooltip title="Edit Master" placement="top">
                <EditOutlined
                  onClick={(ev) => {
                    ev.stopPropagation();
                    history.push(`${PROJECTS}/master/${project?.id}`);
                  }} />
              </Tooltip>
              <Tooltip title="Add Subproject" placement="top">
                <PlusOutlined
                  onClick={(ev) => {
                    ev.stopPropagation();
                    dispatch({ type: PROJECT_SETUP, payload: true });
                    history.push(`${PROJECTS}/${project?.id}/create`);
                  }} />
              </Tooltip>
            </>
          )}
      </Space>
    );
  };

  const panelHeader = (project) => {
    const notifications = project.projects.reduce(
      (accum, subProject) => ({
        activityCount: accum.activityCount + subProject.unread_activity_count,
        enableActivityBadge: accum.enableActivityBadge || Permissions.hasProjectPermission(subProject?.permissions, PROJECT_PERMISSIONS.CAN_VIEW_ACTIVITY),
        enableInboxBadge: accum.enableInboxBadge || Permissions.hasProjectPermission(subProject?.permissions, PROJECT_PERMISSIONS.CAN_USE_INBOX_PACKAGE_CHAT),
      }),
      { activityCount: 0, enableActivityBadge: false, enableInboxBadge: false },
    );
    const projectPackages = find(packages, ['masterId', project?.id]);
    const inboxCount = _.sumBy(projectPackages?.packages, 'unread_messages_count');

    // display badge only if atleast one project has permission
    return (
      <>
        <div className="master-project-details">
          <Space>
            {isInboxActive() && notifications.enableInboxBadge && inboxCount ? <span className="counter inbox" /> : null}
            {isActivityActive() && notifications.enableActivityBadge && notifications.activityCount ? (
              <span className="counter activity" />
            ) : null}
          </Space>
        </div>
        <Tooltip title={project?.name}>
          <span>
            {project?.name}
          </span>
        </Tooltip>
      </>
    );
  };

  const displayList = _.sortBy(list, ['name']);

  // // Hack do display on projects relavant
  // // Construct displaylist based on the user
  // const newdisplayList = _.filter(displayList, (m) => {
  //   // Fetch all the accounts in the subprojects
  //   let p = m?.account;
  //   for (let i = 0; i < m?.projects.length; i++) {
  //     p = [...p, ...m?.projects[i]?.account];
  //   }

  //   if (p.includes(user?.id)) {
  //     let sProjects = [];
  //     for (let i = 0; i < m?.projects.length; i++) {
  //       if (m?.projects[i]?.account.includes(user?.id)) {
  //         sProjects = [m?.projects[i], ...sProjects];
  //       }
  //     }
  //     m.projects = sProjects;
  //     return m; // Master Projects to render
  //   }
  // });

  return !_.isEmpty(displayList) ? (
    <>
      <InfiniteScroll
        dataLength={displayList?.length}
        next={() => {
          console.log('Calling next');
        }}
        hasMore={displayList?.length < totalCount}
        loader={(
          <></>
        )}
        endMessage={
          <></>
        }
        scrollableTarget="scrollableDiv"
      >
        <Collapse
          defaultActiveKey={[activeProject?.masterProject?.id]}
          onChange={() => { }}
          accordion
          className="project-listing-collapse"
          collapsible='icon'
          onI
        >
          {displayList.map((masterProject) => {
            return (
              <Panel
                header={panelHeader(masterProject)}
                className={`panel-heading ${activeProject?.masterProject?.id === masterProject?.id && 'master-active'
                  }`}
                key={masterProject.id}
                extra={genExtra(masterProject)}
                onClick={() => {
                  dispatch(setActiveProject({ ...masterProject, masterProject }));
                  dispatch(setActiveProjectTab('aggregate'));
                  dispatch(setFinancialVerticalType('construction'));
                  history.push(`${PROJECTS}/${masterProject?.id}/aggregate`);
                }}
              >
                {masterProject?.projects?.length ? (
                  _.sortBy(masterProject?.projects, ['name']).map((project) => {
                    const projectPackages = find(packages, ['id', project?.id]);
                    return (
                      <SidebarListItem
                        key={project?.id}
                        project={project}
                        id={project?.id}
                        title={project?.name}
                        desc={project?.description}
                        icon={ContainerOutlined}
                        notifCount={project?.unread_activity_count}
                        activityCount={project?.unread_activity_count}
                        inboxCount={_.sumBy(projectPackages?.packages, 'unread_messages_count')}
                        onDoubleClick={() =>
                          onProjectDBClickHandler(project.id, project.name)
                        }
                        addActiveClass={activeProject?.id === project?.id}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          dispatch(setActiveProjectTab('insights'));
                          onProjectClickHandler(project, masterProject);
                        }}
                        onEdit={(ev) => {
                          ev.stopPropagation();
                          onEditHandler(ev, project, masterProject);
                        }}
                        isDraft={project?.is_draft}
                        isCompleted={project?.is_finished}
                        editBtn={Permissions.hasProjectPermission(project?.permissions, PROJECT_PERMISSIONS.CAN_EDIT_PROJECT)}
                        packages={packages}
                      />
                    );
                  })
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Panel>
            );
          })}
        </Collapse>
      </InfiniteScroll>
    </>
  ) : (
    <>
    </>
  );
}

export default ProjectsAccordianList;
