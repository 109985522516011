import { UploadOutlined } from '@ant-design/icons';
import {
  Button, Modal, notification, Upload,
} from 'antd';
import { useState } from 'react';
import { releaseMarkup, uploadDocuments } from '../../../../services';

function FinalDocUploadModal({
  projectId = '',
  apprvlEntries = [],
  pkgId = '',
  isOpen = '',
  title = 'Final document Upload',
  onOk,
  onCancel,
  getInitialData,
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleOk = () => {
    setConfirmLoading(true);
    addMarkupInApproval();
  };
  const handleCancel = () => {
    setFile(null);
    onCancel();
  };

  const addMarkupInApproval = async () => {
    try {
      const allApprvlIds = apprvlEntries.map((item) => item.id);

      const formData = new FormData();
      formData.append('files', file, `${file.name}`);
      formData.append('project_id', projectId.toString());
      formData.append('generate_markup', true);

      const response = await uploadDocuments(projectId, pkgId, formData);
      const reqBody = {
        approval: [...allApprvlIds],
        markup_file_path: response?.data[0]?.path,
        markup_file_name: response?.data[0]?.fileName,
      };

      releaseMarkup(pkgId, [reqBody])
        .then((res) => {
          if (res.success) {
            setFile(null);
            getInitialData();
            setConfirmLoading(false);
            onOk();
            notification.success({
              message: `${allApprvlIds.length} updated successfully`,
            });
          }
        })
        .catch((err) => {
          setFile(null);
          setConfirmLoading(false);
        });
    } catch (ex) {
      setFile(null);
      setConfirmLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      okText={confirmLoading ? 'Processing' : 'Submit'}
      onCancel={handleCancel}
    >
      <p>Upload the Document for All Approval Entries.</p>
      <Upload
        style={{
          color: '#fff',
        }}
        beforeUpload={() => false}
        showUploadList={false}
        maxCount={1}
        multiple={false}
        onChange={(e) => {
          e.file.status !== 'removed' && setFile(e.file);
        }}
      >
        <Button icon={<UploadOutlined />} type="primary">
          {file !== null ? file?.name : 'Select'}
        </Button>
      </Upload>
    </Modal>
  );
}

export default FinalDocUploadModal;
