import axios from 'axios';
import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Avatar, Space, Tooltip, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getSignedUrl } from '../../../../../../services';
import { DISPLAY_DATETIME_FORMAT, makeAvatar } from '../../../../../../utils';

export default () => {
    const downloadDocument = async ({ file_path, file_name }) => {
        try {
            const response = await getSignedUrl(file_path);
            await axios.get(response.data, { headers: { 'Content-Disposition': 'attachment' } });
            saveAs(response.data, file_name);
        } catch (error) {
            message.error('Error in downloading document');
        }
    };

    return React.useMemo(() => [
        {
            title: 'Equipment',
            dataIndex: ['package', 'name'],
            isSearchable: true,
        },
        {
            title: 'Folder',
            dataIndex: 'dir_value',
            isSearchable: true,
        },
        {
            title: 'Submittal Type',
            dataIndex: 'submittal_status',
        },
        {
            title: 'File Name',
            dataIndex: 'approval_file_name',
            isSearchable: true,
            render: (_, record) => (
                <Space>
                    <Tooltip title="Download">
                        <Button
                            style={{
                                border: 'none',
                            }}
                            data-key="download"
                            type="ghost"
                            onClick={() =>
                                downloadDocument({
                                    file_path: record?.approval_file_path,
                                    file_name: record?.approval_file_name,
                                })
                            }
                        >
                            {record?.approval_file_name}{' '}
                            <DownloadOutlined style={{ fontSize: 18, cursor: 'pointer' }} />
                        </Button>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: 'Mark up File',
            dataIndex: 'file_name',
            render: (_, record) =>
                record?.markup_file_name && (
                    <Space>
                        <Tooltip title="Download">
                            <Button
                                style={{
                                    border: 'none',
                                }}
                                data-key="download"
                                type="ghost"
                                onClick={() =>
                                    downloadDocument({ file_path: record?.markup_file_path })
                                }
                            >
                                {record?.markup_file_name}{' '}
                                <DownloadOutlined style={{ fontSize: 18, cursor: 'pointer' }} />
                            </Button>
                        </Tooltip>
                    </Space>
                ),
        },
        {
            title: 'Submitted at',
            dataIndex: 'updated_at',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
        },
        {
            title: 'Due date',
            dataIndex: 'approval_due_time',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
        },
        {
            title: 'Uploaded by',
            align: 'center',
            dataIndex: 'account',
            render: (account) => {
                if (!isEmpty(account)) {
                    return (
                        <Tooltip title={`${account?.first_name} ${account?.last_name}`}>
                            <Avatar
                                size="small"
                                src={makeAvatar(account?.first_name?.[0], account?.last_name?.[0])}
                            />
                        </Tooltip>
                    );
                }
                return '';
            },
        },
    ]);
};
