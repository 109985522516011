import { memo } from 'react';
import Vendors from './vendors';

const Financials = memo(() => (
    <div className="financials">
        <Vendors />
    </div>
));

export default Financials;
