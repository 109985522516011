export const UPDATE_TAG_INITIATE = 'UPDATE_TAG_INITIATE';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const TAG_LIST = 'TAG_LIST';

export const GET_MASTER_TAGS_LOADING = 'GET_MASTER_TAGS_LOADING';
export const GET_MASTER_TAGS_SUCCESS = 'GET_MASTER_TAGS_SUCCESS';

export const GET_SHIPPING_UPDATE_LIST = 'GET_SHIPPING_UPDATE_LIST';
export const GET_SHIPPING_UPDATE_LIST_SUCCESS = 'GET_SHIPPING_UPDATE_LIST_SUCCESS';
export const GET_SHIPPING_UPDATE_LIST_FAILURE = 'GET_SHIPPING_UPDATE_LIST_FAILURE';
export const UPDATE_SHIPPING = 'UPDATE_SHIPPING';
export const UPDATE_SHIPPING_SUCCESS = 'UPDATE_SHIPPING_SUCCESS';
export const UPDATE_SHIPPING_FAILURE = 'UPDATE_SHIPPING_FAILURE';
