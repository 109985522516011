import { memo } from 'react';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import MasterProjectTabs from './tabs';

const MasterProjects = memo(() => {
  const { activeProject } = useSelector(({ common }) => common);

  return (
    <>
      <div className="mt-20" />
      <Breadcrumb separator=">">
        {/* <Breadcrumb.Item>Alectify</Breadcrumb.Item> */}
        <Breadcrumb.Item>
          {activeProject?.name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <MasterProjectTabs />
    </>
  );
});

export default MasterProjects;
