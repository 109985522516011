import PropTypes from 'prop-types';
import { memo } from 'react';
import { Form, Modal } from 'antd';
import { CreateUpdateContactForm } from '../../components';

const CreateUpdateContact = (props) => {
    const [form] = Form.useForm();

    return (
        <Modal
            width={700}
            destroyOnClose
            onOk={form.submit}
            open={props.visible}
            onCancel={props.onCancel}
            confirmLoading={props.submitting}
            okText={`${props.data ? 'Update' : 'Submit'}`}
            title={`${props.data ? 'Edit' : 'Create'} Contact`}
        >
            <CreateUpdateContactForm
                user={props.data}
                form={form}
                onSubmit={props.onSubmit}
                submitting={props.submitting}
            />
        </Modal>
    );
};

CreateUpdateContact.defaultProps = {
    data: null,
    visible: false,
    submitting: false,
    onCancel: () => {},
    onSubmit: () => {},
};

CreateUpdateContact.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    submitting: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default memo(CreateUpdateContact);
