import { Card, Col, Row, Spin } from 'antd';
import { memo } from 'react';
import { renderAmount } from '../../../../../../utils';

const VendorsQuoteSummary = ({ summary }) => {
    return (
        <Card.Grid className="full-width" hoverable={false}>
            <Spin spinning={summary.fetching}>
                <Row gutter={30}>
                    <Col span={8}>
                        <div class="page-content-container dashboard-card colorful-card bg-light-purple mb-0">
                            <h4 class="card-heading custom-card-heading">Total Sell</h4>
                            <h1 class="dashboard-card-text">
                                {renderAmount(summary?.data?.total_sell)}
                            </h1>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div class="page-content-container dashboard-card colorful-card bg-light-green mb-0">
                            <h4 class="card-heading custom-card-heading">Total Cost</h4>
                            <h1 class="dashboard-card-text">
                                {renderAmount(summary?.data?.total_cost)}
                            </h1>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div class="page-content-container dashboard-card colorful-card bg-light-yellow mb-0">
                            <h4 class="card-heading custom-card-heading">Avg. Margin</h4>
                            <h1 class="dashboard-card-text">
                                {summary?.data?.avg_margin || '0'} %
                            </h1>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </Card.Grid>
    );
};

export default memo(VendorsQuoteSummary);
