import React from 'react';
import moment from 'moment';
import { Popconfirm, Space, Tooltip } from 'antd';
import {
    DeleteTwoTone,
    EditOutlined,
    FileSearchOutlined,
    FileTextOutlined,
} from '@ant-design/icons';
import {
    ASSOCIATE_VENDOR_ITEMS_REASONS,
    DISPLAY_DATE_FORMAT,
    renderAmount,
} from '../../../../../../../../utils';

export default (onActionClick = () => {}) => {
    return React.useMemo(() => [
        {
            key: 'entry_date',
            title: 'Date',
            dataIndex: 'entry_date',
            render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
        },
        {
            key: 'reason',
            title: 'Reason',
            dataIndex: 'reason',
            render: (value) => ASSOCIATE_VENDOR_ITEMS_REASONS[value] || "Original",
        },
        {
            key: 'quote_no',
            title: 'Quote #',
            dataIndex: 'quote_no',
            render: (value) => value || '-',
        },
        {
            key: 'sell',
            title: 'Total Sell',
            dataIndex: 'sell',
            render: renderAmount,
        },
        {
            key: 'cost',
            title: 'Total Cost',
            dataIndex: 'cost',
            render: renderAmount,
        },
        {
            key: 'margin_percent',
            title: 'Margin',
            dataIndex: 'margin_percent',
            render: (value) => `${value || 0} %`,
        },
        {
            key: 'files_count',
            title: 'Attachment(s)',
            dataIndex: 'files_count',
            render: (value) => (
                <Space>
                    <FileTextOutlined />
                    {value || 0}
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            render: (_, record) => (
                <Space>
                    <Tooltip title="Details">
                        <FileSearchOutlined onClick={() => onActionClick('view', record)} />
                    </Tooltip>
                    <Tooltip title="Edit">
                        <EditOutlined onClick={() => onActionClick('edit', record)} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={(ev) => {
                            ev.stopPropagation();
                            onActionClick('delete', record);
                        }}
                    >
                        <Tooltip title="Delete">
                            <DeleteTwoTone twoToneColor="#f56666" />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        },
    ]);
};
