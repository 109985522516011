import Status from './status';

export function InstallationTab({ detail, getPackageDetail, uploadAfterImg, uploadBeforeImg }) {
    return (
        <Status
            detail={detail}
            getPackageDetail={getPackageDetail}
            uploadBeforeImg={uploadBeforeImg}
            uploadAfterImg={uploadAfterImg}
        />
    );
}
