import {
  notification,
} from 'antd';
import * as ActionTypes from './types';
import { fetchTasks } from '../../services';

export const getTasksList = ({
  projectId, page, pageSize, filters = {},
}) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_TASKS_LIST });
    const response = await fetchTasks({
      projectId, page, pageSize, filters,
    });
    dispatch({ type: ActionTypes.GET_TASKS_LIST_SUCCESS, payload: response });
  } catch (error) {
    console.log(error);
  }
};
