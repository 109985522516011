import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Spin } from 'antd';
import { updateBinderHistory } from '../../../../../services';
import { BASIC_REQUIRED_VALIDATION_RULES } from '../../../../../utils';

const EditBinderModal = (props) => {
    const { projectId } = useParams();
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            await updateBinderHistory(projectId, props.data.id, values);
            message.success('Updated Successfully');
            setSubmitting(false);
            props.onCancel();
            props.getBindersHistory();
        } catch (error) {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        form.setFieldsValue(props.data);
    }, []);

    return (
        <Modal
            title="Edit Digital Binder"
            open={props.visible}
            onCancel={props.onCancel}
            onOk={form.submit}
            okText="Update"
            confirmLoading={submitting}
        >
            <Spin spinning={submitting}>
                <Form form={form} onFinish={onSubmit} layout="vertical">
                    <Form.Item
                        name="file_name"
                        label="File Name"
                        rules={BASIC_REQUIRED_VALIDATION_RULES}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

EditBinderModal.defaultProps = {
    visible: false,
    onCancel: () => {},
    getBindersHistory: () => {},
};

EditBinderModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    getBindersHistory: PropTypes.func,
};

export default EditBinderModal;
