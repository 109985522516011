import { Card } from 'antd';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlectifyTable } from '../../../../../../components';
import { getProjectCosts } from '../../../../../../store/billing';
import useColumns from '../project-costs/effects/useColumns';

const VendorInvoices = memo(() => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { projectCosts } = useSelector(({ billings }) => billings);

    const getAllVendorInvoices = (page = 1, per_page = 10) => {
        const params = {
            page,
            per_page,
        };
        dispatch(getProjectCosts(projectId, params));
    };

    useEffect(() => {
        getAllVendorInvoices();
    }, [projectId]);

    const columns = useColumns(null, false);

    return (
        <Card title="Vendor Invoices">
            <AlectifyTable
                columns={columns}
                selectRows={false}
                defaultPageSize={10}
                total={projectCosts.total}
                dataSource={projectCosts.data}
                loading={projectCosts.fetching}
                onDataFetch={({ page, per_page }) => getAllVendorInvoices(page, per_page)}
            />
        </Card>
    );
});

export default VendorInvoices;
