import { Layout } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    createContractor,
    getContractors,
    updateContractors,
} from '../../../../store/bidding-management';
import BasicInfo from './BasicInfo';

const { Content } = Layout;

const CreateOrUpdateContractors = ({ history }) => {
    const dispatch = useDispatch();
    const { projectId, contractorId } = useParams();
    const { organizations, loading, contractor } = useSelector(({ bidding }) => bidding);

    const onFinish = async (values) => {
        values.main_project = projectId;
        if (contractorId) {
            values.id = contractorId;
            dispatch(updateContractors(values, history));
        } else {
            dispatch(createContractor(values, history));
        }
    };

    useEffect(() => {
        if (contractorId) {
            dispatch(getContractors(contractorId));
        }
    }, [contractorId]);

    return (
        <Content>
            <div style={{ width: '95%', margin: 'auto' }}>
                <div className="page-content-container">
                    <div className="project-header">
                        <div className="project-steps">
                            <div className="project-step first-step">
                                {contractorId ? (
                                    <p>
                                        <span>Update {contractor && contractor.name}</span>
                                    </p>
                                ) : (
                                    <p>
                                        <span>Create New Contractor</span>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="steps-content mt-30">
                        <BasicInfo
                            loader={loading}
                            history={history}
                            onFinish={onFinish}
                            contractorId={contractorId}
                            contractor={(contractorId && contractor) || null}
                            organizations={organizations || []}
                        />
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default CreateOrUpdateContractors;
