import {
    FETCH_BIDDING_PROJECTS_INITIATE,
    FETCH_BIDDING_PROJECTS_SUCCESS,
    FETCH_BIDDING_PROJECTS_FAILURE,
    FETCH_ORGANIZATION_INITIATE,
    FETCH_ORGANIZATION_SUCCESS,
    FETCH_ORGANIZATION_FAILURE,
    CREATE_BIDDING_PROJECT_INITIATE,
    CREATE_BIDDING_PROJECT_SUCCESS,
    CREATE_BIDDING_PROJECT_FAILURE,
    FETCH_MAIN_PROJECT_DETAIL_INITIATE,
    FETCH_MAIN_PROJECT_DETAIL_SUCCESS,
    FETCH_MAIN_PROJECT_DETAIL_FAILURE,
    CLEAR_SELECTED_BIDDING_PROJECT,
    SET_BIDDING_ACTIVE_PROJECT,
    CLEAR_BIDDING_ACTIVE_PROJECT,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_INITIATE,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_SUCCESS,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_FAILURE,
    FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_INITATE,
    FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_SUCCESS,
    FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_FAILURE,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_INITIATE,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_SUCCESS,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_FAILURE,
    SET_ACTIVE_CONTRACTOR,
    CLEAR_ACTIVE_CONTRACTOR,
    FETCH_CONTRACTOR_BY_ID_INITIATE,
    FETCH_CONTRACTOR_BY_ID_SUCCESS,
    FETCH_CONTRACTOR_BY_ID_FAILURE,
    UPDATE_CONTRACTOR_BY_ID_INITIATE,
    UPDATE_CONTRACTOR_BY_ID_SUCCESS,
    UPDATE_CONTRACTOR_BY_ID_FAILURE,
    CREATE_CONTRACTOR_INITIATE,
    CREATE_CONTRACTOR_SUCCESS,
    CREATE_CONTRACTOR_FAILURE,
    FETCH_MAIN_PROJECT_DOCUMENTS_INITIATE,
    FETCH_MAIN_PROJECT_DOCUMENTS_SUCCESS,
    FETCH_MAIN_PROJECT_DOCUMENTS_FAILURE,
    GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_INITIATE,
    GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_SUCCESS,
    GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_FAILURE,
    UPDATE_BIDDING_PROJECT_INITIATE,
    UPDATE_BIDDING_PROJECT_SUCCESS,
    UPDATE_BIDDING_PROJECT_FAILURE,
    FETCH_CONTRACTOR_DOCUMENTS_INITIATE,
    FETCH_CONTRACTOR_DOCUMENTS_SUCCESS,
    FETCH_CONTRACTOR_DOCUMENTS_FAILURE,
    GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_INITIATE,
    GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_SUCCESS,
    GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_FAILURE,
} from './types';

export default {
    [FETCH_BIDDING_PROJECTS_INITIATE]: (state) => ({ ...state, loading: true }),
    [FETCH_BIDDING_PROJECTS_SUCCESS]: (state, payload) => ({
        ...state,
        loading: false,
        projects: payload,
    }),
    [FETCH_BIDDING_PROJECTS_FAILURE]: (state, payload) => ({
        ...state,
        errors: {
            message: payload.message || 'Something unexpected happened.',
            state: true,
        },
        loading: false,
    }),
    [FETCH_ORGANIZATION_INITIATE]: (state) => ({ ...state, loading: true }),
    [FETCH_ORGANIZATION_SUCCESS]: (state, payload) => ({
        ...state,
        loading: false,
        organizations: payload,
    }),
    [FETCH_ORGANIZATION_FAILURE]: (state, payload) => ({
        ...state,
        errors: {
            message: payload.message || 'Something unexpected happened.',
            state: true,
        },
        loading: false,
    }),
    [CREATE_BIDDING_PROJECT_INITIATE]: (state) => ({
        ...state,
        loading: true,
    }),
    [CREATE_BIDDING_PROJECT_SUCCESS]: (state) => ({
        ...state,
        loading: false,
    }),
    [CREATE_BIDDING_PROJECT_FAILURE]: (state) => ({
        ...state,
        loading: false,
    }),
    [UPDATE_BIDDING_PROJECT_INITIATE]: (state) => ({
        ...state,
        loading: true,
    }),
    [UPDATE_BIDDING_PROJECT_SUCCESS]: (state, payload) => ({
        ...state,
        loading: false,
        project: payload,
    }),
    [UPDATE_BIDDING_PROJECT_FAILURE]: (state) => ({
        ...state,
        loading: false,
        project: null,
    }),
    [FETCH_MAIN_PROJECT_DETAIL_INITIATE]: (state) => ({
        ...state,
        loading: true,
    }),
    [FETCH_MAIN_PROJECT_DETAIL_SUCCESS]: (state, payload) => ({
        ...state,
        project: payload,
        loading: false,
    }),
    [FETCH_MAIN_PROJECT_DETAIL_FAILURE]: (state) => ({
        ...state,
        loading: false,
    }),
    [CLEAR_SELECTED_BIDDING_PROJECT]: (state) => ({
        ...state,
        loading: false,
        project: null,
    }),
    [SET_BIDDING_ACTIVE_PROJECT]: (state, payload) => ({
        ...state,
        activeProject: payload,
    }),
    [CLEAR_BIDDING_ACTIVE_PROJECT]: (state) => ({
        ...state,
        activeProject: {},
    }),
    [SET_ACTIVE_CONTRACTOR]: (state, payload) => ({
        ...state,
        activeContractor: payload,
    }),
    [CLEAR_ACTIVE_CONTRACTOR]: (state) => ({
        ...state,
        activeContractor: {},
    }),
    [FETCH_BIDDING_VENDOR_ESTIMATIONS_INITIATE]: (state) => ({
        ...state,
        financials: {
            ...state.financials,
            vendors: {
                ...state.financials.vendors,
                fetching: true,
            },
        },
    }),
    [FETCH_BIDDING_VENDOR_ESTIMATIONS_SUCCESS]: (state, payload) => ({
        ...state,
        financials: {
            ...state.financials,
            vendors: {
                ...state.financials.vendors,
                fetching: false,
                data: payload.data,
                meta: payload.meta,
            },
        },
    }),
    [FETCH_BIDDING_VENDOR_ESTIMATIONS_FAILURE]: (state) => ({
        ...state,
        financials: {
            ...state.financials,
            vendors: {
                ...state.financials.vendors,
                fetching: false,
                data: null,
            },
        },
    }),
    [FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_INITATE]: (state) => ({
        ...state,
        financials: {
            ...state.financials,
            summarySheet: {
                ...state.financials.summarySheet,
                fetching: true,
            },
        },
    }),
    [FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_SUCCESS]: (state, payload) => ({
        ...state,
        financials: {
            ...state.financials,
            summarySheet: {
                ...state.financials.summarySheet,
                fetching: false,
                data: payload.data,
                meta: payload.meta,
            },
        },
    }),
    [FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_FAILURE]: (state) => ({
        ...state,
        financials: {
            ...state.financials,
            summarySheet: { ...state.summarySheet, fetching: false, data: null },
        },
    }),
    [FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_INITIATE]: (state) => ({
        ...state,
        financials: {
            ...state.financials,
            vendorItemDetail: {
                ...state.vendorItemDetail,
                fetching: true,
            },
        },
    }),
    [FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_SUCCESS]: (state, payload) => ({
        ...state,
        financials: {
            ...state.financials,
            vendorItemDetail: {
                ...state.vendorItemDetail,
                fetching: false,
                data: payload.data,
            },
        },
    }),
    [FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_FAILURE]: (state) => ({
        ...state,
        financials: {
            ...state.financials,
            vendorItemDetail: {
                ...state.vendorItemDetail,
                fetching: false,
                data: null,
            },
        },
    }),
    [FETCH_CONTRACTOR_BY_ID_INITIATE]: (state) => ({
        ...state,
        loading: true,
    }),
    [FETCH_CONTRACTOR_BY_ID_SUCCESS]: (state, payload) => ({
        ...state,
        loading: false,
        contractor: payload,
    }),
    [FETCH_CONTRACTOR_BY_ID_FAILURE]: (state) => ({
        ...state,
        loading: false,
        contractor: null,
    }),
    [UPDATE_CONTRACTOR_BY_ID_INITIATE]: (state) => ({
        ...state,
        fetching: true,
    }),
    [UPDATE_CONTRACTOR_BY_ID_SUCCESS]: (state, payload) => ({
        ...state,
        fetching: false,
        contractor: payload,
    }),
    [UPDATE_CONTRACTOR_BY_ID_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        contractor: null,
    }),
    [CREATE_CONTRACTOR_INITIATE]: (state) => ({
        ...state,
        fetching: true,
    }),
    [CREATE_CONTRACTOR_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [CREATE_CONTRACTOR_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [FETCH_MAIN_PROJECT_DOCUMENTS_INITIATE]: (state) => ({
        ...state,
        fetching: true,
    }),
    [FETCH_MAIN_PROJECT_DOCUMENTS_SUCCESS]: (state, payload) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            mainProjectDocs: {
                data: payload.data,
                meta: payload.meta,
            },
        },
    }),
    [FETCH_MAIN_PROJECT_DOCUMENTS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            mainProjectDocs: null,
        },
    }),
    [GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_INITIATE]: (state) => ({
        ...state,
        fetching: true,
    }),
    [GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_SUCCESS]: (state, payload) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            mainProjectEstimationDocs: {
                ...state.documents.mainProjectEstimationDocs,
                data: payload?.data,
                meta: payload?.meta,
            },
        },
    }),
    [GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            mainProjectEstimationDocs: {
                ...state.documents.mainProjectEstimationDocs,
            },
        },
    }),
    [FETCH_CONTRACTOR_DOCUMENTS_INITIATE]: (state) => ({
        ...state,
        fetching: true,
        documents: {
            ...state.documents,
            contractorDocs: {
                ...state.documents.contractorDocs,
            },
        },
    }),
    [FETCH_CONTRACTOR_DOCUMENTS_SUCCESS]: (state, payload) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            contractorDocs: payload,
        },
    }),
    [FETCH_CONTRACTOR_DOCUMENTS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            contractorDocs: null,
        },
    }),
    [GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_INITIATE]: (state) => ({
        ...state,
        fetching: true,
    }),
    [GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_SUCCESS]: (state, payload) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            contractorEstimationDocs: payload,
        },
    }),
    [GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            contractorEstimationDocs: null,
        },
    }),
};
