import PropTypes from 'prop-types';
import { memo } from 'react';
import { Card, Col, Modal, Row } from 'antd';
import VendorInvoices from './VendorInvoices';
import CustomerInvoices from './CustomerInvoices';

const ViewModal = memo((props) => {
    return (
        <Modal
            footer={null}
            width={'90%'}
            open={props.visible}
            title="Side by side view"
            onCancel={props.onCancel}
        >
            <Row gutter={15}>
                <Col span={12}>
                    <VendorInvoices />
                </Col>
                <Col span={12}>
                    <CustomerInvoices />
                </Col>
            </Row>
        </Modal>
    );
});

ViewModal.defaultProps = {
    visible: false,
    onCancel: () => {},
};

ViewModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default ViewModal;
