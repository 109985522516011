export const PROJECT_OVERVIEW_OPTIONS = {
  chart: {
    type: 'pie',
  },
  labels: ['Completed', 'Remaining'],
  donut: {
    size: '65%',
    background: 'transparent',
    labels: {
      show: false,
      name: {
        show: true,
        fontSize: '22px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 600,
        color: undefined,
        offsetY: -10,
        formatter(val) {
          return val;
        },
      },
    },
  },
  legend: {
    enabled: true,
    horizontalAlign: 'center',
    show: true,
    position: 'bottom',
    fontSize: 14,
    color: '#555',
    itemMargin: {
      horizontal: 10,
      // vertical: 0,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

export const OPTIONS = {
  chart: {
    height: 350,
    type: 'scatter',
    zoom: {
      type: 'xy',
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: false,
  },

  xaxis: {
    type: 'datetime',
  },
  colors: ['#00538e', '#008FFB', '#FF0000', '#F7670e'],

  legend: {
    position: 'top',
  },
  markers: {
    colors: ['#00538e', '#008FFB', '#FF0000', '#F7670e'],
  },

  yaxis: {
    max: 5,
    min: 0,

    forceNiceScale: true,
    title: {
      text: 'Tags',
    },
    labels: {
      formatter(value) {
        if (value === 0) {
          return 0;
        } if (value === 1) {
          return 'T1-B1';
        } if (value === 2) {
          return 'T2-FB';
        } if (value === 3) {
          return 'T1-HL';
        } if (value === 4) {
          return 'T2-HL';
        }
        return 'T1-PT';
      },
    },
  },

  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },

  noData: {
    text: 'no data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: 'black',
      fontSize: '14px',
    },
  },
};

export const SERIES = [
  {
    name: 'Contractor Planned',
    data: [
      { x: '07/06/2022', y: 3 },
      { x: '01/01/2022', y: 1 },
      { x: '08/08/2023', y: 2 },
    ],
  },

  {
    name: 'Contractor Actual',
    data: [
      { x: '10/06/2022', y: 3 },
      { x: '10/08/2023', y: 2 },
      { x: '01/01/2023', y: 1 },
    ],
  },
  {
    name: 'UDC Planned',
    data: [
      { x: '02/02/2023', y: 5 },
      { x: '03/03/2024', y: 4 },
      { x: '05/03/2023', y: 1 },
    ],
  },

  {
    name: 'UDC Actual',
    data: [
      { x: '03/04/2023', y: 5 },
      { x: '09/28/2024', y: 4 },
      { x: '01/01/2024', y: 1 },
    ],
  },
];
