import { Card, Collapse } from 'antd';
import { useParams } from 'react-router-dom';
import { memo, useState, useEffect } from 'react';
import { AlectifyTable } from '../../../../../../components';
import { fetchProjectCostDocuments } from '../../../../../../services';
import useColumns from './effects/useColumns';

const { Panel } = Collapse;

const VendorInvoices = memo(() => {
    const columns = useColumns();
    const { projectId } = useParams();
    const [documents, setDocuments] = useState({ data: null, fetching: false, total: 0 });

    const getProjectCostDocuments = async (pagination) => {
        try {
            setDocuments({ ...documents, fetching: true });
            const params = {
                page: pagination?.page || 1,
                per_page: pagination?.per_page || 10,
                ordering: pagination?.orderingColumn || null,
            };
            if (pagination?.searchText && pagination?.searchedColumn) {
                const modifiedSearchedColumn =
                    pagination.searchedColumn === 'distributor_po'
                        ? 'vendor_name'
                        : pagination.searchedColumn;
                params[modifiedSearchedColumn] = pagination?.searchText;
            }

            const response = await fetchProjectCostDocuments(projectId, params);
            setDocuments({
                ...documents,
                fetching: false,
                data: response?.data,
                total: response.meta.total_count,
            });
        } catch (error) {
            setDocuments({ ...documents, fetching: false });
            console.log(error);
        }
    };

    useEffect(() => {
        getProjectCostDocuments();
    }, [projectId]);

    return (
        <>
            <Card title="Vendor Invoices" bordered={false}>
                <AlectifyTable
                    columns={columns}
                    defaultPageSize={10}
                    total={documents.total}
                    dataSource={documents.data}
                    loading={documents.fetching}
                    onDataFetch={getProjectCostDocuments}
                    selectRows={false}
                />
            </Card>
        </>
    );
});

export default VendorInvoices;
