import { http } from '../utils/http';

export const downloadZip = async (data) => {
  try {
    const response = await http.post('/create-docs-zip/', data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const shareFiles = async (data) => {
  try {
    const response = await http.post(`/projects/${data?.projectId}/files-share/v1/`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchFileHistory = async (data) => {
  console.log("data: ", data);
  try {
    const response = await http.get(`/share-history/${data?.type}-file/${data?.projectId}/v1/?page=${data?.page}&per_page=${data?.pageSize}`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};
