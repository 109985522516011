import { Card, Col, Row } from 'antd';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AlectifyTable } from '../../../../../../components';
import {
  getFinancialVendorItems,
  getProjectFinancialSummary,
  getVendorQuotesSummary,
  removeVendorEstimations,
} from '../../../../../../store/financials';
import useColumns from './effects/useColumns';
import UpdateVendorItem from './UpdateVendorItem';
import CreateVendorItem from './CreateVendorItem';
import { renderAmount } from '../../../../../../utils';
import AdderDeductVendorItem from './adder-deduct';

const gridStyle = {
  width: '33.3333%',
  textAlign: 'center',
};

const VendorItemList = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { vendors, vendorSummary } = useSelector(({ financials }) => financials);
    const [editVendor, setEditVendor] = useState({ data: null, visible: false });
    const [action, setAction] = useState({ data: null, visible: false, variant: null });

  useEffect(() => {
    getVendorslist();
    dispatch(getVendorQuotesSummary(projectId));
  }, [projectId]);

    const onActionClick = (action, vendorItem) => {
        if (action === 'edit') {
            setEditVendor({ data: vendorItem, visible: true });
        } else {
            setAction({ data: vendorItem, visible: true, variant: action });
        }
    };

    const columns = useColumns(onActionClick);

  const getVendorslist = (pagination) => {
    const params = {
      page: pagination?.page || 1,
      per_page: pagination?.per_page || 10,
    };
    dispatch(getFinancialVendorItems(projectId, params));
  };

    return (
        <Card title="Project Summary Tally Sheet" className="mb-30">
            <Card.Grid className="full-width" hoverable={false}>
                <Row gutter={30}>
                    <Col span={8}>
                        <div class="page-content-container dashboard-card colorful-card bg-light-purple mb-0">
                            <h4 class="card-heading custom-card-heading">Total Sell</h4>
                            <h1 class="dashboard-card-text">
                                {renderAmount(vendorSummary?.data?.total_sell)}
                            </h1>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div class="page-content-container dashboard-card colorful-card bg-light-green mb-0">
                            <h4 class="card-heading custom-card-heading">Total Cost</h4>
                            <h1 class="dashboard-card-text">
                                {renderAmount(vendorSummary?.data?.total_cost)}
                            </h1>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div class="page-content-container dashboard-card colorful-card bg-light-yellow mb-0">
                            <h4 class="card-heading custom-card-heading">Avg. Margin</h4>
                            <h1 class="dashboard-card-text">
                                {vendorSummary?.data?.avg_margin || '0'} %
                            </h1>
                        </div>
                    </Col>
                </Row>
            </Card.Grid>
            <Card.Grid className="full-width" hoverable={false}>
                <CreateVendorItem />
            </Card.Grid>
            <Card.Grid className="full-width" hoverable={false}>
                <AlectifyTable
                    selectRows={false}
                    columns={columns}
                    defaultPageSize={10}
                    loading={vendors.fetching}
                    multipleDeleteOption={false} // disabled multiple delete option
                    onDataFetch={getVendorslist}
                    dataSource={vendors.data || []}
                    total={vendors?.meta?.total_count}
                    onMultipleDelete={(ids) => dispatch(removeVendorEstimations(projectId, ids))}
                />
            </Card.Grid>
            {editVendor.visible && (
                <UpdateVendorItem
                    {...editVendor}
                    onCancel={() => setEditVendor({ data: null, visible: false })}
                />
            )}
            {action.visible && (
                <AdderDeductVendorItem
                    {...action}
                    onCancel={() => setAction({ data: null, visible: false, variant: null })}
                />
            )}
        </Card>
    );
};

export default VendorItemList;
