import { http } from '../utils/http';

export const fetchBranches = async (companyId) => {
  try {
    const response = await http.get(`/admin/companies/branches/?company=${companyId}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createBranch = async (body) => {
  try {
    const response = await http.post('/admin/companies/branches/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createBranchAdmin = async (body) => {
  try {
    const response = await http.post('/admin/companies/branches/admin/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
