import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { memo } from 'react';

const UploadNote = (props) => {
    return (
        <div className="cursor-pointer">
            <Tooltip title={props.title}>
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    );
};

UploadNote.defaultProps = {
    title: 'Total files being uploaded should not exceed more than 500 MB.',
};

UploadNote.propTypes = {
    title: PropTypes.string,
};

export default memo(UploadNote);
