import { useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Upload } from 'antd';
import { memo, useEffect, useState } from 'react';
import { AlectifyTable, UploadNote } from '../../../../../../components';
import useColumns from './effects/useColumns';
import { useDispatch, useSelector } from 'react-redux';
import { getMainProjectDocuments } from '../../../../../../store/bidding-management';
import { DownloadOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import FileUploadModal from './UploadBidDocument';
import './Documents.scss';
import { debounce } from 'lodash';

export default memo(() => {
    const columns = useColumns();
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { fetching, documents } = useSelector(({ bidding }) => bidding);
    const [uploadModal, setUploadModal] = useState(false);
    const [documentsToUpload, setDocumentsToUpload] = useState([]);
    const [fileList, setFileList] = useState([]);
    const { mainProjectDocs } = documents;

    useEffect(() => {
        const params = {
            page: 1,
            per_page: 10,
        };
        dispatch(getMainProjectDocuments(projectId, params));
    }, [projectId]);

    const getProjectDocs = (pagination) => {
        const params = {
            page: pagination?.page || 1,
            per_page: pagination?.per_page || 10,
        };
        dispatch(getMainProjectDocuments(projectId, params));
    };

    const onCancel = () => {
        setUploadModal(false);
    };
    const onUploadDocuments = (ev) => {
        setDocumentsToUpload([...documentsToUpload, ...ev.fileList]);
        setUploadModal(true);
    };

    return (
        <>
            <Card
                title="Bid Documents"
                bordered
                extra={
                    <Upload
                        beforeUpload={() => false}
                        onChange={debounce(onUploadDocuments, 100)}
                        showUploadList={false}
                        multiple
                        fileList={[]}
                    >
                        <Button type="default" shape="round" className="main-upload-btn">
                            <span
                                style={{
                                    margin: '0 8px 0 0',
                                }}
                            >
                                <PlusOutlined /> Add Documents
                            </span>
                            <UploadNote />
                        </Button>
                    </Upload>
                }
            >
                <AlectifyTable
                    columns={columns}
                    defaultPageSize={10}
                    total={mainProjectDocs.meta?.total_count}
                    dataSource={mainProjectDocs?.data}
                    loading={fetching}
                    onDataFetch={getProjectDocs}
                    selectRows={false}
                />
            </Card>
            <FileUploadModal
                visible={uploadModal}
                onCancel={() => setUploadModal(false)}
                projectId={projectId}
                documentsToUpload={documentsToUpload}
                setDocumentsToUpload={setDocumentsToUpload}
                fileList={fileList}
                setFileList={setFileList}
            />
        </>
    );
});
