import { Modal } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';

function DeleteEquipment({ title = '', visible = false, onOk, onCancel, tag = {} }) {
    return (
        <Modal
            title={title}
            centered
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            okText="Delete"
            maskClosable={false}
        >
            <div>
                <div className="delete-eqmt-content">
                    <p>
                        Description :<span>{tag?.description ? tag?.description : 'NA'}</span>
                    </p>
                    <p>
                        For Approval :<span>{tag.approval_dwg ? 'Yes' : 'No'}</span>
                    </p>
                    <p>
                        Manufacturer :<span>{tag?.manufacture?.name}</span>
                    </p>
                </div>
                <p className="mb-0">
                    {/* <strong>Note:</strong>{' '} */}
                    <span>
                        <WarningTwoTone twoToneColor="#faad14" /> If this tag is part of a package,
                        it will be deleted from there.
                    </span>
                </p>
            </div>
        </Modal>
    );
}

export default DeleteEquipment;
