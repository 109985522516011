import { Input } from 'antd';
import React, { Component, useEffect, useRef } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
    align-items: center;
    width: 100%;
`;

function SearchAddress({
  map = null, mapApi = null, addplace = () => {}, ...restProps
}) {
  const searchInput = useRef();
  const autoComplete = useRef();

  useEffect(() => {
    const options = {
      // restrict your search to a specific type of result
      types: ['address'],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    if (mapApi !== null) {
      autoComplete.current = new mapApi.places.Autocomplete(searchInput.current, options);
      autoComplete.current.addListener('place_changed', onPlaceChanged);
      autoComplete.current.bindTo('bounds', map);

      return () => {
        mapApi.event.clearInstanceListeners(searchInput.current);
      };
    }
  }, [mapApi]);

  const onPlaceChanged = () => {
    const place = autoComplete.current.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addplace(place);
    searchInput.current.blur();
  };

  const clearSearchBox = () => {
    searchInput.current.value = '';
  };

  return (
    <Wrapper>
      <input
        className="search-input ant-input"
        ref={searchInput}
        type="text"
        onFocus={clearSearchBox}
        placeholder="Enter a location"
        autoComplete="off"
        {...restProps}
      />
    </Wrapper>
  );
}

export default SearchAddress;
