import { Button } from 'antd';
import { memo, useState } from 'react';
import ViewModal from './ViewModal';

const SideBySideView = memo(() => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="text-right mb-15">
            <Button className="bg-white" onClick={() => setVisible(true)}>
                Side by side view
            </Button>
            <ViewModal visible={visible} onCancel={() => setVisible(false)} />
        </div>
    );
});

export default SideBySideView;
