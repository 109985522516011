import { DeleteTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Collapse,
    Divider,
    List,
    message,
    Modal,
    Popconfirm,
    Row,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { useState } from 'react';
import { uploadBidDocument } from '../../../../../../services/bidding-management';
import { getMainProjectDocuments } from '../../../../../../store/bidding-management';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const { Panel } = Collapse;

const FileUploadModal = ({
    visible = false,
    onCancel = () => {},
    documentsToUpload = [],
    setDocumentsToUpload = () => {},
    setFileList = () => {},
    projectId,
}) => {
    const [loader, setLoader] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [uploadDocumentsList, setuploadDocuments] = useState([]);

    const dispatch = useDispatch();

    const columns = [
        {
            title: 'File name',
            dataIndex: 'file_name',
            width: 400,
            render: (value, record) => (
                <p className="approval-file-name" title={value || record.name}>
                    {value || record.name}
                </p>
            ),
        },
        {
            title: 'Uploaded at',
            dataIndex: 'updated_at',
            width: 400,
            render: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-'),
        },
        {
            title: '',
            dataIndex: 'uid',
            render: (value, record) => {
                return record.uid ? (
                    <Tooltip title="Delete">
                        <Popconfirm
                            okText="Yes"
                            onConfirm={() => deleteDocument(value)}
                            title="Are your sure?"
                        >
                            <DeleteTwoTone
                                twoToneColor="#f56666"
                                style={{ fontSize: 18, cursor: 'pointer' }}
                            />
                        </Popconfirm>
                    </Tooltip>
                ) : null; // Return null if record.uid is falsy
            },
        },
    ];

    const deleteDocument = (uid) => {
        const idx = documentsToUpload.findIndex((item) => item.uid === uid);
        const selectedIdx = selectedRecords.findIndex((item) => item.uid === uid);
        if (idx > -1) {
            documentsToUpload.splice(idx, 1);
            setDocumentsToUpload([...documentsToUpload]);
            message.success('Removed');
        }
        if (selectedIdx > -1) {
            selectedRecords.splice(selectedIdx, 1);
            setSelectedRecords([...selectedRecords]);
        }
    };

    const handleUpload = async () => {
        if (selectedRecords.length === 0) {
            message.error('Please select a file to upload');
            return;
        }
        try {
            setLoader(true);
            const formData = new FormData();
            selectedRecords.forEach((file) => {
                formData.append('files', file.originFileObj);
            });
            const response = await uploadBidDocument(projectId, formData);
            setuploadDocuments([...uploadDocumentsList, ...response.data]);
            dispatch(getMainProjectDocuments(projectId));

            // Remove uploaded documents from documentsToUpload table
            setDocumentsToUpload((prevDocuments) => {
                return prevDocuments.filter((doc) => {
                    return !selectedRecords.some((selectedDoc) => selectedDoc.name === doc.name);
                });
            });

            setLoader(false);
            message.success('File uploaded successfully!');
            setFileList([]);
            setSelectedRecords([]);
        } catch (ex) {
            setLoader(false);
            message.error('Failed to upload file');
        }
    };

    const onCancelClick = () => {
        setDocumentsToUpload([]);
        setSelectedRecords([]);
        setFileList([]);
        setuploadDocuments([]);
        onCancel();
    };
    return (
        <Modal open={visible} width={1300} onCancel={onCancelClick} footer={null} centered>
            <Spin spinning={loader}>
                <Row gutter={30}>
                    <Col span={16}>
                        <div className="card-heading d-flex justify-space-between">
                            <span>
                                Upload Contractor Documents
                                <Tooltip title="Upload Bidding related files">
                                    <QuestionCircleOutlined className="cursor-pointer ml-10" />
                                </Tooltip>
                            </span>
                            <Space>
                                <Button
                                    type="default"
                                    shape="round"
                                    className="main-upload-btn"
                                    onClick={() => setDocumentsToUpload([])}
                                >
                                    Clear
                                </Button>
                            </Space>
                        </div>
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1', '2']}
                            className="site-collapse-custom-collapse"
                        >
                            <Panel
                                key="1"
                                header="Documents To Upload"
                                className="site-collapse-custom-panel-grey"
                            >
                                <Table
                                    className="taglist-table"
                                    size="small"
                                    rowSelection={{
                                        type: 'checkbox',
                                        selectedRowKeys: selectedRecords.map(({ uid }) => uid),
                                        onChange: (_, selectedRows) => {
                                            setSelectedRecords(selectedRows);
                                        },
                                    }}
                                    columns={columns}
                                    rowKey="uid"
                                    dataSource={documentsToUpload.filter((d) => !d?.is_uploaded)}
                                    pagination={documentsToUpload.length > 10}
                                />
                            </Panel>
                            <Panel
                                key="2"
                                header="Uploaded Documents"
                                className="site-collapse-custom-panel"
                            >
                                <Table
                                    className="taglist-table"
                                    size="small"
                                    columns={columns}
                                    rowKey="uid"
                                    dataSource={uploadDocumentsList || []}
                                    pagination={uploadDocumentsList?.length > 10}
                                />
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={8}>
                        <Divider orientation="left">Selected Documents</Divider>
                        <List
                            size="small"
                            bordered
                            dataSource={selectedRecords}
                            renderItem={(item) => (
                                <List.Item>
                                    <Typography.Text>{item.name}</Typography.Text>
                                </List.Item>
                            )}
                        />
                        <div className="text-right mt-30">
                            <Button
                                type="primary"
                                onClick={handleUpload}
                                disabled={selectedRecords.length < 1}
                            >
                                Upload
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};

export default FileUploadModal;
