import { useState, memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlectifyTable } from '../../../../../../components/table';
import {
    getCustomerPurchaseOrders,
    removeCustomerPurchaseOrders,
} from '../../../../../../store/billing';
import CustomerPurchaseOrderDetail from './CustomerPurchaseOrderDetail';
import EditCustomerPurchaseOrder from './EditCustomerPurchaseOrder';
import useColumns from './effects/useColumns';

const CustomerPurchaseOrderList = memo(() => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { customerPurchaseOrders } = useSelector(({ billings }) => billings);
    const [customerPurcahseOrderAction, setCustomerPurchaseOrderAction] = useState({
        edit: false,
        view: false,
        data: null,
    });

    const getAllCustomerPurchaseOrders = (page = 1, per_page = 10) => {
        const params = {
            page,
            per_page,
        };
        dispatch(getCustomerPurchaseOrders(projectId, params));
    };

    useEffect(() => {
        getAllCustomerPurchaseOrders();
    }, [projectId]);

    const onActionClick = (type, customerPo) => {
        setCustomerPurchaseOrderAction({ [type]: true, data: customerPo });
    };

    const columns = useColumns(onActionClick);

    return (
        <>
            <AlectifyTable
                columns={columns}
                defaultPageSize={10}
                multipleDeleteOption
                total={customerPurchaseOrders.total}
                dataSource={customerPurchaseOrders.data}
                loading={customerPurchaseOrders.fetching}
                onMultipleDelete={(ids) => dispatch(removeCustomerPurchaseOrders(projectId, ids))}
                onDataFetch={({ page, per_page }) => getAllCustomerPurchaseOrders(page, per_page)}
            />
            {customerPurcahseOrderAction.edit && (
                <EditCustomerPurchaseOrder
                    data={customerPurcahseOrderAction.data}
                    visible={customerPurcahseOrderAction.edit}
                    onCancel={() =>
                        setCustomerPurchaseOrderAction({ edit: false, view: false, data: null })
                    }
                />
            )}
            {customerPurcahseOrderAction.view && (
                <CustomerPurchaseOrderDetail
                    data={customerPurcahseOrderAction.data}
                    visible={customerPurcahseOrderAction.view}
                    onCancel={() =>
                        setCustomerPurchaseOrderAction({ edit: false, view: false, data: null })
                    }
                />
            )}
        </>
    );
});

export default CustomerPurchaseOrderList;
