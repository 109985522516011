import { http } from '../utils/http';

export const fetchTasks = async ({
  projectId, page, pageSize, filters = {},
}) => {
  try {
    if (Object.keys(filters).length) {
      const response = await http.post(`projects/${projectId}/worktasks/list/?page=${page}&per_page=${pageSize}`, filters);
      return response?.data;
    }
    const response = await http.get(`projects/${projectId}/worktasks/list/?page=${page}&per_page=${pageSize}`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const createTask = async (data) => {
  // Convert equipment to id
  let payload = data;
  if (data?.equipment) {
    payload = { ...data, id: data?.equipment, name: data?.equipment };
  }

  try {
    const response = await http.post(`projects/${data?.projectId}/worktasks/create/`, payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const updateTask = async (data) => {
  try {
    const response = await http.post(`projects/worktasks/${data?.id}/update/`, data);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const deleteTaskAssignee = async ({ projectId, userId }) => {
  try {
    const response = await http.delete(`projects/worktasks/${projectId}/deleteassignee/${userId}`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};
