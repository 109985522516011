import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import {
  Col,
  Input,
  Row,
  Form,
  Select,
  Button,
  Modal,
  Table,
  Tooltip,
  Space,
  AutoComplete,
  Popconfirm,
  notification,
  message,
} from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import _, { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganizations,
  getOrganizationTypes,
  getRoles,
  validateEmail,
  newRemoveUserFromProject,
  newUpdateProject,
  newfetchProjectDetail,
  addUserToProject,
} from '../../../services';
import { makeAvatar, confirmUserContent, PROJECT_API_ENUM, USER_PERMISSIONS, capitalizeText } from '../../../utils';

const { Option } = Select;
const { Option: AutoCompleteOption } = AutoComplete;

function UserAccess({
  users = [],
  projectId = null,
  setUsers = () => {},
  existingUsers = [],
  goBack = () => {},
  onFinish = () => {},
  setLoader = () => {},
  role_templates = {},
}) {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [newUserRoles, setNewUserRoles] = useState([]);
  const { user } = useSelector(({ auth }) => auth);
  const { createNewProject } = useSelector(({ common }) => common);
  const [existingUserModal, setExistingUserModal] = useState(false);
  const [newOrganizations, setNewOrganizations] = useState([]);
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [orgTypeName, setOrgTypeName] = useState('');
  const [roleName, setRoleName] = useState('');
  const [orgId, setOrgId] = useState('');
  const [disableOrgType, setDisableOrgType] = useState(false);
  const [allFieldsDisable, setAllFieldsDisable] = useState(false);
  const [existingUser, setExistingUser] = useState({});

  const fetchOrganizationTypes = async () => {
    try {
      const result = await getOrganizationTypes();
      setOrganizationTypes(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      setLoader(true);
      const response = await newfetchProjectDetail(PROJECT_API_ENUM.USERS, projectId);
      const projectUsers = response?.data
        .filter((usr) => usr.id !== user.id)
        .map((usr) => ({
          ...usr,
          organization_name: usr?.organization?.name,
        }));
      setUsers([...projectUsers]);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchOrganizationTypes();
    if (projectId) {
      fetchUsers();
    }
  }, []);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      render: (text, record) => (
        <Tooltip placement="topLeft" title={text}>
          <p className="mb-0 table-row-name-last-desc">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      render: (text, record) => (
        <Tooltip placement="topLeft" title={text}>
          <p className="mb-0 table-row-name-last-desc">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Organization',
      dataIndex: 'organization_name',
      render: (text, record) => (
        <Tooltip placement="topLeft" title={text}>
          <p className="mb-0 table-row-name-last-desc">{text}</p>
        </Tooltip>
      ),
    },
    // {
    //   title: 'Role',
    //   dataIndex: 'role_name',
    // },
    {
      title: 'Permission',
      dataIndex: ['permission_group', 'name'],
      render: capitalizeText,
    },
    {
      title: '',
      dataIndex: '',
      render: (_, record) => (
        <Tooltip title="Remove User">
          <Popconfirm
            title="Are you sure?"
            onConfirm={(ev) => {
              ev.stopPropagation();
              removeUser(record.id);
            }}
          >
            <DeleteTwoTone
              twoToneColor="#f56666"
              style={{ fontSize: 18, cursor: 'pointer' }}
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];

  const addExistingUser = (values) => {
    const user = existingUsers.find((usr) => usr.id === values.user);
    user.new = false;
    values.organization = orgId;
    values.organization_type_name = orgTypeName;
    values.role_name = roleName;
    setUsers([...users, { ...user, ...values }]);
    resetState();
  };

  const onTypeChange = async (value, option, cb) => {
    try {
      if (option?.children) {
        setOrgTypeName(option.children);
      }
      form2.setFieldsValue({ role: null });
      form.setFieldsValue({ role: null });
      const result = await getRoles(value);
      setNewUserRoles([...result.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const resetState = () => {
    setDisableOrgType(false);
    setOrgId(null);
    setOrgTypeName(null);
    setRoleName(null);
    setNewOrganizations([]);
    form.resetFields();
    form2.resetFields();
    setAllFieldsDisable(false);
    setExistingUser({});
  };

  const addNewUser = async (values) => {
    const localUser = users.find((usr) => usr.email === values.email);
    if (localUser) {
      form2.setFields([{ name: 'email', errors: ['User is already part of the project'] }]);
      setLoader(false);
      return;
    }
    if (user?.email === values?.email) {
      form2.setFields([{ name: 'email', errors: ['This is your own email id. Enter different mail id.'] }]);
      setLoader(false);
      return;
    }
    setLoader(true);
    values.organization = orgId;
    values.branch = user?.branch || null;
    values.role_name = roleName || existingUser?.permission_group?.name;
    values.group = values?.role_template || existingUser?.permission_group?.id;

    if (!_.isEmpty(existingUser)) {
      values.id = existingUser.id;
      const { data, success } = await addUserToProject(PROJECT_API_ENUM.USERS, projectId, values);

      if (success) {
        message.success("User Added");
        const { organization, organization_type, role } = data;
        setUsers([
          ...users,
          {
            ...data,
            organization: organization?.id,
            organization_name: organization?.name,
            organization_type: organization_type?.id,
            organization_type_name: organization_type?.name,
            role_name: role?.name,
            role: role?.id,
          },
        ]);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } else {
      values.new = true;
      values.image_url = makeAvatar(values?.first_name[0], values?.last_name[0]);
      const response = await addUserToProject(PROJECT_API_ENUM.USERS, projectId, values);
      if (response.success) {
        values.id = response?.data.id;

        message.success("User Added");

        const user = { ...response?.data };
        user.organization_name = response?.data?.organization?.name;
        user.organization_type_name = response?.data?.organization_type?.name;
        user.role_name = response?.data?.role?.name;

        setUsers([...users, user]);
        setLoader(false);
      } else {
        setLoader(false);
        notification.error({
          message: 'Failed to add User',
          description: response.message,
        });
      }
    }
    resetState();
    setLoader(false);
  };

  const removeUser = async (userId) => {
    const newUsers = users.filter((user) => user.id !== userId);
    setUsers([...newUsers]);
    const response = await newRemoveUserFromProject(projectId, { id: userId });
    notification.success({ message: response.message });
  };

  const onNext = () => {
    onFinish({ users });
  };

  const onSelect = (val, option) => {
    setOrgId(option.key);
    onTypeChange(option.org_type_id);
    setOrgTypeName(option.org_type);
    setDisableOrgType(true);
    form2.setFieldsValue({ organization_type: option.org_type_id });
    form.setFieldsValue({ organization_type: option.org_type_id });
  };

  const onSearch = _.debounce(async (val) => {
    try {
      setDisableOrgType(false);
      const results = await getOrganizations(val);
      setNewOrganizations([...results.data]);
    } catch (error) {
      console.log(error);
    }
  }, 400);

  const validateUser = async (ev) => {
    const user = await validateEmail({ email: ev.target.value });
    if (user.data) {
      setAllFieldsDisable(true);
      form2.setFieldsValue({
        ...user.data,
        organization_name: user?.data?.organization?.name,
        organization_type: user?.data?.role?.org_type?.id,
        role: user?.data?.role?.name,
        role_template: user?.data?.permission_group?.id,
      });
      setExistingUser(user.data);
    }
  };

  const isValueEmpty = (key) => {
    return !isEmpty(existingUser[key]);
  };

  const isExternalUser = () => {
    return existingUser?.permission_group?.name === USER_PERMISSIONS.EXTERNAL;
  };

  return (
    <>
      <h2 className="step-heading">
        Manage Users & Access Control
      </h2>
      <Form form={form2} layout="vertical" onFinish={addNewUser} validateTrigger="onSubmit">
        <Row gutter={10}>
          <Col span={24}>
            <Row gutter={10}>
              <Col span={4}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Please enter valid email',
                    },
                    {
                      required: true,
                      message: 'Please enter the email',
                    },
                  ]}
                >
                  <Input onBlur={validateUser} disabled={isValueEmpty('email')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the first name',
                    },
                  ]}
                >
                  <Input disabled={isValueEmpty('first_name')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the last name',
                    },
                  ]}
                >
                  <Input disabled={isValueEmpty('last_name')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Organization Name"
                  name="organization_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the organization type',
                    },
                  ]}
                >
                  <AutoComplete
                    onSelect={onSelect}
                    onSearch={onSearch}
                    filterOption={(inputValue, option) => (
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    )}
                    disabled={isValueEmpty('organization')}
                  >
                    {newOrganizations.map((org) => (
                      <AutoComplete.Option
                        key={org?.id}
                        value={org?.name}
                        org_type={org?.org_type?.name}
                        org_type_id={org?.org_type?.id}
                      >
                        {org?.name}
                      </AutoComplete.Option>
                    ))}
                  </AutoComplete>
                </Form.Item>
              </Col>
              {/* <Col span={4}>
                <Form.Item
                  label="Role"
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the role',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    onSelect={(_, option) => setRoleName(option?.children)}
                    disabled={allFieldsDisable}
                  >
                    {newUserRoles.map((role) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
              <Col span={4}>
                <Form.Item
                  label="Permission"
                  name="role_template"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the role',
                    },
                  ]}
                >
                  <Select
                    placeholder="Please select"
                    onSelect={(_, option) => setRoleName(option?.children)}
                    disabled={!isEmpty(existingUser) && !isExternalUser()}
                  >
                    {role_templates?.data?.map((role) => (
                      <Option key={role.id} value={role?.id}>
                        {capitalizeText(role?.name)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
            <Form.Item>
              <Space>
              <Button
                style={{ width: 100 }}
                type="primary"
                className="mt-30"
                htmlType="submit"
              >
                Add
              </Button>
              {allFieldsDisable && (
              <Button
                style={{ width: 100 }}
                type="default"
                className="mt-30"
                onClick={resetState}
              >
                Reset
              </Button>
              )}
              </Space>
            </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={users.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
        className="taglist-table"
        size="small"
        pagination={users.length > 10}
      />
      <div className="text-right">
        <Space>
          <Button
            className="btn-lg btn-default save-btn mt-30"
            type="default"
            htmlType="button"
            onClick={goBack}
          >
            Previous
          </Button>
          <Button
            className="btn-lg btn-primary-lg save-btn mt-30"
            type="primary"
            onClick={onNext}
          >
            Save & Next
          </Button>
        </Space>
      </div>
      <Modal
        visible={existingUserModal}
        onCancel={() => {
          setExistingUserModal(false);
          resetState();
        }}
        title="Add Existing User"
        width={900}
        okText="Add User"
        onOk={form.submit}
      >
        <Form layout="vertical" onFinish={addExistingUser} form={form}>
          <Row gutter={15}>
            <Col span={6}>
              <Form.Item
                label="Exisiting Users"
                name="user"
                rules={[
                  {
                    required: true,
                    message: 'Please select the user',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Please select"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  allowClear
                >
                  {existingUsers.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {`${user.first_name} (${user.email})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Organization Name"
                name="organization_name"
                rules={[
                  {
                    required: true,
                    message: 'Please select the organization type',
                  },
                ]}
              >
                <AutoComplete
                  onSelect={onSelect}
                  onSearch={onSearch}
                  filterOption={(inputValue, option) => (
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  )}
                >
                  {newOrganizations.map((org) => (
                    <AutoComplete.Option
                      key={org.id}
                      value={org?.name}
                      org_type={org?.org_type?.name}
                      org_type_id={org?.org_type?.id}
                    >
                      {org?.name}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Organization Type"
                name="organization_type"
                rules={[
                  {
                    required: true,
                    message: 'Please select the organization type',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  onChange={onTypeChange}
                  disabled={disableOrgType}
                >
                  {organizationTypes.map((org) => (
                    <Option key={org.id} value={org.id}>
                      {org.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Role"
                name="role"
                rules={[
                  {
                    required: true,
                    message: 'Please select the role',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  onSelect={(_, option) => setRoleName(option?.children)}
                >
                  {newUserRoles.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default UserAccess;
