import { Button, Card } from 'antd';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { AlectifyTable } from '../../../../../components';
import { fetchBinderHistory } from '../../../../../services';
import useColumns from './effects/useColumns';
import BinderGeneration from './BinderGeneration';
import EditBinderModal from './EditBinderModal';

const DigitalBinder = () => {
    const { projectId } = useParams();
    const [createBinder, setCreateBinder] = useState(false);
    const [binders, setBinders] = useState({ fetching: false, data: [], total: 0 });
    const [editBinder, setEditBinder] = useState({ visible: false, data: null });

    const toggleBinderModal = () => setCreateBinder(!createBinder);

    const getBindersHistory = async (pagination) => {
        try {
            setBinders({ ...binders, fetching: true });
            const params = {
                page: pagination?.page || 1,
                per_page: pagination?.pageSize || 10,
            };
            const response = await fetchBinderHistory(projectId, params);
            setBinders({ fetching: false, data: response.data, total: response.meta.total_count });
        } catch (error) {
            setBinders({ ...binders, fetching: false });
        }
    };

    useEffect(() => {
        getBindersHistory();
    }, [projectId]);

    const columns = useColumns(setEditBinder);

    return (
        <>
            <Card
                title="Digital Binder History"
                extra={
                    <Button type="primary" onClick={toggleBinderModal}>
                        Create Digital Binder
                    </Button>
                }
            >
                <AlectifyTable
                    selectRows={false}
                    columns={columns}
                    loading={binders.fetching}
                    dataSource={binders.data}
                    total={binders.total}
                    onDataFetch={getBindersHistory}
                    defaultPageSize={10}
                />
            </Card>
            {createBinder && (
                <BinderGeneration
                    visible={createBinder}
                    onCancel={toggleBinderModal}
                    getBindersHistory={getBindersHistory}
                />
            )}
            {editBinder.visible && (
                <EditBinderModal
                    {...editBinder}
                    getBindersHistory={getBindersHistory}
                    onCancel={() => setEditBinder({ visible: false, data: null })}
                />
            )}
        </>
    );
};

export default DigitalBinder;
