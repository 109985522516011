import { useEffect, useState } from 'react';
import { isNull, isObject } from 'lodash';
import {
  Button,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Space,
  Collapse,
  Select,
  Row,
  Col,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAdminProjects,
  login,
  createCompany,
  createBranch,
  createBranchAdmin,
  fetchCompanies,
  fetchBranches,
  getRoles as fetchRoles,
  getOrganizationTypes,
  fetchUsersWithBranches,
  fetchBranchPermissions,
} from '../../services';
import { AlectifyTable } from '../../components';
import { userColumns } from './columns/userColumns';
import { branchColumns } from './columns/branchColumns';
import { roleColumns } from './columns/roleColumns';
import { projectColumns } from './columns/projectColumns';
import {
  addPermissionToGroup,
  removePermissionFromGroup,
  updateBranchGroup,
  updateUserGroup,
  setAdminRecord,
  getAdminUsers,
  getBranchPermissions,
  getPermissionsList,
  getRoleTemplates,
  createRoleTemplate,
  getGroupsList,
  updateUserInfo,
  getProjectsByAdmin,
} from '../../store/admin';
import BranchModal from './modals/BranchModal';
import GroupModal from './modals/GroupModal';
import RoleTemplateModal from './modals/RoleTemplateModal';
import RoleModal from './modals/RoleModal';
import UserModal from './modals/UserModal';
import { DEFAULT_START_PAGE } from '../../utils';
import UserPermissionsModal from './modals/userPermissionsModal';

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

function ProjectsAdmin() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [companyForm] = Form.useForm();
  const [companyBranchForm] = Form.useForm();
  const [branchAdminForm] = Form.useForm();
  const [type, setType] = useState('active');
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [companyModal, setCompanyModal] = useState(false);
  const [branchModal, setBranchModal] = useState(false);
  const [branchAdminModal, setBranchAdminModal] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [modalType, setModalType] = useState(null);
  const { user } = useSelector(({ auth }) => auth);
  const {
    branch_permissions, branch_permissions_loading,
    role_templates, role_templates_loading,
    user_roles, user_roles_loading,
    permission_list,
    record, record_loading,
    action_loading,
    admin_projects, projects_loading,
  } = useSelector(({ admin }) => admin);

  const getAdminProjects = async () => {
    try {
      setLoading(true);
      const response = await fetchAdminProjects(type);
      setProjects([...response?.data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCompanies = async () => {
    try {
      const response = await fetchCompanies();
      setCompanies([...response?.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = async (companyId) => {
    try {
      branchAdminForm.setFieldsValue({ branch: null });
      const response = await fetchBranches(companyId);
      setBranches([...response?.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const getRolesTypes = async () => {
    try {
      const response = await fetchRoles();
      setRoleTypes([...response?.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrganizationTypes = async () => {
    try {
      const response = await getOrganizationTypes();
      setOrganizationTypes(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    await fetchUsersWithBranches();
  };

  const onTypeChange = async (value) => {
    try {
      branchAdminForm.setFieldsValue({ role: null });
      const result = await fetchRoles(value);
      setRoles([...result?.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const onCreateCompany = async (values) => {
    try {
      setFormLoading(true);
      const response = await createCompany(values);
      message.success(response.message);
      setFormLoading(false);
      setCompanyModal(false);
      getCompanies();
      companyForm.resetFields();
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
  };

  const onCreateBranch = async (values) => {
    let response;
    try {
      setFormLoading(true);
      response = await createBranch(values);
      message.success(response?.message);
      setFormLoading(false);
      setBranchModal(false);
      companyBranchForm.resetFields();
      dispatch(getBranchPermissions({ page: DEFAULT_START_PAGE, pageSize: 10 }));
    } catch (error) {
      setFormLoading(false);
    }
  };

  const onCreateBranchAdmin = async (values) => {
    try {
      setFormLoading(true);
      values.is_branch_admin = true;
      await createBranchAdmin(values);
      message.success('User Created');
      setFormLoading(false);
      setBranchAdminModal(false);
      branchAdminForm.resetFields();
    } catch (error) {
      setFormLoading(false);
      const apiErrors = error?.response?.data?.message;
      if (apiErrors) {
        const errors = Object.keys(apiErrors).map((key) => ({
          name: key,
          errors: apiErrors[key],
        }));
        branchAdminForm.setFields(errors);
      }
    }
  };

  useEffect(() => {
    Promise.all([
      getCompanies(),
      fetchOrganizationTypes(),
      getUsers(),
      getRolesTypes(),
    ]);
    dispatch(getProjectsByAdmin({ page: DEFAULT_START_PAGE, pageSize: 10 }));
    dispatch(getBranchPermissions({ page: DEFAULT_START_PAGE, pageSize: 10 }));
    dispatch(getRoleTemplates());
    dispatch(getGroupsList());
    dispatch(getAdminUsers({ page: DEFAULT_START_PAGE, pageSize: 10 }));
    dispatch(getPermissionsList({ page: DEFAULT_START_PAGE, pageSize: 10 }));
  }, [isSuperAdmin]);

  useEffect(() => {
    getAdminProjects();
  }, [type]);

  useEffect(() => {
    dispatch(getBranchPermissions({ page: DEFAULT_START_PAGE, pageSize: 10 }));
    dispatch(getAdminUsers({ page: DEFAULT_START_PAGE, pageSize: 10 }));
  }, []);

  useEffect(() => {
    if (record && modalType === 'update-user' && record?.branch?.company?.id) {
      getBranches(record?.branch?.company?.id);
    }
  }, [record]);

  const closeModal = () => {
    dispatch(setAdminRecord(null));
    setModalType(null);
  };

  const updateUser = (values) => {
    // Resolve vaules for update
    dispatch(updateUserInfo({
      userId: record?.id,
      payload: {
        ...values,
        organization: values?.organization?.id,
        organization_type: values?.organization_type?.value,
        permission_group: isObject(values?.permission_group) ? values?.permission_group?.id : values?.permission_group,
        role: isObject(values?.role) ? values?.role?.id : values?.role,
        branch: isObject(values?.branch) ? values?.branch?.id : values?.branch,
      },
    })).then(() => {
      dispatch(getAdminUsers({ page: DEFAULT_START_PAGE, pageSize: 10 }));
    });
  };
  return (
    <Content className="fixed-layout">
      <div className="d-flex justify-space-between">
        <Space>
          <Button type="primary" onClick={() => setCompanyModal(true)}>
            Create Company
          </Button>
        </Space>
      </div>
      <Collapse
        className="alectify-collapse mb-15 mt-20"
        defaultActiveKey={['branch-permissions']}
        onChange={() => { }}
      >
        <Panel
          header="Branch Permissions"
          key="branch-permissions"
        >
          <Row justify="end" gutter={[10, 0]}>
            <Col className="mb-10">
              <Button type="primary" onClick={() => setBranchModal(true)} className="mr-10">
                Create Branch
              </Button>
            </Col>
          </Row>
          <AlectifyTable
            size="small"
            onDataFetch={(data) => dispatch(getBranchPermissions(data))}
            columns={branchColumns({
              setAdminRecord: (data) => dispatch(setAdminRecord({ ...data, type: 'branch' })),
              setModalType,
            })}
            isEmailSharing={false}
            onDownloadZip={() => { }}
            onEmailShare={() => { }}
            projectId={null}
            dataSource={branch_permissions?.data}
            loading={branch_permissions_loading}
            selectRows={false}
            total={branch_permissions?.meta?.total_count}
            defaultPageSize={10}
            key="branch-permissions"
          />
        </Panel>
        <Panel
          header="Role Permissions"
          key="role-permissions"
        >
          <Row justify="end" gutter={[10, 0]}>
            <Col className="mb-10">
              <Button
                type="primary"
                onClick={() => {
                  setModalType('create-role-template');
                  dispatch(setAdminRecord({}));
                }}
                className="mr-10"
                disabled
              >
                Create Role Template
              </Button>
            </Col>
          </Row>
          <AlectifyTable
            size="small"
            onDataFetch={(data) => dispatch(getRoleTemplates(data))}
            columns={roleColumns({
              setAdminRecord: (data) => dispatch(setAdminRecord({ ...data, type: 'role' })),
              setModalType,
            })}
            isDownloadZip={false}
            isEmailSharing={false}
            onDownloadZip={() => { }}
            onEmailShare={() => { }}
            projectId={null}
            dataSource={role_templates?.data}
            loading={role_templates_loading}
            selectRows={false}
            user={user}
            defaultPageSize={10}
            total={role_templates?.meta?.total_count}
            key="role-permissions"
          />
        </Panel>
        <Panel
          header="User Permissions"
          key="user-permissions"
        >
          <Row justify="end" gutter={[10, 0]}>
            <Col className="mb-10">
              <Button type="primary" onClick={() => setBranchAdminModal(true)}>
                Create User
              </Button>
            </Col>
          </Row>
          <AlectifyTable
            size="small"
            onDataFetch={(data) => dispatch(getAdminUsers(data))}
            columns={userColumns({
              setAdminRecord: (data) => dispatch(setAdminRecord(data)),
              setModalType,
              onChange: (data) => dispatch(changeUserStatus(data)),
            })}
            isDownloadZip={false}
            isEmailSharing={false}
            onDownloadZip={() => { }}
            onEmailShare={() => { }}
            projectId={null}
            dataSource={user_roles?.data}
            selectRows={false}
            defaultPageSize={10}
            loading={user_roles_loading}
            total={user_roles?.meta?.total_count}
            key="user-permissions"
          />
        </Panel>
        <Panel
          header="Projects List"
          key="projects-list"
        >
          <AlectifyTable
            size="small"
            onDataFetch={(data) => dispatch(getProjectsByAdmin(data))}
            columns={projectColumns({
              setAdminRecord: (data) => dispatch(setAdminRecord(data)),
              setModalType,
            })}
            isDownloadZip={false}
            isEmailSharing={false}
            onDownloadZip={() => { }}
            onEmailShare={() => { }}
            projectId={null}
            dataSource={admin_projects?.data}
            selectRows={false}
            defaultPageSize={10}
            loading={projects_loading}
            total={admin_projects?.meta?.total_count}
            key="projects-list"
          />
        </Panel>
      </Collapse>
      {
        record && modalType === 'update-branch'
        && (
          <BranchModal
            key={`${record?.id}-${record?.name}`}
            title={record?.name || 'N/A'}
            visible={!isNull(record) && modalType === 'update-branch'}
            permissions={permission_list?.data}
            onSubmit={() => { }}
            user={user}
            record={record}
            onOk={() => closeModal()}
            onCancel={() => closeModal()}
            loading={!isNull(record) && record_loading}
            addPermission={(data) => dispatch(addPermissionToGroup(data))}
            removePermission={(data) => dispatch(removePermissionFromGroup(data))}
          />
        )
      }
      {
        record && modalType === 'update-branch-group'
        && (
          <GroupModal
            key={`${record?.id}`}
            title={record?.name || record?.permission_group?.name || 'N/A'}
            visible={!isNull(record) && modalType === 'update-branch-group'}
            groups={role_templates?.data}
            onSubmit={() => { }}
            record={record}
            onOk={() => closeModal()}
            onCancel={() => closeModal()}
            loading={!isNull(record) && action_loading}
            updateGroup={(data) => {
              record?.group
                ? dispatch(updateBranchGroup(data)).then(() => closeModal())
                : dispatch(updateUserGroup({
                  ...data,
                })).then(() => closeModal());
            }}
          />
        )
      }
      {
        record && modalType === 'update-role'
        && (
          <RoleModal
            key={`${record?.id}-${record?.name}`}
            title={record?.name || 'N/A'}
            visible={!isNull(record) && modalType === 'update-role'}
            permissions={permission_list?.data}
            onSubmit={() => { }}
            user={user}
            record={record}
            onOk={() => closeModal()}
            onCancel={() => closeModal()}
            loading={record_loading}
            addPermission={(data) => dispatch(addPermissionToGroup(data))}
            removePermission={(data) => dispatch(removePermissionFromGroup(data))}
          />
        )
      }
      {
        record && modalType === 'create-role-template'
        && (
          <RoleTemplateModal
            title="Create Role Template"
            visible={modalType === 'create-role-template'}
            permissions={permission_list?.data}
            onSubmit={() => { }}
            record={record}
            onOk={() => closeModal()}
            onCancel={() => closeModal()}
            loading={record_loading}
            createRoleTemplate={(data) => dispatch(createRoleTemplate(data))}
          />
        )
      }
      {
        record && modalType === 'update-user'
        && (
          <UserModal
            title="Update User "
            visible={modalType === 'update-user'}
            permissions={permission_list?.data}
            role_templates={role_templates?.data}
            organizationTypes={organizationTypes}
            roleTypes={roleTypes}
            branches={branches}
            key={`${record?.id}`}
            onSubmit={() => { }}
            record={record}
            onOk={() => closeModal()}
            onCancel={() => closeModal()}
            loading={record_loading}
            onFinish={updateUser}
          />
        )
      }
      {
        record && modalType === 'edit-user-permissions'
        && (
          <UserPermissionsModal
            title="Edit User Permissions"
            visible={modalType === 'edit-user-permissions'}
            permissions={permission_list?.data}
            role_templates={role_templates?.data}
            roleTypes={roleTypes}
            branches={branches}
            key={`${record?.id}`}
            onSubmit={() => { }}
            record={record}
            onOk={() => closeModal()}
            onCancel={() => closeModal()}
            loading={record_loading}
            onFinish={updateUser}
          />
        )
      }
      {/* <Modal
        visible={authorizeModal}
        okText="Authorize"
        onOk={form.submit}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
        centered
        mask
        confirmLoading={loading}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onAuthorizeSubmit}
          onFinishFailed={() => { }}
          validateTrigger="onSubmit"
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter email',
              },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter password',
              },
              {
                min: 6,
                message: 'Password must contain atleast 6 characters.',
              },
            ]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>
        </Form>
      </Modal> */}
      {/* ============= Company Modal ============ */}
      <Modal
        visible={companyModal}
        okText="Create Company"
        onOk={companyForm.submit}
        onCancel={() => setCompanyModal(false)}
        centered
        mask
        confirmLoading={formLoading}
      >
        <Form
          form={companyForm}
          name="basic"
          layout="vertical"
          onFinish={onCreateCompany}
          onFinishFailed={() => { }}
          validateTrigger="onSubmit"
        >
          <Form.Item
            label="Company Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Required',
              },
            ]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
        </Form>
      </Modal>
      {/* ============= Branch Modal ============ */}
      <Modal
        visible={branchModal}
        okText="Create Branch"
        onOk={companyBranchForm.submit}
        onCancel={() => setBranchModal(false)}
        centered
        mask
        confirmLoading={formLoading}
      >
        <Form
          form={companyBranchForm}
          name="basic"
          layout="vertical"
          onFinish={onCreateBranch}
          onFinishFailed={() => { }}
          validateTrigger="onSubmit"
        >
          <Form.Item
            label="Company Name"
            name="company"
            rules={[
              {
                required: true,
                message: 'Required',
              },
            ]}
          >
            <Select>
              {companies.map((company) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Branch Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Required',
              },
            ]}
          >
            <Input placeholder="Branch Name" />
          </Form.Item>
        </Form>
      </Modal>
      {/* ============= Branch Admin Modal ============ */}
      <Modal
        visible={branchAdminModal}
        okText="Create Admin"
        onOk={branchAdminForm.submit}
        onCancel={() => setBranchAdminModal(false)}
        centered
        mask
        confirmLoading={formLoading}
      >
        <Form
          form={branchAdminForm}
          name="basic"
          layout="vertical"
          onFinish={onCreateBranchAdmin}
          onFinishFailed={() => { }}
          validateTrigger="onSubmit"
        >
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item
                label="Company Name"
                name="company"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Select onChange={getBranches}>
                  {companies.map((company) => (
                    <Option key={company.id} value={company.id}>
                      {company.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Branch"
                name="branch"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Select>
                  {branches.map((branch) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your last name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Organization Name"
                name="organization_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the organization name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Permission"
                name="role_template"
                rules={[
                  {
                    required: true,
                    message: 'Please select the permissions',
                  },
                ]}
              >
                <Select>
                  {role_templates?.data?.map((role) => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Job Title"
                name="job_title"
              >
                <Input placeholder="Enter Job Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Business Contact Number"
                name="business_phone_number"
              >
                <Input placeholder="Enter Business Contact number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Business Address"
                name="business_address"
              >
                <Input placeholder="Enter Business Address" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Address"
                name="address"
              >
                <Input placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password',
                  },
                  {
                    min: 6,
                    message: 'Password must contain atleast 6 characters.',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Content>
  );
}

export default ProjectsAdmin;
