import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Col, Progress, Row,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PROGRESS_GRADIENT_COLOR, renderAmount } from '../../../../../../utils';
import { getProjectCostsSummary } from '../../../../../../store/billing';

const ProjectCostsSummary = memo(() => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { projectCostsSummary } = useSelector(({ billings }) => billings);

  useEffect(() => {
    dispatch(getProjectCostsSummary(projectId));
  }, [projectId]);

    return (
        <Card title="Vendor Summary" loading={projectCostsSummary.fetching}>
            <Row gutter={30} className="po-information">
                {/* <Col span={8}>
                    <label>Original Cost:</label>
                    <span>{renderAmount(projectCostsSummary.data?.originalCost)}</span>
                </Col> */}
                <Col span={12}>
                    <label>Total Cost:</label>
                    <span>{renderAmount(projectCostsSummary.data?.totalCost)}</span>
                </Col>
                <Col span={12}>
                    <label>Remaining to bill:</label>
                    <span>{renderAmount(projectCostsSummary.data?.remainingCost)}</span>
                </Col>
                {/* <Col span={8}>
                    <label>Cost Change:</label>
                    <span>
                        {projectCostsSummary.data?.charged > 0 ? '+' : ''}
                        {projectCostsSummary.data?.charged || 0} %
                    </span>
                </Col> */}
                <Col span={24}>
                    <label>Billed so far: {renderAmount(projectCostsSummary.data?.billedSoFar)} ({projectCostsSummary.data?.completed || 0} %)</label>
                    <Progress
                        percent={projectCostsSummary.data?.completed || 0}
                        strokeColor={PROGRESS_GRADIENT_COLOR}
                        status="normal"
                        showInfo={false}
                    />
                </Col>
            </Row>
        </Card>
    );
});

export default ProjectCostsSummary;
