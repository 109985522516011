import _ from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, notification, Row, Spin, Upload } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlectifyTable, UploadDocumentModal, UploadNote } from '../../components';
import { downloadZip, getSignedUrl, shareFiles } from '../../services';
import { getEquipDocuments, getReviewDocuments } from '../../store/documents';
import { DEFAULT_PAGE_SIZE, DEFAULT_START_PAGE } from '../../utils';
import { packageColumns } from './package_columns';
import { reviewColumns } from './review_columns';

const { Panel } = Collapse;

export const EquipmentDocs = memo(
    ({ isLoading, projectId, pkgId, defaultPackage = {} }) => {
        const dispatch = useDispatch();
        const [documentsToUpload, setDocumentsToUpload] = useState([]);
        const [uploadModal, setUploadModal] = useState(false);
        const {
            equipment_documents,
            equipment_documents_loading,
        } = useSelector(({ documents }) => documents);

        const getAllDocuments = async (pagination) => {
            try {
                const params = {
                    ...pagination,
                    id: projectId,
                    page: pagination?.page || DEFAULT_START_PAGE,
                    pageSize: pagination?.pageSize || DEFAULT_PAGE_SIZE,
                    package_room: pkgId,
                };
                dispatch(getEquipDocuments(params));
            } catch (error) {
                console.log(error);
            }
        };

        useEffect(() => {
            getAllDocuments();
        }, []);

        const downloadDocument = async ({ file_path, file_name }) => {
            const response = await getSignedUrl(file_path);
            window.open(response?.data);
        };

        const onRowSelectionAction = async (data) => {
            //    setRowSelections();
        };

        const downloadDocumentsInZip = async (s3Keys, type) => {
            const response = await downloadZip({
                projectId,
                payload: {
                    docs_group: `${type}-docs`,
                    s3_keys: s3Keys,
                },
            });

            if (response?.success) {
                notification.success({
                    title: 'Received Download Zip Request',
                    message: 'Zip link will be send to you mail id',
                });
            }
        };

        const onDownloadZip = async (data, type) => {
            const s3_keys = data.map((a) => a.file_path);
            await downloadDocumentsInZip(s3_keys, type);
        };

        const onEmailShare = async (emails, data, type) => {
            const file_ids = data.map((a) => a?.id);
            const response = await shareFiles({
                projectId,
                payload: {
                    to_users: emails,
                    document_type: `${type}`,
                    file_ids,
                },
            });

            if (response?.success) {
                notification.success({
                    title: 'Received Download Zip Request',
                    message: 'Shared files',
                });
            }
        };

        const onUploadDocuments = (ev) => {
            setDocumentsToUpload([...documentsToUpload, ...ev.fileList]);
            setUploadModal(true);
        };

        return (
            <Spin spinning={isLoading} tip={isLoading ? 'Please wait, it will take a while' : ''}>
                <Row justify="space-between">
                    <Col span={12}>
                        <h2>Documents</h2>
                    </Col>
                    <Col
                        span={12}
                        style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                    >
                        <Upload
                            beforeUpload={() => false}
                            onChange={_.debounce(onUploadDocuments, 100)}
                            showUploadList={false}
                            multiple
                            fileList={[]}
                        >
                            <Button type="default" shape="round" className="main-upload-btn">
                                <span
                                    style={{
                                        margin: '0 8px 0 0',
                                    }}
                                >
                                    <PlusOutlined /> Add Documents
                                </span>
                                <UploadNote />
                            </Button>
                        </Upload>
                    </Col>
                </Row>
                <>
                    <Collapse
                        className="alectify-collapse mb-15"
                        defaultActiveKey={['project-files']}
                        onChange={() => { }}
                    >
                        <Panel
                            header={`Equipment Files (${equipment_documents?.meta?.total_count
                                ? equipment_documents?.meta?.total_count
                                : 0
                                })`}
                            key="equipment-fies"
                        >
                            <AlectifyTable
                                size="small"
                                onDataFetch={getAllDocuments}
                                columns={packageColumns({
                                    downloadDocument,
                                })}
                                isDownloadZip
                                isEmailSharing
                                onDownloadZip={(data) => onDownloadZip(data, 'equipment')}
                                onEmailShare={(emails, data) =>
                                    onEmailShare(emails, data, 'equipment')
                                }
                                id={projectId}
                                dataSource={equipment_documents?.data}
                                loading={equipment_documents_loading}
                                rowSelection={false}
                                onRowSelectionAction={(data) =>
                                    onRowSelectionAction(data, 'equipment')
                                }
                                total={equipment_documents?.meta?.total_count}
                                key={`${projectId}-eqp-files`}
                            />
                        </Panel>
                    </Collapse>
                    {uploadModal && (
                        <UploadDocumentModal
                            defaultPackage={defaultPackage}
                            visible={uploadModal}
                            projectId={projectId}
                            onCancel={() => setUploadModal(false)}
                            documentsToUpload={documentsToUpload}
                            getAllDocuments={getAllDocuments}
                            setDocumentsToUpload={setDocumentsToUpload}
                            isEquipmentDocuments
                        />
                    )}
                </>
            </Spin>
        );
    },
);
