import { Card, Collapse } from 'antd';
import { useParams } from 'react-router-dom';
import { memo, useState, useEffect } from 'react';
import { AlectifyTable } from '../../../../../../components';
import { fetchFinancialDocuments, fetchMasterBiddingDocuments } from '../../../../../../services';
import useColumns from './effects/useColumns';
import { conditionForVendorAndItem } from '../../../../../../utils';

const { Panel } = Collapse;

const MasterBidDocuments = memo(() => {
    const columns = useColumns();
    const { projectId } = useParams();
    const [documents, setDocuments] = useState({ data: null, fetching: false, total: 0 });

    const getMasterBiddingDocuments = async (pagination) => {
        try {
            setDocuments({ ...documents, fetching: true });
            const params = {
                page: pagination?.page || 1,
                per_page: pagination?.per_page || 10,
            };
            const response = await fetchMasterBiddingDocuments(projectId, params);
            setDocuments({
                ...documents,
                fetching: false,
                data: response?.data,
                total: response.meta.total_count,
            });
        } catch (error) {
            setDocuments({ ...documents, fetching: false });
            console.log(error);
        }
    };

    useEffect(() => {
        getMasterBiddingDocuments();
    }, [projectId]);

    return (
        <Card title="Master Bid Documents" bordered={false}>
            <AlectifyTable
                columns={columns}
                defaultPageSize={10}
                total={documents.total}
                dataSource={documents.data}
                loading={documents.fetching}
                onDataFetch={getMasterBiddingDocuments}
                selectRows={false}
            />
        </Card>
    );
});

export default MasterBidDocuments;
