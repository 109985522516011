import PropTypes from 'prop-types';
import { memo } from 'react';
import { AlectifyTable } from '../../components';
import useColumns from './effects/useColumns';

const ContactList = (props) => {
    const columns = useColumns(props.actionHandler);

    return (
        <AlectifyTable
            columns={columns}
            selectRows={false}
            defaultPageSize={10}
            total={props.contacts.total}
            dataSource={props.contacts.data}
            loading={props.contacts.fetching}
            onDataFetch={props.getContacts}
        />
    );
};

ContactList.defaultProps = {
    contacts: {
        data: PropTypes.array,
        total: PropTypes.number,
        fetching: PropTypes.bool,
    },
    getContacts: () => {},
    actionHandler: () => {},
};

ContactList.propTypes = {
    contacts: PropTypes.object,
    getContacts: PropTypes.func,
    actionHandler: PropTypes.func,
};

export default memo(ContactList);
