import _ from 'lodash';
import { memo, useEffect, useState } from 'react';
import {
  Form, Modal, Input, Spin, notification,
} from 'antd';
import { DragAndDropContainer } from '../../../components';
import '../../../assets/styles/components/package-review.scss';

const { TextArea } = Input;

function CreatePackageModal({
  title = '',
  onSubmit = () => { },
  onUpdate = () => { },
  onCancel = () => { },
  visible = false,
  individualRoomsData = [],
  loading = false,
  initialData = {},
  projectSetup = false,
  getAllPackages = () => { },
  validatePackageName = () => { },
}) {
  const [items, setItems] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getAllPackages();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(initialData)) {
      setItems([...initialData.tags]);
      form.setFieldsValue(JSON.parse(JSON.stringify(initialData)));
    } else {
      setItems([]);
      form.resetFields();
    }
  }, [initialData]);

  const onSubmitHandler = (values) => {
    const pkgExist = validatePackageName(values.name);

    if (!pkgExist) {
      values.tags = projectSetup
        ? items
        : items.map(({ id, packageroom, team_members }) => ({
          id,
          packageroom: packageroom[0],
          team_members,
        }));

      if (!_.isEmpty(initialData)) {
        let allTeamMembers = [];
        items.forEach((item) => {
          if (item?.team_members) {
            allTeamMembers = [...allTeamMembers, ...item.team_members];
          }
        });
        values.team_members = allTeamMembers;
        values.tags = items.map(({ id }) => id);
        onUpdate(values);
      } else {
        if (values.tags.length === 0) {
          notification.error({
            message: 'Tags selected cannot be empty',
          });

          return false;
        }

        // if (values.tags.length < 2) {
        //     notification.error({
        //         message: 'Tags selected should be 2 or more.',
        //     });

        //     return false;
        // }

        if (values.tags && values.tags.length !== 0 && values.tags.length >= 1) {
          onSubmit(values, form.resetFields);
        }
      }
    } else {
      form.setFields([{ name: 'name', errors: ['Package with this name already exist'] }]);
    }
  };

  const cancleHandler = () => {
    form.resetFields();
    onCancel();
  };

  const getInnerSelectedData = (data) => {
    if (data) {
      setItems(data);
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={form.submit}
      onCancel={cancleHandler}
      confirmLoading={loading}
      okText={!_.isEmpty(initialData) ? 'Update' : 'Save'}
      style={{ minWidth: '1000px !important' }}
      className="review-room-modal"
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmitHandler}
          initialValues={initialData}
        >
          <Form.Item
            label="Package Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Package name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: false,
                message: 'Description is required',
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item name="tags" className="mb-0">
            <DragAndDropContainer
              items={individualRoomsData}
              selectedItems={!_.isEmpty(initialData) ? [...initialData.tags] : []}
              getData={getInnerSelectedData}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default memo(CreatePackageModal);
