import * as Action from './types';
import {
    deleteVendorAssociates,
    deleteVendorEstimations,
    fetchProjectFinancialSummary,
    fetchVendorAssociates,
    fetchVendorEstimationDetail,
    fetchVendorEstimations,
    fetchVendorQuotesSummary,
} from '../../services';

export const getProjectFinancialSummary = (projectId, verticalType) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_SUMMARY_INITATE });
        const response = await fetchProjectFinancialSummary(projectId, verticalType);
        dispatch({ type: Action.FETCH_SUMMARY_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: Action.FETCH_SUMMARY_FAILURE });
    }
};

export const getVendorQuotesSummary = (projectId) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_VENDOR_QUOTES_SUMMARY_INITATE });
        const response = await fetchVendorQuotesSummary(projectId);
        dispatch({ type: Action.FETCH_VENDOR_QUOTES_SUMMARY_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: Action.FETCH_VENDOR_QUOTES_SUMMARY_FAILURE });
    }
};

export const getFinancialVendorItems = (projectId, params) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_VENDOR_ESTIMATIONS_INITIATE });
        const response = await fetchVendorEstimations(projectId, params);
        dispatch({ type: Action.FETCH_VENDOR_ESTIMATIONS_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: Action.FETCH_VENDOR_ESTIMATIONS_FAILURE });
    }
};

export const getFinancialVendorItemDetail = (projectId, vendorItemId) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_VENDOR_ESTIMATION_DETAIL_INITIATE });
        const response = await fetchVendorEstimationDetail(projectId, vendorItemId);
        dispatch({ type: Action.FETCH_VENDOR_ESTIMATION_DETAIL_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: Action.FETCH_VENDOR_ESTIMATION_DETAIL_FAILURE });
    }
};

export const removeVendorEstimations = (projectId, vendorEstimateIds) => async (dispatch) => {
    try {
        dispatch({ type: Action.DELETE_VENDOR_ESTIMATIONS_INITIATE });
        await deleteVendorEstimations(projectId, vendorEstimateIds);
        dispatch({ type: Action.DELETE_VENDOR_ESTIMATIONS_SUCCESS });
        dispatch(getFinancialVendorItems(projectId));
        dispatch(getProjectFinancialSummary(projectId));
    } catch (error) {
        dispatch({ type: Action.DELETE_VENDOR_ESTIMATIONS_FAILURE });
    }
};

export const getVendorAssociates = (projectId, vendorItemId, params) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_VENDOR_ASSOCIATES_INITIATE });
        const response = await fetchVendorAssociates(projectId, vendorItemId, params);
        dispatch({ type: Action.FETCH_VENDOR_ASSOCIATES_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: Action.FETCH_VENDOR_ASSOCIATES_FAILURE });
    }
};

export const removeVendorAssociates =
    (projectId, vendorItemId, vendorAssociateIds) => async (dispatch) => {
        try {
            dispatch({ type: Action.DELETE_VENDOR_ASSOCIATES_INITIATE });
            await deleteVendorAssociates(projectId, vendorItemId, vendorAssociateIds);
            dispatch({ type: Action.DELETE_VENDOR_ASSOCIATES_SUCCESS });
            dispatch(getVendorAssociates(projectId, vendorItemId));
            dispatch(getFinancialVendorItems(projectId, { page: 1, per_page: 10 }));
            dispatch(getProjectFinancialSummary(projectId));
            dispatch(getFinancialVendorItemDetail(projectId, vendorItemId));
        } catch (error) {
            dispatch({ type: Action.DELETE_VENDOR_ASSOCIATES_FAILURE });
        }
    };

export const setFinancialVerticalType = (type) => (dispatch) => {
    dispatch({
        type: Action.SET_FINANCIAL_VERTICAL_TYPE,
        payload: type,
    });
};
