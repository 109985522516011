import _ from 'lodash';
import {
  Spin, Modal, Row, Select, Button,
} from 'antd';
import { useState } from 'react';

function GroupModal({
  title = 'Add/Update',
  record,
  visible = false,
  groups = [],
  onOk,
  onCancel,
  updateGroup,
  loading = false,
}) {
  const [group, setGroup] = useState(null);

  return (
    <Modal
      title={title}
      centered
      width={400}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Close"
      maskClosable={false}
      className="equipment-detail-modal"
      footer={null}
    >
      <Spin spinning={loading}>
        <Row>
          <Select
            placeholder="Select Group"
            style={{ width: '100%' }}
            defaultValue={record?.group?.name || record?.permission_group?.name}
            onChange={(g) => setGroup(g)}
            options={groups.map((g) => ({
              value: g?.id,
              label: g?.name,
            }))}
          />
          <Button
            className="mt-20"
            type="primary"
            block
            onClick={() => {
              updateGroup({
                branchId: record?.id,
                payload: {
                  group,
                  user: record?.id,
                },
              });
            }}
          >
            { (record?.group || record?.permission_group) ? 'Update Group' : 'Attach Group'}
          </Button>
        </Row>
      </Spin>
    </Modal>
  );
}

export default GroupModal;
