import { Card } from 'antd';
import './styles.scss';

const { Meta } = Card;

export function SLineThumbnail({
  name, image_url, tags, onView,
}) {
  return (
    <div className="sline-card">
      <Card
        className="cursor-pointer full-width"
        cover={<img alt={name} src={image_url} />}
        onClick={onView}
      >
        <Meta title={name} />
      </Card>
    </div>
  );
}
