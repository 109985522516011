/*
--------------------
Platform Level Permissions
--------------------
*/

import { PLATFORM_PERMISSIONS, PROJECT_PERMISSIONS } from "./constants";

export const can_add_project = (permission) => permission?.can_add_project;

export const can_use_admin_panel = (permission) => permission?.can_use_admin_panel;

export const can_use_one_to_one_chats = (permission) => permission?.can_use_one_to_one_chats;

export const can_view_contacts_sidebar = (permission) => permission?.can_view_contacts_sidebar;

export const can_view_dashboard_sidebar = (permission) => permission?.can_view_dashboard_sidebar;

export const can_view_projects_sidebar = (permission) => permission?.can_view_projects_sidebar;

export const can_view_sharedwithme_sidebar = (permission) => permission?.can_view_sharedwithme_sidebar;

export const hasPlatformPermission = (permissions, permission) => permissions?.includes(permission);

/*
--------------------
Project Level Permissions
--------------------
*/

export const hasProjectPermission = (permissions, permission) => permissions?.includes(permission);
