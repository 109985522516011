import moment from 'moment';
import { debounce, map } from 'lodash';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import {
    AutoComplete,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Spin,
    Upload,
} from 'antd';
import {
    BASIC_REQUIRED_VALIDATION_RULES,
    INPUT_DATE_FORMAT,
    PROJECT_COST_REASONS,
    SERVER_DATE_FORMAT,
} from '../../../../../../utils';
import { getProjectCosts, getProjectCostsSummary } from '../../../../../../store/billing';
import {
    createProjectCost,
    getOrganizations,
    uploadProjectCostDocuments,
} from '../../../../../../services';

const CreateProjectCost = memo(() => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [vendors, setVendors] = useState({
        data: [],
        value: '',
        fetching: false,
    });
    const [hasDocuments, setHasDocuments] = useState(false);

    const onSearchVendor = debounce(async (value) => {
        try {
            if (!value) {
                throw new Error();
            }
            setVendors({ ...vendors, fetching: true });
            const response = await getOrganizations({ search: value });
            setVendors({
                ...vendors,
                fetching: false,
                data: response?.data,
                value,
            });
        } catch (error) {
            setVendors({ ...vendors, fetching: false, data: [] });
        }
    }, 500);

    const uploadDocuments = async (projectCostId, values) => {
        try {
            const { invoice_date, invoice_number, documents } = values;
            const formData = new FormData();
            formData.append('invoice_date', invoice_date);
            formData.append('invoice_number', invoice_number);
            documents.fileList.map((file) => formData.append('files', file.originFileObj));
            const response = await uploadProjectCostDocuments(projectId, projectCostId, formData);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            const { invoice_date, invoice_number, documents, ...formValues } = values;
            formValues.reason = 'invoice';
            formValues.po_date = formValues.po_date.format(SERVER_DATE_FORMAT);
            setSubmitting(true);
            const response = await createProjectCost(projectId, formValues);
            if (documents?.fileList?.length > 0) {
                values.invoice_date = values?.invoice_date?.format(SERVER_DATE_FORMAT);
                await uploadDocuments(response?.data.id, values);
            }
            message.success('Created Successfully');
            dispatch(getProjectCosts(projectId, { page: 1, per_page: 10 }));
            dispatch(getProjectCostsSummary(projectId));
            setSubmitting(false);
            form.resetFields();
        } catch (error) {
            setSubmitting(false);
            console.log(error);
        } finally {
            setHasDocuments(false);
        }
    };

    const onSelectDocuments = (ev) => {
        setHasDocuments(ev.fileList.length > 0);
    };

    return (
        <Spin spinning={submitting}>
            <h1>Create Entry</h1>
            <Form
                form={form}
                layout="vertical"
                className="mb-20"
                onFinish={onSubmit}
                initialValues={{ po_date: moment() }}
            >
                <Row gutter={30}>
                    <Col span={8}>
                        <Form.Item
                            label="Date"
                            name="po_date"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                        >
                            <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Amount"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                            name="amount"
                        >
                            <InputNumber
                                prefix="$"
                                precision={2}
                                min={0}
                                step={0.1}
                                className="full-width"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Vendor"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                            name="vendor"
                        >
                            <AutoComplete
                                showSearch
                                placeholder="Enter Vendor"
                                options={map(vendors.data, (org) => {
                                    return {
                                        name: org?.name,
                                        value: org?.name,
                                    };
                                })}
                                onSearch={onSearchVendor}
                                notFoundContent={
                                    vendors.fetching ? (
                                        <div className="text-center">
                                            <Spin />
                                        </div>
                                    ) : null
                                }
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item
                            label="Comments"
                            name="comments"
                        >
                            <Input placeholder="Enter Comments" />
                        </Form.Item>
                    </Col> */}
                </Row>
                <h1>Invoice Details</h1>
                <Row gutter={30}>
                    <Col span={8}>
                        <Form.Item
                            label="Invoice Date"
                            name="invoice_date"
                            rules={[
                                hasDocuments
                                    ? { required: true, message: 'Please select Invoice Date' }
                                    : { required: false },
                            ]}
                        >
                            <DatePicker
                                disabled={!hasDocuments}
                                className="full-width"
                                format={INPUT_DATE_FORMAT}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Invoice #"
                            name="invoice_number"
                            rules={[
                                hasDocuments
                                    ? { required: true, message: 'Please enter Invoice Number' }
                                    : { required: false },
                            ]}
                        >
                            <Input disabled={!hasDocuments} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Documents" name="documents">
                            <Upload
                                multiple
                                beforeUpload={() => false}
                                onChange={onSelectDocuments}
                            >
                                <Button icon={<UploadOutlined />}>Select Files</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form>
        </Spin>
    );
});

export default CreateProjectCost;
