export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const PACKAGE_LIST = 'PACKAGE_LIST';

export const GET_ALL_PACKAGES_INITIATE = 'GET_ALL_PACKAGES_INITIATE';
export const GET_ALL_PACKAGES_SUCCESS = 'GET_ALL_PACKAGES_SUCCESS';

export const ADD_PACKAGE_TO_LIST = 'ADD_PACKAGE_TO_LIST';

export const GET_MASTER_PACKAGES_LOADING = 'GET_MASTER_PACKAGES_LOADING';
export const GET_MASTER_PACKAGES_SUCCESS = 'GET_MASTER_PACKAGES_SUCCESS';
