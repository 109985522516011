export const BASIC_REQUIRED_VALIDATION_RULES = [{ required: true, message: 'Required' }];

export const EMAIL_REQUIRED_VALIDATION_RULES = [
    {
        type: 'email',
        message: 'Please enter valid email!',
    },
    {
        required: true,
        message: 'Required',
    },
];
