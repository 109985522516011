import {
  find, filter, isEmpty, orderBy,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Empty, Row, Spin, Divider, List, Skeleton, Tag,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { activePackageRead, getChatPackages, resetPackages, setActiveChat } from '../../../store/chat';
import ChatDisplay from './chat-display';

function CollaborationInbox({ projectId, getNotificationsCount = () => { } }) {
  const { packages, loading } = useSelector(({ chat }) => chat);
  const { activeProject } = useSelector(({ common }) => common);

  const [activeTag, setActiveTag] = useState();
  const [tagsLoaders, setTagsLoader] = useState(true);
  const [searchParam, setSearchParam] = useState('');
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const packageData = find(packages, 'id', projectId);

  useEffect(() => {
    setPage(find(packages, 'id', projectId)?.currPage || 1);
  }, []);

  useEffect(() => {
    dispatch(resetPackages({ project: activeProject, projectId }));
  }, [dispatch, projectId]);

  useEffect(() => {
    setTagsLoader(false);
  }, [packageData]);

  useEffect(() => {
    // Make a double call as temp fix.
    dispatch(getChatPackages({
      project: activeProject,
      projectId,
      page: 1,
      search: searchParam,
    }));
  }, []);

  useEffect(() => {
    dispatch(getChatPackages({
      project: activeProject,
      projectId,
      page,
      search: searchParam,
    }));
  }, [dispatch, page, projectId, searchParam]);

  const searchInbox = (value) => {
    if (value) {
      setSearchParam(value);
    } else {
      setSearchParam('');
    }
  };

  const notificationReadHandler = (tag) => (tag?.unread_messages_count > 0 ? 'unread' : 'read');

  // @TODO: Add notifications views/detail functionality using separate API
  const onNotificationClick = async (pkg) => {
    setActiveTag(pkg);

    // Set package messages as read
    const tag = filter(packageData?.packages, (p) => {
      if (p?.id === pkg?.id) return p;
    });
    tag[0].has_unread_messages = false;
    tag[0].unread_messages_count = 0;
    dispatch(activePackageRead(pkg));
    dispatch(setActiveChat(pkg));
  };

  // console.log('hasMore', packageData?.packages?.length, packageData?.meta?.total_count, packageData?.packages?.length < packageData?.meta?.total_count, find(packages, 'id', projectId)?.currPage);

  return (
    <Row key={activeProject?.id}>
      <Col span={6}>
        <Spin spinning={loading}>
          <div className="page-content-container mb-0">
            <div className="task-inbox-actions">
              <Row>
                <Col span={16}>
                  <input
                    type="search"
                    className="inbox-search"
                    placeholder="Search ..."
                    onChange={({ target }) => searchInbox(target.value)}
                  />
                </Col>
              </Row>
            </div>
            <div id="scrollableDiv" className="task-inbox-list custom-scroll-dark">
              <ul>
                {!isEmpty(packageData?.packages) ? (
                  <InfiniteScroll
                    dataLength={packageData?.packages?.length}
                    next={() => {
                      console.log('Call next');
                      setPage(page + 1);
                    }}
                    hasMore={packageData?.packages?.length < packageData?.meta?.total_count}
                    loader={(
                      <Skeleton
                        avatar
                        paragraph={{
                          rows: 1,
                        }}
                        active
                      />
                    )}
                    endMessage={
                      <Divider plain>It is all, nothing more.</Divider>
                    }
                    scrollableTarget="scrollableDiv"
                    key={activeProject?.id}
                  >
                    <List
                      dataSource={orderBy(packageData?.packages, ['project_chat', 'unread_messages_count'], ['asc', 'desc'])}
                      key={activeProject?.id}
                      renderItem={(tag, index) => (
                        <List.Item
                          key={`${tag.name}-${tag.id}-${index}`}
                          className={`task-notification ${tag.id === activeTag?.id ? 'task-active' : ''}`}
                        >
                          <article
                            onClick={() => onNotificationClick(tag)}
                            id={
                              activeTag?.id === tag.id ? 'active' : ''
                            }
                            className={notificationReadHandler(tag)}
                          >
                            <div>
                              <div className="task-inbox-header">
                                <h4>{tag?.name}</h4>
                              </div>
                              <p
                                className="task-inbox-desc"
                                title={tag?.description}
                              >
                                {tag?.description}
                              </p>
                            </div>
                            {
                              tag?.id !== activeTag?.id
                              && (
                                <div className="actions">
                                  {notificationReadHandler(tag)
                                    === 'unread' && (
                                      <Tag color="volcano">
                                        Unread (
                                        {tag?.unread_messages_count}
                                        )
                                      </Tag>
                                    )}
                                </div>
                              )
                            }
                          </article>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                ) : !tagsLoaders && isEmpty(packageData?.packages) ? (
                  <Empty
                    description="You don't have any packages"
                    className="mt-30"
                  />
                ) : null}
              </ul>
            </div>
          </div>
        </Spin>
      </Col>
      <Col span={18}>
        {
          activeTag
            ? (
              <ChatDisplay
                tag={activeTag}
                key={activeTag?.id}
                projectId={projectId}
                projectChat={activeTag?.project_chat}
              />
            )
            : (
              <Empty
                description="You don't have any message"
                className="mt-30"
              />
            )
        }
      </Col>
    </Row>
  );
}

export default CollaborationInbox;
