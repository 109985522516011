import { memo } from 'react';
import { Col, Row } from 'antd';
import ProjectCostsSummary from './ProjectCostsSummary';
import CustomerPurchaseOrderSummary from './CustomerPurchaseOrderSummary';

const BillingSummary = memo(() => (
  <Row gutter={30} className="mb-30">
    <Col span={12}>
      <CustomerPurchaseOrderSummary />
    </Col>
    <Col span={12}>
      <ProjectCostsSummary />
    </Col>
  </Row>
));

export default BillingSummary;
