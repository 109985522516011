import {
    DesktopOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    TeamOutlined,
    UserSwitchOutlined,
    FileTextOutlined,
    InboxOutlined,
    SoundTwoTone,
    StopOutlined,
    CalendarOutlined,
    DollarOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, Layout, Menu, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RESET_STATE } from '../../store/common';
import {
    CONTACTS,
    DASHBOARD,
    makeAvatar,
    PROJECTS,
    removeFromLocal,
    SETTING,
    USERINFO,
    SHARED_WITH_ME,
    PROFILE_SETTINGS,
    isMainDashboardActive,
    isOneToOneChatActive,
    isInboxActive,
    USER_PERMISSIONS,
    PROJECT_TYPES,
} from '../../utils';
import * as Permissions from '../../utils/permissions';
import ProjectIcon, { ContactUsIcon } from '../icons/project-icon';
import fav from '../../assets/images/logo.png';
import logo from '../../assets/images/new_logo.png';
import Help from '../../pages/help';
import './layout.scss';
import ChatWidget from '../chat-widget';
import { sumBy } from 'lodash';

const { Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export function AlectifyLayout({ children }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useSelector(({ auth }) => auth);
    const { packages } = useSelector(({ chat }) => chat);
    const [collapsed, setCollapsed] = useState(true);
    const [toggleAction, setToggleAction] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [permission, setPermission] = useState(null);
    const { activeProject } = useSelector(({ common }) => common);

    const logout = () => {
        dispatch({ type: RESET_STATE });
        removeFromLocal('token');
        localStorage.clear();
        window.location.href = '/login';
    };

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <Link to={PROFILE_SETTINGS}>Profile Settings</Link>
            </Menu.Item>
            <Menu.Item key="4" onClick={logout}>
                <a rel="noopener noreferrer" href="#">
                    Logout
                </a>
            </Menu.Item>
        </Menu>
    );

    const toggle = (action) => {
        if (action === toggleAction) {
            setToggleAction('');
        } else {
            setToggleAction(action);
        }
    };
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const location = useLocation();

    const getCount = () => {
        const agg = packages.reduce(
            (acc, p) => {
                return {
                    count: acc.count + sumBy(p?.packages, 'unread_messages_count'),
                };
            },
            { count: 0 },
        );
        return agg?.count;
    };

    useEffect(() => {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            const scopes = registrations.map((r) => r.scope);
            const el = scopes.find((a) => a.includes('firebase-cloud-messaging-push-scope'));
            if (!el) setPermission('denied');
        });
        Notification.requestPermission().then((permission) => {
            setPermission(permission);
        });
    }, []);

    const goToProjects = () => {
        let url = '';
        if (activeProject?.type === PROJECT_TYPES.SUB_PROJECT) {
            url = `${PROJECTS}/${activeProject?.masterProject?.id}/sub-project/${activeProject?.id}`;
        } else {
            url = `${PROJECTS}/${activeProject?.masterProject?.id}/`;
        }
        history.push(url);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Modal
                className="modalBody"
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                getContainer={false}
            >
                <Help handleOk={handleOk} />
            </Modal>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={setCollapsed}
                width={250}
                theme="dark"
                trigger={null}
            >
                <div className="logo">
                    {collapsed ? (
                        <img
                            onClick={goToProjects}
                            src={fav}
                            alt="Alectify"
                            width={50}
                            height={50}
                            className="cursor-pointer"
                        />
                    ) : (
                        <img
                            src={logo}
                            alt="Alectify"
                            className="cursor-pointer"
                            onClick={goToProjects}
                        />
                    )}
                </div>
                {user && (
                    <Menu
                        theme="dark"
                        triggerSubMenuAction="click"
                        defaultSelectedKeys={[location.pathname]}
                        openKeys={!collapsed ? ['sub1'] : []}
                        mode="inline"
                        style={{ display: 'flex', flexDirection: 'column', height: '90%' }}
                    >
                        {isMainDashboardActive() && (
                            <Menu.Item
                                key="/dashboard"
                                icon={<DesktopOutlined />}
                                disabled={
                                    !Permissions.can_view_dashboard_sidebar(
                                        user?.platform_permissions,
                                    )
                                }
                                onClick={() => history.push(DASHBOARD)}
                            >
                                Dashboard
                            </Menu.Item>
                        )}
                        <Menu.Item
                            key="/projects"
                            icon={<ProjectIcon />}
                            disabled={
                                !Permissions.can_view_projects_sidebar(user?.platform_permissions)
                            }
                            onClick={goToProjects}
                        >
                            Projects
                        </Menu.Item>
                        {user?.permission_group?.name !== USER_PERMISSIONS.EXTERNAL && (
                            <Menu.Item
                                key="/contacts"
                                icon={<TeamOutlined />}
                                disabled={
                                    !Permissions.can_view_contacts_sidebar(
                                        user?.platform_permissions,
                                    )
                                }
                                onClick={() => history.push(CONTACTS)}
                            >
                                Contacts
                            </Menu.Item>
                        )}

                        <Menu.Item
                            key="/shared"
                            icon={<FileTextOutlined />}
                            disabled={
                                !Permissions.can_view_sharedwithme_sidebar(
                                    user?.platform_permissions,
                                )
                            }
                            onClick={() => history.push(SHARED_WITH_ME)}
                        >
                            Shared with me
                        </Menu.Item>
                        {user?.permission_group?.name !== USER_PERMISSIONS.EXTERNAL && (
                            <>
                                <Menu.Item
                                    key="/calendar"
                                    icon={<CalendarOutlined />}
                                    // disabled={!Permissions.can_view_sharedwithme_sidebar(user?.platform_permissions)}
                                    onClick={() => history.push('/calendar')}
                                >
                                    Calendar
                                </Menu.Item>
                                <Menu.Item
                                    key="/bidding-management"
                                    icon={<DollarOutlined />}
                                    onClick={() => history.push('/bidding-management')}
                                >
                                    Bidding Management
                                </Menu.Item>
                            </>
                        )}

                        {Permissions.can_use_admin_panel(user?.platform_permissions) &&
                            !user.is_superuser && (
                                <Menu.Item
                                    key="/it-admin"
                                    icon={<SettingOutlined />}
                                    // disabled={!Permissions.can_view_sharedwithme_sidebar(user?.platform_permissions)}
                                    onClick={() => history.push('/it-admin')}
                                >
                                    Admin
                                </Menu.Item>
                            )}
                        {user?.is_superuser && (
                            <Menu.Item
                                key="/projects-admin"
                                icon={<SettingOutlined />}
                                // disabled={!Permissions.can_view_sharedwithme_sidebar(user?.platform_permissions)}
                                onClick={() => history.push('/projects-admin')}
                            >
                                Super Admin
                            </Menu.Item>
                        )}
                        {/* {feature_inbox_groups_chats(user.features) && (
                        <SubMenu
                            key="sub1"
                            title="Chats"
                            icon={
                                <WechatOutlined
                                    style={{ fontSize: '23px' }}
                                    onClick={() => setCollapsed((state) => !state)}
                                />
                            }
                        >
                            <Menu.Item key="5">
                                <div className="chatWrapper">
                                    <CometChatUserList />
                                </div>
                            </Menu.Item>
                        </SubMenu>
                    )} */}

                        {/* <Menu.Item
                        key="/setting"
                        disabled
                        onClick={() => history.push(SETTING)}
                        icon={<SettingOutlined />}
                    >
                        Settings
                    </Menu.Item> */}

                        {/* <Menu.Item
                        disabled
                        key="/userinfo"
                        onClick={() => history.push(USERINFO)}
                        icon={<UserSwitchOutlined />}
                    >
                        User Info
                    </Menu.Item> */}

                        <Menu.Item
                            key="bottom-spacer"
                            disabled
                            style={{ marginTop: 'auto', display: 'hidden' }}
                        />
                        <Menu.Item key="7" icon={<ContactUsIcon />} onClick={showModal}>
                            Contact Us
                        </Menu.Item>
                    </Menu>
                )}
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background alectify-header"
                    style={{ paddingLeft: 15 }}
                >
                    <div className="header-left d-flex">
                        <div
                            className="trigger cursor-pointer d-inline-block"
                            onClick={() => setCollapsed(!collapsed)}
                            style={{ fontSize: 20 }}
                        >
                            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </div>
                    </div>
                    <div className="header-right">
                        <Space align="center">
                            {permission === 'denied' && (
                                <Button
                                    shape="default"
                                    icon={<StopOutlined twoToneColor="#eb2f96" />}
                                    onClick={() => {
                                        Notification.requestPermission().then((permission) => {
                                            if (permission === 'granted') {
                                                const notification = new Notification(
                                                    'Notifications enabled!',
                                                );
                                            }
                                        });
                                    }}
                                />
                            )}
                            {isInboxActive() && (
                                <div className="mt-10">
                                    <Badge
                                        className="site-badge-count-109"
                                        count={getCount()}
                                        overflowCount={9}
                                        offset={[0, 0]}
                                        color="#ff6f71"
                                    >
                                        <InboxOutlined style={{ fontSize: 20 }} />
                                    </Badge>
                                </div>
                            )}
                            <Avatar
                                className="ml-10"
                                src={
                                    user?.image ||
                                    makeAvatar(user?.first_name[0], user?.last_name[0])
                                }
                            />
                            <Dropdown overlay={menu} placement="bottomRight">
                                <Button>
                                    <span style={{ marginInlineEnd: 5 }}>Welcome</span>
                                    {user?.first_name}
                                </Button>
                            </Dropdown>
                        </Space>
                    </div>
                </Header>
                {children}
                {isOneToOneChatActive() &&
                    Permissions.can_use_one_to_one_chats(user?.platform_permissions) && (
                        <ChatWidget />
                    )}
                <Footer>
                    &copy; Copyrights Alectify {new Date().getFullYear()}. All Rights Reserved.
                </Footer>
            </Layout>
        </Layout>
    );
}
