import moment from 'moment';
import { memo, useState } from 'react';
import {
  Modal, Form, DatePicker, Select, Input, InputNumber, Spin,
} from 'antd';
import { INPUT_DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../../utils';

const { TextArea } = Input;

function ShippingUpdateModal({
  onSubmit = () => { },
  onCancel = () => { },
  visible = false,
  tag,
  loading = false,
}) {
  const [form] = Form.useForm();
  const [status, setStatus] = useState(null);

  const onSubmitHandler = (values) => {
    values.tag_id = tag;
    values.ship_date = moment(values.ship_date, SERVER_DATE_FORMAT).format(SERVER_DATE_FORMAT);
    onSubmit(values);
    setStatus(null);
    form.resetFields();
  };

  const cancelHandler = () => {
    onCancel();
  };

  return (
    <Modal
      title="Add Tracking Information"
      open={visible}
      onOk={form.submit}
      onCancel={cancelHandler}
      okText="Save"
    >
      {
        loading
          ? <Spin />
          : (
            <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
              {/* <Form.Item
                label="Status"
                name="shipment_status"
                rules={[{ required: true, message: 'Shipping status is required' }]}
              >
                <Select
                  placeholder="Select Shipping Status"
                  options={[
                    // {
                    //   value: 'Revised',
                    //   label: 'Revised Ship Date',
                    // },
                    {
                      value: 'Shipped',
                      label: 'Shipped',
                    },
                  ]}
                  onChange={(value) => setStatus(value)}
                />
              </Form.Item>
              <Form.Item label="Ship Date" name="ship_date" rules={[{ required: true, message: 'Ship Date is required' }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  format={INPUT_DATE_FORMAT}
                  disabledDate={(current) => moment().add(-1, 'days') >= current}
                />
              </Form.Item> */}
              {/* {
                status === 'Shipped'
                  ? (
                    <> */}
                      <Form.Item label="Carrier Tracking Id" name="carrier_tracking_id" rules={[{ required: false, message: 'Tracking Id is required' }]}>
                        <Input style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item label="Carrier" name="carrier" rules={[{ required: false, message: 'Shipping status is required' }]}>
                        <Input style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item label="Bill of Landing" name="bill_of_landing" rules={[{ required: false, message: 'Bill of landing is required' }]}>
                        <Input style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item label="Quantity" name="quantity" rules={[{ required: false, message: 'Quantity is required' }]}>
                        <InputNumber min={1} style={{ width: '100%' }} controls={false} />
                      </Form.Item>
                      <Form.Item label="Shipping Method" name="ship_method" rules={[{ required: false, message: 'Shipping method is required' }]}>
                        <Input style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item label="From Address" name="from_address" rules={[{ required: false, message: 'From Address is required' }]}>
                        <TextArea style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item label="To Address" name="to_address" rules={[{ required: false, message: 'To Address is required' }]}>
                        <TextArea style={{ width: '100%' }} />
                      </Form.Item>
                    {/* </>
                  )
                  : null
              } */}
            </Form>
          )
      }
    </Modal>
  );
}

export default memo(ShippingUpdateModal);
