import React, { useEffect } from 'react';
import { Spin, Row, Col, Form, Button, Input } from 'antd';

const BasicInfo = ({ onFinish, loader, contractor, contractorId }) => {
    const [form] = Form.useForm();
    const onSubmit = async (values) => {
        onFinish(values);
    };

    useEffect(() => {
        if (contractor) {
            form.setFieldsValue({
                ...contractor,
            });
        }
    }, [contractor]);
    return (
        <Spin spinning={loader}>
            <Row className="row">
                <Col flex="8 0 auto" className="column">
                    <h2 className="step-heading mb-20">Contractor Information</h2>
                </Col>
            </Row>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={contractor && { ...contractor }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Required',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item className="text-right mb-0">
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        htmlType="submit"
                    >
                        {contractorId ? 'Update' : 'Create'}
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};
export default BasicInfo;
