import { memo, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { AlectifyTable } from '../table';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_START_PAGE,
} from '../../utils';

export const SearchListModal = memo(
    ({
        title = '',
        projectId,
        pkgId,
        onRow = () => { },
        onCancel = () => { },
        visible = false,
        loading = false,
        footer = null,
        width,
        columns = [],
        fetchData = () => { },
    }) => {
        const [apiResponse, setApiResponse] = useState({ fetching: false, data: [], meta: {} });
        const getAllValues = async (_params = {}) => {
            try {
                setApiResponse({
                    ...apiResponse,
                    fetching: true,
                });
                const params = {
                    id: projectId,
                    packageId: pkgId,
                    type: 'dropdown',
                    page: DEFAULT_START_PAGE,
                    per_page: DEFAULT_PAGE_SIZE,
                    ..._params,
                };
                const response = await fetchData(params);
                setApiResponse({ ...apiResponse, ...response });
            } catch (error) {
            }
        };

        const getTableData = async (params = {}) => {
            await getAllValues(params);
        };

        useEffect(() => {
            getAllValues();
        }, []);

        return (
            <Modal
                title={`${title}`}
                open={visible}
                onCancel={onCancel}
                onOk={onCancel}
                confirmLoading={loading}
                okText="Close"
                width={width}
                footer={footer}
            >
                <AlectifyTable
                    size="small"
                    onDataFetch={getTableData}
                    columns={columns}
                    isDownloadZip={false}
                    isEmailSharing={false}
                    onDownloadZip={() => { }}
                    onEmailShare={() => { }}
                    id={projectId}
                    packageId={pkgId}
                    dataSource={apiResponse?.data}
                    loading={apiResponse?.fetching}
                    selectRows={false}
                    onRowSelectionAction={() => { }}
                    total={apiResponse?.meta?.total_count}
                    key={`${projectId}-eqp-files`}
                    onRow={onRow}
                />
            </Modal>
        );
    },
);
