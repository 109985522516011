import { Empty, Tabs } from 'antd';
import { memo, useState } from 'react';
import Billing from './billing';
import Insights from './insights';
import Financials from './financials';
import Documents from './documents';
import CustomerPurchaseOrder from './customer-po';
import DigitalBinder from './binder';
import { isExternalUser } from '../../../dashboard/helper';
import { useSelector } from 'react-redux';

const MasterProjectTabs = () => {
  const { user } = useSelector(({ auth }) => auth);
  const [activeTab, setActiveTab] = useState('insights');

  const tabItems = [
    {
      key: 'insights',
      label: 'Insights',
      visible: true,
      children: <Insights />,
    },
    {
      key: 'vendor-quotes',
      label: 'Vendor Quotes',
      visible: !isExternalUser(user),
      children: <Financials />,
    },
    {
      key: 'customer-po',
      label: 'Customer PO',
      visible: !isExternalUser(user),
      children: <CustomerPurchaseOrder />, //
    },
    {
      key: 'billing',
      label: 'Billing',
      visible: !isExternalUser(user),
      children: <Billing />,
    },
    {
      key: 'documents',
      label: 'All Documents',
      visible: !isExternalUser(user),
      children: <Documents />,
    },
    {
      key: 'digital-binder',
      label: "Digital Binder",
      visible: !isExternalUser(user),
      children: <DigitalBinder />,
    },
  ];

  return (
    <div className="dashboard-tabs">
        <Tabs
          animated={false}
          items={tabItems.filter(({ visible }) => visible)}
          activeKey={activeTab}
          onChange={setActiveTab}
          destroyInactiveTabPane
          className="project-detail-tabs white-bg-tabs nested-tabs"
        />
    </div>
  );
};

export default memo(MasterProjectTabs);
