import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Tooltip, Avatar, Space, Tag, Button } from 'antd';
import { DeliveredProcedureOutlined, ExpandOutlined } from '@ant-design/icons';
import { DISPLAY_DATE_FORMAT, SERVER_DATE_FORMAT, PROJECTS } from '../../utils';

export const dateViewColumns = ({
    permissions,
    handleApprovalDrawing,
    setModalType,
    setRecord,
}) => {
    const history = useHistory();

    const goToTagDetail = (record) => {
        const url = `${PROJECTS}/${record?.project}/package/${record?.packageroom?.[0].id}?tag_id=${record?.id}`;
        history.push(url);
    };

    return React.useMemo(() => [
        {
            title: 'Ref #',
            dataIndex: 'reference_number',
            width: 110,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (value, record) => <Button type="link" onClick={() => goToTagDetail(record)}>{value}</Button>,
        },
        {
            title: 'Line #',
            dataIndex: 'line_number',
            width: 140,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Tag Name',
            dataIndex: 'name',
            width: 150,
            render: (text, record) => (
                <Space size="middle">
                    <Avatar
                        src={
                            record.tag_image_url ||
                            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlBRzUn5IDV4IYZHfu5NUJ7OaY9WEMTTWOQQ&usqp=CAU'
                        }
                    />
                    <Tooltip placement="topLeft" title={text}>
                        <p className="mb-0 tagName-master-td" title={text}>
                            {text}
                        </p>
                    </Tooltip>
                </Space>
            ),
            isSearchable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 250,
            render: (text, record) => ({
                props: {
                    className: 'description-td',
                },
                children: (
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                ),
            }),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 90,
            align: 'center',
        },
        // {
        //   title: 'Approval',
        //   dataIndex: 'approval_dwg',
        //   width: 150,
        //   align: 'center',
        //   render: (status) => ({
        //     props: {
        //       className: 'approval-design-td',
        //     },
        //     children: (
        //       <Tag
        //           className="custom-tag"
        //           style={!status ? { color: '#555' } : {}}
        //           color={status ? '#1dbf73' : '#eee'}
        //       >
        //           {status ? 'Yes' : 'No'}
        //       </Tag>
        //     ),
        //   }),
        // },
        {
            title: 'Manual Schedule',
            dataIndex: 'is_manual_tracking',
            width: 150,
            align: 'center',
            render: (status) => ({
                props: {
                    className: 'approval-design-td',
                },
                children: (
                    <Tag
                        className="custom-tag"
                        style={!status ? { color: '#555' } : {}}
                        color={status ? '#1dbf73' : '#eee'}
                    >
                        {status ? 'Yes' : 'No'}
                    </Tag>
                ),
            }),
        },
        {
            title: 'Order Entry',
            dataIndex: 'order_date',
            render: (value) => {
                if (value) {
                    return moment(value, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
                }
                return '-';
            },
        },
        {
            title: 'Planned Ship',
            dataIndex: 'ship_date',
            render: (value) => {
                if (value) {
                    return moment(value, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
                }
                return '-';
            },
        },
        {
            title: 'Actual Ship',
            dataIndex: 'actual_ship_date',
            render: (value) => {
                if (value) {
                    return moment(value, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
                }
                return '-';
            },
        },
        // {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   render: (value, record) => {
        //     const filteredItem = DATE_STATUSES.filter((status) => status.value === record.status);

        //     return <p>{filteredItem.length ? filteredItem[0]?.label : 'NA'}</p>;
        //   },
        // },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => (
                <Space>
                    <Tooltip title="View">
                        <ExpandOutlined
                            onClick={(ev) => {
                                ev.stopPropagation();
                                setModalType('view');
                                setRecord(record);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Shipments">
                        <DeliveredProcedureOutlined
                            style={{ fontSize: 16, marginRight: 5 }}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                setModalType('shipment');
                                setRecord(record);
                            }}
                        />
                        {record?.ship_dates_count || 0}
                    </Tooltip>
                </Space>
            ),
        },
    ]);
};
