import _ from 'lodash';
import { Tooltip, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const roleColumns = ({
  setModalType = () => {},
  setAdminRecord = () => {},
}) => [
  {
    title: 'Role Name',
    dataIndex: 'name',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Role Name',
    dataIndex: ['owned_by', 'name'],
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Enable/Disable',
    dataIndex: '',
    render: (_, record) => (
      <Space>
        <Tooltip title="Edit">
          <EditOutlined
            onClick={() => {
              setModalType('update-role');
              setAdminRecord(record);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];
