import axios from 'axios';
import { uuidv4 } from '@firebase/util';
import { initializeApp } from 'firebase/app';
import { useSelector, useDispatch } from 'react-redux';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { updateInboxUnreadMessages, updateContactUnreadMessages, updateInboxProjectUnreadMessages } from './app/store/chat/actions';
import { isActivityActive, isInboxActive } from './app/utils';
import Routes from './routes';

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const BASE_URL = process.env.REACT_APP_BASE_URL?.replace('/api', '');

  console.log("BASE URL: ", process.env.REACT_APP_BASE_URL);

  if (user?.token && isInboxActive() && isActivityActive()) {
    // Initialize Firebase
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);

    // Get token
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((token) => {
      if (user?.token) {
        axios.post(
          `${BASE_URL}/devices/`,
          {
            registration_id: token,
            type: 'web',
            device_id: uuidv4(),
          },
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          },
        );
      }
    });

    onMessage(messaging, (payload) => {
      const user_id = payload?.data?.to_user_id;
      if (user_id === user?.id) {
        const packageMessages = JSON.parse(payload?.data?.package_messages);
        const chatMessages = JSON.parse(payload?.data?.one_on_one_msgs);
        const projectMessages = JSON.parse(payload?.data?.project_messages);
        if (packageMessages?.length) {
          dispatch(updateInboxUnreadMessages(packageMessages));
        }
        if (chatMessages?.length) {
          dispatch(updateContactUnreadMessages(chatMessages));
        }
        if (projectMessages?.length) {
          dispatch(updateInboxProjectUnreadMessages(projectMessages));
        }
      }
    });
  }

  return (
    <Routes />
  );
}

export default App;
