import { RESET_STATE } from '../common';
import { GET_AUTH_USER, UPDATE_AUTH_USER } from './types';

const initialState = {
  error: null,
  user: {},
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AUTH_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };

    case UPDATE_AUTH_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
