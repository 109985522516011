import * as ActionTypes from './types';
import { fetchDashboardDetails } from '../../services';

export const getDashboardDetails = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.FETCH_DASHBOARD_DETAILS });
    const response = await fetchDashboardDetails(companyId);
    dispatch({ type: ActionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
  } catch (e) {
    dispatch({ type: ActionTypes.FETCH_DASHBOARD_DETAILS_FAILED });
  }
};
