import GoogleMapReact from 'google-map-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Marker } from '../../../components';
import { MAP_VIEW } from '../../../store/common';
import { setActiveProject } from '../../../store/common/actions';
import { PROJECTS } from '../../../utils';

function MapListing({
  setMapShow, onProjectClickHandler, projects, centerLocation,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClick = (obj) => {
    setActiveProject(obj);
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
          libraries: ['places', 'geometry'],
        }}
        zoom={4}
        center={centerLocation}
      >
        {projects.map((project) => (
          <Marker
            key={project?.id}
            lat={project?.latitude}
            lng={project?.longitude}
            onClick={() => {
              onClick(project);
              onProjectClickHandler(project);
            }}
            text={project?.name}
            description={project?.description}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}
export default MapListing;
