import { memo, useState } from 'react';
import { Button, Spin, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { TagListView } from '../../../../../components';
import { generateKanbanAllReport, generateMasterTagReport } from '../../../../../services';

const Equipments = memo(() => {
    const { subProjectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [datesView, setDatesView] = useState(true);

    const generatekanbanReport = async () => {
        try {
            setLoading(true);
            const response = await generateKanbanAllReport(subProjectId);
            if (response?.data) {
                const link = document.createElement('a');
                link.href = response?.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false);
            } else {
                message.success('No Data Found during Review Process Kanban data extaction');
                setLoading(false);
            }
        } catch (error) {
            message.error('No Data Found during Review Process Kanban data extaction.');
            setLoading(false);
        }
    };

    const generateMasterTags = async () => {
        try {
            setLoading(true);
            const response = await generateMasterTagReport(subProjectId);
            if (response?.data) {
                const link = document.createElement('a');
                link.href = response?.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false);
            } else {
                message.success('No data to generate');
                setLoading(false);
            }
        } catch (error) {
            message.error('Technical issue');
            setLoading(false);
        }
    };

    return (
        <Spin spinning={loading}>
            <div className="tags-header">
                <div className="buttons">
                    <Button
                        style={{
                            marginRight: '1%',
                        }}
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={generateMasterTags}
                    >
                        Download Equipments
                    </Button>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={generatekanbanReport}
                    >
                        Download Comment Summary
                    </Button>
                </div>
                <div>
                    <span
                        style={{
                            color: '#7b7b7b',
                        }}
                    >
                        Progress View
                    </span>
                    <Switch
                        defaultChecked
                        style={{
                            margin: '0 10px',
                        }}
                        onChange={setDatesView}
                    />
                    <span
                        style={{
                            color: '#7b7b7b',
                        }}
                    >
                        Dates View
                    </span>
                </div>
            </div>

            <div
                className="page-content-container"
                style={{
                    padding: 0,
                    overflow: 'hidden',
                }}
            >
                <TagListView
                    key={subProjectId}
                    slineId={subProjectId}
                    datesView={datesView}
                    masterTagView
                />
            </div>
        </Spin>
    );
});

export default Equipments;
