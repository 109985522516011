import {
  ApiOutlined,
  AppstoreOutlined,
  ClusterOutlined,
  EnvironmentOutlined,
  FireFilled,
  FolderOpenOutlined,
  UnorderedListOutlined,
  InboxOutlined,
  DownloadOutlined,
  BellOutlined,
  BarsOutlined,
  MenuUnfoldOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  Empty,
  Layout,
  Menu,
  Row,
  Spin,
  Switch,
  Tabs,
  Tooltip,
  message,
  Space,
  Select,
  Badge,
  Form,
} from 'antd';
import _, { sumBy, find } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  SearchBox,
  Sidebar,
  TagListView,
  SelectWithAdditonaliList,
} from '../../../components';
import {
  fetchProjects,
  fetchSlinesByProject,
  removeProjectDocs,
  saveProjectDocs,
  generateKanbanAllReport,
  generateMasterTagReport,
  fetchProjectSearchResults,
  fetchNotificationsCount,
  fetchChatMessagesCount,
  fetchMasterAggregate,
  saveSlineEquipmentDocs,
} from '../../../services';
import {
  getAllProjects,
  MAP_VIEW,
  PROJECT_SETUP,
} from '../../../store/common';
import {
  mapViewDisplay,
  setActiveProject,
  setActiveProjectTab,
} from '../../../store/common/actions';
import { getAllPackages } from '../../../store/package';
import {
  DEFAULT_START_PAGE,
  DEFAULT_PAGE_SIZE,
  PROJECTS,
  showNotification,
  isInsightsActive,
  isActivityActive,
  isInboxActive,
  isBirdseyeActive,
  isTasksActive,
  isPackagesActive,
  isFilesActive,
  PROJECT_PERMISSIONS,
  isManualScheduleActive,
} from '../../../utils';
import * as Permissions from '../../../utils/permissions';
import MapListing from './map-listing';
import Packages from './packages';
import ProjectDocsAccordian from './project-docs-accordian';
import ProjectsAccordianList from './projects-accordian';
import SlinesList from './slines';
import TaskInbox from './task-inbox';
import {
  getProjectDocuments,
} from '../../../store/documents';
import NewDocuments from './new-documents';
import CollaborationInbox from './inbox';
import Tasks from './tasks';
import MasterProjects from '../../master-projects/details';
import ManualSchedule from '../../sub-projects/details/tabs/manual-schedule';
import Insights from '../../sub-projects/details/tabs/insights';

const { TabPane } = Tabs;
const { Content } = Layout;

function ProjectListing({ history }) {
  const [searchForm] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const { packages } = useSelector(({ chat }) => chat);
  const { permissions } = useSelector(({ permission }) => permission);
  const [active, setActive] = useState(true);
  const {
    activeProject, activeProjectTab, mapView,
  } = useSelector(
    ({ common }) => common,
  );
  const { tabId, projectId } = useParams();
  const [center, setCenter] = useState([]);
  const [slines, setSlines] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsMeta, setProjectsMeta] = useState({ total_count: 0 });
  const [datesView, setDatesView] = useState(true);
  const [slineLoader, setSlineLoader] = useState(false);
  const [docsLoader, setDocsLoader] = useState(false);
  const [projectLoader, setProjectLoader] = useState(false);
  const [createPackageVisible, setCreatePackageVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredSlines, setFilteredSlines] = useState([]);
  const [projectSearchText, setProjectSearchText] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [hideSidebar, setHideSidebar] = useState(false);
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const [uploadMode, setUploadMode] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [tempProject, setTempProject] = useState({});
  const [masterAggregate, setMasterAggregate] = useState({});
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [slinesMeta, setSlinesMeta] = useState(null);
  const [inboxMsgCount, setInboxMsgCount] = useState(0);
  const [page, setPage] = useState(DEFAULT_START_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  useEffect(async () => {
    if (tabId === 'aggregate') {
      const response = await fetchMasterAggregate(projectId);
      setMasterAggregate(response?.data);
      dispatch(mapViewDisplay(false));
    }
  }, [tabId, projectId]);

  const getProjects = async () => {
    try {
      setProjectLoader(true);
      const response = await fetchProjects({ per_page: 20, page: 1, active });
      if (response) {
        // Get master project
        // dispatch(setActiveProject(activeProject || response?.data[0]));
        // Default Sorting
        const payload = _.orderBy(
          response?.data,
          [
            function (item) {
              return item.is_draft;
            },
            function (item) {
              return item.is_finished;
            },
            function (item) {
              return item.unread_activity_count;
            },
            function (item) {
              return item.name;
            },
          ],
          ['desc', 'asc', 'desc', 'asc'],
        );
        onProjectClickHandler(
          activeProject?.id || payload[0]?.projects[0]?.id,
          activeProject?.name || payload[0]?.projects[0]?.name,
          activeProject?.masterProject || payload[0],
          activeProject || payload[0]?.projects[0],
        );
        setProjectsMeta(response?.meta);
        setProjects([...payload]);
        setProjectLoader(false);
      }
    } catch (ex) {
      setProjectLoader(false);
    }
  };

  const onProjectListClickHandler = (project, master) => {
    setCenter([project.latitude || 37.0902, project.longitude || -95.7129]);
    dispatch(setActiveProject({ masterProject: master, ...project }));
  };

  useEffect(() => {
    getProjects();
    setCenter([37.0902, -95.7129]);
  }, [active]);

  useEffect(() => {
    if (activeProject && activeProjectTab === 'birds-eye' && !uploadMode) {
      getSinglelines(activeProject?.id);
    }
  }, [activeProject, activeProjectTab, uploadMode, page, pageSize]);

  const getSinglelines = async (projectId) => {
    const params = {
      page,
      page_page: pageSize,
    };
    setSlineLoader(true);
    const response = await fetchSlinesByProject(projectId, params);
    setSlines([...response?.data]);
    setSlinesMeta(response?.meta);
    setSlineLoader(false);
  };

  const onProjectClickHandler = async (
    projectId,
    projectName,
    masterProject,
    project,
    fromMap = false,
  ) => {
    setSelectedProject(projectId);
    if (projectId) {
      try {
        setDocumentsToUpload([]);
        setUploadMode(false);
        setTempProject({ id: projectId, name: projectName });
        dispatch(
          setActiveProject({
            ...project,
            id: projectId,
            name: projectName,
            masterProject,
            permissions: project?.permissions,
          }),
        );
        if (fromMap) {
          dispatch(setActiveProjectTab('aggregate'));
          history.push(`${PROJECTS}/${projectId}/aggregate`);
        } else {
          const newTab = activeProjectTab === 'aggregate' ? 'insights' : activeProjectTab;
          dispatch(setActiveProjectTab(newTab));
          history.push(`${PROJECTS}/${projectId}/${newTab}`);
        }

        Promise.all([
          // fetchProjectDocuments(projectId),
          dispatch(getAllPackages(projectId)),
          // getSinglelines(projectId),
        ]);
      } catch (ex) { }
    }
  };

  const searchByTags = (value) => {
    setSlineLoader(true);
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const filtered = slines.filter((sline) => {
          const tagFound = sline.tags.find((tag) => tag.name.toLowerCase().includes(value.toLowerCase()));
          if (tagFound) {
            return sline;
          }
        });
        resolve(filtered);
      }, 1000);
    }).then((filtered) => {
      setFilteredSlines([...filtered]);
      setSlineLoader(false);
    });
  };

  const searchProject = (value) => {
    setProjectLoader(true);

    // Local search for projects
    new Promise((resolve, reject) => {
      // Search for both master and sub projects
      const filtered = projects.filter((project) => {
        if (project.name.toLowerCase().includes(value.toLowerCase())) {
          return project;
        }
        // Search in sub projects also
        const subProjectNames = _.map(project.projects, (p) => p?.name);
        const filteredSubProjects = _.filter(project.projects, (p) => _.includes(p?.name.toLowerCase(), value.toLowerCase()));
        if (filteredSubProjects.length) {
          return project;
        }
      });
      resolve(filtered);
    }).then((filtered) => {
      setFilteredProjects([...filtered]);
      setProjectLoader(false);
    });
  };

  const fetchProjectDocuments = async (projectId) => {
    dispatch(getProjectDocuments(projectId));
  };

  const uploadProjectDocs = async (fileList, key) => {
    try {
      setDocsLoader(true);
      const formData = new FormData();
      fileList.map((file) => formData.append('files', file));
      formData.append('dir_key', key);
      formData.append('id', selectedProject);
      let res;
      if (key === 'single_lines' || key === 'site_plan') {
        res = await saveSlineEquipmentDocs(projectId, formData);
      } else {
        res = await saveProjectDocs(projectId, formData);
      }

      await fetchProjectDocuments(selectedProject);
      setDocsLoader(false);
    } catch (ex) {
      setDocsLoader(false);
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      setDocsLoader(true);
      const response = await removeProjectDocs(selectedProject, { id: documentId });
      showNotification(response.message);
      fetchProjectDocuments(selectedProject);
      setDocsLoader(false);
    } catch (error) {
      setDocsLoader(false);
      console.log(error);
    }
  };

  const onModeChange = (checked) => {
    dispatch(setActiveProject(null));
    dispatch(setActiveProjectTab(activeProjectTab));
  };

  const uploadDocumentsNew = (ev) => {
    setDocumentsToUpload([...documentsToUpload, ...ev.fileList]);
    // setUploadMode(true);
  };

  const toggleUploadMode = () => {
    setActiveProjectTab('upload-documents');
    history.replace(`${PROJECTS}/${activeProject?.id}/upload-documents`);
    setUploadMode(!uploadMode);
  };

  const generatekanbanReport = async () => {
    try {
      if (activeProject.id) {
        setMainLoader(true);
        const response = await generateKanbanAllReport(activeProject.id);

        if (response?.data) {
          const link = document.createElement('a');
          link.href = response?.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setMainLoader(false);
        } else {
          message.success('No Data Found during Review Process Kanban data extaction');
          setMainLoader(false);
        }
      } else {
        message.success('No data to generate');
      }
    } catch (error) {
      message.error('No Data Found during Review Process Kanban data extaction.');
      setMainLoader(false);
    }
  };

  const [notificationsCount, setNotificationsCount] = useState(0);
  const [msgCount, setMsgCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setNotificationsCount(notificationsCount);
  }, [notificationsCount]);

  const getNotificationsCount = async () => {
    try {
      if (activeProject?.id) {
        const response = await fetchNotificationsCount(activeProject.id);
        setNotificationsCount(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getChatMessageCount = async () => {
    try {
      if (activeProject?.id) {
        const response = await fetchChatMessagesCount(activeProject.id);
        setMsgCount(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeProject) {
      getNotificationsCount();
      getChatMessageCount();
    }
  }, [activeProject?.id]);

  useEffect(() => {
    if (activeProject) {
      const packageData = find(packages, ['id', activeProject?.id]);
      setInboxMsgCount(sumBy(packageData?.packages, 'unread_messages_count'));
    }
  }, [packages]);

  const generateMasterTags = async () => {
    try {
      if (activeProject.id) {
        setMainLoader(true);
        const response = await generateMasterTagReport(activeProject.id);
        if (response?.data) {
          const link = document.createElement('a');
          link.href = response?.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setMainLoader(false);
        } else {
          message.success('No data to generate');
          setMainLoader(false);
        }
      } else {
        message.success('No data to generate');
      }
    } catch (error) {
      message.error('Technical issue');
      setMainLoader(false);
    }
  };

  /**
     * Handle Project level Search
     */

  useEffect(() => {
    if (searchQuery) {
      getProjectSearchResults(searchQuery);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const getProjectSearchResults = async (query) => {
    setSearchLoading(true);
    const res = await fetchProjectSearchResults({
      projectI: activeProject?.id,
      body: {
        search_str: query,
      },
    });

    if (res.success) {
      setSearchLoading(false);
      setSearchResults(res.data);
    } else {
      setSearchResults([]);
      setSearchLoading(false);
    }
  };

  const handleProjectSearch = (value) => {
    if (value) {
      setSearchQuery(value);
    } else {
      setSearchQuery(null);
    }
  };

  const handleProjectChange = (id) => {
    history.push(`/projects/${activeProject?.id}/package/${id}`);
  };

  const onResultClick = (id) => {
    history.push(`/projects/${activeProject?.id}/package/${id}`);
  };

  const clearProjectSearch = () => {
    setSearchQuery(null);
    setSearchResults([]);
  };

  const setPageInfo = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  return (
    <Spin
      spinning={mainLoader}
      style={{ minHeight: 200 }}
      tip={mainLoader ? 'Please wait, it will take a while' : ''}
    >
      <Content
        className={
          hideSidebar ? 'position-relative mb-0 mt-0 ml-20' : 'content-width-sidebar'
        }
      >
        {hideSidebar && (
          <Tooltip title="Open Projects" placement="right">
            <div
              className="sidebar-toggler open"
              onClick={() => setHideSidebar(!hideSidebar)}
            >
              <MenuUnfoldOutlined />
            </div>
          </Tooltip>
        )}

        <Row gutter={30}>
          {
            Permissions.can_view_projects_sidebar(user?.platform_permissions)
              ? (
                <><Col span={hideSidebar ? 0 : 5}>
                  <Sidebar
                    addNewBtn={Permissions.can_add_project(user?.platform_permissions)}
                    customChildren={(
                      <>
                        <div className="accordian-section-title d-flex">
                          <div>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseCircleOutlined />}
                              onChange={(e) => setActive(e)}
                              defaultChecked />
                          </div>
                          <div className='ml-10'>
                            {active
                              ? `Active Projects (${projectsMeta?.total_count})`
                              : `Completed Projects (${projectsMeta?.total_count})`}
                          </div>
                        </div>
                        <div className="switch-body">
                          <Tooltip title="Map View">
                            <div
                              className={`map-toggle-btn ${mapView ? 'active-toggle' : ''}`}
                              onClick={() => {
                                dispatch(mapViewDisplay(true));
                                history.push(`${PROJECTS}`);
                              }}
                            >
                              <EnvironmentOutlined
                                style={{ fontSize: '1rem', marginRight: '4px' }} />
                            </div>
                          </Tooltip>
                          <Tooltip title="Details View">
                            <div
                              className={`map-toggle-btn ${!mapView ? 'active-toggle' : ''}`}
                              onClick={() => {
                                dispatch(mapViewDisplay(false));
                                history.replace(`${PROJECTS}`);
                              }}
                            >
                              <UnorderedListOutlined
                                style={{ fontSize: '1rem', marginRight: '4px' }} />
                            </div>
                          </Tooltip>
                        </div>
                        <SearchBox
                          className='mb-20 mt-10'
                          placeholder="Search Projects ..."
                          value={projectSearchText}
                          onChange={(ev) => {
                            setProjectSearchText(ev.target.value);
                            searchProject(ev.target.value);
                          }} />
                      </>
                    )}
                    heading={() => 'Construction Projects'}
                    loader={projectLoader}
                    addNewText="New Master Project"
                    addNewOnClick={() => {
                      dispatch({ type: PROJECT_SETUP, payload: true });
                      history.push(`${PROJECTS}/create-master`);
                    }}
                    toggleSidebar={() => setHideSidebar(!hideSidebar)}
                  >
                    {Permissions.can_view_projects_sidebar(user?.platform_permissions)
                      && (
                        <ProjectsAccordianList
                          activeProject={activeProject}
                          filtered={!projectLoader && projectSearchText}
                          onProjectDBClickHandler={(projectId, name) => {
                            dispatch({
                              type: MAP_VIEW,
                            });
                            dispatch(setActiveProjectTab('map'));
                            onProjectClickHandler(projectId, name);
                          }}
                          onProjectClickHandler={(project, master) => {
                            dispatch({
                              type: MAP_VIEW,
                              payload: false,
                            });
                            dispatch(setActiveProjectTab(activeProjectTab));
                            onProjectClickHandler(project.id, project.name, master, project);
                            onProjectListClickHandler(project, master);
                          }}
                          isCompleted={false}
                          list={projectSearchText ? filteredProjects : projects}
                          listMeta={{}}
                          active={active}
                          setActive={setActive}
                          totalCount={projectsMeta?.total_count} />
                      )}
                  </Sidebar>
                </Col><Col span={hideSidebar ? 24 : 19} className="fixed-right">
                    {mapView ? (
                      <MapListing
                        onProjectClickHandler={(project) => {
                          onProjectClickHandler(
                            project?.id,
                            project?.name,
                            project,
                            project,
                            true,
                          );
                        }}
                        projects={projectSearchText ? filteredProjects : projects}
                        centerLocation={center}
                        activeProject={activeProject} />
                    ) : tabId === 'aggregate' ? (
                      <MasterProjects />
                    ) : (
                      <>
                        <div className="mt-20" />
                        <div className="breadcrumb-with-heading">
                          <Space>
                            <Breadcrumb separator=">">
                              <Breadcrumb.Item
                                onClick={() => {
                                  const project = activeProject?.masterProject;
                                  dispatch(
                                    setActiveProject({
                                      ...project,
                                      masterProject: project,
                                    }),
                                  );
                                  dispatch(setActiveProjectTab('aggregate'));
                                  history.push(
                                    `${PROJECTS}/${project?.id}/aggregate`,
                                  );
                                }}
                              >
                                <span className="cursor-pointer">
                                  {activeProject?.masterProject?.name}
                                </span>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>
                                {activeProject?.name || 'Projects'}
                              </Breadcrumb.Item>
                            </Breadcrumb>
                          </Space>
                          <div style={{ width: 500 }}>
                            <Form layout="vertical" form={searchForm}>
                              <SelectWithAdditonaliList
                                noLabels
                                form={searchForm}
                                formItemClass="mb-0"
                                projectId={activeProject?.id}
                                setValue={(pkg) => onResultClick(pkg.id)}
                                enablePackages={isPackagesActive()} />
                            </Form>
                          </div>
                          <div>
                            {/* {Permissions.can_add_project_document(permissions) && !activeProject.is_finished && (
                                    // <Upload
                                    //     beforeUpload={() => false}
                                    //     onChange={_.debounce(uploadDocumentsNew, 100)}
                                    //     showUploadList={false}
                                    //     multiple
                                    // >
                                    <Button
                                        type={uploadMode ? 'primary' : 'default'}
                                        className="main-upload-btn"
                                        onClick={toggleUploadMode}
                                    >
                                        <FolderOpenOutlined /> Upload Documents
                                    </Button>
                                    // </Upload>
                                )} */}
                          </div>
                        </div>

                        <Spin spinning={projectLoader}>
                          <div
                            className={projects.length > 0 ? '' : 'no-projects-wrapper'}
                          >
                            {projects.length > 0 ? (
                              <div>
                                <Tabs
                                  defaultActiveKey={activeProjectTab}
                                  key={activeProject?.id}
                                  activeKey={uploadMode ? '' : activeProjectTab}
                                  onChange={(tabId) => {
                                    dispatch(setActiveProjectTab(tabId));
                                    history.replace(
                                      `${PROJECTS}/${activeProject?.id}/${tabId}`,
                                    );
                                    setUploadMode(false);
                                  }}
                                  className={`project-detail-tabs white-bg-tabs nested-tabs ${activeProject?.name} ${uploadMode
                                    ? 'upload-doc-open'
                                    : 'upload-doc-hidden'}`}
                                  animated={false}
                                  tabBarExtraContent={{
                                    right: (
                                      <>
                                        {/* <Upload
                                                                beforeUpload={() => false}
                                                                onChange={_.debounce(
                                                                    uploadDocumentsNew,
                                                                    100,
                                                                )}
                                                                showUploadList={false}
                                                                multiple
                                                            >
                                                                <Button
                                                                    className="btn-tab"
                                                                    type="primary"
                                                                >
                                                                    <UploadOutlined /> Upload Documents
                                                                </Button>
                                                            </Upload> */}
                                        {activeProjectTab === 'tags' ? (
                                          <>
                                            {/* <div className="sline-search white-bg-search">
                                                                            <SearchBox
                                                                                placeholder="Search by Tags "
                                                                                onChange={(ev) => {
                                                                                    setSearchText(
                                                                                        ev.target.value,
                                                                                    );
                                                                                    searchByTags(
                                                                                        ev.target.value,
                                                                                    );
                                                                                }}
                                                                                value={searchText}
                                                                            />
                                                                        </div> */}
                                          </>
                                        ) : activeProjectTab
                                          === 'activity' ? (
                                          <></>
                                        ) : null}
                                      </>
                                    ),
                                  }}
                                >
                                  {isInsightsActive() && Permissions.hasProjectPermission(activeProject?.permissions, PROJECT_PERMISSIONS.CAN_VIEW_INSIGHTS)
                                    && (
                                    <TabPane
                                      tab={(
                                        <span>
                                          <FireFilled
                                            style={{ fontSize: 20 }} />
                                          Insights
                                        </span>
                                      )}
                                      key="insights"
                                      className="custom-tab"
                                    >
                                      <Insights _projectId={activeProject?.id} />
                                    </TabPane>
                                  )}
                                  {isBirdseyeActive() && Permissions.hasProjectPermission(activeProject?.permissions, PROJECT_PERMISSIONS.CAN_VIEW_BIRDS_EYE_VIEW)
                                    && (
                                      <TabPane
                                        tab={(
                                          <span>
                                            <ClusterOutlined
                                              style={{ fontSize: 20 }} />
                                            Bird's Eye View
                                          </span>
                                        )}
                                        key="birds-eye"
                                      >
                                        {!uploadMode
                                          && (
                                            <Spin spinning={slineLoader}>
                                              <SlinesList
                                                filtered={!slineLoader
                                                  && searchText}
                                                singlelines={searchText
                                                  ? filteredSlines
                                                  : slines}
                                                activeProject={activeProject}
                                                setPageInfo={setPageInfo}
                                                page={page}
                                                slinesMeta={slinesMeta} />
                                            </Spin>
                                          )}
                                      </TabPane>
                                    )}
                                  <TabPane
                                    tab={(
                                      <span>
                                        <ApiOutlined
                                          style={{ fontSize: 20 }} />
                                        {' '}
                                        Equipments ({activeProject?.active_tag_count || 0})
                                      </span>
                                    )}
                                    key="tags"
                                  >
                                    {activeProjectTab === 'tags' ? (
                                      <div className="tags-header">
                                        <div className="buttons">
                                          <Button
                                            style={{
                                              marginRight: '1%',
                                            }}
                                            type="primary"
                                            icon={<DownloadOutlined />}
                                            onClick={generateMasterTags}
                                          >
                                            Download Equipments
                                          </Button>
                                          {/* <Button
                                            type="primary"
                                            icon={<DownloadOutlined />}
                                            onClick={generatekanbanReport}
                                          >
                                            Download Comment Summary
                                          </Button> */}
                                        </div>
                                        <div>
                                          <span
                                            style={{
                                              color: '#7b7b7b',
                                            }}
                                          >
                                            Progress View
                                          </span>
                                          <Switch
                                            defaultChecked
                                            style={{
                                              margin: '0 10px',
                                            }}
                                            onChange={setDatesView} />
                                          <span
                                            style={{
                                              color: '#7b7b7b',
                                            }}
                                          >
                                            Dates View
                                          </span>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div
                                      className="page-content-container"
                                      style={{
                                        padding: 0,
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {activeProject && (
                                        <TagListView
                                          key={activeProject?.id}
                                          slineId={activeProject?.id}
                                          datesView={datesView}
                                          masterTagView />
                                      )}
                                    </div>
                                  </TabPane>
                                  {isManualScheduleActive() && Permissions.hasProjectPermission(activeProject?.permissions, PROJECT_PERMISSIONS.CAN_USE_MANUAL_SCHEDULE) && (
                                    <TabPane
                                      tab={(
                                        <span>
                                          <ApiOutlined
                                            style={{ fontSize: 20 }} />
                                          {' '}
                                          Manual Schedule ({activeProject?.active_manual_tag_count || 0})
                                        </span>
                                      )}
                                      key="manual-schedule"
                                    >
                                      {activeProject && (
                                        <ManualSchedule projectId={activeProject?.id} />
                                      )}
                                    </TabPane>
                                  )}
                                  {isPackagesActive() && Permissions.hasProjectPermission(activeProject?.permissions, PROJECT_PERMISSIONS.CAN_VIEW_PACKAGES)
                                    && (
                                      <TabPane
                                        tab={(
                                          <span>
                                            <AppstoreOutlined
                                              style={{ fontSize: 20 }} />
                                            {' '}
                                            Packages
                                          </span>
                                        )}
                                        key="packages"
                                      >
                                        {activeProject?.id
                                          && activeProjectTab === 'packages' && (
                                            <Packages
                                              key={activeProject?.id}
                                              setCreatePackageVisible={setCreatePackageVisible}
                                              createPackageVisible={createPackageVisible}
                                              projectId={activeProject?.id} />
                                          )}
                                      </TabPane>
                                    )}
                                  {isFilesActive() && Permissions.hasProjectPermission(activeProject?.permissions, PROJECT_PERMISSIONS.CAN_VIEW_FILES)
                                    && (
                                      <TabPane
                                        tab={(
                                          <span>
                                            <FolderOpenOutlined
                                              style={{ fontSize: 20 }} />
                                            {' '}
                                            Documents
                                          </span>
                                        )}
                                        key="files"
                                        className="files-tab"
                                      >
                                        {activeProjectTab === 'files' && (
                                          <ProjectDocsAccordian
                                            hideUploadView={() => setUploadMode(false)}
                                            isFiles
                                            isLoading={docsLoader}
                                            uploadDocs={uploadProjectDocs}
                                            deleteDocument={deleteDocument}
                                            projectId={activeProject?.id}
                                            uploadMode={uploadMode}
                                            documentsToUpload={documentsToUpload}
                                            setDocumentsToUpload={setDocumentsToUpload}
                                            onUploadFiles={uploadDocumentsNew} />
                                        )}
                                      </TabPane>
                                    )}
                                  {isInboxActive() && Permissions.hasProjectPermission(activeProject?.permissions, PROJECT_PERMISSIONS.CAN_USE_INBOX_PACKAGE_CHAT)
                                    && (
                                      <TabPane
                                        tab={(
                                          <span>
                                            <InboxOutlined
                                              style={{ fontSize: 20 }} />
                                            <Badge
                                              className="site-badge-count-109"
                                              count={inboxMsgCount}
                                              overflowCount={9}
                                              offset={[15, 0]}
                                              color="#ff6f71"
                                            >
                                              Inbox
                                            </Badge>
                                          </span>
                                        )}
                                        key={`inbox`}
                                      >
                                        <CollaborationInbox
                                          key={activeProject?.id}
                                          projectId={activeProject?.id} />
                                      </TabPane>
                                    )}
                                  {isActivityActive() && Permissions.hasProjectPermission(activeProject?.permissions, PROJECT_PERMISSIONS.CAN_VIEW_ACTIVITY)
                                    && (
                                      <TabPane
                                        tab={(
                                          <span>
                                            <BellOutlined
                                              style={{ fontSize: 20 }} />
                                            <Badge
                                              offset={[15, 0]}
                                              count={notificationsCount}
                                              overflowCount={9}
                                              color="#faad14"
                                            >
                                              Activity
                                            </Badge>
                                          </span>
                                        )}
                                        key="activity"
                                      >
                                        <TaskInbox
                                          notifCount={notificationsCount}
                                          setNotifCount={setNotificationsCount}
                                          projectId={activeProject?.id}
                                          getCount={getNotificationsCount}
                                          getAllProjects={getProjects}
                                          key={activeProject?.id} />
                                      </TabPane>
                                    )}
                                  {/* {isTasksActive() // && Permissions.hasProjectPermission(activeProject?.permissions, PROJECT_PERMISSIONS.CAN_VIEW_TASKS)
                                    && (
                                      <TabPane
                                        tab={(
                                          <span>
                                            <BarsOutlined
                                              style={{ fontSize: 20 }} />
                                            Tasks
                                          </span>
                                        )}
                                        key="tasks"
                                      >
                                        <Tasks
                                          key={activeProject?.id}
                                          projectId={activeProject?.id} />
                                      </TabPane>
                                    )} */}
                                </Tabs>
                                {activeProjectTab !== 'upload-documents' ? (
                                  <>
                                    {uploadMode && (
                                      <NewDocuments
                                        projectId={activeProject?.id}
                                        documentsToUpload={documentsToUpload}
                                        setDocumentsToUpload={setDocumentsToUpload}
                                        onUploadFiles={uploadDocumentsNew} />
                                    )}
                                  </>
                                ) : null}
                              </div>
                            ) : !projectLoader && projects.length === 0 ? (
                              <Empty
                                style={{ fontSize: 16, color: '#777' }}
                                description="No projects found, please create"
                                className="no-projects-empty"
                                imageStyle={{ height: 230 }}
                              >
                                {/* {feature_create_project(user.features) && (
                                                <Button
                                                    className="btn-lg btn-primary-lg save-btn"
                                                    type="primary"
                                                    onClick={() =>
                                                        history.push(`${PROJECTS}/create`)
                                                    }
                                                >
                                                    Create New
                                                </Button>
                                            )} */}
                              </Empty>
                            ) : !projectLoader && _.isEmpty(permissions) ? (
                              <Empty
                                style={{ fontSize: 16, color: '#777' }}
                                description="You don't have permissions for this project"
                                className="no-projects-empty"
                                imageStyle={{ height: 230 }} />
                            ) : null}
                          </div>
                        </Spin>
                      </>
                    )}
                  </Col>
                </>
              )
              : (
                <div className='no-permissions'>
                  <StopOutlined style={{ fontSize: '56px', marginBottom: 20 }} />
                  <h1 className='justify-center'>
                    You dont have Permissions to View Projects
                  </h1>
                </div>
)
          }
        </Row>
      </Content>
    </Spin>
  );
}

export default memo(ProjectListing);
