import { Tabs } from 'antd';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import ContractorDcouments from './Documents';
import VendorQoutes from './VendorQuotes';
import VendorDocuments from './VendorDocuments';

const ContractorsDetailTabs = (props) => {
    const history = useHistory();
    const activeTab = new URLSearchParams(props.location.search).get('page') || 'vendor-quotes';

    const tabItems = [
        {
            key: 'vendor-quotes',
            label: 'Vendor Quotes',
            children: <VendorQoutes />,
        },
        {
            key: 'contractor-documents',
            label: 'Contractor Specific Documents',
            children: <ContractorDcouments />,
        },
        {
            key: 'vendor-documents',
            label: 'Vendor Documents',
            children: <VendorDocuments />,
        },
    ];

    return (
        <div className="dashboard-tabs">
            <Tabs
                items={tabItems}
                animated={false}
                activeKey={activeTab}
                destroyInactiveTabPane
                className="project-detail-tabs white-bg-tabs nested-tabs"
                onChange={(tabId) => history.push({ search: `page=${tabId}` })}
            />
        </div>
    );
};

export default memo(ContractorsDetailTabs);
