const { $ } = window;

export function activateMaps(container) {
  const zoomMap = $(container).ZoomArea({
    zoomLevel: 1,
    minZoomLevel: 1,
    maxZoomLevel: 15,
    parentOverflow: 'auto',
    exceptionsZoom: ['marker-all'],
    hideWhileAnimate: ['map-area', 'marker-all'],
    externalIncrease: '.map-control-zoomin',
    externalDecrease: '.map-control-zoomout',
    virtualScrollbars: false,
    usedAnimateMethod: 'jquery',
  });

  resizeFilterPanel();
}

export function resizeFilterPanel() {
  $('.map-filter').removeAttr('style');
  if (parseInt($('.measurement').css('max-width'), 10) > 768) {
    $('.map-filter').css('height', `${$('.maps-container').outerHeight()}px`);
  }
}
