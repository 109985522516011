import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

export function PredictGantt({ height = 600, seriesData, optionsData }) {
  const [series, setSeries] = useState([]);
  useEffect(() => {
    const modifyData = [];
    for (const row of seriesData) {
      for (const idx in row.data) {
        if (Object.hasOwn(row.data, idx)) {
          const start_date = new Date(row.data[idx].y[0]).getTime();
          const end_date = new Date(row.data[idx].y[1]).getTime();
          row.data[idx].y[0] = start_date;
          row.data[idx].y[1] = end_date;
        }
      }
      modifyData.push(row);
    }
    setSeries([...modifyData]);
  }, [seriesData]);

  return (
    <div id="chart">
      <ReactApexChart options={optionsData} series={series} type="rangeBar" height={height} />
    </div>
  );
}
