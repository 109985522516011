import _ from 'lodash';
import {
  Avatar, Select, Space, Tag, Tooltip,
} from 'antd';
import { makeAvatar } from '../../utils';
import './styles.scss';

const { Option } = Select;

export function UsersIcon({
  heading = '',
  teamMembers = [],
  isReleased = false,
  tags = [],
  activeTag = {},
  releaseMessage = 'Review process is over',
  onTagChange = () => {},
  showNoApproval = false,
}) {
  return (
    <div className="approval-process mb-10 align-center">
      <Space>
        {!_.isEmpty(tags) ? (
          <Select style={{ width: 200 }} value={activeTag?.id} onChange={onTagChange}>
            {tags.map((tag) => (
              <Option key={tag.id} value={tag.id}>
                {tag.name}
              </Option>
            ))}
          </Select>
        ) : (
          <h1 className="mb-0">{heading}</h1>
        )}

        {isReleased && <Tag color="red">{releaseMessage}</Tag>}
        {showNoApproval && <Tag color="red">No Approval Required</Tag>}
      </Space>
      <div className="icon">
        <Avatar.Group>
          {teamMembers
                        && teamMembers.map((member) => (
                          <Tooltip
                            title={`${member.first_name} ${member.last_name}`}
                            key={member.id}
                          >
                            <Avatar
                              className="circle-icon"
                              src={
                                        member.image
                                        || makeAvatar(member.first_name[0], member.last_name[0])
                                    }
                            />
                          </Tooltip>
                        ))}
        </Avatar.Group>
      </div>
    </div>
  );
}
