import React from 'react';
import moment from 'moment';
import { Avatar, Tooltip } from 'antd';
import { DISPLAY_DATE_FORMAT, makeAvatar } from '../../../utils';

export default () => React.useMemo(() => [
  {
    key: '1',
    title: 'Name',
    dataIndex: 'name',
  },
  {
    key: '2',
    title: 'Description',
    dataIndex: 'description',
  },
  {
    key: '3',
    title: 'Created at',
    dataIndex: 'created_at',
    render: (value) => (value ? moment(value).format(DISPLAY_DATE_FORMAT) : ''),
  },
  {
    key: '4',
    title: 'Created by',
    dataIndex: 'created_by',
    render: (user) => (
      <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
        <Avatar src={makeAvatar(user?.first_name?.[0], user?.last_name?.[0])} />
      </Tooltip>
    ),
  },
]);
