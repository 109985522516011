import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BIDDING_MANAGEMENT, CONTRACTORS } from '../../utils';
import BidManagementLayout from './Layout';
import BidProjectDetails from './MainProjects/MainProjectDetails/BidProjectDetails';
import ContractorDetails from './Contractors/ContractorDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveBiddingProject } from '../../store/bidding-management';

export default () => {
    const bidding = useSelector(({ bidding }) => bidding);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!_.isEmpty(bidding.activeProject)) {
            history.push(`${BIDDING_MANAGEMENT}/${bidding.activeProject.id}`);
        } else if (!_.isEmpty(bidding.projects)) {
            dispatch(setActiveBiddingProject(bidding.projects[0]));
            history.push(`${BIDDING_MANAGEMENT}/${bidding.projects[0].id}`);
        }
    }, [bidding.projects, bidding.project]);

    return (
        <BidManagementLayout>
            <Switch>
                <Route
                    exact
                    path={`${BIDDING_MANAGEMENT}/:projectId`}
                    component={BidProjectDetails}
                />
                <Route
                    exact
                    path={`${BIDDING_MANAGEMENT}/:projectId${CONTRACTORS}/:contractorId`}
                    component={ContractorDetails}
                />
            </Switch>
        </BidManagementLayout>
    );
};
