import { memo } from 'react';
import { Collapse, Empty, Space, Tooltip } from 'antd';
import { ContainerOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import * as Permissions from '../../utils/permissions';
import { SidebarListItem } from '../../components';
import {
    isActivityActive,
    isInboxActive,
    PROJECTS,
    PROJECT_PERMISSIONS,
    PROJECT_TYPES,
} from '../../utils';
import { PROJECT_SETUP, setActiveProject, setActiveProjectTab } from '../../store/common';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { Panel } = Collapse;

const ProjectListItems = memo((props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useSelector(({ auth }) => auth);
    const { packages } = useSelector(({ chat }) => chat);
    const { activeProject } = useSelector(({ common }) => common);

    const onEditHandler = (ev, project, masterProject) => {
        ev.stopPropagation();
        dispatch(
            setActiveProject({
                ...project,
                masterProject,
                type: PROJECT_TYPES.SUB_PROJECT,
            }),
        );
        dispatch({ type: PROJECT_SETUP, payload: false });
        history.push(`${PROJECTS}/${masterProject?.id}/${project?.id}/edit?activeTab=1`);
    };

    const genExtra = (project) => {
        return (
            <Space>
                {Permissions.can_add_project(user?.platform_permissions) && (
                    <>
                        <Tooltip title="Edit Master" placement="top">
                            <EditOutlined
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    history.push(`${PROJECTS}/master/${project?.id}`);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Add Subproject" placement="top">
                            <PlusOutlined
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    dispatch({ type: PROJECT_SETUP, payload: true });
                                    history.push(`${PROJECTS}/${project?.id}/create`);
                                }}
                            />
                        </Tooltip>
                    </>
                )}
            </Space>
        );
    };

    const panelHeader = (project) => {
        const isInboxAllowed = Permissions.hasProjectPermission(
            project?.permissions,
            PROJECT_PERMISSIONS.CAN_USE_INBOX_PACKAGE_CHAT,
        );
        const isActivityAllowed = Permissions.hasProjectPermission(
            project?.permissions,
            PROJECT_PERMISSIONS.CAN_USE_INBOX_PACKAGE_CHAT,
        );
        // display badge only if atleast one project has permission
        return (
            <>
                <div className="master-project-details">
                    <Space>
                        {isInboxActive() && isInboxAllowed && project.unread_chat_count ? (
                            <span className="counter inbox" />
                        ) : null}
                        {isActivityActive() &&
                        isActivityAllowed &&
                        project.unread_activity_count ? (
                            <span className="counter activity" />
                        ) : null}
                    </Space>
                </div>
                <Tooltip title={project?.name}>
                    <span>{project?.name}</span>
                </Tooltip>
            </>
        );
    };

    return (
            <Collapse
                accordion
                collapsible="icon"
                className="project-listing-collapse"
                defaultActiveKey={[activeProject?.masterProject?.id]}
            >
                {props.projects.map((masterProject) => {
                    return (
                        <Panel
                            header={panelHeader(masterProject)}
                            className={`panel-heading ${
                                activeProject?.masterProject?.id === masterProject.id
                                    ? 'master-active'
                                    : ''
                            }`}
                            key={masterProject.id}
                            extra={genExtra(masterProject)}
                            onClick={() => {
                                dispatch(
                                    setActiveProject({
                                        ...masterProject,
                                        masterProject,
                                        type: PROJECT_TYPES.MASTER_PROJECT,
                                    }),
                                );
                                history.push(`${PROJECTS}/${masterProject?.id}`);
                            }}
                        >
                            <ul>
                            {masterProject?.projects?.length ? (
                                masterProject?.projects.map((project) => {
                                    return (
                                        <SidebarListItem
                                            key={project?.id}
                                            project={project}
                                            id={project?.id}
                                            title={project?.name}
                                            desc={project?.description}
                                            icon={ContainerOutlined}
                                            notifCount={project?.unread_activity_count}
                                            activityCount={project?.unread_activity_count}
                                            inboxCount={project?.unread_chat_count || 0}
                                            addActiveClass={activeProject?.id === project?.id}
                                            onClick={(ev) => {
                                                ev.stopPropagation();
                                                dispatch(
                                                    setActiveProject({
                                                        ...project,
                                                        masterProject,
                                                        type: PROJECT_TYPES.SUB_PROJECT,
                                                    }),
                                                );
                                                history.push(
                                                    `/projects/${masterProject.id}/sub-project/${project.id}?page=insights`,
                                                );
                                            }}
                                            onEdit={(ev) => {
                                                ev.stopPropagation();
                                                onEditHandler(ev, project, masterProject);
                                            }}
                                            isDraft={project?.is_draft}
                                            isCompleted={project?.is_finished}
                                            editBtn={Permissions.hasProjectPermission(
                                                project?.permissions,
                                                PROJECT_PERMISSIONS.CAN_EDIT_PROJECT,
                                            )}
                                            packages={packages}
                                        />
                                    );
                                })
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                            </ul>
                        </Panel>
                    );
                })}
            </Collapse>
    );
});

export default ProjectListItems;
