import PropTypes from 'prop-types';
import { Button, Empty } from 'antd';

function NotFoundMessage(props) {
  return (
    <div className="text-center">
      <Empty description="No Summary Sheet Available" className="small-text" />
      <Button type="primary" className="mt-15" onClick={() => props.setCreateSummary(true)}>
        Create New
      </Button>
    </div>
  );
}

NotFoundMessage.defaultProps = {
  setCreateSummary: () => {},
};

NotFoundMessage.propTypes = {
  setCreateSummary: PropTypes.func,
};

export default NotFoundMessage;
