import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { CustomAPILoaderWrapper } from './customloader.style';

function CustomAPILoader({ text = '' }) {
  return (
    <CustomAPILoaderWrapper>
      <Spin
        tip={text}
        indicator={(
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
                  )}
      />
    </CustomAPILoaderWrapper>
  );
}

export default CustomAPILoader;
