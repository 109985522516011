import _ from 'lodash';
import {
  Spin, Modal, Row, Table, Col, Input, Button, Form,
} from 'antd';
import { useState } from 'react';
import { PAGE_SIZE_OPTIONS } from '../../../utils';

function RoleTemplateModal({
  title,
  record,
  visible = false,
  groups = [],
  permissions = [],
  onOk,
  onCancel,
  createRoleTemplate,
  loading = false,
}) {
  const [form] = Form.useForm();
  const [name, setName] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: 'Features',
      dataIndex: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const a1 = a.name.split(' ')[1];
        const b1 = b.name.split(' ')[1];
        return a1.localeCompare(b1);
      },
      render: (value, rec) => value.split(' ')[1],
    },
    {
      title: 'Feature Type',
      dataIndex: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const a1 = a.name.split(' ')[0];
        const b1 = b.name.split(' ')[0];
        return a1.localeCompare(b1);
      },
      render: (value, rec) => value.split(' ')[0],
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSubmit = () => {
    createRoleTemplate({
      payload: {
        name,
        permissions: selectedRowKeys,
      },
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Modal
      title={title}
      centered
      width={800}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Close"
      maskClosable={false}
      className="equipment-detail-modal"
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={onSubmit}
        >
          <Row>
            <Col>
              <Form.Item
                label="Template name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Template Name',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Template Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>

            </Col>
          </Row>
          <Row>
            <div style={{ width: '100%' }}>
              <Table
                onRow={() => {}}
                columns={(columns)}
                loading={loading}
                onChange={(pagination) => {
                  setPage(pagination?.current);
                  setPageSize(pagination?.pageSize);
                }}
                rowSelection={{
                  type: 'Checkbox',
                  ...rowSelection,
                }}
                onSelect={(record) => {}}
                pagination={{
                  total: permissions?.length,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  pageSize,
                  showSizeChanger: true,
                  current: page,
                }}
                dataSource={permissions.map((d) => ({ ...d, key: d?.id }))}
              />
            </div>
          </Row>
          <Button
            className="mt-20"
            type="primary"
            block
            onClick={form.submit}
          >
            Create Role Template
          </Button>
        </Form>
      </Spin>
    </Modal>
  );
}

export default RoleTemplateModal;
