import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Col, Empty, Row, Pagination,
} from 'antd';
import {
  DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, PROJECTS, SLINES,
} from '../../../utils';
import { SLineThumbnail } from '../../../components';

function SlinesList({
  singlelines, filtered, activeProject, page, pageSize, setPageInfo, slinesMeta,
}) {
  const history = useHistory();

  return (
    <>
      <Row gutter={30}>
        {!_.isEmpty(singlelines) ? (
          singlelines.map((sline) => (
            <Col span={6} key={sline.name + sline.id}>
              <SLineThumbnail
                {...sline}
                onView={() => history.push({
                  pathname: `${PROJECTS}/${activeProject?.id}${SLINES}/${sline.id}`,
                  state: activeProject?.name,
                })}
              />
            </Col>
          ))
        ) : _.isEmpty(singlelines) && !_.isEmpty(filtered) ? (
          <Col span={24}>
            <Empty description="This tag doesn't exist in any single line of this project!" />
          </Col>
        ) : (
          <Col span={24}>
            <Empty description="single lines are not found for this project" />
          </Col>
        )}
      </Row>
      <Row justify="end">
        <Col>
          {!_.isEmpty(singlelines) && (
            <Pagination
              defaultCurrent={page}
              total={slinesMeta?.total_count}
              onChange={(page, pageSize) => setPageInfo(page, pageSize)}
              showSizeChanger
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              defaultPageSize={DEFAULT_PAGE_SIZE}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default SlinesList;
