import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlectifyTable } from '../table';
import { getShippingUpdateByTag } from '../../store/tags';
import { DEFAULT_PAGE_SIZE, DEFAULT_START_PAGE } from '../../utils';
import useShipmentColumns from './effects/useShipmentColumns';

const ShipmentsModal = (props) => {
    const dispatch = useDispatch();
    const columns = useShipmentColumns();
    const { shippingDetails, shippingMeta, shippingLoader } = useSelector(({ tags }) => tags);

    const fetchShipments = (params) => {
        dispatch(
            getShippingUpdateByTag({
                id: props?.data?.id,
                page: params?.page || DEFAULT_START_PAGE,
                pageSize: params?.pageSize || DEFAULT_PAGE_SIZE,
            }),
        );
    };

    useEffect(() => {
        fetchShipments();
    }, []);

    return (
        <Modal
        width={900}
            footer={null}
            open={props.visible}
            onCancel={props.onCancel}
            title={`${props?.data?.name} Shipments`}
        >
            <AlectifyTable
                size="small"
                onDataFetch={fetchShipments}
                columns={columns}
                isDownloadZip={false}
                isEmailSharing={false}
                onDownloadZip={() => {}}
                onEmailShare={() => {}}
                id={props?.record?.id}
                dataSource={shippingDetails}
                loading={shippingLoader}
                selectRows={false}
                total={shippingMeta?.total_count}
                key={`${props?.record?.id}-shipment`}
            />
        </Modal>
    );
};

ShipmentsModal.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
};

ShipmentsModal.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default memo(ShipmentsModal);
