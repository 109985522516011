import { Badge, Col, Row } from 'antd';
import React from 'react';
import './user-cards-status.scss';

function UserStatus() {
  const cardStatus = [
    {
      status: 'Under Approval',
      count: 1,
      backgroundColor: 'pink',
    },
    {
      status: 'Release',
      count: 2,
      backgroundColor: 'blue',
    },
    {
      status: 'Resubmit',
      count: 3,
      backgroundColor: 'brown',
    },
    {
      status: 'Information',
      count: 4,
      backgroundColor: 'light-blue',
    },
  ];
  return (
    <Row className="status-body" gutter={15}>
      {cardStatus.map((card) => (
        <Col span={6}>
          <div
            className={`page-content-container dashboard-card colorful-card bg-${card.backgroundColor}`}
          >
            <h4 className="card-heading custom-card-heading">{card.status}</h4>
            <h1 className="dashboard-card-text">{card.count}</h1>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default UserStatus;

  <Row gutter={15}>
    <Col span={6}>
      <div className="page-content-container dashboard-card colorful-card bg-pink">
        <h4 className="card-heading custom-card-heading">Total Projects</h4>
        <h1 className="dashboard-card-text">457</h1>
      </div>
    </Col>
    <Col span={6}>
      <div className="page-content-container dashboard-card colorful-card bg-blue">
        <h4 className="card-heading custom-card-heading">Active Projects</h4>
        <h1 className="dashboard-card-text">54</h1>
      </div>
    </Col>
    <Col span={6}>
      <div className="page-content-container dashboard-card colorful-card bg-brown">
        <h4 className="card-heading custom-card-heading">Contractors</h4>
        <h1 className="dashboard-card-text">23</h1>
      </div>
    </Col>
    <Col span={6}>
      <div className="page-content-container dashboard-card colorful-card bg-light-blue">
        <h4 className="card-heading custom-card-heading">Files</h4>
        <h1 className="dashboard-card-text">41,107</h1>
      </div>
    </Col>
  </Row>;
