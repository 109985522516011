import React from 'react';
import { Popconfirm, Tooltip } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { get } from 'lodash';
import { capitalizeText } from '../../../../../utils';

export default (removeUser = () => {}) => {
    return React.useMemo(() => [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            render: (text, record) => (
                <Tooltip placement="topLeft" title={get(record, 'first_name', '-')}>
                    <p className="mb-0 table-row-name-last-desc">
                        {get(record, 'first_name', '-')}
                    </p>
                </Tooltip>
            ),
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            render: (text, record) => (
                <Tooltip placement="topLeft" title={get(record, 'last_name', '-')}>
                    <p className="mb-0 table-row-name-last-desc">{get(record, 'last_name', '-')}</p>
                </Tooltip>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Organization',
            dataIndex: 'organization_name',
            render: (text, record) => (
                <Tooltip placement="topLeft" title={get(record, 'organization_name', '-')}>
                    <p className="mb-0 table-row-name-last-desc">
                        {get(record, 'organization_name', '-')}
                    </p>
                </Tooltip>
            ),
        },

        {
            title: 'Permission',
            dataIndex: ['permission_group', 'name'],
            render: capitalizeText,
        },
        {
            title: '',
            dataIndex: '',
            render: (_, record) => (
                <Tooltip title="Remove User">
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={(ev) => {
                            ev.stopPropagation();
                            removeUser(record.id);
                        }}
                    >
                        <DeleteTwoTone
                            twoToneColor="#f56666"
                            style={{ fontSize: 18, cursor: 'pointer' }}
                        />
                    </Popconfirm>
                </Tooltip>
            ),
        },
    ]);
};
