import { message } from 'antd';
import {
    deleteCustomerPurchaseOrders,
    deleteProjectCosts,
    fetchCustomerPurchaseOrders,
    fetchCustomerPurchaseOrdersSummary,
    fetchProjectCosts,
    fetchProjectCostsSummary,
} from '../../services';
import * as Action from './types';

export const getProjectCosts = (projectId, params) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_PROJECT_COSTS_INITIATE });
        const response = await fetchProjectCosts(projectId, params);
        dispatch({ type: Action.FETCH_PROJECT_COSTS_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: Action.FETCH_PROJECT_COSTS_FAILURE });
    }
};

export const getProjectCostsSummary = (projectId, verticalType) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_PROJECT_COSTS_SUMMARY_INITIATE });
        const response = await fetchProjectCostsSummary(projectId, verticalType);
        dispatch({ type: Action.FETCH_PROJECT_COSTS_SUMMARY_SUCCESS, payload: response?.data });
    } catch (error) {
        dispatch({ type: Action.FETCH_PROJECT_COSTS_SUMMARY_FAILURE });
    }
};

export const removeProjectCosts = (projectId, projectCostIds) => async (dispatch) => {
    try {
        dispatch({ type: Action.DELETE_PROJECT_COSTS_INITIATE });
        await deleteProjectCosts(projectId, projectCostIds);
        message.success('Deleted Successfully');
        dispatch({ type: Action.DELETE_PROJECT_COSTS_SUCCESS });
        dispatch(getProjectCosts(projectId, { page: 1, per_page: 10 }));
        dispatch(getProjectCostsSummary(projectId));
    } catch (error) {
        dispatch({ type: Action.DELETE_PROJECT_COSTS_FAILURE });
    }
};

export const getCustomerPurchaseOrders = (projectId, params) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_CUSTOMER_PO_INITIATE });
        const response = await fetchCustomerPurchaseOrders(projectId, params);
        dispatch({ type: Action.FETCH_CUSTOMER_PO_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: Action.FETCH_CUSTOMER_PO_FAILURE });
    }
};

export const getCustomerPurchaseOrdersSummary = (projectId) => async (dispatch) => {
    try {
        dispatch({ type: Action.FETCH_CUSTOMER_PO_SUMMARY_INITIATE });
        const response = await fetchCustomerPurchaseOrdersSummary(projectId);
        dispatch({ type: Action.FETCH_CUSTOMER_PO_SUMMARY_SUCCESS, payload: response?.data });
    } catch (error) {
        dispatch({ type: Action.FETCH_CUSTOMER_PO_SUMMARY_FAILURE });
    }
};

export const removeCustomerPurchaseOrders = (projectId, customerPoIds) => async (dispatch) => {
    try {
        dispatch({ type: Action.DELETE_CUSTOMER_PO_INITIATE });
        await deleteCustomerPurchaseOrders(projectId, customerPoIds);
        message.success('Deleted Successfully');
        dispatch({ type: Action.DELETE_CUSTOMER_PO_SUCCESS });
        dispatch(getCustomerPurchaseOrders(projectId, { page: 1, per_page: 10 }));
        dispatch(getCustomerPurchaseOrdersSummary(projectId));
    } catch (error) {
        dispatch({ type: Action.DELETE_CUSTOMER_PO_FAILURE });
    }
};
