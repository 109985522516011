import { memo } from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PROJECTS, PROJECT_TYPES } from '../../../utils';
import { setActiveProject } from '../../../store/common';
import SubProjectDetailTabs from './tabs';

const SubProjectDetail = memo((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { activeProject } = useSelector(({ common }) => common);

    return (
        <>
            <div className="mt-20" />
            <Breadcrumb separator=">">
                <Breadcrumb.Item
                    className="cursor-pointer"
                    onClick={() => {
                        dispatch(
                            setActiveProject({
                                ...activeProject?.masterProject,
                                type: PROJECT_TYPES.MASTER_PROJECT,
                                masterProject: activeProject?.masterProject,
                            }),
                        );
                        history.push(`${PROJECTS}/${activeProject?.masterProject?.id}`);
                    }}
                >
                    {activeProject?.masterProject?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span className="cursor-pointer">{activeProject?.name}</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <SubProjectDetailTabs {...props} />
        </>
    );
});

export default SubProjectDetail;
