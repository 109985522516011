import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useEffect, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AlectifyTable } from '../../../../../../../components';
import { getVendorAssociates, removeVendorAssociates } from '../../../../../../../store/financials';
import useColumns from './effects/useColumns';
import VendorAssociateDetail from './VendorAssociateDetail';
import UpdateVendorAssociate from './UpdateVendorAssociate';

const VendorAssociateList = memo((props) => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { vendorAssociates } = useSelector(({ financials }) => financials);
    const [actions, setActions] = useState({ data: null, view: false, edit: false });

    const getVendorAssociateslist = (pagination) => {
        const params = {
            page: pagination?.page || 1,
            per_page: pagination?.per_page || 5,
        };
        dispatch(getVendorAssociates(projectId, props?.id, params));
    };

    const deleteVendorAssociates = (vendorAssociateIds) => {
        dispatch(removeVendorAssociates(projectId, props?.id, vendorAssociateIds));
        message.success('Deleted Successfully');
    };

    const onActionClick = (action, record) => {
        if (action === 'delete') {
            deleteVendorAssociates([record.id]);
        } else {
            setActions({ data: { ...record, vendorItemId: props?.id }, [action]: true });
        }
    };

    const onHideModal = () => {
        setActions({ data: null, visible: false, view: false, edit: false });
    };

    useEffect(() => {
        getVendorAssociateslist();
    }, []);

    const columns = useColumns(onActionClick);

    return (
        <>
            <AlectifyTable
                columns={columns}
                multipleDeleteOption={true}
                dataSource={vendorAssociates?.data || []}
                loading={vendorAssociates?.fetching}
                onDataFetch={getVendorAssociateslist}
                total={vendorAssociates?.meta?.total_count}
                onMultipleDelete={deleteVendorAssociates}
            />
            {actions.view && (
                <VendorAssociateDetail
                    data={actions.data}
                    visible={actions.view}
                    onCancel={onHideModal}
                />
            )}
            {actions.edit && (
                <UpdateVendorAssociate
                    data={actions.data}
                    visible={actions.edit}
                    onCancel={onHideModal}
                />
            )}
        </>
    );
});

export default VendorAssociateList;
