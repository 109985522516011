import moment from 'moment';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Spin,
    Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
    BASIC_REQUIRED_VALIDATION_RULES,
    CUSTOMER_PO_REASONS,
    INPUT_DATE_FORMAT,
    SERVER_DATE_FORMAT,
} from '../../../../../../utils';
import {
    getCustomerPurchaseOrders,
    getCustomerPurchaseOrdersSummary,
} from '../../../../../../store/billing';
import {
    createCustomerPurchaseOrder,
    uploadCustomerPurchaseOrderDocuments,
} from '../../../../../../services';

const { Option } = Select;

const CreateCustomerPurchaseOrder = memo(() => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [hasDocuments, setHasDocuments] = useState(false);

    const uploadDocuments = async (customerPoId, values) => {
        try {
            const { invoice_date, invoice_number, documents } = values;
            const formData = new FormData();
            formData.append('invoice_date', invoice_date);
            formData.append('invoice_number', invoice_number);
            documents.fileList.map((file) => formData.append('files', file.originFileObj));
            await uploadCustomerPurchaseOrderDocuments(projectId, customerPoId, formData);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            const { invoice_date, invoice_number, documents, ...formValues } = values;
            formValues.reason = 'invoice';
            formValues.po_date = formValues.po_date.format(SERVER_DATE_FORMAT);
            setSubmitting(true);
            const response = await createCustomerPurchaseOrder(projectId, formValues);
            if (documents?.fileList?.length > 0) {
                values.invoice_date = values.invoice_date.format(SERVER_DATE_FORMAT);
                await uploadDocuments(response?.data.id, values);
            }
            message.success('Created Successfully');
            dispatch(getCustomerPurchaseOrders(projectId, { page: 1, per_page: 10 }));
            dispatch(getCustomerPurchaseOrdersSummary(projectId));
            setSubmitting(false);
            form.resetFields();
        } catch (error) {
            setSubmitting(false);
        }
    };

    const onSelectDocuments = (ev) => {
        setHasDocuments(ev.fileList.length > 0);
    };

    return (
        <Spin spinning={submitting}>
            <h1>Create Entry</h1>
            <Form
                form={form}
                layout="vertical"
                className="mb-20"
                onFinish={onSubmit}
                initialValues={{ po_date: moment() }}
            >
                <Row gutter={30}>
                    <Col span={8}>
                        <Form.Item
                            label="Date"
                            name="po_date"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                        >
                            <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item
                            label="Reason"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                            name="reason"
                        >
                            <Select placeholder="Please Select">
                                {Object.keys(CUSTOMER_PO_REASONS).map((key) => (
                                    <Option value={key} key={key}>
                                        {CUSTOMER_PO_REASONS[key]}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col span={8}>
                        <Form.Item
                            label="Amount"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                            name="amount"
                        >
                            <InputNumber
                                prefix="$"
                                precision={2}
                                min={0}
                                step={0.1}
                                className="full-width"
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item
                            label="Comments"
                            name="comments"
                        >
                            <Input placeholder="Enter Comments" />
                        </Form.Item>
                    </Col> */}
                </Row>
                <h1>Invoice Details</h1>
                <Row gutter={30}>
                    <Col span={8}>
                        <Form.Item
                            label="Invoice Date"
                            name="invoice_date"
                            rules={[
                                hasDocuments
                                    ? { required: true, message: 'Please select Invoice Date' }
                                    : { required: false },
                            ]}
                        >
                            <DatePicker
                                disabled={!hasDocuments}
                                className="full-width"
                                format={INPUT_DATE_FORMAT}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Invoice #"
                            name="invoice_number"
                            rules={[
                                hasDocuments
                                    ? { required: true, message: 'Please enter Invoice number' }
                                    : { required: false },
                            ]}
                        >
                            <Input disabled={!hasDocuments} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Documents" name="documents">
                            <Upload
                                multiple
                                beforeUpload={() => false}
                                onChange={onSelectDocuments}
                            >
                                <Button icon={<UploadOutlined />}>Select Files</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form>
        </Spin>
    );
});

export default CreateCustomerPurchaseOrder;
