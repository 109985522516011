import _ from 'lodash';
import {
  Spin, Modal, Row, Col, Button, Form, Input, Select, AutoComplete,
} from 'antd';
import { useEffect, useState } from 'react';
import { getOrganizations } from '../../../services';

function UserModal({
  title,
  record,
  visible = false,
  role_templates = [],
  organizationTypes = [],
  roleTypes = [],
  branches = [],
  onOk,
  onCancel,
  onFinish,
  updateGroup,
  loading = false,
}) {
  const [form] = Form.useForm();
  const [newOrganizations, setNewOrganizations] = useState([]);

  const onSelectPermissionGroup = (e) => {
    const permission_group = _.find(role_templates, ['value', e]);
    record.permission_group = permission_group;
  };

  useEffect(() => {
    const initialData = { ...record };
    initialData.permission_group = {
      value: record?.permission_group?.id,
      label: record?.permission_group?.name,
    };
    initialData.organization = record?.organization?.name;
    initialData.organization_type = {
      value: record?.role?.org_type?.id,
    };
    initialData.role = {
      value: record?.role?.id,
    };
    initialData.company = {
      value: record?.branch?.company?.name,
    };
    initialData.branch = {
      value: record?.branch?.id,
    };
    initialData.branch = {
      value: record?.branch?.id,
    };
    form.setFieldsValue(initialData);
  }, [record]);

  const onSelect = (val, option) => {
    form.setFieldsValue({ organization: option });
  };

  const onSearch = _.debounce(async (val) => {
    try {
      const results = await getOrganizations(val);
      setNewOrganizations([...results.data]);
    } catch (error) {
    }
  }, 400);

  return (
    <Modal
      title={title}
      centered
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Close"
      maskClosable={false}
      className="equipment-detail-modal"
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => {}}
          validateTrigger="onSubmit"
          initialValues={record}
        >
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item
                label="Company Name"
                name="company"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Company Namee',
                  },
                ]}
              >
                <Select
                  disabled
                  onSelect={onSelectPermissionGroup}
                  options={branches.map((r) => ({
                    value: r?.id,
                    label: r?.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Branch"
                name="branch"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Branch',
                  },
                ]}
              >
                <Select
                  onSelect={onSelectPermissionGroup}
                  options={branches.map((r) => ({
                    value: r?.id,
                    label: r?.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter First name',
                  },
                ]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Please last name',
                  },
                ]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter email',
                  },
                ]}
              >
                <Input placeholder="Enter email address" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact Number"
                name="contact_number"
              >
                <Input placeholder="Enter Contact number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Organization"
                name="organization"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Organization',
                  },
                ]}
              >
                <AutoComplete
                  onSelect={onSelect}
                  onSearch={onSearch}
                  filterOption={(inputValue, option) => (
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  )}
                >
                  {newOrganizations.map((org) => (
                    <AutoComplete.Option
                      key={org?.id}
                      value={org?.name}
                      org_type={org?.org_type?.name}
                      org_type_id={org?.org_type?.id}
                    >
                      {org?.name}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Permission"
                name="permission_group"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Permission group',
                  },
                ]}
              >
                <Select
                  onSelect={onSelectPermissionGroup}
                  options={role_templates.map((r) => ({
                    value: r?.id,
                    label: r?.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Job Title"
                name="job_title"
              >
                <Input placeholder="Enter Job Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Business Contact Number"
                name="business_phone_number"
              >
                <Input placeholder="Enter Business Contact number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Business Address"
                name="business_address"
              >
                <Input placeholder="Enter Business Address" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Address"
                name="address"
              >
                <Input placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="mt-10">
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Update User
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default UserModal;
