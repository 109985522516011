import _ from 'lodash';
import {
  Col, Row, Form, Input, Button, notification, Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { register, getOrganizationTypes, getRoles } from '../../../../app/services';
import './style.scss';
import { makeAvatar } from '../../../../app/utils';
// import signupbanner from '../../../assets/images/signup-banner.jpeg';
import authBanner from '../../../../app/assets/images/auth-banner.jpg';

function Signup({ history }) {
  const { user } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [roles, setRoles] = useState([]);

  const fetchOrganizationTypes = async () => {
    try {
      const result = await getOrganizationTypes();
      setOrganizationTypes(result.data);
    } catch (error) {
    }
  };

  const onTypeChange = async (value, option, cb) => {
    try {
      form.setFieldsValue({ role: null });
      const result = await getRoles(value);
      setRoles([...result.data]);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchOrganizationTypes();
  }, []);

  const onSubmit = async (values) => {
    try {
      delete values.confirmPassword;
      values.image_url = makeAvatar(values?.first_name[0], values?.last_name[0]);
      setLoading(true);
      const response = await register(values);
      if (response) {
        const uid = values.first_name;

        const name = values.first_name;
        user.setName(name);
        notification.success({
          message: 'Registered Successfully',
          description:
                        'You have been registered successfully, please login to your account.',
        });
        setTimeout(() => {
          history.push('/login');
        }, 1000);
      }
      setLoading(false);
      form.resetFields();
    } catch (ex) {
      const apiErrors = ex?.response?.data?.data;
      if (apiErrors) {
        const errors = Object.keys(apiErrors).map((key) => ({
          name: key,
          errors: apiErrors[key],
        }));
        form.setFields(errors);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      history.push('/projects');
    }
  }, []);

  return (
    <Row className="auth-container height-100vh" align="middle">
      <Col span={12}>
        <img src={authBanner} className="auth-image" />
      </Col>
      <Col span={12}>
        <div className="auth-form-container">
          <div className="auth-form-header">
            <h3>
              Welcome to
              {' '}
              <span className="color-primary">Alectify!</span>
            </h3>
            <p>Create your new account</p>
          </div>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={() => {}}
            autoComplete="off"
          >
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your first name',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your last name',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please enter your email',
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Organization Name"
                  name="organization_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the organization name',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Type"
                  name="organization_type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the type',
                    },
                  ]}
                >
                  <Select onChange={onTypeChange}>
                    {organizationTypes.map((org) => (
                      <Select.Option key={org.id} value={org.id}>
                        {org.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Role"
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the role',
                    },
                  ]}
                >
                  <Select>
                    {roles.map((role) => (
                      <Select.Option key={role.id} value={role.id}>
                        {role.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password',
                    },
                    {
                      min: 6,
                      message: 'Password must contain atleast 6 characters.',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!',
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item className="mt-50">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-lg btn-primary-lg"
                loading={loading}
              >
                Register
              </Button>
            </Form.Item>
          </Form>
          <div className="mt-50">
            <p style={{ fontSize: 15 }}>
              Already have an account?
              {' '}
              <Link className="btn btn-outline-primary" to="/login">
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Signup;
