export const MESSAGES = {
    GENERAL: {
        UNEXPECTED_ERROR: 'Something unexpected happened.',
        USER_ADDED: 'User added.',
    },
    BIDDING: {
        PROJECT_CREATED: 'Project has been created successfully.',
        PROJECT_UPDATED: 'Project has been updated successfully.',
        BASIC_INFO_SAVED: "Basic info saved.",
    },
};
