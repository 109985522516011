import { Breadcrumb } from 'antd';
import ContractorsDetailTabs from './Tabs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BIDDING_MANAGEMENT } from '../../../../utils';

const ContractorDetails = (props) => {
    const { activeProject, activeContractor } = useSelector(({ bidding }) => bidding);
    const history = useHistory();

    return (
        <>
            <div className="mt-20" />
            <Breadcrumb separator=">">
                <Breadcrumb.Item onClick={() => history.push(`${BIDDING_MANAGEMENT}`)}>
                    <span className="cursor-pointer">Alectify</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    onClick={() => history.push(`${BIDDING_MANAGEMENT}/${activeProject.id}`)}
                >
                    <span className="cursor-pointer">{activeProject?.name}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span>{activeContractor?.name}</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <ContractorsDetailTabs {...props} />
        </>
    );
};

export default ContractorDetails;
