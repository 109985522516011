import React, { useState } from 'react';
import { Avatar, Badge } from 'antd';
import './user-card.scss';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setTitleValue } from '../../store/common';

function UserCard() {
  const dispatch = useDispatch();
  const userData = [
    {
      name: 'Alex',
      option1: 'Construction',
      option2: 'Markups',
      option3: 'As build',
    },

    {
      name: 'Mathew',
      option1: 'Construction',
      option2: 'Markups',
      option3: 'As build',
    },
    {
      name: 'Peter',
      option1: 'Construction',
      option2: 'Markups',
      option3: 'As build',
    },
    {
      name: 'Shaw',
      option1: 'Construction',
      option2: 'Markups',
      option3: 'As build',
    },
    {
      name: 'John',
      option1: 'Construction',
      option2: 'Markups',
      option3: 'As build',
    },
  ];

  return (
    <>
      {userData.map((user) => (
        <div className="card-main">
          <div className="card-header">
            <div>
              <Avatar className="avatar" size="large" icon={<UserOutlined />} />
            </div>
            <div className="heading-name">
              <h3>{user.name}</h3>
            </div>
          </div>
          <div className="sub-heading">
            <div
              className="sub-heading-content"
              onClick={() => dispatch(setTitleValue(user.option1))}
            >
              {user.option1}
              <div className="sub-heading-number">12</div>
            </div>
            <div
              className="sub-heading-content"
              onClick={() => dispatch(setTitleValue(user.option2))}
            >
              {user.option2}
              <div className="sub-heading-number">2</div>
            </div>
            <div
              className="sub-heading-content"
              onClick={() => dispatch(setTitleValue(user.option3))}
            >
              {user.option3}
              <div className="sub-heading-number">112</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default UserCard;
