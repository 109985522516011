import React from 'react';

import { BIDDING_MANAGEMENT } from '../../../../utils';
import MainProjectListing from './MainProjectListing';
import { SearchBox, Sidebar } from '../../../../components';

export default ({
    hideSidebar,
    setHideSidebar,
    history,
    loading,
    projectSearchText,
    onSearchProject,
    filteredProjects,
    projects,
}) => {
    return (
        <Sidebar
            addNewBtn
            heading={() => 'Bidding Management'}
            addNewText="New Project"
            toggleSidebar={() => setHideSidebar(!hideSidebar)}
            addNewOnClick={() => {
                history.push(`${BIDDING_MANAGEMENT}/create`);
            }}
            loader={loading}
            customChildren={
                <>
                    <SearchBox
                        className="mb-20 mt-10"
                        placeholder="Search Projects ..."
                        value={projectSearchText}
                        onChange={onSearchProject}
                    />
                </>
            }
        >
            <MainProjectListing
                history={history}
                projects={projectSearchText ? filteredProjects || [] : projects || []}
                loading={loading}
            />
        </Sidebar>
    );
};
