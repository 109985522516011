import _ from 'lodash';

export const searchBidProjects = (projects, searchKey) =>
    new Promise((resolve, reject) => {
        const filtered = projects.filter((project) => {
            if (project.name.toLowerCase().includes(searchKey.toLowerCase())) {
                return project;
            }
            const subProjectNames = _.map(project.projects, (p) => p?.name);

            const filteredSubProjects = _.filter(
                project.projects,
                (p) =>
                    // eslint-disable-next-line
                    _.includes(p?.name.toLowerCase(), searchKey.toLowerCase()),
                // eslint-disable-next-line
            );
            // eslint-disable-next-line
            if (filteredSubProjects.length) {
                return project;
            }
        });
        resolve(filtered);
    });

export const contractorMenu = [
    {
        label: 'Edit',
        key: '1',
    },
    {
        label: 'Bid Won',
        key: '2',
    },
    {
        label: 'Auto Create Project',
        key: '3',
        disabled: true,
    },
];
