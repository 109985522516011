import { http } from '../utils/http';

export const fetchCompanies = async () => {
  try {
    const response = await http.get('/admin/companies/');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createCompany = async (body) => {
  try {
    const response = await http.post('/admin/companies/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCompanyProjects = async (companyId, params) => {
  try {
    const response = await http.get(`/admin/companies/${companyId}/projects/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCompanyUsers = async (companyId, params) => {
  try {
    const response = await http.get(`/admin/companies/${companyId}/users/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
