import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  Avatar, message, Space, Tooltip,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { DISPLAY_DATE_FORMAT, makeAvatar } from '../../../../../../../utils';

export default () => {
  const downloadDocument = async ({ signed_url, file_name }) => {
    try {
      await axios.get(signed_url, { headers: { 'Content-Disposition': 'attachment' } });
      saveAs(signed_url, file_name);
    } catch (error) {
      message.error('Error in downloading document');
    }
  };

  return React.useMemo(() => [
    {
      key: 'file_name',
      title: 'File Name',
      dataIndex: 'file_name',
      render: (value, record) => (
        <Space>
          <Tooltip title="Download">
            <DownloadOutlined
              className="cursor-pointer"
              onClick={() => downloadDocument(record)}
            />
          </Tooltip>
          <span>{value || ''}</span>
        </Space>
      ),
    },
    {
      key: 'created_at',
      title: 'Uploaded At',
      dataIndex: 'created_at',
      render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
    },
    {
      key: 'uploaded_by',
      title: 'Uploaded By',
      dataIndex: 'uploaded_by',
      align: 'center',
      render: (user) => (
        <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
          <Avatar
            size="small"
            src={user?.image || makeAvatar(user?.first_name?.[0], user?.last_name?.[0])}
          />
        </Tooltip>
      ),
    },
  ]);
};
