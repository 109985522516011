import PropTypes from 'prop-types';
import { memo } from 'react';
import { Card, Empty, Spin } from 'antd';

const BinderPreview = (props) => {
    return (
        <Spin spinning={props.fetching} tip="Feching Preview">
            <Card title="Digital Binder Preview" bodyStyle={{ height: 'calc(100vh - 138px)' }}>
                {props.data ? (
                    <iframe
                        src={URL.createObjectURL(props.data)}
                        frameborder="0"
                        height="100%"
                        width="100%"
                        title="Digital Binder Preview"
                    />
                ) : (
                    <Empty className='mt-30' description="Click on preview button for Binder preview" />
                )}
            </Card>
        </Spin>
    );
};

BinderPreview.defaultProps = {
    data: null,
    fetching: false,
};

BinderPreview.propTypes = {
    data: PropTypes.any,
    fetching: PropTypes.bool,
};

export default memo(BinderPreview);
