import {
  map, find, isEmpty, isNull, unionBy, isString,
} from 'lodash';
import { memo, useEffect, useState } from 'react';
import {
  Row, Col, Form, Modal, Input, Spin, Select, DatePicker, Tooltip, Popover, Avatar, Space, Button,
} from 'antd';
import '../../../assets/styles/components/task.scss';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { INPUT_DATE_FORMAT, SERVER_DATE_FORMAT, makeAvatar } from '../../../utils';
import {
  fetchPackageDetails,
  fetchProjectSearchResults,
} from '../../../services';
import { SelectFromList } from './SelectFromList';
import { searchTableColumns } from './searchTablecolumns';
import { getTagsByProject } from '../../../store/tags';
import { searchPackageColumns } from './searchPackageColumns';
import { getPackagesByProject } from '../../../store/package';

const { TextArea } = Input;
const { Option } = Select;

function CreateTaskModal({
  title = '',
  projectId,
  onSubmit = () => { },
  onUpdate = () => { },
  onCancel = () => { },
  visible = false,
  loading = false,
  initialData = {},
  projectSetup = false,
  onTagSearch = () => { },
}) {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [status, setStatus] = useState(null);
  const [users, setUsers] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [taskLevel, setTaskLevel] = useState(null);
  const { activeProject } = useSelector(({ common }) => common);
  const { masterTags, masterTagsLoading } = useSelector(({ tags }) => tags);
  const { masterPackages, masterPackagesLoading } = useSelector(({ packages }) => packages);

  const onSubmitHandler = (values) => {
    values.due_date = moment(values?.due_date, moment.ISO_8601).format(SERVER_DATE_FORMAT);
    values.assignees = map(assignees, (m) => m?.id);
    if (Object.keys(initialData).length) {
      if (values?.completion_date) {
        values.completion_date = moment(values?.completion_date, moment.ISO_8601).format(SERVER_DATE_FORMAT);
      }
      onUpdate({ ...initialData, ...values });
    } else {
      values.id = isString(values?.name) ? values?.name : values?.name?.[0].id;
      values.status = 'PENDING';
      onSubmit(values);
    }
  };

  /**
     * Handle Project level Search
     */
  useEffect(() => {
    if (searchQuery) {
      getProjectSearchResults(searchQuery);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const getProjectSearchResults = async (query) => {
    setSearchLoading(true);
    const res = await fetchProjectSearchResults({
        projectId: activeProject?.id,
        body: {
        search_str: query,
      }});

    if (res.success) {
      setSearchLoading(false);
      setSearchResults(res.data);
    } else {
      setSearchResults([]);
      setSearchLoading(false);
    }
  };

  useEffect(async () => {
    // To populate initial Values
    if (Object.keys(initialData).length) {
      // Get team members
      try {
        const response = await fetchPackageDetails(projectId, initialData?.package?.id);
        setUsers(response?.data?.team_members);
        setAssignees(initialData?.assignees);

        initialData.due_date = moment(initialData.due_date, SERVER_DATE_FORMAT);

        if (initialData?.completion_date) {
          initialData.completion_date = moment(initialData.completion_date, SERVER_DATE_FORMAT);
        }

        if (initialData?.package && initialData?.tag) {
          initialData.name = initialData?.tag?.name;
        } else { // For package level
          initialData.name = initialData?.package.name;
        }

        // Fields modified
        form.setFieldsValue(initialData);
      } catch (e) {

      }
    }
  }, [form, initialData]);

  const cancleHandler = () => {
    form.resetFields();
    onCancel();
  };

  const handleChange = (value) => {
    setStatus(value);
  };

  const onSelect = async (tagId) => {
    // Get the users for the selected tag
    const tag = find(masterTags?.data, 'id', tagId);

    // Get the package users
    try {
      const response = await fetchPackageDetails(projectId, tag?.packageroom[0]?.id);
      setUsers(response?.data?.team_members);
    } catch (e) {
    }
  };

  const onSelectPackage = async (id) => {
    // Get the users for the selected tag
    const packageSelected = find(masterPackages?.data, 'id', id);
    setUsers(packageSelected?.team_members);
  };

  const addMembersContent = () => (
    <Form
      form={form2}
      style={{ width: 200 }}
      layout="vertical"
      onFinish={(value) => {
        const member = find(users, (u) => u?.id === value?.member_id);
        setAssignees(unionBy([member], assignees), 'id');
      }}
    >
      <Form.Item name="member_id" rules={[{ required: true, message: 'Please select' }]}>
        <Select
          showSearch
          placeholder="Please select"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          allowClear
          style={{ width: '100%' }}
        >
          {users.map((user) => (
            <Option key={user.id} value={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className="text-right mt-15">
        <Space>
          <Button type="default" onClick={() => { }}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Space>
      </div>
    </Form>
  );

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={form.submit}
      onCancel={cancleHandler}
      confirmLoading={loading}
      okText={!isEmpty(initialData) ? 'Update' : 'Save'}
      style={{ minWidth: '1000px !important' }}
      className="review-room-modal"
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmitHandler}
          initialValues={{}}
        >
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                label="Task Tracking"
                name="task_level"
                rules={[
                  {
                    required: true,
                    message: 'Task level is required',
                  },
                ]}
              >
                <Select
                  loading={isSearchLoading}
                  defaultValue={searchQuery}
                  showSearch
                  allowClear
                  showArrow={false}
                  filterOption={false}
                  onSelect={(e) => {
                    setTaskLevel(e);
                    setSearchQuery(null);
                  }}
                  notFoundContent={null}
                  placeholder="Select Task Level"
                  options={[
                    { value: 'Tag', label: 'Tag' },
                    { value: 'Package', label: 'Package' },
                  ]}
                  disabled={!isEmpty(initialData)}
                />
              </Form.Item>
            </Col>
            <Col offset={12} />
            {
              !isEmpty(initialData)
                ? (
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                    >
                      <Input value={form.getFieldValue('name')?.name} disabled />
                    </Form.Item>
                  </Col>
                )
                : (
                  <Col span={12}>
                    {taskLevel === 'Tag'
                      ? (
                        <SelectFromList
                          columns={searchTableColumns({})}
                          onDataFetch={getTagsByProject}
                          data={masterTags}
                          dataFetch={() => { }}
                          title="Equipment List"
                          projectId={projectId}
                          form={form}
                          loading={masterTagsLoading}
                          onSelect={onSelect}
                          label="Tag"
                          name="name"
                          taskLevel={taskLevel}
                          key={taskLevel}
                          disabled={isNull(taskLevel)}
                          displayList={isEmpty(initialData)}
                        />
                      )
                      : (
                        <SelectFromList
                          columns={searchPackageColumns({})}
                          onDataFetch={getPackagesByProject}
                          data={masterPackages}
                          dataFetch={() => { }}
                          title="Packages List"
                          projectId={projectId}
                          form={form}
                          loading={masterPackagesLoading}
                          onSelect={onSelectPackage}
                          label="Package"
                          name="name"
                          taskLevel={taskLevel}
                          key={taskLevel}
                          disabled={isNull(taskLevel)}
                          displayList={isEmpty(initialData)}
                        />
                      )}
                  </Col>
                )
            }
            <Col span={12}>
              <Form.Item
                label="Team Members"
              >
                <Avatar.Group>
                  {users.map((member, idx) => (
                    <Tooltip
                      key={member.email}
                      title={`${member.first_name} ${member.last_name}`}
                    >
                      <Avatar
                        className="circle-icon"
                        src={makeAvatar(
                          member.first_name[0],
                          member.last_name[0],
                        )}
                      />
                    </Tooltip>
                  ))}
                </Avatar.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Task Name"
                name="summary"
                rules={[
                  {
                    required: true,
                    message: 'Task name is required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {!!Object.keys(initialData).length
              && (
                <Col span={12}>
                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: 'Status is required',
                      },
                    ]}
                  >
                    <Select
                      onChange={handleChange}
                      placeholder="Select Status"
                      options={[
                        {
                          value: 'PENDING',
                          key: 'PENDING',
                        },
                        {
                          value: 'COMPLETED',
                          key: 'COMPLETED',
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              )}
            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Description is required',
                  },
                ]}
              >
                <TextArea
                  defaultValue={initialData?.description}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Assignees"
                name="assignees"
                rules={[
                  {
                    message: 'Assignees is required',
                    validator: (_, value) => {
                      if (assignees.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                  },
                ]}
              >
                <Avatar.Group>
                  {assignees.map((member, idx) => (
                    <Tooltip
                      key={member.email}
                      title={`${member.first_name} ${member.last_name}`}
                    >
                      <Avatar
                        className="circle-icon"
                        src={makeAvatar(
                          member.first_name[0],
                          member.last_name[0],
                        )}
                      />
                    </Tooltip>
                  ))}
                  <Tooltip title="Add Member">
                    <Popover
                      placement="rightTop"
                      title="Add New Member"
                      content={addMembersContent()}
                      trigger="click"
                    >
                      <Avatar
                        className="circle-icon"
                        style={{
                          color: '#f56a00',
                          backgroundColor: '#fde3cf',
                        }}
                        onClick={() => { }}
                      >
                        <PlusOutlined />
                      </Avatar>
                    </Popover>
                  </Tooltip>
                </Avatar.Group>
              </Form.Item>
            </Col>
            {
              Object.keys(initialData).length ? (
                <>
                  <Col span={12}>
                    <Form.Item
                      label="Due Date"
                      name="due_date"
                      rules={[
                        {
                          required: true,
                          message: 'Date is required',
                        },
                      ]}
                    >
                      <DatePicker
                        format={INPUT_DATE_FORMAT}
                        style={{ width: '100%' }}
                        disabledDate={(current) => moment().add(-1, 'days') >= current}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Completion Date"
                      name="completion_date"
                    >
                      <DatePicker
                        format={INPUT_DATE_FORMAT}
                        style={{ width: '100%' }}
                        disabledDate={(current) => moment().add(-1, 'days') >= current}
                      />
                    </Form.Item>
                  </Col>
                </>
              )
                : (
                  <Col span={24}>
                    <Form.Item
                      label="Due Date"
                      name="due_date"
                      rules={[
                        {
                          required: true,
                          message: 'Date is required',
                        },
                      ]}
                    >
                      <DatePicker
                        format={INPUT_DATE_FORMAT}
                        style={{ width: '100%' }}
                        disabledDate={(current) => moment().add(-1, 'days') >= current}
                      />
                    </Form.Item>
                  </Col>
                )
            }
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default memo(CreateTaskModal);
