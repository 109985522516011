import React from 'react';
import { Avatar } from 'antd';
import { makeAvatar } from '../../../utils';

export default () => React.useMemo(() => [
  {
    key: '0',
    width: 50,
    dataIndex: 'image',
    render: (_, user) => (
      <Avatar
        src={user?.image || makeAvatar(user?.first_name?.[0], user?.last_name?.[0])}
      />
    ),
  },
  {
    key: '1',
    title: 'First Name',
    dataIndex: 'first_name',
  },
  {
    key: '2',
    title: 'Last Name',
    dataIndex: 'last_name',
  },
  {
    key: '3',
    title: 'Email',
    dataIndex: 'email',
  },
]);
