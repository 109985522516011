export const FETCH_BIDDING_PROJECTS_INITIATE = 'FETCH_BIDDING_PROJECTS_INITIATE';
export const FETCH_BIDDING_PROJECTS_SUCCESS = 'FETCH_BIDDING_PROJECTS_SUCCESS';
export const FETCH_BIDDING_PROJECTS_FAILURE = 'FETCH_BIDDING_PROJECTS_FAILURE';

export const FETCH_MAIN_PROJECT_DETAIL_INITIATE = 'FETCH_MAIN_PROJECT_DETAIL_INITIATE';
export const FETCH_MAIN_PROJECT_DETAIL_SUCCESS = 'FETCH_MAIN_PROJECT_DETAIL_SUCCESS';
export const FETCH_MAIN_PROJECT_DETAIL_FAILURE = 'FETCH_MAIN_PROJECT_DETAIL_FAILURE';

export const FETCH_ORGANIZATION_INITIATE = 'FETCH_ORGANIZATION_INITIATE';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATIONFAILURE';

export const CREATE_BIDDING_PROJECT_INITIATE = 'CREATE_BIDDING_PROJECT_INITIATE';
export const CREATE_BIDDING_PROJECT_SUCCESS = 'CREATE_BIDDING_PROJECT_SUCCESS';
export const CREATE_BIDDING_PROJECT_FAILURE = 'CREATE_BIDDING_PROJECT_FAILURE';

export const UPDATE_BIDDING_PROJECT_INITIATE = 'UPDATE_BIDDING_PROJECT_INITIATE';
export const UPDATE_BIDDING_PROJECT_SUCCESS = 'UPDATE_BIDDING_PROJECT_SUCCESS';
export const UPDATE_BIDDING_PROJECT_FAILURE = 'UPDATE_BIDDING_PROJECT_FAILURE';
export const CLEAR_SELECTED_BIDDING_PROJECT = 'CLEAR_SELECTED_BIDDING_PROJECT';

export const SET_BIDDING_ACTIVE_PROJECT = 'SET_BIDDING_ACTIVE_PROJECT';
export const CLEAR_BIDDING_ACTIVE_PROJECT = 'CLEAR_BIDDING_ACTIVE_PROJECT';
export const SET_ACTIVE_CONTRACTOR = 'SET_ACTIVE_CONTRACTOR';
export const CLEAR_ACTIVE_CONTRACTOR = 'CLEAR_ACTIVE_CONTRACTOR';

export const FETCH_BIDDING_VENDOR_ESTIMATIONS_INITIATE =
    'FETCH_BIDDING_VENDOR_ESTIMATIONS_INITIATE';
export const FETCH_BIDDING_VENDOR_ESTIMATIONS_SUCCESS = 'FETCH_BIDDING_VENDOR_ESTIMATIONS_SUCCESS';
export const FETCH_BIDDING_VENDOR_ESTIMATIONS_FAILURE = 'FETCH_BIDDING_VENDOR_ESTIMATIONS_FAILURE';

export const FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_INITATE =
    'FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_INITATE';
export const FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_SUCCESS =
    'FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_SUCCESS';
export const FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_FAILURE =
    'FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_FAILURE';

export const FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_INITIATE =
    'FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_INITIATE';
export const FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_SUCCESS =
    'FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_SUCCESS';
export const FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_FAILURE =
    'FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_FAILURE';

export const FETCH_CONTRACTOR_BY_ID_INITIATE = 'FETCH_CONTRACTOR_BY_ID_INITIATE';
export const FETCH_CONTRACTOR_BY_ID_SUCCESS = 'FETCH_CONTRACTOR_BY_ID_SUCCESS';
export const FETCH_CONTRACTOR_BY_ID_FAILURE = 'FETCH_CONTRACTOR_BY_ID_FAILURE';

export const UPDATE_CONTRACTOR_BY_ID_INITIATE = 'UPDATE_CONTRACTOR_BY_ID_INITIATE';
export const UPDATE_CONTRACTOR_BY_ID_SUCCESS = 'UPDATE_CONTRACTOR_BY_ID_SUCCESS';
export const UPDATE_CONTRACTOR_BY_ID_FAILURE = 'UPDATE_CONTRACTOR_BY_ID_FAILURE';

export const CREATE_CONTRACTOR_INITIATE = 'CREATE_CONTRACTOR_INITIATE';
export const CREATE_CONTRACTOR_SUCCESS = 'CREATE_CONTRACTOR_SUCCESS';
export const CREATE_CONTRACTOR_FAILURE = 'CREATE_CONTRACTOR_FAILURE';

export const FETCH_MAIN_PROJECT_DOCUMENTS_INITIATE = 'FETCH_MAIN_PROJECT_DOCUMENTS_INITIATE';
export const FETCH_MAIN_PROJECT_DOCUMENTS_SUCCESS = 'FETCH_MAIN_PROJECT_DOCUMENTS_SUCCESS';
export const FETCH_MAIN_PROJECT_DOCUMENTS_FAILURE = 'FETCH_MAIN_PROJECT_DOCUMENTS_FAILURE';

export const GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_INITIATE =
    'GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_INITIATE';
export const GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_SUCCESS =
    'GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_SUCCESS';
export const GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_FAILURE =
    'GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_FAILURE';

export const FETCH_CONTRACTOR_DOCUMENTS_INITIATE = 'FETCH_CONTRACTOR_DOCUMENTS_INITIATE';
export const FETCH_CONTRACTOR_DOCUMENTS_SUCCESS = 'FETCH_CONTRACTOR_DOCUMENTS_SUCCESS';
export const FETCH_CONTRACTOR_DOCUMENTS_FAILURE = 'FETCH_CONTRACTOR_DOCUMENTS_FAILURE';

export const GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_INITIATE =
    'GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_INITIATE';
export const GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_SUCCESS =
    'GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_SUCCESS';
export const GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_FAILURE =
    'GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_FAILURE';
