import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Button,
  Input,
  Tooltip,
  Row,
  Col,
  Collapse,
  notification,
  Space,
  Avatar,
  Popconfirm,
  Popover,
  Select,
  Form,
  Card,
  Spin,
} from 'antd';
import {
  SearchOutlined,
  EditOutlined,
  DeleteTwoTone,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  addMemberInPackage,
  deletePackageRoom,
  fetchPackagesSectionWise,
  fetchProjectUsers,
  newfetchProjectDetail,
  newUpdateProject,
  removeMemberFromPackage,
  updatePackageRoom,
} from '../../../services';
import { setPackage, addPackageToList } from '../../../store/package/actions';
import {
  PROJECTS,
  PACKAGE,
  makeAvatar,
  checkPackageManufactures,
  PROJECT_API_ENUM,
  PAGE_SIZE_OPTIONS,
} from '../../../utils';
import CreatePackageModal from './create-package';
import { CustomAPILoader } from '../../../components/loaders';

const { Panel } = Collapse;
const { Option } = Select;

function Packages({
  projectId,
  createPackageVisible = false,
  setCreatePackageVisible = () => { },
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const searchInput = useRef();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchColumn] = useState('');
  const [individualPackages, setIndividualPackages] = useState({ data: [], meta: {} });
  const [roomPackages, setRoomPackages] = useState({ data: [], meta: {} });
  const [pkgsWithoutApprovalDwg, setPkgsWithoutApprovalDwg] = useState({ data: [], meta: {} });
  const [createPkgLoading, setCreatePkgLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activePackage, setActivePackage] = useState({});
  const [displayAddMembers, setDisplayAddMembers] = useState('');
  const [users, setUsers] = useState([]);
  const [pagePackageSize, setPackagePageSize] = useState(5);
  const [pageWithAdSize, setWithAdPageSize] = useState(5);
  const [pageWithoutAdSize, setWithoutAdPageSize] = useState(5);

  const [isPackagesRoomFetching, setPackagesRoomFetching] = useState(false);
  const [isEqpmtWithApprvlFetching, setEqpmtWithApprvlFetching] = useState(false);
  const [isEqpmtWithoutApprvlFetching, setEqpmtWithoutApprvlFetching] = useState(false);

  const [packagesRoomPage, setPackagesRoomPage] = useState(5);
  const [EqpmtWithApprvlPage, setEqpmtWithApprvlPage] = useState(5);
  const [EqpmtWithoutApprvlPage, setEqpmtWithoutApprvlPage] = useState(5);

  const showNotification = (message = '', type = 'success', placement = 'topRight') => {
    notification[type]({
      message,
      placement,
    });
  };

  const getProjectUsers = async () => {
    try {
      const response = await fetchProjectUsers(projectId);
      setUsers([...response?.data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectUsers();
  }, []);

  const handlePageSizeChange = async (current, pageSize, section) => {
    if (section === 'room_packages') {
      setPackagePageSize(pageSize);
    } else if (section === 'individual_packages') {
      setWithAdPageSize(pageSize);
    } else {
      setWithoutAdPageSize(pageSize);
    }
  };

  const getPackages = async (section, page = 1, pageSize = 5) => {
    if (section === 'room_packages') {
      setPackagesRoomFetching(true);
      const response = await fetchPackagesSectionWise(projectId, section, page, pageSize);
      if (response.success) {
        setPackagesRoomPage(page);
        setRoomPackages({ data: [...response?.data], meta: { ...response.meta } });
        setPackagesRoomFetching(false);
      } else {
        setPackagesRoomFetching(false);
      }
    } else if (section === 'individual_packages') {
      setEqpmtWithApprvlFetching(true);
      const response = await fetchPackagesSectionWise(projectId, section, page, pageSize);
      if (response.success) {
        setEqpmtWithApprvlPage(page);
      }
      setIndividualPackages({ data: [...response?.data], meta: { ...response.meta } });
      setEqpmtWithApprvlFetching(false);
    } else {
      setEqpmtWithoutApprvlFetching(true);
      const response = await fetchPackagesSectionWise(projectId, section, page, pageSize);
      if (response.success) {
        setEqpmtWithoutApprvlPage(page);
      }
      setPkgsWithoutApprovalDwg({ data: [...response?.data], meta: { ...response.meta } });
      setEqpmtWithoutApprvlFetching(false);
    }
  };

  const onPageChange = async (page, pageSize, section) => {
    await getPackages(section, page, pageSize);
  };

  const handleEdit = (record) => {
    setActivePackage({ ...record });
    setCreatePackageVisible(true);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const onCreatePackage = async (data, cb) => {
    try {
      let allTeamMembers = [];
      data.tags.forEach(({ team_members }) => {
        allTeamMembers = [...allTeamMembers, ...team_members];
      });
      const team_members = _.uniqBy(allTeamMembers, 'id');
      data.team_members = team_members.map(({ id }) => id);
      data.tags = data.tags.map(({ id }) => id);
      setCreatePkgLoading(true);
      const response = await newUpdateProject(PROJECT_API_ENUM.PACKAGES, projectId, data);
      dispatch(addPackageToList(response?.data));
      showNotification('Package successfully created');
    } catch (error) {
      console.log(error);
    } finally {
      setCreatePkgLoading(false);
      setCreatePackageVisible(false);
      if (cb) {
        cb();
      }
      getPackages();
    }
  };

  const onUpdatePackage = async (data, cb) => {
    try {
      const allTeamMembers = [...data.team_members, ...activePackage.team_members];
      const team_members = _.uniqBy(allTeamMembers, 'id');
      data.team_members = team_members.map(({ id }) => id);
      data.tags = data.tags.map(({ id }) => id);
      setCreatePkgLoading(true);
      const response = await updatePackageRoom(projectId, activePackage.id, data);
      showNotification('Package successfully updated');
    } catch (ex) {
      throw (ex);
    } finally {
      setCreatePkgLoading(false);
      setCreatePackageVisible(false);
      if (cb) {
        cb();
      }
      getPackages();
    }
  };

  const onDeletePackage = async (pkgId) => {
    try {
      setLoading(true);
      const response = await deletePackageRoom(projectId, pkgId);
      if (response) {
        showNotification('Package successfully deleted');
      }
    } catch (ex) {
      throw (ex);
    } finally {
      getPackages();
    }
  };

  const onCancel = () => {
    setActivePackage({});
    setCreatePackageVisible(false);
  };

  const onAddMemberInPackage = async (values) => {
    try {
      const { pkg, member_id } = values;
      const user = users.find((user) => user.id === member_id);
      const isUserExist = pkg.team_members.some((team_member) => team_member.id === user.id);
      if (isUserExist) {
        form.setFields([{ name: 'member_id', errors: ['User already exist'] }]);
        return;
      }
      setLoading(true);
      const response = await addMemberInPackage(projectId, pkg.id, member_id);
      showNotification(response.message);
      setDisplayAddMembers('');
      form.resetFields();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const addMembersContent = (pkg) => (
    <Form
      form={form}
      style={{ width: 200 }}
      layout="vertical"
      onFinish={(values) => onAddMemberInPackage({ ...values, pkg })}
    >
      <Form.Item name="member_id" rules={[{ required: true, message: 'Please select' }]}>
        <Select
          showSearch
          placeholder="Please select"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          allowClear
          style={{ width: '100%' }}
        >
          {users.map((user) => (
            <Option key={user.id} value={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className="text-right mt-15">
        <Space>
          <Button type="default" onClick={() => setDisplayAddMembers('')}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Space>
      </div>
    </Form>
  );

  const removeMember = async (memberId, pkgId, section) => {
    try {
      setLoading(true);
      const response = await removeMemberFromPackage(projectId, pkgId, memberId);
      showNotification(response.message);
      setLoading(false);
      setDisplayAddMembers('');
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) { showNotification(error.response?.data.message, 'error'); }
    }
  };

  const goToPackageDetail = (record) => ({
    onClick: () => {
      const id = _.has(record, 'packageroom') ? record.tags[0].packageroom[0] : record.id;
      const url = `${PROJECTS}/${projectId}${PACKAGE}/${id}`;
      history.push(url);
    },
  });

  const individualPackageColumns = [
    {
      title: '',
      dataIndex: 'warning',
      width: 0,
      render: (_, record) => checkPackageManufactures(record),
    },
    {
      title: 'Tag Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (text) => ({
        props: {
          className: 'cursor-pointer',
        },
        children: text,
      }),
      onCell: goToPackageDetail,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => ({
        props: {
          className: 'description-td cursor-pointer',
        },
        children: (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      }),
      onCell: goToPackageDetail,
    },
    {
      title: 'Team Members',
      dataIndex: 'team_members',
      key: 'team_members',
      render: (team_members, record) => team_members && (
        <div className="package-team-members">
          <Avatar.Group>
            {team_members.map((member, idx) => (
              <Tooltip
                key={member.email}
                title={`${member.first_name} ${member.last_name}`}
              >
                <Avatar
                  className="circle-icon"
                  src={
                    member.image
                    || makeAvatar(
                      member.first_name[0],
                      member.last_name[0],
                    )
                  }
                />
              </Tooltip>
            ))}
            {/* {Permissions.can_edit_packages(permissions) && (
                                <Tooltip title="Add Member">
                                    <Popover
                                        placement="rightTop"
                                        title="Add New Member"
                                        content={addMembersContent({
                                            ...record,
                                            id: record?.tags[0].packageroom[0],
                                        })}
                                        trigger="click"
                                        visible={displayAddMembers === record.id}
                                    >
                                        <Avatar
                                            className="circle-icon"
                                            style={{
                                                color: '#f56a00',
                                                backgroundColor: '#fde3cf',
                                            }}
                                            onClick={() => setDisplayAddMembers(record.id)}
                                        >
                                            <PlusOutlined />
                                        </Avatar>
                                    </Popover>
                                </Tooltip>
                            )} */}
          </Avatar.Group>
        </div>
      ),
    },
  ];

  const groupsPackageColumns = [
    {
      title: '',
      dataIndex: 'warning',
      width: 0,
      render: (_, record) => checkPackageManufactures(record),
    },
    {
      title: 'Room Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (text) => ({
        props: {
          className: 'cursor-pointer',
        },
        children: text,
      }),
      onCell: goToPackageDetail,
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (text, record) => ({
        props: {
          className: 'description-td cursor-pointer',
        },
        children: (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      }),
      onCell: goToPackageDetail,
    },
    {
      title: 'Total Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, record) => ({
        props: {
          className: 'cursor-pointer',
        },
        children: record.tags.length,
      }),
      onCell: goToPackageDetail,
    },
    {
      title: 'Team Members',
      dataIndex: 'team_members',
      key: 'team_members',
      render: (team_members, record) => team_members && (
        <div className="package-team-members">
          <Avatar.Group>
            {team_members.map((member, idx) => (
              <Tooltip
                key={member.email}
                title={`${member.first_name} ${member.last_name}`}
              >
                <Popconfirm
                  onConfirm={() => removeMember(member.id, record.id)}
                  title="Are your want to remove this user?"
                >
                  <Avatar
                    className="circle-icon"
                    src={
                      member.image
                      || makeAvatar(
                        member.first_name[0],
                        member.last_name[0],
                      )
                    }
                  />
                </Popconfirm>
              </Tooltip>
            ))}
          </Avatar.Group>
        </div>
      ),
    },
  ];

  const nestedTagsColumn = [
    {
      title: 'Tag Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => ({
        props: {
          className: 'description-td',
        },
        children: (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      }),
    },
    {
      title: 'Approval',
      dataIndex: 'Approval',
      key: 'approval_dwg',
      render: (value, record) => <p>{record?.approval_dwg ? 'Yes' : 'No'}</p>,
    },
  ];

  return (
    <>
      {!loading ? (
        <>
          <Collapse
            className="packages-collapse alectify-collapse"
            onChange={(e) => {
              getPackages('room_packages');
            }}
          >
            <Panel header="Package Rooms" key="1">
              {isPackagesRoomFetching ? (
                <CustomAPILoader text="Fetching items..." />
              ) : (
                <Table
                  size="small"
                  loading={isPackagesRoomFetching}
                  columns={groupsPackageColumns}
                  pagination={{
                    total: roomPackages.meta.total_count,
                    onShowSizeChange: (current, pageSize) => handlePageSizeChange(current, pageSize, 'room_packages'),
                    onChange: (page, pageSize) => onPageChange(page, pageSize, 'room_packages'),
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageSize: pagePackageSize,
                    showSizeChanger: true,
                    current: packagesRoomPage,
                  }}
                  className="taglist-table"
                  expandable={{
                    expandedRowRender: (record) => (
                      <Table
                        rowKey="id"
                        columns={nestedTagsColumn}
                        dataSource={record.tags}
                        className="ant-table-container"
                        pagination={{
                          total: record.tags.length,
                          showSizeChanger: true,
                        }}
                      />
                    ),
                  }}
                  dataSource={roomPackages.data.map((room) => ({
                    ...room,
                    key: room?.id,
                  }))}
                />
              )}
            </Panel>
          </Collapse>
          <Collapse
            className="packages-collapse alectify-collapse"
            onChange={() => getPackages('individual_packages')}
          >
            <Panel
              header={
                'Equipment with Approval Drawings'
              }
              key="2"
            >
              {isEqpmtWithApprvlFetching ? (
                <CustomAPILoader text="Fetching items..." />
              ) : (
                <Table
                  size="small"
                  columns={individualPackageColumns}
                  dataSource={individualPackages.data.map((tag) => ({
                    ...tag,
                    key: tag.id,
                  }))}
                  className="taglist-table"
                  loading={isEqpmtWithApprvlFetching}
                  pagination={{
                    total: individualPackages.meta.total_count,
                    onChange: (page, pageSize) => onPageChange(page, pageSize, 'individual_packages'),
                    pageSize: pageWithAdSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    onShowSizeChange: (current, pageSize) => handlePageSizeChange(current, pageSize, 'individual_packages'),
                    showSizeChanger: true,
                    current: EqpmtWithApprvlPage,
                  }}
                />
              )}
            </Panel>
          </Collapse>
          <Collapse
            className="packages-collapse alectify-collapse"
            onChange={() => getPackages('packages_without_approval_dwg')}
          >
            <Panel
              header={'Equipment without Approval Drawings'}
              key="3"
            >
              {isEqpmtWithoutApprvlFetching ? (
                <CustomAPILoader text="Fetching items..." />
              ) : (
                <Table
                  size="small"
                  columns={individualPackageColumns}
                  dataSource={pkgsWithoutApprovalDwg.data.map((tag) => ({
                    ...tag,
                    key: tag.id,
                  }))}
                  className="taglist-table"
                  loading={loading}
                  pagination={{
                    total: pkgsWithoutApprovalDwg.meta.total_count,
                    onChange: (page, pageSize) => onPageChange(page, pageSize, 'packages_without_approval_dwg'),
                    onShowSizeChange: (current, pageSize) => handlePageSizeChange(current, pageSize, 'packages_without_approval_dwg'),
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageSize: pageWithoutAdSize,
                    showSizeChanger: true,
                    current: EqpmtWithoutApprvlPage,
                  }}
                />
              )}
            </Panel>
          </Collapse>
        </>
      ) : (
        <Card loading bordered={false} style={{ background: 'transparent' }} />
      )}

      {createPackageVisible && (
        <CreatePackageModal
          title={`${activePackage ? 'Update' : 'Create'} Package`}
          visible={createPackageVisible}
          individualRoomsData={individualPackages}
          loading={createPkgLoading}
          initialData={{ ...activePackage }}
          onSubmit={onCreatePackage}
          onUpdate={onUpdatePackage}
          onCancel={onCancel}
        />
      )}
    </>
  );
}

export default Packages;
