import { memo } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import Activites from './Activites';

const Activity = memo(() => {
    const { subProjectId } = useParams();
    return (
        <Row className="row-body">
            <Col span={24}>
                <div>
                    <Activites
                        projectId={subProjectId}
                        getAllProjects={() => {}}
                        getNotificationsCount={() => {}}
                    />
                </div>
            </Col>
        </Row>
    );
});

export default Activity;
