import { memo } from 'react';
import { Col, Row } from 'antd';
import PurchaseOrderForm from './PurchaseOrderForm';
import CurrentPurchaseOrderInfo from './CurrentPurchaseOrderInfo';

const CustomerPurchaseOrder = memo(() => {
    return (
        <Row gutter={30} className="mb-30">
            {/* <Col span={8}>
                <CurrentPurchaseOrderInfo />
            </Col> */}
            <Col span={24}>
                <PurchaseOrderForm />
            </Col>
        </Row>
    );
});

export default CustomerPurchaseOrder;
