import _ from 'lodash';
import { Tooltip, Avatar, Space } from 'antd';
import { PACKAGES_TYPES } from '../../../utils';

export const searchPackageColumns = ({
  downloadDocument = () => { },
}) => [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Type',
      dataIndex: 'package_type',
      render: (package_type) => PACKAGES_TYPES[package_type],
    },
  ];
