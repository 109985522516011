import { useState, memo } from 'react';
import { Button, Form, Input, message } from 'antd';
import { changePassword } from '../../../../services';

const ChangePassword = memo(() => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    const validatePassowrd = (text, getFieldValue) => {
        return !text || getFieldValue('new_password') === text;
    };

    const onChangePassword = async (values) => {
        try {
            setSubmitting(true);
            const response = await changePassword(values);
            message.success(response?.message);
            form.resetFields();
            setSubmitting(false);
        } catch (error) {
            message.error('Failed to update password');
            setSubmitting(false);
        }
    };

    return (
        <>
            <h1 className="mb-20">Change Password</h1>
            <Form form={form} layout="vertical" onFinish={onChangePassword} style={{ width: '80%' }}>
                <Form.Item
                    label="Current Password"
                    name="old_password"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                        {
                            min: 6,
                            message: 'Password must contain atleast 6 characters.',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Current Password" />
                </Form.Item>
                <Form.Item
                    label="New Password"
                    name="new_password"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                        {
                            min: 6,
                            message: 'Password must contain atleast 6 characters.',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="New Password" />
                </Form.Item>
                <Form.Item
                    label="Confirm New Password"
                    name="verify_new_password"
                    dependencies={['new_password']}
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                        {
                            min: 6,
                            message: 'Password must contain atleast 6 characters.',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (validatePassowrd(value, getFieldValue)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error('The two passwords that you entered do not match!'),
                                );
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Confirm New Password" />
                </Form.Item>
                <Button type="primary" htmlType="submit" loading={submitting} className="mt-10">
                    Change Password
                </Button>
            </Form>
        </>
    );
});

export default ChangePassword;
