import { RESET_STATE } from '../common';
import {
  FETCH_SINGLELINES_FAILURE,
  FETCH_SINGLELINES_INTIATE,
  FETCH_SINGLELINES_SUCCESS,
  FETCH_SINGLELINE_DETAIL_FAILURE,
  FETCH_SINGLELINE_DETAIL_INITIATE,
  FETCH_SINGLELINE_DETAIL_SUCCESS,
  UPDATE_TAG_INITIATE,
  UPDATE_TAG_SUCCESS,
  HIDE_LOADER,
} from './types';

const initialState = {
  list: [],
  loading: false,
  error: null,
  detail: {},
};

export const singlelineReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SINGLELINES_INTIATE:
    case FETCH_SINGLELINE_DETAIL_INITIATE:
    case UPDATE_TAG_INITIATE:
      return { ...state, loading: true };

    case FETCH_SINGLELINES_SUCCESS:
      return { ...state, loading: false, list: payload };

    case FETCH_SINGLELINE_DETAIL_SUCCESS:
      return { ...state, loading: false, detail: payload };

    case FETCH_SINGLELINES_FAILURE:
    case FETCH_SINGLELINE_DETAIL_FAILURE:
      return {
        ...state, loading: false, list: [], error: payload,
      };

    case HIDE_LOADER:
      return { ...state, loading: false };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
