import { useParams } from 'react-router-dom';
import { Button, Tabs, Card, Tag } from 'antd';
import { memo, useState, useEffect } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { fetchMasterProjectsDocumentsCount } from '../../../../../services';
import VendorQuotes from './vendor-quotes';
import VendorInvoices from './vendor-invoices';
import CustomerInvoices from './customer-invoices';
import MasterBidDocuments from './bid-documents';
import ContractorDocuments from './contractor-documents';
import DownloadDigitalBinder from './DownloadDigitalBinder';
import CustomerPurchaseOrderDocuments from './customer-po-documents';

const Documents = memo(() => {
    const { projectId } = useParams();
    const [analytics, setAnalytics] = useState({});
    const [visible, setVisible] = useState(false);

    const getCount = (key) => {
        return `(${analytics[key] || 0})`;
    };

    const tabItems = [
        {
            key: 'vendor-quotes',
            label: `Vendor Quotes ${getCount('vendor_quotes_docs_count')}`,
            children: <VendorQuotes />,
        },
        {
            key: 'customer-pos',
            label: `Customer POs ${getCount('customer_po_docs_count')}`,
            children: <CustomerPurchaseOrderDocuments />,
            disabled: true,
        },
        {
            key: 'vendor-invoices',
            label: `Vendor Invoices ${getCount('vendor_invoice_docs_count')}`,
            children: <VendorInvoices />,
        },
        {
            key: 'customer-invoices',
            label: `Customer Invoices ${getCount('customer_invoice_docs_count')}`,
            children: <CustomerInvoices />,
        },
        {
            key: 'bid-documents',
            label: `Master Bid Documents ${getCount('master_bid_docs_count')}`,
            children: <MasterBidDocuments />,
        },
        {
            key: 'contract-documents',
            label: `Contractor Specific Documents ${getCount('contractor_bid_docs_count')}`,
            children: <ContractorDocuments />,
        },
        {
            key: 'email-attachments',
            label: `Email Attachments ${getCount('email_attachments')}`,
            children: <VendorInvoices />,
            disabled: true,
        },
    ];

    const getDocumentsCount = async () => {
        try {
            const response = await fetchMasterProjectsDocumentsCount(projectId);
            setAnalytics({ ...response.data });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getDocumentsCount();
    }, [projectId]);

    return (
        <>
            <Card
                title={
                    <>
                        All Documents <Tag color="green">Read Only</Tag>
                    </>
                }
                // extra={
                //     <Button onClick={() => setVisible(true)} className="mb-10 justify-end">
                //         <DownloadOutlined /> Download Digital Binder
                //     </Button>
                // }
            >
                <Tabs tabPosition="left" items={tabItems} className="document-tabs bg-white" />
                <DownloadDigitalBinder visible={visible} onCancel={() => setVisible(false)} />
            </Card>
        </>
    );
});

export default Documents;
