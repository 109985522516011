export const FETCH_SUMMARY_INITATE = 'FETCH_SUMMARY_INITATE';
export const FETCH_SUMMARY_SUCCESS = 'FETCH_SUMMARY_SUCCESS';
export const FETCH_SUMMARY_FAILURE = 'FETCH_SUMMARY_FAILURE';

export const FETCH_VENDOR_QUOTES_SUMMARY_INITATE = 'FETCH_VENDOR_QUOTES_SUMMARY_INITATE';
export const FETCH_VENDOR_QUOTES_SUMMARY_SUCCESS = 'FETCH_VENDOR_QUOTES_SUMMARY_SUCCESS';
export const FETCH_VENDOR_QUOTES_SUMMARY_FAILURE = 'FETCH_VENDOR_QUOTES_SUMMARY_FAILURE';

export const FETCH_VENDOR_ESTIMATIONS_INITIATE = 'FETCH_VENDOR_ESTIMATIONS_INITIATE';
export const FETCH_VENDOR_ESTIMATIONS_SUCCESS = 'FETCH_VENDOR_ESTIMATIONS_SUCCESS';
export const FETCH_VENDOR_ESTIMATIONS_FAILURE = 'FETCH_VENDOR_ESTIMATIONS_FAILURE';

export const DELETE_VENDOR_ESTIMATIONS_INITIATE = 'DELETE_VENDOR_ESTIMATIONS_INITIATE';
export const DELETE_VENDOR_ESTIMATIONS_SUCCESS = 'DELETE_VENDOR_ESTIMATIONS_SUCCESS';
export const DELETE_VENDOR_ESTIMATIONS_FAILURE = 'DELETE_VENDOR_ESTIMATIONS_FAILURE';

export const FETCH_VENDOR_ASSOCIATES_INITIATE = 'FETCH_VENDOR_ASSOCIATES_INITIATE';
export const FETCH_VENDOR_ASSOCIATES_SUCCESS = 'FETCH_VENDOR_ASSOCIATES_SUCCESS';
export const FETCH_VENDOR_ASSOCIATES_FAILURE = 'FETCH_VENDOR_ASSOCIATES_FAILURE';

export const DELETE_VENDOR_ASSOCIATES_INITIATE = 'DELETE_VENDOR_ASSOCIATES_INITIATE';
export const DELETE_VENDOR_ASSOCIATES_SUCCESS = 'DELETE_VENDOR_ASSOCIATES_SUCCESS';
export const DELETE_VENDOR_ASSOCIATES_FAILURE = 'DELETE_VENDOR_ASSOCIATES_FAILURE';

export const FETCH_VENDOR_ESTIMATION_DETAIL_INITIATE = 'FETCH_VENDOR_ESTIMATION_DETAIL_INITIATE';
export const FETCH_VENDOR_ESTIMATION_DETAIL_SUCCESS = 'FETCH_VENDOR_ESTIMATION_DETAIL_SUCCESS';
export const FETCH_VENDOR_ESTIMATION_DETAIL_FAILURE = 'FETCH_VENDOR_ESTIMATION_DETAIL_FAILURE';

export const SET_FINANCIAL_VERTICAL_TYPE = 'SET_FINANCIAL_VERTICAL_TYPE';
