import _ from 'lodash';
import { memo, useEffect, useState } from 'react';
import { Form, Modal, Input, Spin, notification } from 'antd';
import { DragAndDropContainer } from '../../../../components';
import '../../../../assets/styles/components/package-review.scss';

const { TextArea } = Input;

function UpdatePackage({
    title = '',
    onSubmit = () => {},
    onUpdate = () => {},
    onCancel = () => {},
    visible = false,
    individualRoomsData = [],
    loading = false,
    initialData = {},
    projectSetup = false,
    getAllPackages = () => {},
    validatePackageName = () => {},
}) {
    const [items, setItems] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getAllPackages();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(initialData)) {
            setItems([...initialData.tags]);
            form.setFieldsValue(JSON.parse(JSON.stringify(initialData)));
        } else {
            setItems([]);
            form.resetFields();
        }
    }, [initialData]);

    const onSubmitHandler = (values) => {
        if (!_.isEmpty(initialData)) {
            values.tags = items.map(({ id }) => id);

            if (values.tags.length === 0) {
                notification.error({
                    message: 'Selected tags cannot be empty',
                });

                return false;
            }

            // if (values.tags.length > 0 && values.tags.length < 1) {
            //     notification.error({
            //         message: 'Selected tags must be more than 1',
            //     });

            //     return false;
            // }

            if (values.tags.length && values.tags.length !== 0 && values.tags.length >= 1) {
                onSubmit(values, form.resetFields);
            }
        } else {
            onSubmit(values, form.resetFields);
        }
    };

    const cancelHandler = () => {
        form.resetFields();
        onCancel();
    };

    const getInnerSelectedData = (data) => {
        if (data) {
            setItems(data);
        }
    };

    return (
        <Modal
            title={title}
            visible={visible}
            onOk={form.submit}
            onCancel={cancelHandler}
            confirmLoading={loading}
            okText={!_.isEmpty(initialData) ? 'Update' : 'Save'}
            style={{ minWidth: '1000px !important' }}
            className="review-room-modal"
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical" onFinish={onSubmitHandler} initialValues={{}}>
                    <Form.Item
                        label="Package Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Package name is required',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: false,
                                message: 'Description is required',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item name="tags" className="mb-0">
                        <DragAndDropContainer
                            items={individualRoomsData}
                            selectedItems={!_.isEmpty(initialData) ? [...initialData.tags] : []}
                            getData={getInnerSelectedData}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}

export default memo(UpdatePackage);
