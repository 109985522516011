import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Spin } from 'antd';
import {
    DISPLAY_DATE_FORMAT,
    PROJECT_COST_REASONS,
    renderAmount,
} from '../../../../../../utils';
import { AlectifyTable } from '../../../../../../components';
import useDocumentColumns from './effects/useDocumentColumns';
import { fetchContractorVendorEstimatesById } from '../../../../../../services/bidding-management';

const VendorQuoteDetail = (props) => {
    const columns = useDocumentColumns();
    const { contractorId } = useParams();
    const [documents, setDocuments] = useState({ data: [], fetching: false, total: 0 });

    const getDetails = async () => {
        try {
            setDocuments({
                ...documents,
                fetching: true,
            });
            const response = await fetchContractorVendorEstimatesById(contractorId, props.data.id);
            setDocuments({
                ...documents,
                data: response?.data?.documents || [],
                fetching: false,
            });
        } catch (ex) {
            throw ex;
        }
    };

    useEffect(getDetails, []);

    return (
        <Modal
            centered
            width={900}
            footer={false}
            open={props.visible}
            onCancel={props.onCancel}
            title="Vendor Quote Detail"
            className="equipment-detail-modal"
        >
            <Card title="Information" className="mb-20">
                <Row className="details-view" gutter={30}>
                    <Col span={8}>
                        <label>Date:</label>
                        <span>
                            {moment(props.data?.entry_date).format(DISPLAY_DATE_FORMAT) || '-'}
                        </span>
                    </Col>
                    <Col span={8}>
                        <label>Quote #:</label>
                        <span>{props.data?.vendor_quote_no || '-'}</span>
                    </Col>
                    <Col span={8}>
                        <label>Total Sell:</label>
                        <span>{renderAmount(props.data?.total_sell)}</span>
                    </Col>
                    <Col span={8}>
                        <label>Total Cost:</label>
                        <span>{renderAmount(props.data?.total_cost)}</span>
                    </Col>
                    <Col span={8}>
                        <label>Avg. Margin:</label>
                        <span>{props.data?.margin_percent || 0} %</span>
                    </Col>
                </Row>
            </Card>
            <Card title="Attachment(s)">
                <Spin spinning={documents.fetching}>
                    <AlectifyTable
                        size="small"
                        columns={columns}
                        loading={false}
                        selectRows={false}
                        total={documents.total}
                        dataSource={documents.data}
                        showPagination={false}
                    />
                </Spin>
            </Card>
        </Modal>
    );
};

VendorQuoteDetail.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
};

VendorQuoteDetail.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default memo(VendorQuoteDetail);
