import { Card, Tabs } from 'antd';
import { useState } from 'react';
import AccountInformation from './account-information';
import ChangePassword from './change-password';

const ProfileSettingTabs = () => {
    const [activeTab, setActiveTab] = useState('account-info');

    const tabItems = [
        {
            key: 'account-info',
            label: 'Account',
            children: <AccountInformation />,
        },
        {
            key: 'change-password',
            label: 'Change Password',
            children: <ChangePassword />,
        },
    ];

    return (
        <div className="profile-settings-tabs">
           <Card title="Profile Settings">
           <Tabs
                animated={false}
                items={tabItems}
                activeKey={activeTab}
                onChange={setActiveTab}
                destroyInactiveTabPane
                tabPosition="left"
            />
           </Card>
        </div>
    );
};

export default ProfileSettingTabs;
