import { Card } from 'antd';

import detector from '../../../../assets/images/eaton_lv_swgr.gif';
import goodbox from '../../../../assets/images/goodbox.jpeg';
import badbox from '../../../../assets/images/badbox.jpeg';
import './analytics.scss';

function Analytics() {
  return (
    <>
      <Card title="Automatic Object Detection">
        <img src={detector} style={{ height: 700 }} />
      </Card>
      <Card title="Damage Detection">
        <img src={goodbox} style={{ width: 400 }} />
        <img src={badbox} style={{ width: 400 }} />
      </Card>
    </>
  );
}

export default Analytics;
