import * as ActionTypes from './types';

const initialState = {
  documents: [],
  project_documents_loading: false,
  equipment_documents_loading: false,
  review_documents_loading: false,
  file_history_loading: false,
  project_documents: {},
  equipment_documents: {},
  review_documents: {},
  file_history: {},
};

export const documentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FETCH_PROJECT_DOCUMENTS:
      return { ...state, documents: [...payload] };
    case ActionTypes.FETCH_PROJECT_DOCUMENTS_V2_INIT:
      return { ...state, project_documents_loading: true };
    case ActionTypes.FETCH_PROJECT_DOCUMENTS_V2:
      return { ...state, project_documents: payload, project_documents_loading: false };
    case ActionTypes.FETCH_EQUIPMENT_DOCUMENTS_INIT:
      return { ...state, equipment_documents_loading: true };
    case ActionTypes.FETCH_EQUIPMENT_DOCUMENTS:
      return { ...state, equipment_documents: payload, equipment_documents_loading: false };
    case ActionTypes.FETCH_REVIEW_DOCUMENTS_INIT:
      return { ...state, review_documents_loading: true };
    case ActionTypes.FETCH_REVIEW_DOCUMENTS:
      return { ...state, review_documents: payload, review_documents_loading: false };
    case ActionTypes.FETCH_SHARED_DOCUMENTS_INIT:
      return { ...state, [`${payload?.type}_documents_loading`]: true, [`${payload?.type}_documents`]: {} };
    case ActionTypes.FETCH_SHARED_DOCUMENTS:
      return { ...state, [`${payload?.type}_documents`]: payload, [`${payload?.type}_documents_loading`]: false, equipment_documents_loading: false };
    case ActionTypes.FETCH_DOCUMENT_HISTORY_INIT:
      return { ...state, file_history: {}, file_history_loading: true };
    case ActionTypes.FETCH_DOCUMENT_HISTORY_SUCCESS:
      return { ...state, file_history: payload, file_history_loading: false };
    case ActionTypes.RESET_STATE:
    case ActionTypes.CLEAR_DOCUMENT_STATE:
      return initialState;

    default:
      return state;
  }
};
