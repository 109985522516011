import GoogleMapReact from 'google-map-react';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { memo, useState, useEffect } from 'react';
import { PROJECTS } from '../../utils';
import { Marker } from '../../components';
import { fetchProjects } from '../../services';
import { mapViewDisplay, setActiveProject } from '../../store/common';

const ProjectMapListing = (props) => {
    const dispatch = useDispatch();
    const [center, setCenter] = useState([37.0902, -95.7129]);
    const [projects, setProjects] = useState({ fetching: false, data: [] });

    const getProjects = async () => {
        try {
            setProjects({ ...projects, fetching: true });
            const response = await fetchProjects({ per_page: 50, page: 1, active: true });
            setProjects({ fetching: false, data: response.data });
        } catch (error) {
            setProjects({ ...projects, fetching: false });
        }
    };

    useEffect(() => {
        getProjects();
    }, []);

    return (
        <Spin spinning={projects.fetching}>
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                        libraries: ['places', 'geometry'],
                    }}
                    zoom={4}
                    center={center}
                >
                    {projects.data.map((masterProject) => (
                        <Marker
                            key={masterProject?.id}
                            lat={masterProject?.latitude}
                            lng={masterProject?.longitude}
                            onClick={() => {
                                dispatch(mapViewDisplay(false));
                                dispatch(setActiveProject({ ...masterProject, masterProject, type: PROJECT_TYPES.MASTER_PROJECT }));
                                props.history.push(`${PROJECTS}/${masterProject?.id}`);
                            }}
                            text={masterProject?.name}
                            description={masterProject?.description}
                        />
                    ))}
                </GoogleMapReact>
            </div>
        </Spin>
    );
};

export default memo(ProjectMapListing);
