import React from 'react';
import { Avatar, Popconfirm, Space, Tooltip } from 'antd';
import { makeAvatar } from '../../../utils';
import { DeleteTwoTone, EditOutlined } from '@ant-design/icons';

export default (onActionClick = () => {}) =>
    React.useMemo(() => [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'first_name',
            isSearchable: true,
            render: (_, record) => (
                <Space>
                    <Avatar
                        className="circle-icon"
                        src={record?.image || makeAvatar(record.first_name[0], record.last_name[0])}
                    />
                    <p className="text-body mb-0">
                        {record.first_name} {record.last_name}
                    </p>
                </Space>
            ),
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'organization_type',
        //     render: (organization_type) => organization_type?.name || '-',
        // },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
            render: (value) => value || '-',
        },
        {
            key: 'contact_number',
            title: 'Contact number',
            dataIndex: 'contact_number',
            render: (value) => value || '-',
        },
        {
            key: "organization",
            title: 'Organization',
            dataIndex: 'organization',
            render: (organization) => organization?.name || '-',
        },
        {
            key: "job_title",
            title: 'Job Title',
            dataIndex: 'job_title',
            render: (value) => value || '-',
        },
        {
            key: 'action',
            align: 'center',
            title: 'Actions',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size={10}>
                    <Tooltip title="Edit">
                        <EditOutlined
                            style={{ fontSize: 17 }}
                            onClick={() => onActionClick(true, record, 'edit')}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ]);
