import React, { useEffect, useState } from 'react';
import { Spin, Row, Col, Form, Button, Input, AutoComplete } from 'antd';
import SearchAddress from '../../../master-projects/create-edit/SearchAddress';
import GoogleMapReact from 'google-map-react';
import { Marker } from '../../../../components';

const BasicInfo = ({ onFinish, projectId = null, organizations, loader, type, project }) => {
    const [form] = Form.useForm();
    const [apiReady, setApiReady] = useState(false);
    const [center, setCenter] = useState([]);
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [mapApi, setMapApi] = useState(null);
    const [address, setAddress] = useState(null);
    const [map, setMap] = useState(null);

    const onSubmit = async (values) => {
        values.latitude = lat;
        values.longitude = lng;
        onFinish(values);
    };

    const _onChange = ({ center, zoom }) => {
        setCenter(center);
    };
    const _onClick = (value) => {
        const { lat, lng } = value;
        setLat(lat);
        setLng(lng);
        _generateAddress(lat, lng);
    };

    const handleApiLoaded = (map, maps) => {
        if (map && maps) {
            setApiReady(true);
            setMap(map);
            setMapApi(maps);
        }
    };

    const setCurrentLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCenter([position.coords.latitude, position.coords.longitude]);
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
            });
        }
    };

    const _generateAddress = (_lat, _lng) => {
        const geocoder = new mapApi.Geocoder();
        geocoder.geocode({ location: { lat: _lat, lng: _lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    setAddress(results[0].formatted_address);
                    form.setFieldsValue({ address: results[0].formatted_address });
                } else {
                }
            } else {
            }
        });
    };

    const addPlace = (place) => {
        setLat(place.geometry.location.lat());
        setLng(place.geometry.location.lng());
        setAddress(place.formatted_address);
        form.setFieldsValue({
            address: place.formatted_address,
        });
    };

    useEffect(() => {
        if (project) {
            form.setFieldsValue({
                ...project,
            });
            if (project?.latitude && project?.longitude) {
                setCenter([project.latitude, project.longitude]);
                setLat(project.latitude);
                setLng(project.longitude);
            } else {
                setCurrentLocation();
            }
        }
        if (project?.latitude && project?.longitude) {
            setCenter([project.latitude, project.longitude]);
            setLat(project.latitude);
            setLng(project.longitude);
        } else {
            setCurrentLocation();
        }
        if (project?.latitude && project?.longitude) {
            setCenter([project.latitude, project.longitude]);
            setLat(project.latitude);
            setLng(project.longitude);
        } else {
            setCurrentLocation();
        }
    }, [project]);

    return (
        <Spin spinning={loader}>
            <Row className="row">
                <Col flex="8 0 auto" className="column">
                    {projectId && type === 'sub' ? (
                        <h2 className="step-heading mb-20">Contractor Information</h2>
                    ) : (
                        <h2 className="step-heading mb-20">Project Information</h2>
                    )}
                </Col>
            </Row>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={project && { ...project }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Required',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>
                <>
                    <Form.Item
                        label="Owner Organization"
                        tooltip="The company to which the equipment will be owned (also known as End User)"
                        name="owner"
                        rules={[
                            {
                                required: true,
                                message: 'Owner is required',
                            },
                        ]}
                    >
                        <AutoComplete
                            virtual
                            showSearch
                            placeholder="Search or Select an organization"
                            optionFilterProp="children"
                            autoClearSearchValue={false}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {organizations?.map((org) => (
                                <AutoComplete.Option value={org.name}>
                                    {org.name}
                                </AutoComplete.Option>
                            ))}
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item
                        label="Site Address"
                        name="address"
                        rules={[
                            {
                                required: false,
                                message: 'Site address is required',
                            },
                        ]}
                    >
                        {apiReady && (
                            <SearchAddress
                                map={map}
                                mapApi={mapApi}
                                addplace={addPlace}
                                onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : '')}
                            />
                        )}
                    </Form.Item>
                    <div style={{ height: '442px', width: '100%' }}>
                        <GoogleMapReact
                            zoom={4}
                            center={center}
                            bootstrapURLKeys={{
                                key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                                libraries: ['places', 'geometry'],
                            }}
                            onChange={_onChange}
                            onClick={_onClick}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        >
                            {lat && lng && <Marker text={address} lat={lat} lng={lng} />}
                        </GoogleMapReact>
                    </div>
                </>
                <Form.Item className="text-right mb-0">
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        htmlType="submit"
                    >
                  Save & Next
                 </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};
export default BasicInfo;
