import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Space, Tooltip } from 'antd';
import { EditOutlined, ExpandOutlined } from '@ant-design/icons';
import { displayValue, PROJECTS, renderDate } from '../../../../../../utils';

export default (onActionClick = () => {}) => {
    const history = useHistory();

    const goToTagDetail = (record) => {
        const url = `${PROJECTS}/${record?.project}/package/${record?.packageroom?.[0].id}?tag_id=${record?.id}`;
        history.push(url);
    };

    return React.useMemo(() => [
        {
            title: 'Ref #',
            width: 120,
            dataIndex: 'reference_number',
            key: 'reference_number',
            fixed: 'left',
            render: (value, record) => <Button type="link" onClick={() => goToTagDetail(record)}>{value}</Button>,
        },
        {
            title: 'Tag Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 100,
            render: (value) => <Tooltip title={value}>{value}</Tooltip>,
        },
        {
            title: 'Description',
            width: 100,
            dataIndex: 'description',
            key: 'description',
            render: (value) => <Tooltip title={value}>{displayValue(value)}</Tooltip>,
        },
        {
            title: 'Line #',
            dataIndex: 'line_number',
            key: 'line_number',
            width: 100,
            render: displayValue,
        },
        {
            title: 'Order',
            children: [
                {
                    title: 'Order Date',
                    dataIndex: 'order_date',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Order Entry Manufacturer',
                    dataIndex: 'actual_order_date',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Lead Time (Weeks)',
                    dataIndex: 'lead_time_weeks',
                    width: 100,
                    render: (value) => value || '-',
                },
            ],
        },
        {
            title: 'Approval',
            children: [
                {
                    title: 'Expected Approval Dwg',
                    dataIndex: 'approval_date',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Actual Approval Dwg',
                    dataIndex: 'actual_approval_date',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Approved Dwg Returned',
                    dataIndex: 'actual_approved_date',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Resubmittal',
                    dataIndex: 'resubmittal_date',
                    render: renderDate,
                    width: 110,
                },
            ],
        },
        {
            title: 'Release',
            children: [
                {
                    title: 'Expected Release ',
                    dataIndex: 'release_date',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Actual Release',
                    dataIndex: 'actual_release_date',
                    render: renderDate,
                    width: 110,
                },
            ],
        },
        {
            title: 'Testing',
            children: [
                {
                    title: 'Witness Testing',
                    dataIndex: 'test_date',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Record Dwgs',
                    dataIndex: 'actual_final_date',
                    render: renderDate,
                    width: 110,
                },
            ],
        },
        {
            title: 'Ship',
            children: [
                {
                    title: 'Customer OnSite Request',
                    dataIndex: 'expected_ship_date_by_customer',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Planned Ship',
                    dataIndex: 'ship_date',
                    render: renderDate,
                    width: 110,
                },
                {
                    title: 'Actual Ship',
                    dataIndex: 'actual_ship_date',
                    render: renderDate,
                    width: 110,
                },
            ],
        },
        {
            width: 70,
            key: 'actions',
            title: 'Actions',
            dataIndex: 'actions',
            align: 'center',
            fixed: 'right',
            render: (_, record) => (
                <Space>
                    <Tooltip title="View">
                        <ExpandOutlined onClick={() => onActionClick('detail', record)} />
                    </Tooltip>
                    <Tooltip title="Edit">
                        <EditOutlined
                            style={{ fontSize: 17 }}
                            onClick={() => onActionClick('edit', record)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ]);
};
