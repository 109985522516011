import { memo } from 'react';
import { Modal } from 'antd';

function TagDetailModal({
  title = '', onSubmit = () => {}, onCancel = () => {}, visible = false,
}) {
  return (
    <Modal title={title} visible={visible} onOk={onSubmit} onCancel={onCancel}>
      <label style={{ fontWeight: 600 }}>Status:</label>
      <p>Waiting for Approval drawings from Manufacturer</p>
      <label style={{ fontWeight: 600 }}>Description:</label>
      <p>Transformer, 75KVA, 600V-120/208V, 3P,4W</p>
      <label style={{ fontWeight: 600 }}>Electrical Single Lines:</label>
      <p>
        <a href="#" style={{ display: 'block' }}>
          1.jpg
        </a>
        <a href="#" style={{ display: 'block' }}>
          2.jpg
        </a>
      </p>
      <label style={{ fontWeight: 600 }}>Approval Drawings:</label>
      <p>
        <a href="#" style={{ display: 'block' }}>
          1.jpg
        </a>
        <a href="#" style={{ display: 'block' }}>
          2.jpg
        </a>
      </p>
    </Modal>
  );
}

export default memo(TagDetailModal);
