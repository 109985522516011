import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { memo } from 'react';
import PropTypes from 'prop-types';

const BidManagementWarningModal = (props) => {
    return (
        <>
            <Modal
                open={props.showBidWonWarningModal}
                onCancel={() => props.setShowBidWonWarningModal(false)}
                closable={false}
                footer={[
                    props.contractorAlreadyWon ? (
                        <Button
                            key="ok"
                            type="primary"
                            onClick={() => props.setShowBidWonWarningModal(false)}
                        >
                            OK
                        </Button>
                    ) : (
                        <>
                            <Button
                                key="cancel"
                                onClick={() => props.setShowBidWonWarningModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                key="continue"
                                type="primary"
                                onClick={() => props.confirmAndUpdateBidWinner()}
                            >
                                Continue
                            </Button>
                        </>
                    ),
                ]}
            >
                <>
                    <div className="d-flex justify-content-center mb-20">
                        <ExclamationCircleOutlined className="warning-icon" />
                    </div>
                    <p className="d-flex justify-content-center fontSize-15">
                        {`${
                            props.contractorAlreadyWon
                                ? 'You already won the bid.'
                                : 'A Contractor has already won the bid. Do you want to override it?'
                        }`}
                    </p>
                </>
            </Modal>
        </>
    );
};

BidManagementWarningModal.defaultProps = {
    showBidWonWarningModal: false,
    contractorAlreadyWon: false,
    setShowBidWonWarningModal: () => {},
    confirmAndUpdateBidWinner: () => {},
};

BidManagementWarningModal.propTypes = {
    showBidWonWarningModal: PropTypes.bool,
    contractorAlreadyWon: PropTypes.bool,
    setShowBidWonWarningModal: PropTypes.func,
    confirmAndUpdateBidWinner: PropTypes.func,
};

export default memo(BidManagementWarningModal);
