import AWS from 'aws-sdk';

const { REACT_APP_BUCKET_ACCESS_KEY, REACT_APP_BUCKET_SECRET_KEY } = process.env;

const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

AWS.config.update({
  accessKeyId: REACT_APP_BUCKET_ACCESS_KEY,
  secretAccessKey: REACT_APP_BUCKET_SECRET_KEY,
});

class S3 {
  constructor() {
    this.s3 = new AWS.S3();
  }

  /**
     * Upload file to S3
     */
  async upload(filename, data, mimeType, isPrivate = true) {
    try {
      const accessLevel = isPrivate ? 'private' : 'public-read';
      const params = {
        ACL: accessLevel,
        Body: data,
        ContentType: mimeType,
        Bucket: BUCKET_NAME,
        Key: filename,
      };
      const result = await this.s3.upload(params).promise();
      return result;
    } catch (error) {}
  }

  /**
     * Download file from S3
     */
  async download({ filename }) {
    try {
      const params = { Bucket: BUCKET_NAME, Key: filename };
      const result = s3.getObject(params).promise();
      if (result.error !== null) {
        console.log('Failed to retrieve an object -- ', result.error);
      } else {
        console.log('Loaded bytes -- ', result.data.ContentLength);
        // do something with data.Body
      }
      return result;
    } catch (error) {}
  }

  /**
     * Get signed file url S3
     */
  async privateSignedUrl(filename) {
    try {
      const params = {
        Bucket: BUCKET_NAME,
        Key: filename,
        Expires: 60 * 30, // time in seconds: e.g. 60 * 5 = 5 mins
      };
      const result = await this.s3.getSignedUrl('getObject', params);
      return result;
    } catch (error) {
      console.log('error');
      throw error;
    }
  }

  /**
     * Delete file from S3
     */
  async deleteFile(fileurl) {
    try {
      const params = {
        Bucket: BUCKET_NAME,
        Key: fileurl,
      };
      const result = await this.s3.deleteObject(params).promise();
      return result;
    } catch (error) {
      throw error;
    }
  }

  /**
     * Get files from S3
     */
  async getFilesList(prefix) {
    try {
      const params = {
        Bucket: BUCKET_NAME,
        Prefix: prefix,
      };
      const result = await this.s3.listObjectsV2(params).promise();
      return result;
    } catch (error) {
      throw error;
    }
  }
}

export const s3 = new S3();
