import _ from 'lodash';
import * as ActionTypes from './types';

const initialState = {
  loading: false,
  details: {},
};

export const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FETCH_DASHBOARD_DETAILS:
      return { ...state, loading: true };

    case ActionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS:
      return { ...state, loading: false, details: payload };

    case ActionTypes.FETCH_DASHBOARD_DETAILS_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};
