import { isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Sidebar } from '../../components';
import { fetchProjects } from '../../services';
import { PROJECT_SETUP, setActiveProject } from '../../store/common';
import { PROJECTS, PROJECT_TYPES } from '../../utils';
import * as Permissions from '../../utils/permissions';
import ProjectList from './ProjectsList';
import ProjectsSidebarHeader from './ProjectsSidebarHeader';

const ProjectsSidebar = memo((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector(({ auth }) => auth);
    const { activeProject } = useSelector(({ common }) => common);
    const [active, setActive] = useState(true);
    const [projects, setProjects] = useState([]);
    const [projectsMeta, setProjectsMeta] = useState({ total_count: 0, page: 1 });
    const [projectLoader, setProjectLoader] = useState(false);
    const [projectSearchText, setProjectSearchText] = useState('');
    const [filterProjects, setFilterProjects] = useState(null);

    const searchProjects = () => {
        const filteredProjects = projects.filter((project) => project.name.toLowerCase().includes(projectSearchText.toLowerCase()));
        setFilterProjects(filteredProjects);
    };

    useEffect(() => {
        searchProjects();
    }, [projectSearchText]);

    const getProjects = async (page = 1) => {
        try {
            setProjectLoader(true);
            const response = await fetchProjects({ per_page: 15, page, active });
            if (response) {
                // Default Sorting
                const payload = _.orderBy(
                    response?.data,
                    [
                        function (item) {
                            return item.is_draft;
                        },
                        function (item) {
                            return item.is_finished;
                        },
                        function (item) {
                            return item.unread_activity_count;
                        },
                        function (item) {
                            return item.name;
                        },
                    ],
                    ['desc', 'asc', 'desc', 'asc'],
                );
                setProjectsMeta({ ...response?.meta, page });
                setProjects([...(page === 1 ? [] : projects), ...payload]);
                setProjectLoader(false);
                // Get master project
                if (isEmpty(activeProject)) {
                    dispatch(
                        setActiveProject({
                            ...payload[0],
                            masterProject: payload[0],
                            type: PROJECT_TYPES.MASTER_PROJECT,
                        }),
                    );
                    history.push(`${PROJECTS}/${payload[0]?.id}`);
                }
            }
        } catch (ex) {
            setProjectLoader(false);
        }
    };

    useEffect(() => {
        getProjects();
    }, [active]);

    const onPageChange = () => {
        getProjects(projectsMeta?.page + 1);
    };

    return (
        <Sidebar
            addNewBtn={Permissions.can_add_project(user?.platform_permissions)}
            customChildren={
                <ProjectsSidebarHeader
                    active={active}
                    setActive={setActive}
                    projectsMeta={projectsMeta}
                    projectSearchText={projectSearchText}
                    setProjectSearchText={setProjectSearchText}
                />
            }
            heading={() => 'Construction Projects'}
            loader={projectLoader}
            addNewText="New Master Project"
            addNewOnClick={() => {
                dispatch({ type: PROJECT_SETUP, payload: true });
                history.push(`${PROJECTS}/create-master`);
            }}
            toggleSidebar={() => props.setHideSidebar(!props.hideSidebar)}
        >
            <ProjectList
                active={active}
                setActive={setActive}
                projectsMeta={projectsMeta}
                onPageChange={onPageChange}
                projectSearchText={projectSearchText}
                projects={filterProjects?.length ? filterProjects : projects}
            />
        </Sidebar>
    );
});

export default ProjectsSidebar;
