import InfiniteScroll from 'react-infinite-scroll-component';
import { memo } from 'react';
import { Button, Divider, Skeleton, Spin, Tooltip } from 'antd';
import { DoubleLeftOutlined, PlusOutlined } from '@ant-design/icons';
import './styles.scss';

const Sidebar = ({
    children,
    customChildren,
    heading = () => {},
    addNewText = '',
    loader = false,
    addNewBtn = false,
    addNewOnClick = () => {},
    toggleSidebar = () => {},
    displayAddBtn = false,
}) => {
    return (
        <div className="filters-sidebar">
            <div className="sidebar-heading">
                <h4>{heading()}</h4>
                {addNewBtn && (
                    <Tooltip title={addNewText}>
                        <Button shape="circle" icon={<PlusOutlined />} onClick={addNewOnClick} />
                    </Tooltip>
                )}
            </div>
            {customChildren}
            <div id="scrollableDiv" className="sidebar-listing custom-scroll">
                {loader && <div className="mt-20" />}
                <Spin spinning={loader}>
                    <InfiniteScroll
                        dataLength={children.props.projects?.length}
                        next={children.props.onPageChange}
                        hasMore={children.props.projectsMeta?.total_count > children.props.projects?.length}
                        endMessage={!loader && <Divider plain>It is all, nothing more.</Divider>}
                        scrollableTarget="scrollableDiv"
                        loader={
                            loader && (
                                <Skeleton
                                    paragraph={{ rows: 4 }}
                                    block={true}
                                    active
                                />
                            )
                        }
                    >
                        {children}
                    </InfiniteScroll>
                </Spin>
            </div>
            <Tooltip title="Hide Projects" placement="right">
                <div className="sidebar-toggler" onClick={toggleSidebar}>
                    <DoubleLeftOutlined />
                </div>
            </Tooltip>
        </div>
    );
};

export default memo(Sidebar);
