import _ from 'lodash';
import {
  Input, Button, Upload, Tag,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PlusOutlined } from '@ant-design/icons';
import { ReadyState } from 'react-use-websocket';

const { TextArea } = Input;

function MessageBox({
  sendMessage,
  readyState,
  onUploadChatDocuments,
}) {
  const { user } = useSelector(({ auth }) => auth);
  const [message, setMessage] = useState(null);
  const [documentsToUpload, setDocumentsToUpload] = useState([]);

  const onSendMessage = (e) => {
    if (!_.isEmpty(message.trim())) {

      sendMessage({
        from: user?.id,
        text: message.replace(/^\s+|\s+$/g, ''),
        content_type: 'text',
        client: 'web',
      });

      setMessage(null);
      const e = document.getElementById('chat-message-box');
      if (e && e.innerText && e.innerHTML) {
        e.innerHTML = '';
        e.innerText = '';
        console.log(e);
      }
    }
  };

  const onUploadDocument = (e) => {
    setDocumentsToUpload([...documentsToUpload, ...e.fileList]);
  };

  const removeFile = (removedDoc) => {
    const newDocuments = documentsToUpload.filter((document) => document !== removedDoc);
    setDocumentsToUpload([...newDocuments]);
  };

  const confirmUpload = () => {
    onUploadChatDocuments(documentsToUpload);
    setDocumentsToUpload([]);
  };

  return (
    <div className="message-box">
      <div className="input-box">
        {
          documentsToUpload?.length
            ? (
              <div className="user-input">
                <TextArea
                  id='chat-message-box'
                  allowClear
                  placeholder="Enter message ..."
                  defaultValue={message}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onPressEnter={(e) => onSendMessage(e)}
                />
              </div>
            )
            : (
              <div className="user-input">
                <TextArea
                  allowClear
                  id='chat-message-box'
                  placeholder="Enter message ..."
                  defaultValue={message}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onPressEnter={(e) => onSendMessage(e)}
                />
              </div>
            )
        }
        <div className="message-btns">
          {
            documentsToUpload.length
              ? <Button type="primary" onClick={(e) => confirmUpload(e)} disabled={readyState !== ReadyState.OPEN}>Upload Documents</Button>
              : <Button type="primary" onClick={(e) => onSendMessage(e)} disabled={readyState !== ReadyState.OPEN}>Send</Button>
          }
        </div>
      </div>
      <div className="extra-btns">
        <Upload
          beforeUpload={() => false}
          onChange={_.debounce(onUploadDocument, 100)}
          showUploadList={false}
          multiple
          maxCount={5}
          fileList={[]}
        >
          <Button type="primary" className="main-upload-btn">
            <span
              style={{
                margin: '0 8px 0 0',
                color: 'white',
              }}
            >
              <PlusOutlined />
              {' '}
              Attach files
            </span>
          </Button>
        </Upload>
        <div>
          {
            documentsToUpload.map((m) => <Tag closable onClose={() => removeFile(m)}>{m.name}</Tag>)
          }
        </div>
      </div>
    </div>
  );
}

export default MessageBox;
