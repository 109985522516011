import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Breadcrumb, Layout, Card, Row, Col, Select,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  fetchTagDetails, fetchImageEquipmentDocs, saveEquipmentDocs, fetchProjectSearchResults,
} from '../../services';
import { setImageDocs, getEquipmentDocuments } from '../../store/equipment-docs';
// import { SelectPackage } from './select-package';
import { ReviewTab } from './tabs/review';
import { ManufactureTab } from './tabs/manufacture';
import { InstallationTab } from './tabs/installation';
import { PACKAGE_MENU } from '../../utils';
import '../../assets/styles/components/package-detail.scss';

const { Content } = Layout;

function TagDetail({ history, taskIbox = false }) {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchLoading, setSearchLoading] = useState(false);

  const { activeProject } = useSelector(
    ({ common }) => common,
  );

  const { tagId, projectId } = useParams();
  const {
    tag,
    equipments: { equipmentDocs },
  } = useSelector((state) => state);

  useEffect(() => {
    getTagDetail(tagId);
  }, []);

  /**
     * Handle Project level Search
     */

  useEffect(() => {
    if (searchQuery) {
      getProjectSearchResults(searchQuery);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const getProjectSearchResults = async (query) => {
    setSearchLoading(true);
    const res = await fetchProjectSearchResults({
      projectId: activeProject?.id,
      body: {
        search_str: query,
      },
    });

    if (res.success) {
      setSearchLoading(false);
      setSearchResults(res.data);
    } else {
      setSearchResults([]);
      setSearchLoading(false);
    }
  };

  const handleProjectSearch = (value) => {
    if (value) {
      setSearchQuery(value);
    } else {
      setSearchQuery(null);
    }
  };

  const handleProjectChange = (id) => {
    history.push(`/projects/${activeProject?.id}/package/${id}`);
  };

  const clearProjectSearch = () => {
    setSearchQuery(null);
    setSearchResults([]);
  };

  useEffect(() => {
    Promise.all([getTagDetail(tagId), getDocuments(tagId)]);
  }, [tagId]);

  const [isLoading, setLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const [activeMenu, setActiveMenu] = useState(PACKAGE_MENU[0]);

  const getDocuments = async () => {
    dispatch(getEquipmentDocuments(projectId, tagId));
  };

  const getTagDetail = async (id) => {
    try {
      setLoading(true);
      const response = await fetchTagDetails(id);
      setDetail(response);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const getImageDocs = async (pkgId) => {
    try {
      setLoading(true);
      const response = await fetchImageEquipmentDocs(projectId, pkgId);
      dispatch(setImageDocs(response?.data));
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const getClassName = (menuItem) => `arrow${activeMenu === menuItem ? '-highlight' : ''}`;

  const uploadEquipmentDocs = async (fileList, key) => {
    try {
      setLoading(true);
      const formData = new FormData();
      fileList.map((file) => formData.append('files', file));
      formData.append('is_package', false);
      formData.append('dir_key', key);
      formData.append('project_id', projectId);
      formData.append('id', tagId);
      await saveEquipmentDocs(projectId, tagId, formData);
      getDocuments(tagId);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  return (
    <Content>
      <Card className="card_body">
        <div className="main_body" style={{ width: '100%' }}>
          {!taskIbox && (
            <div className="bread">
              <div className="bread">
                <Breadcrumb separator=">" className="mb-20">
                  <Breadcrumb.Item
                    onClick={() => {
                      history.push('/projects');
                    }}
                  >
                    <span className="cursor-pointer">Alectify</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => history.goBack()}>
                    <span className="cursor-pointer">Tag</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href="">{detail?.name}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          )}
          <div className="topMenu">
            <Breadcrumb.Item
              onClick={() => {
                history.push('/projects');
              }}
            >
              Alectify
            </Breadcrumb.Item>
            <Row>
              {
                PACKAGE_MENU.map((menuItem, index) => (
                  <Col span={6} key={index}>
                    <span
                      className={getClassName(menuItem)}
                      onClick={() => setActiveMenu(menuItem)}
                    >
                      {menuItem}
                    </span>
                  </Col>
                ))
              }
            </Row>
          </div>
          <div>
            <>
              {activeMenu === 'Review' && (
                <ReviewTab
                  isLoading={isLoading}
                  detail={detail}
                  tagId={tagId}
                  projectId={projectId}
                  equipmentDocs={equipmentDocs?.list}
                  uploadDocs={uploadEquipmentDocs}
                />
              )}
              {activeMenu === 'Manufacture' && (
                <ManufactureTab
                  tagId={tagId}
                  detail={detail}
                  projectId={projectId}
                  isLoading={isLoading}
                  equipments={equipmentDocs}
                  uploadDocs={uploadEquipmentDocs}
                />
              )}
              {activeMenu === 'Shipment' && (
                <InstallationTab
                  detail={detail}
                  isLoading={isLoading}
                  equipmentDocs={equipmentDocs?.list}
                  uploadDocs={uploadEquipmentDocs}
                />
              )}
            </>
          </div>
        </div>
      </Card>
    </Content>
  );
}

export default TagDetail;
