import { memo } from 'react';
import { Collapse } from 'antd';
import ProjectCostList from './ProjectCostList';
import CreateProjectCost from './CreateProjectCost';

const { Panel } = Collapse;

const ProjectCosts = memo(() => {
    return (
        <Collapse className="mb-30">
            <Panel header="Vendor Invoices" key="1" className='white-bg'>
                <CreateProjectCost />
                <ProjectCostList />
            </Panel>
        </Collapse>
    );
});

export default ProjectCosts;
