import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Tooltip, Layout, Card, Row, Col, Avatar,
} from 'antd';
// import { SelectPackage } from './select-package';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import { getTasksList } from '../../../store/tasks/actions';
import ChatDisplay from '../listing/chat-display';
import { DISPLAY_DATETIME_FORMAT, SERVER_DATE_FORMAT, makeAvatar } from '../../../utils';

const { Content } = Layout;

function TaskDetail({ history }) {
  const dispatch = useDispatch();
  const { packageId, projectId, taskId } = useParams();
  const [check, setCheck] = useState(true);
  const [task, setTask] = useState(null);
  const { taskList, taskMeta } = useSelector(({ tasks }) => tasks);

  // Get a list of tasks and filter by id
  useEffect(() => {
    dispatch(getTasksList({
      projectId, page: 1, pageSize: 600, filters: {},
    }));
  }, []);

  useEffect(() => {
    const task = _.find(taskList, ['id', parseInt(taskId)]);
    setTask(task);
  }, [taskList]);

  return (
    <Content>
      <Card className="task-details-content">
        <Row justify="center" className="task-heading" gutter={15}>
          <Col>Task Details</Col>
        </Row>
        <Row justify="center" gutter={15}>
          <Col span={12}>
            <Row gutter={15} className="task-details">
              <Col span={6}>
                <div className="property">
                  <div className="title">Name: </div>
                  <div className="value">
                    {' '}
                    {task?.summary}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Description: </div>
                  <div className="value">
                    {' '}
                    {task?.description}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Package: </div>
                  <div className="value">
                    {' '}
                    {task?.package?.name}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Tag: </div>
                  <div className="value">
                    {' '}
                    {task?.tag?.name}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Status: </div>
                  <div className="value">
                    {' '}
                    {task?.status}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Due Date: </div>
                  <div className="value">{task?.due_date && moment(task?.due_date, SERVER_DATE_FORMAT).format(DISPLAY_DATETIME_FORMAT)}</div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Completion Date: </div>
                  <div className="value">
                    {task?.completion_date && moment(task?.completion_date, SERVER_DATE_FORMAT).format(DISPLAY_DATETIME_FORMAT)}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Team Members: </div>
                  <div className="value">
                    <Avatar.Group>
                      {task?.package?.team_members.map((member, idx) => (
                        <Tooltip
                          key={member.email}
                          title={`${member.first_name} ${member.last_name}`}
                        >
                          <Avatar
                            className="circle-icon"
                            src={makeAvatar(
                              member.first_name[0],
                              member.last_name[0],
                            )}
                          />
                        </Tooltip>
                      ))}
                    </Avatar.Group>
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Assignees: </div>
                  <div className="value">
                    <Avatar.Group>
                      {task?.assignees.map((member, idx) => (
                        <Tooltip
                          key={member.email}
                          title={`${member.first_name} ${member.last_name}`}
                        >
                          <Avatar
                            className="circle-icon"
                            src={makeAvatar(
                              member.first_name[0],
                              member.last_name[0],
                            )}
                          />
                        </Tooltip>
                      ))}
                    </Avatar.Group>
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Created At: </div>
                  <div className="value">
                    {' '}
                    {moment(task?.created_at, moment.ISO_8601).format(DISPLAY_DATETIME_FORMAT)}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className="property">
                  <div className="title">Updated At: </div>
                  <div className="value">
                    {' '}
                    {moment(task?.updated_at, moment.ISO_8601).format(DISPLAY_DATETIME_FORMAT)}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <>
        <input
          className="checkBox-input"
          type="checkbox"
          name=""
          id="check"
          checked={check}
          onChange={() => setCheck(!check)}
        />
        <div className="quick-chat">
          <label htmlFor="check">
            <span className="show" id="show">
              <LeftOutlined />
            </span>
            <span className="show" id="hide">
              <RightOutlined />
            </span>
          </label>
        </div>
      </>
    </Content>
  );
}

export default TaskDetail;
