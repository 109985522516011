import _, { size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, Badge, Spin, Tabs,
} from 'antd';
import { UsersIcon } from '../../../../components/users-icon';
import { getApprovals, getMarkups } from '../../../../store/approval';
import { EquipmentDocs } from '../../equipment_docs';
import Overview from '../overview';
import Approval from './approval';
import Status from './status';
import Review from './review';
import Kanban from './kanban';
import { SubscriptionMessage } from '../../../../components';
import Analytics from '../installation/analytics';

const { TabPane } = Tabs;

export function ReviewTab({
  detail,
  pkgId = '',
  projectId = '',
  isLoading = false,
  equipmentDocs = [],
  uploadDocs = () => {},
  uploadForVideos = () => {},
  getPackageDetail = () => {},
}) {
  const dispatch = useDispatch();
  const {
    approvals, approvalsLoading, markupsLoading, approvalsForReview,
  } = useSelector(
    ({ approval }) => approval,
  );
  const { allPackages, loading: pkgsLoading } = useSelector(({ packages }) => packages);
  const { user } = useSelector(({ auth }) => auth);
  const [activeTab, setActiveTab] = useState('1');
  const [markupDetail, setMarkupDetail] = useState([]);
  const [hasReviewAndApproval, setHasReviewAndApproval] = useState(false);
  const { equipment_documents, review_documents } = useSelector(({ documents }) => documents);

  const getInitialData = () => {
    Promise.all([dispatch(getApprovals(pkgId)), dispatch(getMarkups(pkgId))]);
  };

  const checkForApprovalReviewType = (type) => {
    if (type === 'equiment_with_package_room') {
      return true;
    }

    if (type === 'individual_equiment_with_approval_drwng') {
      return true;
    }

    return false;
  };

  useEffect(() => {
    // if (!pkgsLoading && Object.keys(detail).length) {
    const typeStatus = checkForApprovalReviewType(detail.package_type);
    setHasReviewAndApproval(typeStatus);
    // }
  }, [pkgId, detail, pkgsLoading]);

  useEffect(() => {
    setActiveTab('1');
    getInitialData();
  }, [pkgId]);

  function UserDetails() {
    return (
      <UsersIcon
        heading={detail?.name}
        isReleased={detail?.is_released}
        teamMembers={detail?.team_members}
        releaseMessage="This Package is Released"
        showNoApproval={!(detail.is_approval && hasReviewAndApproval)}
      />
    );
  }

  const getDocumentCount = () => {
    let count = 0;
    equipmentDocs.map((e) => {
      count += e.dir_files.length;
    });
    return count;
  };

  return (
    <Spin spinning={approvalsLoading || markupsLoading}>
      <Tabs
        tabPosition="left"
        activeKey={activeTab}
        defaultActiveKey={activeTab}
        onChange={setActiveTab}
      >
        <TabPane tab="Status" key="1">
          {UserDetails()}
          {activeTab === '1' && <Status tags={detail?.tags} pkgId={pkgId} />}
        </TabPane>
        {/* {detail.is_approval && hasReviewAndApproval && ( */}
        <TabPane
          key="2"
          tab={`Approval (${approvals.length})`}
          disabled={!(detail.is_approval && hasReviewAndApproval)}
        >
          {UserDetails()}
          {activeTab === '2' && (
          <Approval
            pkgId={pkgId}
            projectId={projectId}
            isReleased={detail?.is_released}
            setMarkupDetail={setMarkupDetail}
          />
          )}
        </TabPane>
        {/* )} */}
        {/* {detail.is_approval && hasReviewAndApproval && ( */}
        <TabPane
          key="3"
          tab={`Review (${approvalsForReview?.length || 0})`}
          disabled={!(detail.is_approval && hasReviewAndApproval)}
        >
          {UserDetails()}
          {activeTab === '3' && (
          <Review
            pkgId={pkgId}
            detail={detail}
            projectId={projectId}
            isReleased={detail?.is_released}
            getPackageDetail={getPackageDetail}
            setMarkupDetail={setMarkupDetail}
          />
          )}
        </TabPane>
        {/* )} */}
        {/* <TabPane tab="Analytics" key="4">
          {/* <Overview />
          <Analytics />
        </TabPane> */}
        <TabPane
          tab="Comments Summary"
          key="5"
          disabled={
                        !(
                          detail.is_approval
                            && hasReviewAndApproval
                        )
                    }
        >
          {activeTab === '5' && (
          <Kanban
            projectId={projectId}
            pkgId={pkgId}
            markupDetail={markupDetail}
            setMarkupDetail={setMarkupDetail}
          />
          )}
        </TabPane>
        {/* )} */}

        {/* <TabPane tab={`Documents (${equipment_documents?.meta?.total_count + review_documents?.meta?.total_count})`} key="6">
                    {feature_equipment_documents(user.features) ? (
                        <EquipmentDocs
                            pkgId={pkgId}
                            projectId={projectId}
                            data={equipmentDocs}
                            isLoading={isLoading}
                            uploadDocs={uploadDocs}
                            uploadForVideos={uploadForVideos}
                        />
                    ) : (
                        <SubscriptionMessage />
                    )}
                </TabPane> */}
      </Tabs>
    </Spin>
  );
}
