import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectDetails from './ProjectDetails';
import MasterProjects from '../master-projects/details';
import SubProjectDetail from '../sub-projects/details';
import ProjectMapListing from './ProjectMapListing';

const ProjectsView = memo(() => {
    return (
        <ProjectDetails>
            <Switch>
                <Route
                    exact
                    path={'/projects/map'}
                    component={ProjectMapListing}
                />
                <Route
                    exact
                    path={'/projects/:projectId'} // @TODO: will change to masterProjectId
                    component={MasterProjects}
                />
                <Route
                    exact
                    path={'/projects/:masterProjectId/sub-project/:subProjectId'}
                    component={SubProjectDetail}
                />
            </Switch>
        </ProjectDetails>
    );
});

export default ProjectsView;
