import { Button, Collapse, Empty, Space, Spin, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import ContractorListing from './ContractorListing';
import { BIDDING_MANAGEMENT } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveBiddingProject } from '../../../../store/bidding-management';

const { Panel } = Collapse;

const MainProjectListing = ({ history, projects, loading }) => {
    const { activeProject } = useSelector(({ bidding }) => bidding);
    const dispatch = useDispatch();
    return (
        <Spin spinning={loading}>
            <Collapse accordion className="project-listing-collapse" collapsible="icon">
                {projects &&
                    projects.map((masterProject) => {
                        return (
                            <Panel
                                header={<div>{masterProject.name || ''}</div>}
                                className={`panel-heading ${
                                    activeProject?.id === masterProject.id && 'master-active'
                                }`}
                                onClick={() => {
                                    dispatch(setActiveBiddingProject(masterProject)); // @TODO: Remove it
                                    history.push(`${BIDDING_MANAGEMENT}/${masterProject.id}`);
                                }}
                                key={masterProject.id}
                                extra={
                                    <Space>
                                        <>
                                            <Tooltip title="Edit Master" placement="top">
                                                <EditOutlined
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        history.push(
                                                            `${BIDDING_MANAGEMENT}/create/${masterProject.id}`,
                                                        );
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Add Contractor" placement="top">
                                                <PlusOutlined
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        history.push(
                                                            `${BIDDING_MANAGEMENT}/contractor/create/${masterProject.id}`,
                                                        );
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    </Space>
                                }
                            >
                                {masterProject && masterProject.projects.length > 0 ? (
                                    <ContractorListing contractors={masterProject} />
                                ) : (
                                    <Empty
                                        description={
                                            <span>
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        history.push(
                                                            `${BIDDING_MANAGEMENT}/contractor/create/${masterProject.id}`,
                                                        );
                                                    }}
                                                >
                                                    Create Contractor
                                                </Button>
                                            </span>
                                        }
                                    />
                                )}
                            </Panel>
                        );
                    })}
            </Collapse>
        </Spin>
    );
};

export default MainProjectListing;
