import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { map, find, join, isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { memo, useEffect, useRef, useState } from 'react';
import {
    Table,
    Tag,
    Button,
    Input,
    Tooltip,
    Row,
    Col,
    Collapse,
    notification,
    Avatar,
    Select,
    Form,
    Card,
} from 'antd';
import { ContainerOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { fetchProjectUsers, createTask, updateTask } from '../../../../../services';
import {
    makeAvatar,
    PAGE_SIZE_OPTIONS,
    SERVER_DATE_FORMAT,
    INPUT_DATE_FORMAT,
    PROJECTS,
    PROJECT_PERMISSIONS,
} from '../../../../../utils';
import { getTasksList } from '../../../../../store/tasks/actions';
import * as Permissions from '../../../../../utils/permissions';
import CreateTask from './CreateTask';

const { Option } = Select;

const status_options = [
    {
        value: 'PENDING',
        key: 'PENDING',
    },
    {
        value: 'COMPLETED',
        key: 'COMPLETED',
    },
];

function Tasks() {
    const { subProjectId } = useParams();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const searchInput = useRef();
    const { permissions } = useSelector(({ permission }) => permission);
    const { taskList, taskMeta, taskListLoading } = useSelector(({ tasks }) => tasks);
    const { activeProject } = useSelector(({ common }) => common);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchColumn] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeTask, setActiveTask] = useState(null);
    const [users, setUsers] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(1);
    const [createTaskVisible, setCreateTaskVisible] = useState(false);
    const [taskDetails, setViewTaskDetails] = useState(false);
    const [filters, setFilters] = useState({});

    const showNotification = (message = '', type = 'success', placement = 'topRight') => {
        notification[type]({
            message,
            placement,
        });
    };

    const getProjectUsers = async () => {
        try {
            const response = await fetchProjectUsers(subProjectId);
            setUsers([...response?.data]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(async () => {
        await getProjectUsers();
    }, []);

    useEffect(async () => {
        dispatch(
            getTasksList({
                projectId: subProjectId,
                page,
                pageSize,
                filters,
            }),
        );
    }, [page, pageSize]);

    const handlePageSizeChange = async (current, pageSize, section) => {
        setPageSize(pageSize);
    };

    const onPageChange = async (page, pageSize, section) => {
        setPage(page);
    };

    const handleEdit = (record) => {
        setActiveTask({ ...record });
        setCreateTaskVisible(true);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, width: 200 }}>
                <Row gutter={5}>
                    <Col span={12} className="mb-10">
                        <Button
                            onClick={() => handleReset(clearFilters, dataIndex)}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Search
                        </Button>
                    </Col>
                    <Col span={24}>
                        {dataIndex === 'assignees' ? (
                            <Select
                                showSearch
                                placeholder="Please select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                mode="multiple"
                                onChange={(value) => {
                                    setFilters({ ...filters, assignees: value });
                                }}
                                value={filters.assignees}
                                allowClear
                                style={{ width: '100%' }}
                            >
                                {users.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                        {`${user.first_name} ${user.last_name}`}
                                    </Option>
                                ))}
                            </Select>
                        ) : dataIndex === 'status' ? (
                            <Select
                                showSearch
                                placeholder="Please select"
                                optionFilterProp="children"
                                mode="multiple"
                                onChange={(value) => {
                                    setFilters({ ...filters, status: value });
                                }}
                                value={filters.status}
                                allowClear
                                style={{ width: '100%' }}
                            >
                                {status_options.map((p) => (
                                    <Option key={p.key} value={p.value}>
                                        {`${p.key}`}
                                    </Option>
                                ))}
                            </Select>
                        ) : (
                            <Input
                                ref={searchInput}
                                placeholder={`Search ${dataIndex}`}
                                value={filters?.[dataIndex]}
                                onChange={(e) =>
                                    setFilters({ ...filters, [dataIndex]: e.target.value })
                                }
                                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                style={{ marginBottom: 8, display: 'block' }}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : '#838383' }} />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
            }
            return '';
        },
        onFilterDropdownOpenChange: (visible) => {
            if (!visible) {
                if (filters?.status && filters?.status.length === 0) delete filters.status;
                dispatch(
                    getTasksList({
                        projectId: subProjectId,
                        page,
                        pageSize,
                        filters,
                    }),
                );
            }
        },
        render: (text) => {
            if (searchedColumn === dataIndex) {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                );
            }
            return text;
        },
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        dispatch(
            getTasksList({
                projectId: subProjectId,
                page,
                pageSize,
                filters,
            }),
        );
    };

    const handleReset = (clearFilters, dataIndex) => {
        clearFilters();
        delete filters[dataIndex];
        setFilters(filters);

        // Tmp fix
        dispatch(
            getTasksList({
                projectId: subProjectId,
                page,
                pageSize,
                filters,
            }),
        );
    };

    const onCreateTask = async (data, cb) => {
        try {
            const response = await createTask({ projectId: subProjectId, ...data });
            setCreateTaskVisible(false);
            notification.success({
                message: 'Task Added Successfully',
                description: '',
            });
            dispatch(getTasksList({ projectId: subProjectId, page, pageSize }));
        } catch (error) {
            console.log(error);
        } finally {
        }
    };

    const onUpdateTask = async (data, cb) => {
        try {
            const response = await updateTask({ projectId: subProjectId, ...data });
            setCreateTaskVisible(false);
            notification.success({
                message: 'Task Updated Successfully',
                description: '',
            });
            dispatch(getTasksList({ projectId: subProjectId, page, pageSize }));
            setActiveTask(null);
        } catch (ex) {
            throw ex;
        } finally {
        }
    };

    const onDeleteTask = async (pkgId) => {
        try {
            setLoading(true);
        } catch (ex) {
            throw ex;
        } finally {
        }
    };

    const onCancel = () => {
        setCreateTaskVisible(false);
        setActiveTask(null);
    };

    const taskColumns = [
        {
            title: 'Package',
            dataIndex: 'package',
            filteredValue: filters.package && [filters.package],
            ...getColumnSearchProps('package'),
            render: (_, record) => <div>{record?.package?.name}</div>,
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            filteredValue: filters.tag && [filters.tag],
            ...getColumnSearchProps('tag'),
            render: (_, record) => <div>{record?.tag?.name}</div>,
        },
        {
            title: 'TaskId',
            dataIndex: 'id',
        },
        {
            title: 'Task',
            dataIndex: 'summary',
        },
        {
            title: 'Detail',
            dataIndex: 'description',
        },
        {
            title: 'Assignee(s)',
            dataIndex: 'assignees',
            filteredValue: filters.assignees,
            ...getColumnSearchProps('assignees'),
            render: (_, record) => (
                <Avatar.Group>
                    {record?.assignees.map((member, idx) => (
                        <Tooltip
                            key={member?.email}
                            title={`${member?.first_name} ${member?.last_name}`}
                        >
                            <Avatar
                                className="circle-icon"
                                src={makeAvatar(member?.first_name?.[0], member?.last_name?.[0])}
                            />
                        </Tooltip>
                    ))}
                </Avatar.Group>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            render: (_, record) => (
                <Tooltip
                    key={record?.created_by?.email}
                    title={`${record?.created_by?.first_name} ${record?.created_by?.last_name}`}
                >
                    <Avatar
                        className="circle-icon"
                        src={makeAvatar(
                            record?.created_by?.first_name?.[0],
                            record?.created_by?.last_name?.[0],
                        )}
                    />
                </Tooltip>
            ),
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            render: (_, record) => (
                <>{moment(record?.due_date, SERVER_DATE_FORMAT).format(INPUT_DATE_FORMAT)}</>
            ),
        },
        {
            title: 'Completion Date',
            dataIndex: 'completion_date',
            render: (_, record) => {
                if (record?.completion_date) {
                    return (
                        <>
                            {moment(record?.completion_date, SERVER_DATE_FORMAT).format(
                                INPUT_DATE_FORMAT,
                            )}
                        </>
                    );
                }
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filteredValue: filters.status,
            ...getColumnSearchProps('status'),
        },
        {
            title: 'Action',
            dataIndex: 'status',
            render: (_, record) => (
                <div key={record?.id}>
                    {true && (
                        <Tooltip key={record?.id} title="Edit Task">
                            <Button
                                key={record?.id}
                                type="text"
                                onClick={() => {
                                    setActiveTask(record);
                                    setCreateTaskVisible(true);
                                }}
                                icon={<EditOutlined />}
                            />
                        </Tooltip>
                    )}
                    <Tooltip key={record?.id} title="View Task">
                        <Button
                            key={record?.id}
                            type="text"
                            onClick={() => {
                                history.push(
                                    `${PROJECTS}/${subProjectId}/${record?.package?.id}/task/${record?.id}`,
                                );
                                setActiveTask(record);
                                setCreateTaskVisible(true);
                            }}
                            icon={<ContainerOutlined />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const onRemoveFilter = (f) => {
        delete filters[f];
        setFilters(filters);
        dispatch(
            getTasksList({
                projectId: subProjectId,
                page,
                pageSize,
                filters,
            }),
        );
    };

    const getAssineesForFilter = (assignees) => {
        let displayString = '';
        const members = map(assignees, (u) => {
            const m = find(users, ['id', u]);
            if (m) displayString += `${m?.first_name} ${m?.last_name}, `;
        });

        return displayString.replace(/,\s*$/, '');
    };

    return (
        <>
            {!loading ? (
                <>
                    <Row justify="space-between">
                        {Object.keys(filters).length > 0 ? (
                            <Col flex={1} className="table-filter">
                                <div className="label">Filters </div>
                                {Object.keys(filters).map((f) => {
                                    const value = filters[f];
                                    return (
                                        <Tag
                                            closable
                                            onClose={() => {
                                                onRemoveFilter(f);
                                            }}
                                        >
                                            <span className="filter-name">{`${f} :`}</span>
                                            {f === 'assignees'
                                                ? getAssineesForFilter(value)
                                                : isArray(value)
                                                ? join(value, ',')
                                                : value}
                                        </Tag>
                                    );
                                })}
                            </Col>
                        ) : (
                            <Col />
                        )}
                        <Col span={2}>
                            {Permissions.hasProjectPermission(
                                activeProject?.permissions,
                                PROJECT_PERMISSIONS.CAN_MODIFY_TASKS,
                            ) && (
                                <Button onClick={() => setCreateTaskVisible(true)} type="primary">
                                    <PlusOutlined />
                                    New Task
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24}>
                            <Table
                                dataSource={taskList}
                                columns={taskColumns}
                                loading={taskListLoading}
                                pagination={{
                                    showSizeChanger: true,
                                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                                    onChange: (page, pageSize) => onPageChange(page, pageSize),
                                    onShowSizeChange: (current, pageSize) =>
                                        handlePageSizeChange(current, pageSize),
                                    pageSize,
                                    current: page,
                                    total: taskMeta?.total_count,
                                }}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <Card loading bordered={false} style={{ background: 'transparent' }} />
            )}

            {createTaskVisible && (
                <CreateTask
                    title={`${activeTask ? 'Update' : 'Create New'} Task`}
                    projectId={subProjectId}
                    initialData={{ ...activeTask }}
                    visible={createTaskVisible}
                    onSubmit={onCreateTask}
                    onUpdate={onUpdateTask}
                    onCancel={onCancel}
                />
            )}
        </>
    );
}

export default memo(Tasks);
