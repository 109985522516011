import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import {
    AutoComplete, Col, Row, Spin, Tooltip,
} from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { SearchListModal } from './search-list-modal';

function SelectWithList(props) {
    const [visible, setVisible] = useState(false);
    const [selected, setSelectedValue] = useState(false);
    const [list, setList] = useState({ fetching: false, data: [], text: '' });

    const onSelection = (record) => {
        setVisible(false);
        setSelectedValue(record?.name);
        props?.onSelect({ ...record });
    };

    const onSearch = _.debounce(async (name) => {
        setSelectedValue(name);
        try {
            setList({ ...list, fetching: true, text: name });
            const response = await props?.onSearchFilter({
                name,
                id: props?.projectId,
                packageId: props?.pkgId,
            });
            setList({
                ...list,
                fetching: false,
                text: name,
                data: response?.data,
            });
        } catch (error) {
        }
    }, 500);

    useEffect(() => {
        setSelectedValue(props?.defaultValue);
    }, []);

    return (
        <>
            <Row gutter={15} className='w-100 align-items-center'>
                <Col span={5}>{props?.displayLabel}</Col>
                <Col span={13}>
                    <AutoComplete
                        value={selected}
                        className='w-100'
                        onSearch={onSearch}
                        notFoundContent={
                            list.fetching ? (
                                <div className="text-center">
                                    <Spin size="small" />
                                </div>
                            ) : list?.data?.length === 0 && list?.text ? (
                                'Not Found'
                            ) : null
                        }
                        options={(list?.data || []).map((item) => ({
                            ...item,
                            value: item.id,
                            label: item.name,
                        }))}
                        onSelect={(_, option) => onSelection(option)}
                        placeholder={props?.searchLabel || `Search`}
                        allowClear
                    />
                </Col>
                <Col span={6}>
                    <Tooltip title="View List">
                        <UnorderedListOutlined
                            onClick={() => setVisible(true)}
                        />
                    </Tooltip>
                </Col>
            </Row>
            {visible && (
                <SearchListModal
                    visible={visible}
                    onRow={onSelection}
                    projectId={props?.projectId}
                    onCancel={() => setVisible(!visible)}
                    title={props?.title}
                    width={props?.width}
                    pkgId={props?.pkgId}
                    columns={props?.columns}
                    fetchData={props?.onDataFetch}
                />
            )}
        </>
    );
}

SelectWithList.defaultProps = {
    projectId: null,
    pkgId: null,
    required: false,
    width: 800,
    enablePackages: true,
    formItemClass: null,
    onSearchFilter: () => { },
    fetchData: () => { },
};

SelectWithList.propTypes = {
    form: PropTypes.any,
    noLabel: PropTypes.bool,
    required: PropTypes.bool,
    onSearchFilter: PropTypes.func,
    fetchData: PropTypes.func,
    projectId: PropTypes.string,
    pkgId: PropTypes.string,
};

export default memo(SelectWithList);
