import { isEmpty, isNull } from 'lodash';
import React, { useState } from 'react';
import EquipmentDetails from '../../../../components/taglist/EquipmentDetails';
import { getTagsByPackage } from '../../../../store/tags';
import {
    Avatar, Breadcrumb, Layout, Card, Modal, Tooltip, Form, Row, Col, Spin,
} from 'antd';
import { useSelector } from 'react-redux';
import { fetchTagsByPackage } from '../../../../services';
import { makeAvatar } from '../../../../utils';
import { searchColumns } from './columns';
import SelectWithList from '../../../../components/select-with-list';

export function DetailsTab({
    detail,
    record,
    isLoading = false,
    onSelect = () => { },
    pkgId,
    projectId,
}) {
    const { masterTags, masterTagsLoading } = useSelector(({ tags }) => tags);
    return (
        <>
            <Row justify={'end'} gutter={16} className='mb-10 d-flex align-items-center'>
                {/* <Col span={6} className="d-flex align-items-center">
                    <SelectWithList
                        columns={searchColumns({})}
                        onDataFetch={fetchTagsByPackage}
                        displayLabel={`Equipment:`}
                        data={masterTags}
                        dataFetch={() => { }}
                        title="Equipment List"
                        projectId={projectId}
                        pkgId={pkgId}
                        form={null}
                        onSearch={fetchTagsByPackage}
                        loading={masterTagsLoading}
                        onSelect={(r) => onSelect(r?.id)}
                        onSearchFilter={fetchTagsByPackage}
                        label="Equipment"
                        name="name"
                        searchLabel={`Search Equipment`}
                        key={`Tag`}
                    />
                </Col> */}
                <Col className="d-flex align-items-center">
                    <div className="mr-10">Team Members: </div>
                    <Avatar.Group>
                        {detail?.team_members?.map((member, idx) => (
                            <Tooltip
                                key={member.email}
                                title={`${member.first_name} ${member.last_name}`}
                            >
                                <Avatar
                                    className="circle-icon"
                                    src={makeAvatar(
                                        member.first_name[0],
                                        member.last_name[0],
                                    )}
                                />
                            </Tooltip>
                        ))}
                    </Avatar.Group>
                </Col>
            </Row>
            <Spin spinning={isLoading}>
                <Row gutter={15}>
                    <Col span={20}>
                        <EquipmentDetails record={record} hideManualScheudle />
                    </Col>
                    <Col span={4}>
                        <img src={record?.tag_image_url} width={`100%`} />
                    </Col>
                </Row>
            </Spin>
        </>
    );
}
