export const FETCH_PROJECT_COSTS_INITIATE = 'FETCH_PROJECT_COSTS_INITIATE';
export const FETCH_PROJECT_COSTS_SUCCESS = 'FETCH_PROJECT_COSTS_SUCCESS';
export const FETCH_PROJECT_COSTS_FAILURE = 'FETCH_PROJECT_COSTS_FAILURE';

export const FETCH_PROJECT_COSTS_SUMMARY_INITIATE = 'FETCH_PROJECT_COSTS_SUMMARY_INITIATE';
export const FETCH_PROJECT_COSTS_SUMMARY_SUCCESS = 'FETCH_PROJECT_COSTS_SUMMARY_SUCCESS';
export const FETCH_PROJECT_COSTS_SUMMARY_FAILURE = 'FETCH_PROJECT_COSTS_SUMMARY_FAILURE';

export const DELETE_PROJECT_COSTS_INITIATE = 'DELETE_PROJECT_COSTS_INITIATE';
export const DELETE_PROJECT_COSTS_SUCCESS = 'DELETE_PROJECT_COSTS_SUCCESS';
export const DELETE_PROJECT_COSTS_FAILURE = 'DELETE_PROJECT_COSTS_FAILURE';

export const FETCH_CUSTOMER_PO_INITIATE = 'FETCH_CUSTOMER_PO_INITIATE';
export const FETCH_CUSTOMER_PO_SUCCESS = 'FETCH_CUSTOMER_PO_SUCCESS';
export const FETCH_CUSTOMER_PO_FAILURE = 'FETCH_CUSTOMER_PO_FAILURE';

export const FETCH_CUSTOMER_PO_SUMMARY_INITIATE = 'FETCH_CUSTOMER_PO_SUMMARY_INITIATE';
export const FETCH_CUSTOMER_PO_SUMMARY_SUCCESS = 'FETCH_CUSTOMER_PO_SUMMARY_SUCCESS';
export const FETCH_CUSTOMER_PO_SUMMARY_FAILURE = 'FETCH_CUSTOMER_PO_SUMMARY_FAILURE';

export const DELETE_CUSTOMER_PO_INITIATE = 'DELETE_CUSTOMER_PO_INITIATE';
export const DELETE_CUSTOMER_PO_SUCCESS = 'DELETE_CUSTOMER_PO_SUCCESS';
export const DELETE_CUSTOMER_PO_FAILURE = 'DELETE_CUSTOMER_PO_FAILURE';
