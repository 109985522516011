export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';

export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const GET_CHAT_MESSAGES_FAILED = 'GET_CHAT_MESSAGES_FAILED';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_INIT = 'GET_CHAT_MESSAGES_INIT';
export const SET_CHAT_MESSAGE = 'SET_CHAT_MESSAGE';
export const SET_CHAT_MESSAGE_FAILED = 'SET_CHAT_MESSAGE_FAILED';

export const RESET_PACKAGES = 'RESET_PACKAGES';
export const GET_CHAT_PACKAGES = 'GET_CHAT_PACKAGES';
export const GET_CHAT_PACKAGES_SUCCESS = 'GET_CHAT_PACKAGES_SUCCESS';
export const GET_CHAT_PACKAGES_FAILED = 'GET_CHAT_PACKAGES_FAILED';

export const UPDATE_INBOX_MESSAGE = 'UPDATE_INBOX_MESSAGE';
export const UPDATE_PROJECT_INBOX_MESSAGE = 'UPDATE_PROJECT_INBOX_MESSAGE';

export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_LIST_SUCCESS = 'GET_CONTACT_LIST_SUCCESS';
export const GET_CONTACT_LIST_FAILED = 'GET_CONTACT_LIST_FAILED_FAILED';

export const UPDATE_CONTACT_MESSAGE = 'UPDATE_CONTACT_MESSAGE';
export const ACTIVE_CONTACT_READ = 'ACTIVE_CONTACT_READ';
export const ACTIVE_PACKAGE_READ = 'ACTIVE_PACKAGE_READ';
