import _ from 'lodash';
import actionHandlers from './actionHandlers';

const initialState = {
    projects: [],
    loading: false,
    documents: {
        mainProjectDocs: {
            data: [],
            meta: {},
        },
        contractorDocs: {
            data: [],
            meta: {},
        },
        mainProjectEstimationDocs: {
            data: [],
            meta: {},
        },
        contractorEstimationDocs: {
            data: [],
            meta: {},
        },
    },
    errors: {
        message: '',
        state: false,
    },
    organizations: [],
    project: {},
    contractor: {},
    activeProject: {},
    activeContractor: {},
    financials: {
        summarySheet: {
            fetching: false,
            data: null,
            meta: {},
        },
        vendors: {
            meta: {},
            data: [],
            fetching: false,
        },
        vendorAssociates: {
            meta: {},
            data: null,
            fetching: false,
        },
        vendorItemDetail: {
            data: null,
            fetching: false,
        },
    },
};

export const biddingProjectsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const actionHandler = actionHandlers[type];

    if (actionHandler) {
        return actionHandler(state, payload);
    }

    return state;
};
