export const saveToLocal = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getFromLocal = (key) => {
  const data = localStorage.getItem(key);
  return JSON.parse(data);
};

export const removeFromLocal = (key) => {
  localStorage.removeItem(key);
};
