import _ from 'lodash';
import { Tooltip, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const projectUserColumns = ({
    setRecord = () => {},
}) => [
  {
    title: 'User',
    dataIndex: ['user', 'email'],
  },
];
