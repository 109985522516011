import { memo } from 'react';
import { Collapse } from 'antd';
import CustomerPurchaseOrderList from './CustomerPurchaseOrderList';
import CreateCustomerPurchaseOrder from './CreateCustomerPurchaseOrder';

const { Panel } = Collapse;

const CustomerPurchaseOrders = memo(() => {
    return (
        <Collapse className="mb-30">
            <Panel header="Customer Invoices" key="1" className='white-bg'>
                <CreateCustomerPurchaseOrder />
                <CustomerPurchaseOrderList />
            </Panel>
        </Collapse>
    );
});

export default CustomerPurchaseOrders;
