import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Tooltip, notification } from 'antd';
import { BIDDING_MANAGEMENT, CONTRACTORS } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBiddingProjects, setActiveContractor } from '../../../../store/bidding-management';
import { updateBidStatusForContractor } from '../../../../services/bidding-management';
import BidManagementWarningModal from '../../../../components/modals/upload-document/bid-management/BidManagementWarningModal';
import ProjectOwnerModal from './ProjectOwnerModal';

const ContractorListing = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { activeProject } = useSelector(({ bidding }) => bidding);
    const [showBidWonWarningModal, setShowBidWonWarningModal] = useState(false);
    const [selectedContractorId, setSelectedContractorId] = useState('');
    const [contractorAlreadyWon, setContractorAlreadyWon] = useState(false);
    const [showProjectOwnerModal, setShowProjectOwnerModal] = useState(false);

    const contractorBidWon = (contractorDetail) => {
        const { id, has_won } = contractorDetail;

        setSelectedContractorId(id);

        if (has_won || props.contractors.bid_won_by) {
            setShowBidWonWarningModal(true);
            setContractorAlreadyWon(has_won);
        } else {
            executeContractorBidUpdate(id);
        }
    };

    const confirmAndUpdateBidWinner = () => {
        setShowBidWonWarningModal(false);
        executeContractorBidUpdate(selectedContractorId);
    };

    const executeContractorBidUpdate = async (contractorId) => {
        try {
            const response = await updateBidStatusForContractor({ contractor_id: contractorId });
            if (response.success) {
                notification.success({
                    message: `${response.message}`,
                });
                dispatch(getAllBiddingProjects());
            }
        } catch (error) {
            notification.error({
                message: `${error?.response?.data?.message || 'Something unexpected happened'}`,
            });
        }
    };

    const generateItems = (contractor) => {
        return [
            {
                label: 'Edit',
                key: '1',
                onClick: (ev) => {
                    ev.domEvent.stopPropagation();
                    history.push(
                        `${BIDDING_MANAGEMENT}/contractor/create/${activeProject.id}/${contractor.id}`,
                    );
                },
            },
            {
                label: 'Bid Won',
                key: '2',
                onClick: (ev) => {
                    ev.domEvent.stopPropagation();
                    contractorBidWon(contractor);
                },
            },
            {
                label: 'Auto Transfer Project',
                key: '3',
                disabled: !contractor?.has_won,
                onClick: (ev) => {
                    ev.domEvent.stopPropagation();
                    setShowProjectOwnerModal(true);
                    setSelectedContractorId(contractor.id);
                },
            },
        ];
    };

    return (
        <ul>
            {props.contractors?.projects?.map((contractor) => (
                <li key={contractor.id}>
                    <div
                        className={`list-item-container ${
                            contractor.id ===
                            history.location.pathname.split('/')[
                                history.location.pathname.split('/').length - 1
                            ]
                                ? 'active'
                                : ''
                        }`}
                        onClick={(ev) => {
                            ev.stopPropagation();
                            dispatch(setActiveContractor(contractor));
                            history.push(
                                `${BIDDING_MANAGEMENT}/${activeProject.id}${CONTRACTORS}/${contractor.id}`,
                            );
                        }}
                    >
                        <div className="list-detail">
                            <Tooltip title={contractor?.name}>
                                <span className="sidebar-list-text">{contractor?.name}</span>
                            </Tooltip>
                            <div className="d-flex justify-space-between align-items-center">
                                {contractor.has_won ? (
                                    <Badge count={'Bid Won'} color="#63a66a" />
                                ) : null}
                                <Dropdown
                                    className="ml-5"
                                    menu={{ items: generateItems(contractor) }}
                                >
                                    <MoreOutlined />
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
            {showBidWonWarningModal && (
                <BidManagementWarningModal
                    showBidWonWarningModal={showBidWonWarningModal}
                    setShowBidWonWarningModal={setShowBidWonWarningModal}
                    confirmAndUpdateBidWinner={confirmAndUpdateBidWinner}
                    contractorAlreadyWon={contractorAlreadyWon}
                />
            )}
            {showProjectOwnerModal && (
                <ProjectOwnerModal
                    visible={showProjectOwnerModal}
                    contractorId={selectedContractorId}
                    onCancel={() => setShowProjectOwnerModal(false)}
                />
            )}
        </ul>
    );
};

ContractorListing.defaultProps = {
    contractors: [],
};

ContractorListing.propTypes = {
    contractors: PropTypes.array,
};

export default memo(ContractorListing);
