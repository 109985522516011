import {
  fetchEquipDocs, fetchFileHistory, fetchProjectDocs, fetchProjectDocsV2, fetchReviewDocs, fetchSharedDocuments,
} from '../../services';
import * as ActionTypes from './types';

export const saveDocumentsToStore = (documents, projectId) => (dispatch) => {
  dispatch({ type: ActionTypes.UPLOAD_DOCUMENTS_FROM_LOCAL, payload: { documents, projectId } });
};

export const switchDocumentsMode = (payload) => (dispatch) => {
  dispatch({ type: ActionTypes.SWITCH_TO_UPLOAD_DOCUMENTS_MODE, payload });
};

export const getProjectDocuments = (projectId) => async (dispatch) => {
  const response = await fetchProjectDocs(projectId);
  dispatch({
    type: ActionTypes.FETCH_PROJECT_DOCUMENTS,
    payload: response?.data,
  });
};

export const getProjectDocumentsV2 = (data) => async (dispatch) => {
  dispatch({ type: ActionTypes.FETCH_PROJECT_DOCUMENTS_V2_INIT });
  const response = await fetchProjectDocsV2(data);
  dispatch({
    type: ActionTypes.FETCH_PROJECT_DOCUMENTS_V2,
    payload: response,
  });
};

export const getReviewDocuments = (data) => async (dispatch) => {
  dispatch({ type: ActionTypes.FETCH_REVIEW_DOCUMENTS_INIT });
  const response = await fetchReviewDocs(data);
  dispatch({
    type: ActionTypes.FETCH_REVIEW_DOCUMENTS,
    payload: response,
  });
};

export const getEquipDocuments = (data) => async (dispatch) => {
  dispatch({ type: ActionTypes.FETCH_EQUIPMENT_DOCUMENTS_INIT });
  const response = await fetchEquipDocs(data);
  dispatch({
    type: ActionTypes.FETCH_EQUIPMENT_DOCUMENTS,
    payload: response,
  });
};

export const getSharedDocuments = (data, type) => async (dispatch) => {
  dispatch({ type: ActionTypes.FETCH_SHARED_DOCUMENTS_INIT, payload: { type } });
  const response = await fetchSharedDocuments(data, type);
  dispatch({
    type: ActionTypes.FETCH_SHARED_DOCUMENTS,
    payload: { ...response, type },
  });
};

export const getFileHistory = (data, type) => async (dispatch) => {
  dispatch({ type: ActionTypes.FETCH_DOCUMENT_HISTORY_INIT, payload: { type } });
  const response = await fetchFileHistory(data);
  dispatch({
    type: ActionTypes.FETCH_DOCUMENT_HISTORY_SUCCESS,
    payload: { ...response, type },
  });
};
