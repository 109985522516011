import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, message, Space, Tooltip } from 'antd';
import { DISPLAY_DATE_FORMAT, makeAvatar } from '../../../../../../utils';
import { getSignedUrl } from '../../../../../../services';

export default (setEditBinder = () => {}) => {
    const downloadDocument = async ({ s3_key, file_name }) => {
        try {
            const response = await getSignedUrl(s3_key);
            await axios.get(response.data, { headers: { 'Content-Disposition': 'attachment' } });
            saveAs(response.data, file_name);
        } catch (error) {
            message.error('Error in downloading document');
        }
    };

    return React.useMemo(() => [
        {
            key: 'file_name',
            title: 'File Name',
            dataIndex: 'file_name',
            // isSearchable: true,
            render: (value, record) => (
                <Space>
                    <Tooltip title="Download">
                        <DownloadOutlined
                            className="cursor-pointer"
                            onClick={() => downloadDocument(record)}
                        />
                    </Tooltip>
                    <span>{value || ''}</span>
                </Space>
            ),
        },
        {
            key: 'created_at',
            title: 'Uploaded At',
            dataIndex: 'created_at',
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
            render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
        },
        {
            key: 'uploaded_by',
            title: 'Created By',
            dataIndex: 'uploaded_by',
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
            align: 'center',
            render: (user) => (
                <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
                    <Avatar
                        size="small"
                        src={user?.image || makeAvatar(user?.first_name?.[0], user?.last_name?.[0])}
                    />
                </Tooltip>
            ),
        },
        {
            key: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <Tooltip title="Edit">
                    <EditOutlined
                        style={{ fontSize: 14 }}
                        onClick={() => setEditBinder({ visible: true, data: record })}
                    />
                </Tooltip>
            ),
        },
    ]);
};
