import { memo, useEffect, useState } from 'react';
import {
    Col,
    Input,
    Row,
    Form,
    Select,
    Button,
    Modal,
    Table,
    Space,
    AutoComplete,
    notification,
} from 'antd';
import { debounce, get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
    getOrganizations,
    getOrganizationTypes,
    getRoles,
    validateEmail,
    newRemoveUserFromProject,
    newfetchProjectDetail,
    addUserToProject,
    fetchProjectUsersDetailForBidding,
    removeUserForBiddingProject,
} from '../../../../services';
import { makeAvatar, PROJECT_API_ENUM, capitalizeText } from '../../../../utils';
import { MESSAGES } from '../../../../utils/messages';
import { addUserToProjectForBidding } from '../../../../services/bidding-management';
import { isExternalUser, isValueEmpty } from '../../../dashboard/helper';
import useColumns from './effects/useColumns';

const { Option } = Select;
const { Option: AutoCompleteOption } = AutoComplete;

function UserAccess({
    users = [],
    projectId = null,
    setUsers = () => {},
    existingUsers = [],
    goBack = () => {},
    onFinish = () => {},
    setLoader = () => {},
    role_templates = {},
    biddingPage = false,
}) {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [newUserRoles, setNewUserRoles] = useState([]);
    const { user } = useSelector(({ auth }) => auth);
    const [existingUserModal, setExistingUserModal] = useState(false);
    const [newOrganizations, setNewOrganizations] = useState([]);
    const [organizationTypes, setOrganizationTypes] = useState([]);
    const [orgTypeName, setOrgTypeName] = useState('');
    const [roleName, setRoleName] = useState('');
    const [orgId, setOrgId] = useState('');
    const [disableOrgType, setDisableOrgType] = useState(false);
    const [existingUser, setExistingUser] = useState({});

    const fetchOrganizationTypes = async () => {
        try {
            const result = await getOrganizationTypes();
            setOrganizationTypes(result.data);
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
            });
        }
    };

    const fetchUsers = async () => {
        try {
            setLoader(true);
            const response = await newfetchProjectDetail(PROJECT_API_ENUM.USERS, projectId);
            const projectUsers = response?.data
                .filter((usr) => usr.id !== user.id)
                .map((usr) => ({
                    ...usr,
                    organization_name: usr?.organization?.name,
                }));
            setUsers([...projectUsers]);
            setLoader(false);
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
            });
            setLoader(false);
        }
    };

    const fetchUsersForBidding = async () => {
        try {
            setLoader(true);
            const response = await fetchProjectUsersDetailForBidding(projectId);
            const projectUsers = response?.data
                .filter((usr) => usr.id !== user.id)
                .map((usr) => ({
                    ...usr,
                    organization_name: usr?.organization?.name,
                }));
            setUsers([...projectUsers]);
            setLoader(false);
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
            });
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchOrganizationTypes();
        if (projectId && !biddingPage) {
            fetchUsers();
        }
        if (projectId && biddingPage) {
            fetchUsersForBidding();
        }
    }, []);

    const addExistingUser = (values) => {
        const user = existingUsers.find((usr) => usr.id === values.user);
        user.new = false;
        values.organization = orgId;
        values.organization_type_name = orgTypeName;
        values.role_name = roleName;
        setUsers([...users, { ...user, ...values }]);
        resetState();
    };

    const onTypeChange = async (value, option, cb) => {
        try {
            if (option?.children) {
                setOrgTypeName(option.children);
            }
            form2.setFieldsValue({ role: null });
            form.setFieldsValue({ role: null });
            const result = await getRoles(value);
            setNewUserRoles([...result.data]);
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
            });
        }
    };

    const resetState = () => {
        setDisableOrgType(false);
        setOrgId(null);
        setOrgTypeName(null);
        setRoleName(null);
        setNewOrganizations([]);
        form.resetFields();
        form2.resetFields();
        setExistingUser({});
    };

    const addNewUser = async (values) => {
        try {
            setLoader(true);

            const localUser = users.find((usr) => usr.email === values.email);
            if (localUser) {
                form2.setFields([
                    { name: 'email', errors: ['User is already part of the project'] },
                ]);
                return;
            }

            if (user?.email === values?.email) {
                form2.setFields([
                    {
                        name: 'email',
                        errors: ['This is your own email id. Enter a different email id.'],
                    },
                ]);
                return;
            }

            values.organization = orgId;
            values.branch = user?.branch || null;
            values.role_name = roleName || existingUser?.permission_group?.name;
            values.group = values?.role_template || existingUser?.permission_group?.id;

            let response;
            if (!isEmpty(existingUser) && !biddingPage) {
                values.id = existingUser.id;
                response = await addUserToProject(PROJECT_API_ENUM.USERS, projectId, values);
            } else if (biddingPage) {
                const bidValues = {
                    ...values,
                    id: existingUser?.id,
                    // group: 6,
                    image_url: makeAvatar(values?.first_name[0], values?.last_name[0]),
                };
                response = await addUserToProjectForBidding(projectId, bidValues);
                fetchUsersForBidding();
            } else {
                values.new = true;
                values.image_url = makeAvatar(values?.first_name[0], values?.last_name[0]);
                response = await addUserToProject(PROJECT_API_ENUM.USERS, projectId, values);
            }

            if (response.success) {
                notification.success({
                    message: 'Success',
                    description: MESSAGES.GENERAL.USER_ADDED,
                });

                const updatedUser = {
                    ...response.data,
                    organization: response.data?.organization?.id,
                    organization_name: response.data?.organization?.name,
                    organization_type_name: response.data?.organization_type?.name,
                    role_name: response.data?.role?.name,
                    role: response.data?.role?.id,
                };

                setUsers([...users, updatedUser]);
            }

            resetState();
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
            });
        } finally {
            setLoader(false);
        }
    };

    const removeUser = async (userId) => {
        try {
            setLoader(true);
            if (biddingPage) {
                const newUsers = users.filter((user) => user.id !== userId);
                setUsers([...newUsers]);
                const response = await removeUserForBiddingProject(projectId, { user_id: userId });
                notification.success({ message: response.message });
                setLoader(false);
            } else {
                const newUsers = users.filter((user) => user.id !== userId);
                setUsers([...newUsers]);
                const response = await newRemoveUserFromProject(projectId, { id: userId });
                notification.success({ message: response.message });
                setLoader(false);
            }
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
            });
            setLoader(false);
        }
    };

    const onNext = () => {
        onFinish({ users });
    };

    const onSelect = (val, option) => {
        setOrgId(option.key);
        onTypeChange(option.org_type_id);
        setOrgTypeName(option.org_type);
        setDisableOrgType(true);
        form2.setFieldsValue({ organization_type: option.org_type_id });
        form.setFieldsValue({ organization_type: option.org_type_id });
    };

    const onSearch = debounce(async (val) => {
        try {
            setDisableOrgType(false);
            const results = await getOrganizations(val);
            setNewOrganizations([...results.data]);
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
            });
        }
    }, 400);

    const validateUser = async (ev) => {
        const user = await validateEmail({ email: ev.target.value });
        if (user.data) {
            form2.setFieldsValue({
                ...user.data,
                organization_name: user?.data?.organization?.name,
                organization_type: user?.data?.role?.org_type?.id,
                role: user?.data?.role?.name,
                role_template: user?.data?.permission_group?.id,
            });
            setExistingUser(user.data);
        }
    };

    const allColumns = useColumns(removeUser);

    const columns = biddingPage
        ? allColumns.filter((column) => column.title !== 'Permission')
        : allColumns;
    return (
        <>
            <h2 className="step-heading">Manage Users & Access Control</h2>
            <Form form={form2} layout="vertical" onFinish={addNewUser} validateTrigger="onSubmit">
                <Row gutter={10}>
                    <Col span={24}>
                        <Row gutter={10}>
                            <Col span={4}>
                                <Form.Item
                                    label="Email Address"
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Please enter valid email',
                                        },
                                        {
                                            required: true,
                                            message: 'Please enter the email',
                                        },
                                    ]}
                                >
                                    <Input
                                        onBlur={validateUser}
                                        disabled={isValueEmpty(existingUser, 'email')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the first name',
                                        },
                                    ]}
                                >
                                    <Input disabled={isValueEmpty(existingUser, 'first_name')} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the last name',
                                        },
                                    ]}
                                >
                                    <Input disabled={isValueEmpty(existingUser, 'last_name')} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Organization Name"
                                    name="organization_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select the organization type',
                                        },
                                    ]}
                                >
                                    <AutoComplete
                                        onSelect={onSelect}
                                        onSearch={onSearch}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        disabled={isValueEmpty(existingUser, 'organization')}
                                    >
                                        {newOrganizations.map((org) => (
                                            <AutoComplete.Option
                                                key={org?.id}
                                                value={org?.name}
                                                org_type={org?.org_type?.name}
                                                org_type_id={org?.org_type?.id}
                                            >
                                                {org?.name}
                                            </AutoComplete.Option>
                                        ))}
                                    </AutoComplete>
                                </Form.Item>
                            </Col>

                            {biddingPage ? (
                                <></>
                            ) : (
                                <Col span={4}>
                                    <Form.Item
                                        label="Permission"
                                        name="role_template"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select the role',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Please select"
                                            onSelect={(_, option) => setRoleName(option?.children)}
                                            disabled={
                                                !isEmpty(existingUser) &&
                                                !isExternalUser(existingUser)
                                            }
                                        >
                                            {role_templates?.data?.map((role) => (
                                                <Option key={role.id} value={role?.id}>
                                                    {capitalizeText(role?.name)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={4}>
                                <Form.Item>
                                    <Space>
                                        <Button
                                            style={{ width: 100 }}
                                            type="primary"
                                            className="mt-30"
                                            htmlType="submit"
                                        >
                                            Add
                                        </Button>
                                        <Button
                                            style={{ width: 100 }}
                                            type="default"
                                            className="mt-30"
                                            onClick={resetState}
                                        >
                                            Reset
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={users.map((item) => ({ ...item, key: item.id }))}
                columns={columns}
                className="taglist-table"
                size="small"
                pagination={users.length > 10}
            />
            <div className="text-right">
                <Space>
                    <Button
                        className="btn-lg btn-default save-btn mt-30"
                        type="default"
                        htmlType="button"
                        onClick={goBack}
                    >
                        Previous
                    </Button>
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        onClick={onNext}
                    >
                        {`${biddingPage ? 'Save & Finish' : 'Save & Next'}`}
                    </Button>
                </Space>
            </div>
            <Modal
                open={existingUserModal}
                onCancel={() => {
                    setExistingUserModal(false);
                    resetState();
                }}
                title="Add Existing User"
                width={900}
                okText="Add User"
                onOk={form.submit}
            >
                <Form layout="vertical" onFinish={addExistingUser} form={form}>
                    <Row gutter={15}>
                        <Col span={6}>
                            <Form.Item
                                label="Existing Users"
                                name="user"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the user',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Please select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    allowClear
                                >
                                    {existingUsers.map((user) => (
                                        <Option key={user.id} value={user.id}>
                                            {`${user.first_name} (${user.email})`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Organization Name"
                                name="organization_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the organization type',
                                    },
                                ]}
                            >
                                <AutoComplete
                                    onSelect={onSelect}
                                    onSearch={onSearch}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                >
                                    {newOrganizations.map((org) => (
                                        <AutoComplete.Option
                                            key={org.id}
                                            value={org?.name}
                                            org_type={org?.org_type?.name}
                                            org_type_id={org?.org_type?.id}
                                        >
                                            {org?.name}
                                        </AutoComplete.Option>
                                    ))}
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Organization Type"
                                name="organization_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the organization type',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    onChange={onTypeChange}
                                    disabled={disableOrgType}
                                >
                                    {organizationTypes.map((org) => (
                                        <Option key={org.id} value={org.id}>
                                            {org.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Role"
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the role',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    onSelect={(_, option) => setRoleName(option?.children)}
                                >
                                    {newUserRoles.map((role) => (
                                        <Option key={role.id} value={role.id}>
                                            {role.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default memo(UserAccess);
