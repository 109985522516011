import _ from 'lodash';
import { Tooltip, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const projectColumns = ({
  setModalType = () => {},
  setAdminRecord = () => {},
}) => [
  {
    title: 'Name',
    dataIndex: 'name',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Master Project',
    dataIndex: ['master_project', 'name'],
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Owner',
    dataIndex: ['owner'],
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Edit User Permissions',
    dataIndex: '',
    render: (_, record) => (
      <Space>
        <Tooltip title="Edit">
          <EditOutlined
            onClick={() => {
              setModalType('edit-user-permissions');
              setAdminRecord(record);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];
