import PropTypes from 'prop-types';
import { useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { AutoComplete, Form, message, Modal, Spin } from 'antd';
import { BASIC_REQUIRED_VALIDATION_RULES } from '../../../../utils';
import { validateEmail } from '../../../../services';
import { transferBidProject } from '../../../../services/bidding-management';
import { getAllBiddingProjects } from '../../../../store/bidding-management';

const ProjectOwnerModal = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [users, setUsers] = useState({ fetching: false, data: [], text: '' });
    const { activeProject } = useSelector(({ bidding }) => bidding);

    const onSearching = debounce(async (email) => {
        if (email) {
            setUsers({ ...users, fetching: true, text: email });
            const response = await validateEmail({ email });
            setUsers({
                ...users,
                fetching: false,
                data: response.data ? [...users.data, response.data] : [],
            });
        } else {
            setSelectedUser({});
            setUsers({ ...users, fetching: false, data: [] });
        }
    }, 500);

    const onSelectUser = (option) => {
        setSelectedUser(option);
        form.setFieldsValue({ email: `${option.first_name} ${option.last_name}` });
    };

    const onProjectTransfer = async () => {
        try {
            if (isEmpty(selectedUser)) {
                form.setFields([{ name: 'email', errors: ['Please select user'] }]);
                return;
            }
            setSubmitting(true);
            await transferBidProject(props.contractorId, { owner_id: selectedUser.id });
            setSubmitting(false);
            message.success('Project Transfered Successfully');
            props.onCancel();
            dispatch(getAllBiddingProjects());
        } catch (error) {
            console.log(error);
            setSubmitting(false);
        }
    };

    return (
        <Modal
            title="Add Project Owner"
            okText="Transfer Project"
            open={props.visible}
            onCancel={props.onCancel}
            onOk={onProjectTransfer}
            confirmLoading={submitting}
        >
            <Spin spinning={submitting} tip="Transfering ...">
                <Form layout="vertical" form={form}>
                    <Form.Item
                        name="email"
                        label="Project Owner"
                        rules={BASIC_REQUIRED_VALIDATION_RULES}
                    >
                        <AutoComplete
                            onSearch={onSearching}
                            notFoundContent={
                                users.fetching ? (
                                    <div className="text-center">
                                        <Spin size="small" />
                                    </div>
                                ) : users?.data?.length === 0 && users.text ? (
                                    'Not Found'
                                ) : null
                            }
                            options={(users.data || []).map((item) => ({
                                ...item,
                                value: item.id,
                                label: `${item.first_name} ${item.last_name}`,
                            }))}
                            onSelect={(_, option) => onSelectUser(option)}
                            placeholder={'Enter Email Address'}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

ProjectOwnerModal.defaultProps = {
    visible: false,
    contractorId: '',
    onCancel: () => {},
};

ProjectOwnerModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    contractorId: PropTypes.string,
};

export default ProjectOwnerModal;
