import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons';
import { Avatar, message, Space, Tooltip } from 'antd';
import { DISPLAY_DATE_FORMAT, makeAvatar, renderAmount } from '../../../../../../../utils';

export default () => {
  const downloadDocument = async ({ signed_url, file_name }) => {
    try {
      await axios.get(signed_url, { headers: { 'Content-Disposition': 'attachment' } });
      saveAs(signed_url, file_name);
    } catch (error) {
      message.error('Error in downloading document');
    }
  };

  return React.useMemo(() => [
    {
      key: 'vendor',
      title: 'Vendor',
      dataIndex: 'distributor_po',
      render: (po) => po?.vendor?.name || '-',
      isSearchable: true,
    },
    // {
    //   key: 'reason',
    //   title: 'Reason',
    //   dataIndex: 'distributor_po',
    //   render: (po) => PROJECT_COST_REASONS[po?.reason] || '-',
    // },
    {
      key: 'invoice_date',
      title: 'Invoice Date',
      dataIndex: 'invoice_date',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
    },
    {
      key: 'invoice_number',
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => value || '-',
    },
    {
      key: 'invoice_cost',
      title: 'Invoice Cost',
      dataIndex: 'distributor_po',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (po) => renderAmount(po?.amount),
    },
    {
      key: 'file_name',
      title: 'File Name',
      dataIndex: 'file_name',
      isSearchable: true,
      render: (value, record) => (
        <Space>
          <Tooltip title="Download">
            <DownloadOutlined className="cursor-pointer" onClick={() => downloadDocument(record)} />
          </Tooltip>
          <span>{value || ''}</span>
        </Space>
      ),
    },
    {
      key: 'created_at',
      title: 'Uploaded At',
      dataIndex: 'created_at',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
    },
    {
      key: 'uploaded_by',
      title: 'Uploaded By',
      dataIndex: 'uploaded_by',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      render: (user) => (
        <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
          <Avatar
            size="small"
            src={user?.image || makeAvatar(user?.first_name?.[0], user?.last_name?.[0])}
          />
        </Tooltip>
      ),
    },
  ]);
};
