import _ from 'lodash';
import { Tooltip, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const branchColumns = ({
  setModalType = () => {},
  setAdminRecord = () => {},
  setShowModal = () => {},
}) => [
  {
    title: 'Company',
    dataIndex: ['company', 'name'],
  },
  {
    title: 'Branch Name',
    dataIndex: 'name',
  },
  {
    title: 'Edit Permissions',
    dataIndex: '',
    render: (_, record) => (
      <Space>
        <Tooltip title="Edit">
          <EditOutlined
            onClick={() => {
              setModalType('update-branch');
              setAdminRecord(record);
              setShowModal(true);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];
