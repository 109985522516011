import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Tooltip, Avatar, Space, Tag, Button } from 'antd';
import { ExpandOutlined } from '@ant-design/icons';
import { ProgressBar } from '../index';
import { PROJECTS } from '../../utils';

export const progressViewColumns = ({
    permissions,
    handleApprovalDrawing,
    setModalType,
    setRecord,
}) => {
    const history = useHistory();

    const goToTagDetail = (record) => {
        const url = `${PROJECTS}/${record?.project}/package/${record?.packageroom?.[0].id}?tag_id=${record?.id}`;
        history.push(url);
    };

    return React.useMemo(() => [
        {
            title: 'Ref #',
            dataIndex: 'reference_number',
            width: 110,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (value, record) => <Button type="link" onClick={() => goToTagDetail(record)}>{value}</Button>,
        },
        {
            title: 'Line #',
            dataIndex: 'line_number',
            width: 140,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Tag Name',
            dataIndex: 'name',
            width: 150,
            render: (text, record) => (
                <Space size="middle">
                    <Avatar
                        src={
                            record.tag_image_url ||
                            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlBRzUn5IDV4IYZHfu5NUJ7OaY9WEMTTWOQQ&usqp=CAU'
                        }
                    />
                    <Tooltip placement="topLeft" title={text}>
                        <p className="mb-0 tagName-master-td" title={text}>
                            {text}
                        </p>
                    </Tooltip>
                </Space>
            ),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 150,
            render: (text, record) => ({
                props: {
                    className: 'description-td',
                },
                children: (
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                ),
            }),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 150,
        },
        {
            title: 'Manual Schedule',
            dataIndex: 'is_manual_tracking',
            width: 150,
            align: 'left',
            render: (status, record) => ({
                children: (
                    <Tag
                        className="custom-tag"
                        style={!record?.is_manual_tracking ? { color: '#555' } : {}}
                        color={record?.is_manual_tracking ? '#1dbf73' : '#eee'}
                    >
                        {record?.is_manual_tracking ? 'Yes' : 'No'}
                    </Tag>
                ),
            }),
        },
        {
            title: 'Equipment Progress',
            dataIndex: 'progress',
            render: (data, record) => {
                const progress = Number(data);
                return (
                    <div style={{ width: '90%' }}>
                        <ProgressBar
                            progress={Number(data).toFixed()}
                            text={`${
                                record?.order_date
                                    ? moment(record?.order_date).format('DD MMM, YYYY')
                                    : 'N/A'
                            } - ${
                                record?.ship_date
                                    ? moment(record?.ship_date).format('DD MMM, YYYY')
                                    : 'N/A'
                            }`}
                            bgcolor={progress >= 100 ? '#e37172' : ''}
                            height={22}
                        />
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => (
                <Space>
                    <Tooltip title="View">
                        <ExpandOutlined
                            onClick={(event) => {
                                event.stopPropagation();
                                setModalType('view');
                                setRecord(record);
                            }}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ]);
};
