import React, { useEffect, useState } from 'react';
import {
  Avatar, Empty, Badge, Input,
} from 'antd';
import { DownloadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import '../../assets/styles/components/dnd.scss';
import './chat-widget.scss';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import _ from 'lodash';
import { makeAvatar } from '../../utils';
import { getAllUserChatMessages, setChatMessage } from '../../store/chat';

const WS_BASE_URL = process.env.REACT_APP_WS_URL;

export function SingleChat({ contact, closeChat }) {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const [socketUrl, setSocketUrl] = useState(`${WS_BASE_URL}`);
  const [message, setMessage] = useState('');
  const { loading, messageData = [] } = useSelector(({ chat }) => chat);
  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);

  const {
    sendMessage, sendJsonMessage, readyState, lastJsonMessage,
  } = useWebSocket(socketUrl, {
    onOpen: () => { },
    onClose: () => { },
    onMessage: (m) => { },
    onError: () => { },
    onReconnectStop: () => { },
    shouldReconnect: (closeEvent) => false,
    retryOnError: false,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const onSendMessage = () => {
    if (!_.isEmpty(message.trim())) {
      sendJsonMessage({
        from: user?.id,
        text: message,
        content_type: 'text',
      });
      setMessage('');
    }
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      // Ignore if last message is send by same user
      const sent_by = JSON.parse(lastJsonMessage?.sent_by);
      if (sent_by?.id === user?.id) {
        sent_by.id = lastJsonMessage?.sent_to_user;
      }
      dispatch(setChatMessage({
        id: sent_by?.id,
        message: lastJsonMessage,
        user: JSON.parse(lastJsonMessage?.sent_by),
      }));

      setTimeout(() => { scrollToBottom(lastJsonMessage); }, 200);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    getChatMessages({
      id: contact?.id,
      page,
      pageLimit,
    });
    setSocketUrl(`${WS_BASE_URL}/user/${contact?.id}/?${user.token}`);
  }, [contact]);

  const getChatMessages = ({ id, page, pageLimit }) => {
    setPage(page + 1);
    dispatch(getAllUserChatMessages({
      id,
      page: page + 1,
      pageLimit,
    }));
  };

  const scrollToBottom = (m) => {
    const element = document.getElementById('chat');
    element && element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
  };

  const info = _.find(messageData, ['id', contact?.id], null);

  return (
    <div className="single-chat">
      <div className="chat-header">
        <div>
          {contact?.first_name}
          {' '}
          {contact?.last_name}
          <div className="remove-contact">
            <CloseCircleOutlined onClick={() => closeChat(null)} style={{ color: '#16aaff' }} />
          </div>
          {connectionStatus === 'Open' && <Badge dot status="success" size="large" />}
        </div>
      </div>
      <div className="chat-content">
        <div className="chat" id="chat">
          <div className="message-box">
            <div className="messages">
              {
                info?.messages.length
                  ? info?.messages.map((m) => (
                    <div className={`chat-message-box ${user?.id === m?.sent_by?.id && 'justify-end'}`} key={m?.id} id={m?.id}>
                      <div className="chat-message">
                        {
                          user?.id === m.sent_by?.id
                            ? (
                              <Avatar
                                size="small"
                                src={
                                  user?.image
                                  || makeAvatar(user?.first_name[0], user?.last_name[0])
                                }
                              />
                            )
                            : (
                              <Avatar
                                size="small"
                                src={
                                  m.sent_by?.image
                                  || makeAvatar(m.sent_by?.first_name[0], m.sent_by?.last_name[0])
                                }
                              />
                            )
                        }
                        {
                          m?.content_type === 'text' ? (
                            <div className="content text-message">
                              <div className="text">{m.text}</div>
                              <div className="time">{new Date(m.created_at).toLocaleString()}</div>
                            </div>
                          )
                            : (
                              <div className="content file-message">
                                <a href={m?.s3_url} target="_blank" rel="noreferrer">
                                  <DownloadOutlined style={{ fontSize: '16px', color: '#08c', marginRight: 10 }} />
                                  <div className="text">{m?.text || m?.file_name}</div>
                                </a>
                                <div className="time">{new Date(m?.created_at).toLocaleString()}</div>
                              </div>
                            )
                        }

                      </div>
                    </div>
                  ))
                  : (
                    <>
                      {!loading && (
                        <Empty
                          description="No messages to display"
                          className="mt-30"
                        />
                      )}
                    </>
                  )
              }
            </div>
          </div>

        </div>
        <Input
          className="message-input"
          placeholder="Type message ..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          onPressEnter={(e) => onSendMessage(e)}
        />
      </div>
    </div>
  );
}
