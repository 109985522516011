import _ from 'lodash';
import { RESET_STATE } from '../common';
import * as ActionTypes from './types';

const initialState = {
  list: [],
  masterTags: [],
  shippingDetails: [],
  updateLoader: false,
  loading: false,
  shippingLoader: false,
  masterTagsLoading: false,
  error: null,
};

export const tagReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.TAG_LIST:
      return { ...state, list: payload };

    case ActionTypes.GET_MASTER_TAGS_LOADING:
    case ActionTypes.UPDATE_TAG_INITIATE:
      return { ...state, masterTagsLoading: true };

    case ActionTypes.GET_MASTER_TAGS_SUCCESS:
      return { ...state, masterTags: payload, masterTagsLoading: false };

    case ActionTypes.UPDATE_TAG_SUCCESS:
      return { ...state, masterTagsLoading: false };

    case ActionTypes.GET_SHIPPING_UPDATE_LIST:
      return { ...state, shippingLoader: true };

    case ActionTypes.GET_SHIPPING_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        shippingDetails: payload?.data,
        shippingMeta: payload?.meta,
        shippingLoader: false,
      };
    case ActionTypes.GET_SHIPPING_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        shippingLoader: false,
      };
    case ActionTypes.UPDATE_SHIPPING:
      return { ...state, updateLoader: false };
    case ActionTypes.UPDATE_SHIPPING_SUCCESS:
      console.log(_.sortBy([...state.shippingDetails, payload?.data], ['updated_at', 'asc']));
      return {
        ...state,
        shippingDetails: _.sortBy([...state.shippingDetails, payload?.data], ['updated_at']).reverse(),
        updateLoader: false,
      };
    case ActionTypes.UPDATE_SHIPPING_FAILURE:
      return { ...state, updateLoader: false };
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
