import React, { useEffect } from 'react';
import { Card, Space, Tag } from 'antd';
import { AlectifyTable } from '../../../../../../components';
import useColumns from './effects/useColumns';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFinancialEstimationDocumentsMainProject } from '../../../../../../store/bidding-management';
import './index.scss';

export default () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const columns = useColumns();

    const {
        documents: { mainProjectEstimationDocs },
        fetching,
    } = useSelector(({ bidding }) => bidding);

    useEffect(() => {
        dispatch(
            getFinancialEstimationDocumentsMainProject(projectId, {
                page: 1,
                per_page: 10,
            }),
        );
    }, [projectId]);

    const getDocuments = (pagination) => {
        const params = {
            page: pagination?.page || 1,
            per_page: pagination?.per_page || 10,
        };
        dispatch(getFinancialEstimationDocumentsMainProject(projectId, params));
    };

    return (
        <Card
            title={
                <Space>
                    <span>Master Vendor Documents</span> <Tag color='green'>Read Only</Tag>
                </Space>
            }
            bordered
        >
            <AlectifyTable
                columns={columns}
                defaultPageSize={10}
                total={mainProjectEstimationDocs?.meta?.total_count || 0}
                dataSource={mainProjectEstimationDocs?.data || []}
                loading={fetching}
                onDataFetch={getDocuments}
                selectRows={false}
            />
        </Card>
    );
};
