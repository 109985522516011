import {
    AUTO_TRANSFER_PROJECT,
    CLONE_CONTACTOR_FINANCIALS_FROM_PROJECT,
    CRAETE_BIDDING_PROJECT_URL,
    CREATE_BIDDING_VENDOR_ESTIMATION,
    CREATE_CONTRACTORS,
    DELETE_CONTRACTOR_VENDOR_QUOTES,
    FETCH_BIDDING_PROJECT_URL,
    FETCH_BID_PROJECT_FINANCIAL_SUMMARY,
    FETCH_BID_VENDOR_ESTIMATIONS,
    FETCH_CONTRACTORS_VENDORS_SUMMARY,
    FETCH_CONTRACTORS_VENDOR_QUOTES,
    GET_CONTRACTOR_DOCUMENTS,
    GET_CONTRACTOR_FINANCIAL_ESTIMATE_DETAIL,
    GET_CONTRACTOR_FINANCIAL_ESTIMATE_DOCUMENTS,
    GET_MAIN_PROJECT_DOC_LIST,
    GET_VENDOR_ESTIMATION_PROJECT_DOCUMENTS,
    UPDATE_BIDDING_USER,
    UPDATE_CONTRACTORS_VENDOR_QUOTE,
    UPDATE_CONTRACTOR_WIN_STATUS,
    UPLOAD_CONTRACTOR_DOCUMENTS,
    UPLOAD_MAUN_PROJECT_VENDOR_DOC,
} from '../utils/endpoints';
import { fileHttp, http } from '../utils/http';

export const fetchBiddingProjects = (params = null) =>
    http.get(FETCH_BIDDING_PROJECT_URL, params || {});

export const postMainProjectAPI = async (body, params = {}) => {
    try {
        const response = await http.post(CRAETE_BIDDING_PROJECT_URL, body);
        return response?.data?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateMainProjectAPI = async (body, params = {}) => {
    try {
        const response = await http.patch(`${CRAETE_BIDDING_PROJECT_URL}${body.id}/`, body);
        return response.data?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchProjectDetailById = (id) => http.get(`${FETCH_BIDDING_PROJECT_URL}${id}/`);

export const fetchBiddingVendorEstimations = async (projectId, params) => {
    try {
        const response = await http.get(
            FETCH_BID_VENDOR_ESTIMATIONS.replace('{projectId}', projectId),
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchBiddingProjectFinancialSummary = async (projectId) => {
    try {
        const response = await http.get(
            FETCH_BID_PROJECT_FINANCIAL_SUMMARY.replace('{projectId}', projectId),
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const createBiddingVendorEstimation = async (projectId, body) => {
    try {
        const response = await http.post(
            CREATE_BIDDING_VENDOR_ESTIMATION.replace('{projectId}', projectId),
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateBiddingVendorEstimations = async (projectId, vendorItemId, body) => {
    try {
        const response = await http.patch(
            `${CREATE_BIDDING_VENDOR_ESTIMATION.replace('{projectId}', projectId)}${vendorItemId}/`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const deleteBiddingVendorEstimations = async (projectId, vendorItemId) => {
    try {
        const response = await http.delete(
            `${CREATE_BIDDING_VENDOR_ESTIMATION.replace('{projectId}', projectId)}`,
            {
                vendor_estimate_ids: [vendorItemId],
            },
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchBiddingVendorEstimationsById = async (projectId, vendorId, params = {}) => {
    try {
        const response = await http.get(
            `${CREATE_BIDDING_VENDOR_ESTIMATION.replace('{projectId}', projectId)}${vendorId}/`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchContractorsVendorQuotes = async (contractorId, params) => {
    try {
        const response = await http.get(
            `${FETCH_CONTRACTORS_VENDOR_QUOTES.replace('{contractorId}', contractorId)}`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateContractorsVendorQuotes = async (contractorId, vendorQuoteId, body) => {
    try {
        const response = await http.patch(
            `${UPDATE_CONTRACTORS_VENDOR_QUOTE.replace('{contractorId}', contractorId).replace(
                '{vendorQuoteId}',
                vendorQuoteId,
            )}`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchContractorsVendorsSummary = async (contractorId) => {
    try {
        const response = await http.get(
            `${FETCH_CONTRACTORS_VENDORS_SUMMARY.replace('{contractorId}', contractorId)}`,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const createContractors = async (body) => {
    try {
        const response = await http.post(`${CREATE_CONTRACTORS}`, body);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateBidStatusForContractor = async (body) => {
    try {
        const response = await http.post(`${UPDATE_CONTRACTOR_WIN_STATUS}`, body);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchContractor = async (id) => {
    try {
        const response = await http.get(`${CREATE_CONTRACTORS}${id}/`);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateContractorById = async (body) => {
    try {
        const response = await http.patch(`${CREATE_CONTRACTORS}${body.id}/`, body);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchMainProjectDocuments = async (projectId, params) => {
    try {
        const response = await http.get(
            `${GET_MAIN_PROJECT_DOC_LIST.replace('{projectId}', projectId)}`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const uploadMainProjectBiddingVendorDocs = async (
    projectId,
    vendorAssociateId,
    formData,
) => {
    try {
        const response = await fileHttp.post(
            `${UPLOAD_MAUN_PROJECT_VENDOR_DOC.replace('{projectId}', projectId).replace(
                '{vendorId}',
                vendorAssociateId,
            )}`,
            formData,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const vendorEstimatesDocumentMainProject = async (projectId, params) => {
    try {
        const response = await http.get(
            `${GET_VENDOR_ESTIMATION_PROJECT_DOCUMENTS.replace('{projectId}', projectId)}`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const uploadBidDocument = async (projectId, formData) => {
    try {
        const response = await fileHttp.post(
            `${GET_MAIN_PROJECT_DOC_LIST.replace('{projectId}', projectId)}`,
            formData,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const cloneContractorFinancialsFromProject = async (
    contractorId,
    body = { vendor_estimate_ids: [] },
) => {
    try {
        const response = await http.post(
            `${CLONE_CONTACTOR_FINANCIALS_FROM_PROJECT.replace('{contractorId}', contractorId)}`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const deleteContractorVendorEstimations = async (ids = [], contractorId) => {
    try {
        const response = await http.delete(
            `${DELETE_CONTRACTOR_VENDOR_QUOTES.replace('{contractorId}', contractorId)}`,
            {
                vendor_estimate_ids: ids,
            },
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const uploadContractorDocuments = async (contractorId, formData) => {
    try {
        const response = await fileHttp.post(
            `${UPLOAD_CONTRACTOR_DOCUMENTS.replace('{contractorId}', contractorId)}`,
            formData,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchAllContractorDocs = async (contractorId, params) => {
    try {
        const response = await http.get(
            `${GET_CONTRACTOR_DOCUMENTS.replace('{contractorId}', contractorId)}`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchContractorFinancialDocs = async (contractorId, params) => {
    try {
        const response = await http.get(
            `${GET_CONTRACTOR_FINANCIAL_ESTIMATE_DOCUMENTS.replace(
                '{contractorId}',
                contractorId,
            )}`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchContractorVendorEstimatesById = async (contractorId, estimationId, params) => {
    try {
        const response = await http.get(
            `${GET_CONTRACTOR_FINANCIAL_ESTIMATE_DETAIL.replace(
                '{contractorId}',
                contractorId,
            ).replace('{estimateId}', estimationId)}`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const addUserToProjectForBidding = async (projectId, body) => {
    try {
        const response = await http.post(
            `${UPDATE_BIDDING_USER.replace('{projectId}', projectId)}`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const transferBidProject = async (projectId, body) => {
    try {
        const response = await http.post(
            `${AUTO_TRANSFER_PROJECT.replace('{projectId}', projectId)}`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};
