import React from 'react';

import { Card, Progress } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';

import './card.scss';

export function Analytics() {
  return (
    <>
      {/* <Card className="lightColor">
                <Progress
                    strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }}
                    percent={50}
                    size="small"
                    status="active"
                />
            </Card>
            <Card title="Predictions" className="cardBody">
                <p>1. Test area for LV breakers is expected to be full for next six weeks.</p>
                <p>2. LV breaker trip unit shortage expected.</p>
                <p>3. Supply chain issues - electronic components shortage.</p>
                <p
                    style={{
                        fontWeight: '400',
                    }}
                >
                    No Predictions Found
                </p>
            </Card> */}
      <Card title="Factory acceptance test" className="cardBodyBottom">
        <div className="cardBottom">
          <p style={{ fontSize: '110%' }}> Video Analytics </p>
          <VideoCameraOutlined style={{ fontSize: '160%' }} />
        </div>
      </Card>
    </>
  );
}

export default Analytics;
