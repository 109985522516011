import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import { AlectifyTable } from '../../../../../components';
import { fetchManualSchedules } from '../../../../../services';
import useColumns from './effects/useColumns';
import EditManualSchedule from './EditManualSchedule';
import ViewModal from '../../../../../components/taglist/ViewModal';

const ManualSchedule = ({ projectId }) => {
    const { subProjectId } = useParams();
    const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
    const [action, setAction] = useState({ detail: false, edit: false, data: null });
    const [manualSchedules, setManualSchedules] = useState({ fetching: false, data: [], total: 0 });

    const getManualSchedule = async (_params) => {
        try {
            setManualSchedules({ ...manualSchedules, fetching: true });
            if (!isEmpty(_params)) {
                setPagination({ page: _params?.page, pageSize: _params?.pageSize });
            }
            const params = {
                page: _params?.page || pagination.page,
                per_page: _params?.pageSize || pagination.pageSize,
            };
            const response = await fetchManualSchedules(projectId || subProjectId, params);
            setManualSchedules({
                fetching: false,
                data: response.data,
                total: response.meta.total_count,
            });
        } catch (error) {
            console.log(error);
            setManualSchedules({ ...manualSchedules, fetching: false });
        }
    };

    useEffect(() => {
        getManualSchedule();
    }, [projectId || subProjectId]);

    const onActionClick = (type, record) => {
        setAction({ ...action, [type]: true, data: record });
    };

    const columns = useColumns(onActionClick);

    return (
        <>
            <h1 className="mb-15">Equipments with Manual Dates Schedule</h1>
            <div
                className="page-content-container"
                style={{
                    padding: 0,
                    overflow: 'hidden',
                    minHeight: 'auto',
                }}
            >
                <AlectifyTable
                    bordered
                    columns={columns}
                    selectRows={false}
                    scroll={{ x: 1500 }}
                    defaultPageSize={10}
                    total={manualSchedules.total}
                    onDataFetch={getManualSchedule}
                    dataSource={manualSchedules.data}
                    className="manual-schedule-table"
                    loading={manualSchedules.fetching}
                />
            </div>
            {action.edit && (
                <EditManualSchedule
                    data={action.data}
                    visible={action.edit}
                    onCancel={() => setAction({ ...action, edit: false, data: null })}
                    getManualSchedule={getManualSchedule}
                />
            )}
            {action.detail && (
                <ViewModal
                    record={action.data}
                    visible={action.detail}
                    onCancel={() => setAction({ ...action, detail: false, data: null })}
                />
            )}
        </>
    );
};

export default memo(ManualSchedule);
