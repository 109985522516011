import React, { useState } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { find } from 'lodash';
import SearchModal from './SearchModal';
import { fetchProjectSearchResults } from '../../../services';

export function SelectFromList({
  key,
  form,
  taskLevel,
  label = 'Equipment',
  name = 'equipment',
  title = '',
  columns = [],
  onDataFetch = () => { },
  onSearch = fetchProjectSearchResults,
  onSelect = () => { },
  setValue = () => { },
  projectId = '',
  packageId = '',
  loading = false,
  data = {},
  required = false,
  disabled = true,
  displayList = true,
}) {
  const [visible, setVisible] = useState(false);
  const [record, setRecord] = useState(null);
  const [options, setOptions] = useState([]);

  const onRowClick = (record, rowIndex) => {
    setVisible(!visible);
    setRecord(record);
    onSelect(record?.id);
    setValue(record?.packageroom?.[0]?.id);
    if (form) {
      form.setFieldValue('id', record?.id);
      form.setFieldValue(name, record?.id || record?.packageroom?.[0]?.id);
    }
  };

  const onSearchValue = async (value) => {
    if (value?.length) {
      const response = await onSearch({ id: projectId, packageId, body: { search_str: value } });
      let data;
      if (taskLevel === 'Tag') {
        data = response?.data?.tags;
      } else if (taskLevel === 'Package') {
        data = response?.data?.packages;
      } else {
        data = response?.data;
      }
      setOptions(data);
    }
  };

  const onChangeValue = async (option) => {
    if (taskLevel === 'Tag') {
      setRecord({ ...option, name: option?.label });
      onSelect(option?.value);

      const selection = find(options, ['id', option?.value]);
      setValue(selection?.packageroom?.[0]);
      if (form) {
        form.setFieldValue(name, selection?.id);
      }
    } else {
      setRecord({ ...option, name: option?.label });
      const selection = find(options, ['id', option?.value]);
      setValue(selection);
      onSelect(option?.value);
      if (form) {
        form.setFieldValue(name, selection?.id);
      }
    }
  };

  return (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required,
            message: 'Required',
          },
        ]}
      >
        <Select
          placeholder="Please select"
          showSearch
          defaultValue={record && record?.name}
          value={record && record?.name}
          onSearch={onSearchValue}
          onChange={(e, o) => onChangeValue(o)}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          disabled={disabled}
          options={options.map((o) => ({
            value: o?.id,
            label: o?.name,
          }))}
        />
        {
          displayList
          && (
            <Tooltip title="View List">
              <UnorderedListOutlined
                style={{ marginLeft: '2%' }}
                onClick={() => setVisible(true)}
              />
            </Tooltip>
          )
        }
      </Form.Item>
      <SearchModal
        columns={columns}
        visible={visible}
        title={title}
        data={data}
        projectId={projectId}
        packageId={packageId}
        onDataFetch={onDataFetch}
        onCancel={() => setVisible(!visible)}
        onRow={onRowClick}
        loading={loading}
      />
    </>
  );
}
