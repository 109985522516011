import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Tooltip, Empty, Button } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { searchBidProjects } from '../MainProjects/BiddingManagementListing.helper';
import { getAllBiddingProjects } from '../../../store/bidding-management';
import Sidebar from './Sidebar';
import './index.scss';
import { BIDDING_MANAGEMENT } from '../../../utils';

const { Content } = Layout;

export default (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    const [hideSidebar, setHideSidebar] = useState(false);
    const [filteredProjects, setFilteredProjects] = useState(null);
    const [projectSearchText, setProjectSearchText] = useState('');
    const bidding = useSelector(({ bidding }) => bidding);
    const { projects, loading } = bidding;
    const history = useHistory();

    useEffect(() => {
        dispatch(getAllBiddingProjects());
    }, []);

    const onSearchProject = async (event) => {
        setProjectSearchText(event.target.value);
        const filtered = await searchBidProjects(projects, event.target.value);
        setFilteredProjects([...filtered]);
    };

    return (
        <Content
            className={hideSidebar ? 'position-relative mb-0 mt-0 ml-20' : 'content-width-sidebar'}
        >
            {hideSidebar && (
                <Tooltip title="Open Projects" placement="right">
                    <div
                        className="sidebar-toggler open"
                        onClick={() => setHideSidebar(!hideSidebar)}
                    >
                        <DoubleRightOutlined />
                    </div>
                </Tooltip>
            )}
            <Row gutter={30}>
                <Col span={hideSidebar ? 0 : 5}>
                    <Sidebar
                        hideSidebar={hideSidebar}
                        projects={projects}
                        setHideSidebar={setHideSidebar}
                        filteredProjects={filteredProjects}
                        history={history}
                        loading={loading}
                        projectSearchText={projectSearchText}
                        onSearchProject={onSearchProject}
                    />
                </Col>
                <Col span={hideSidebar ? 24 : 19} className="fixed-right">
                    {_.isEmpty(projects) ? (
                        <div className="project_main_page_empty_state">
                            <Empty
                                image={Empty.PRESENTED_IMAGE_DEFAULT}
                                description={<span>No Projects found.</span>}
                            >
                                <Button
                                    type="primary"
                                    onClick={() => history.push(`${BIDDING_MANAGEMENT}/create`)}
                                >
                                    Create New
                                </Button>
                            </Empty>
                        </div>
                    ) : (
                        children
                    )}
                </Col>
            </Row>
        </Content>
    );
};
