import { http, fileHttp } from '../utils/http';

export const saveFcmToken = async (body) => {
  try {
    const response = await http.post('/save-fcm-tokens/', body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProjects = async ({ per_page = 30, page, active }) => {
  try {
    const response = await http.get(`/paginatedmasterprojects/?per_page=${per_page}&is_finished=${active ? 0 : 1}&page=${page}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchMasterAggregate = async (id) => {
  try {
    if (id) {
      const response = await http.get(`/masterprojects/${id}/insights/`);
      return response?.data;
    }
  } catch (ex) {
    throw ex;
  }
};

export const markCompleted = async (projectId) => {
  try {
    const response = await http.patch(`/projects/${projectId}/mark-as-finished/`, {});
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchProjectDocsV2 = async ({
  id, page, pageSize, searchedColumn, searchText, orderingColumn,
}) => {
  try {
    let url = `projects/${id}/project-documents/?page=${page}&per_page=${pageSize}`;

    // Hack for dir_value
    if (searchedColumn === 'dir_value') searchedColumn = 'dir_key';

    if (searchedColumn && searchText) {
      url += `&${searchedColumn}=${searchText}`;
    } else if (orderingColumn) {
      url += `&ordering=${orderingColumn}`;
    }
    const response = await http.get(url);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchEquipDocs = async ({
  id, page, pageSize, searchedColumn, searchText, orderingColumn, package_room,
}) => {
  let url = `projects/${id}/equipment-documents/?page=${page}&per_page=${pageSize}`;

  // Hack for dir_value
  if (searchedColumn === 'dir_value') searchedColumn = 'dir_key';
  if (package_room) url += `&package_room=${package_room}`;

  if (searchedColumn && searchText) {
    url += `&${searchedColumn}=${searchText}`;
  } else if (orderingColumn) {
    url += `&ordering=${orderingColumn}`;
  }
  const response = await http.get(url);
  return response?.data;
};

export const fetchReviewDocs = async ({
  id, page, pageSize, searchedColumn, searchText, orderingColumn, package_room,
}) => {
  let url = `projects/${id}/review-documents/?page=${page}&per_page=${pageSize}`;

  // Hack for dir_value
  if (searchedColumn === 'dir_value') searchedColumn = 'dir_key';
  if (package_room) url += `&package_room=${package_room}`;

  if (searchedColumn && searchText) {
    url += `&${searchedColumn}=${searchText}`;
  } else if (orderingColumn) {
    url += `&ordering=${orderingColumn}`;
  }
  const response = await http.get(url);
  return response?.data;
};

export const fetchSharedDocuments = async ({
  projectId, page, pageSize, searchedColumn, searchText, orderingColumn,
}, type) => {
  let url = `shared-files/${type}/v1/?page=${page}&per_page=${pageSize}`;
  if (searchedColumn && searchText) {
    url += `&${searchedColumn}=${searchText}`;
  } else if (orderingColumn) {
    url += `&ordering=${orderingColumn}`;
  }
  const response = await http.get(url);
  return response?.data;
};

export const fetchProjectCalendarEvents = async (subProjectId, params) => {
  try {
    const response = await http.get(`/projects/${subProjectId}/calendar-tags-due-date/`, params);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllDeadlines = async () => {
  try {
    const response = await http.get(`/user-deadlines/`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMasterBiddingDocuments = async (projectId, params) => {
  try {
    const response = await http.get(`/masterprojects/${projectId}/bid-docs/`, params);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchContractorSpecificDocs = async (projectId, params) => {
  try {
    const response = await http.get(`/masterprojects/${projectId}/contractor-docs/`, params);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMasterProjectsDocumentsCount = async (projectId) => {
  try {
    const response = await http.get(`/masterprojects/${projectId}/documents-count/`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
