import _, { isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Layout, notification, Spin, Tag } from 'antd';
import {
    fetchProjectDocs,
    newfetchProjectDetail,
    newUpdateProject,
    removeProjectDocs,
    markCompleted,
    fetchMasterProjectDetail,
    makeProjectActive,
} from '../../../services';
import Packages from './packages';
import BasicInfo from './basic-info';
import Documents from './documents';
import UserAccess from './users-access';
import ManageEquipments from './manage-equipments';
import { getAllPackages } from '../../../store/package';
import { getRoleTemplates } from '../../../store/admin';
import { setActiveProject } from '../../../store/common';
import { isPackagesActive, PROJECT_API_ENUM, PROJECT_TYPES, showNotification } from '../../../utils';

const { Content } = Layout;

const steps = [
    {
        title: 'Project Information',
        id: 1,
    },
    {
        title: 'Users Access',
        id: 2,
    },
    {
        title: 'Manage Equipments',
        id: 3,
    },
    ...[
        isPackagesActive()
            ? {
                  title: 'asdsManage Packages',
                  id: 4,
              }
            : {},
    ],
];

const CreateEditSubProject = ({ history }) => {
    const { search } = useLocation();
    const { user } = useSelector(({ auth }) => auth);
    const { role_templates } = useSelector(({ admin }) => admin);

    const params = new URLSearchParams(search);
    const { createNewProject, activeProject } = useSelector(({ common }) => common);
    const { projectId, masterProjectId } = useParams();
    const [current, setCurrent] = useState(+params.get('activeTab') || 1);
    const [masterProject, setMasterProject] = useState({});
    const [formValues, setFormValues] = useState({});
    const [existingUsers, setExistingUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showTip, setShowTip] = useState(false);
    const [tags, setTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [equipmentErrors, setEquipmentErrors] = useState([]);
    const [documents, setDocuments] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (masterProjectId) {
            getMasterProjectDetail();
            dispatch(getRoleTemplates());
        }
    }, [masterProjectId]);

    useEffect(() => {
        if (projectId) {
            Promise.all([getProjectDetail(), dispatch(getRoleTemplates())]);
        }
    }, [projectId]);

    const getMasterProjectDetail = async () => {
        try {
            setLoader(true);
            const response = await fetchMasterProjectDetail(
                PROJECT_API_ENUM.BASIC_INFO,
                masterProjectId,
            );
            setMasterProject({ ...response?.data[0] });
            setFormValues({ ...formValues, owner: response?.data[0]?.owner });
            setLoader(false);
        } catch (error) {
            setLoader(false);
            notification.error({ message: "Master Project doesn't exist", duration: 5 });
        }
    };

    const getProjectDetail = async () => {
        try {
            setLoader(true);
            const response = await newfetchProjectDetail(PROJECT_API_ENUM.BASIC_INFO, projectId);
            setFormValues({ ...response?.data });
            setLoader(false);
        } catch (error) {
            setLoader(false);
            notification.error({ message: "Subproject doesn't exist", duration: 5 });
        }
    };

    const getProjectDocuments = async () => {
        try {
            const response = await fetchProjectDocs(projectId);
            setDocuments(response?.data);
        } catch (e) {
            console.log('Error while fetching project docs : ', e);
        }
    };

    const deleteDocument = async (documentId) => {
        try {
            setLoader(true);
            const response = await removeProjectDocs(projectId, { id: documentId });
            showNotification(response.message);
            getProjectDocuments();
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };

    const goBack = () => {
        setCurrent(current - 1);
        history.replace({
            pathname: `/projects/${masterProjectId}/${projectId}/edit`,
            search: `?activeTab=${current - 1}`,
        });
    };

    const onFinish = (values) => {
        setFormValues({ ...formValues, ...values });
        if (projectId) {
            history.replace({
                pathname: `/projects/${masterProjectId}/${projectId}/edit`,
                search: `?activeTab=${current + 1}`,
            });
        }
        if (current < 5) {
            setCurrent(current + 1);
        }
    };

    const actionCompleted = () => {
        notification.success({
            message: 'Project Updated',
            description: 'The project has been successfully updated',
        });
        setCurrent(0);
        setLoader(false);
        history.push({
            pathname: `/projects/${masterProject?.id}/sub-project/${projectId}`,
            search: 'page=insights',
        });
    };

    const onTabClick = (item) => {
        if (projectId) {
            setCurrent(item.id);
            history.replace({
                pathname: `/projects/${masterProjectId}/${projectId}/edit`,
                search: `?activeTab=${item.id}`,
            });
        }
    };

    const uploadProjectDocs = async (values) => {
        try {
            setLoader(true);
            await Promise.all(
                Object.keys(values).map(async (key) => {
                    if (!_.isEmpty(values[key])) {
                        const { fileList } = values[key];
                        const formData = new FormData();
                        formData.append('dir_key', key);
                        formData.append('id', projectId);
                        Object.values(fileList).forEach((file) => {
                            formData.append('files', file.originFileObj);
                        });
                        await newUpdateProject('documents', projectId, formData);
                    }
                }),
            ).then(actionCompleted);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            // if (!_.isEmpty(values)) {
            //     await uploadProjectDocs(values);
            // } else {
                await makeProjectActive(projectId);
                dispatch(
                    setActiveProject({
                        id: projectId,
                        name: formValues?.name || activeProject?.name,
                        masterProject,
                        permissions: activeProject?.permissions,
                        type: PROJECT_TYPES.SUB_PROJECT,
                    }),
                );
                actionCompleted();
            // }
        } catch (error) {
            console.log('error', error);
            setLoader(false);
            setShowTip(false);
        }
    };

    const onComplete = async (projectId) => {
        await markCompleted(projectId);
        history.replace({
            pathname: '/projects/',
        });
    };

    return (
        <Content>
            <div style={{ width: '95%', margin: 'auto' }}>
                <div className="page-content-container">
                    <div className="project-header">
                        <div className="project-steps">
                            <div className="project-step first-step">
                                {createNewProject ? (
                                    <p>
                                        <span>Create New Subproject</span>
                                    </p>
                                ) : (
                                    <p>
                                        Modifying Project <span>{activeProject?.name}</span>
                                    </p>
                                )}
                            </div>
                            {steps.map(
                                (item) =>
                                    !isEmpty(item) && (
                                        <div
                                            className={`project-step cursor-pointer ${
                                                item.id === current ? 'active' : ''
                                            }`}
                                            key={item.title}
                                            onClick={() => onTabClick(item)}
                                        >
                                            {item.title}
                                        </div>
                                    ),
                            )}
                        </div>
                        <div>{formValues.is_draft && <Tag color="orange">Draft</Tag>}</div>
                    </div>
                    <div className="steps-content mt-30">
                        <Spin
                            spinning={loader}
                            tip={showTip ? 'Please wait, it will take a while' : ''}
                        >
                            {current === 1 ? (
                                <BasicInfo
                                    basicInfo={formValues}
                                    masterProjectInfo={masterProject}
                                    onFinish={onFinish}
                                    projectId={projectId}
                                    history={history}
                                    createNewProject={createNewProject}
                                    setLoader={setLoader}
                                    onComplete={onComplete}
                                />
                            ) : current === 2 ? (
                                <UserAccess
                                    existingUsers={existingUsers}
                                    organizations={organizations}
                                    setOrganizations={setOrganizations}
                                    users={users}
                                    goBack={goBack}
                                    setUsers={setUsers}
                                    onFinish={onFinish}
                                    projectId={projectId}
                                    setLoader={setLoader}
                                    role_templates={role_templates}
                                />
                            ) : current === 3 ? (
                                <ManageEquipments
                                    basicInfo={formValues}
                                    tags={tags}
                                    goBack={goBack}
                                    setTags={setTags}
                                    onFinish={onSubmit}
                                    orgs={organizations}
                                    equipmentErrors={equipmentErrors}
                                    projectId={projectId}
                                    setLoader={setLoader}
                                    setEquipmentErrors={setEquipmentErrors}
                                />
                            ) : current === 4 ? (
                                <Packages
                                    goBack={goBack}
                                    tags={tags}
                                    users={users}
                                    setTags={setTags}
                                    onFinish={onFinish}
                                    onSubmit={onSubmit}
                                    projectId={projectId}
                                    setLoader={setLoader}
                                />
                            ) : current === 5 ? (
                                <Documents
                                    documents={documents}
                                    goBack={goBack}
                                    onSubmit={onSubmit}
                                    projectId={projectId}
                                    deleteDocument={deleteDocument}
                                />
                            ) : null}
                        </Spin>
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default CreateEditSubProject;
