import { Card } from 'antd';
import { AlectifyTable } from '../../../../../../components';
import { memo } from 'react';
import useColumns from './effects/useColumns';
import VendorQuoteDetail from './VendorQuoteDetail';
import VendorQuoteEdit from './VendorQuoteEdit';

const VendorQuotesList = ({
    vendorQuotes,
    onActionClick,
    getVendorslist,
    action,
    setAction,
    deleteEstimations,
    getVendorsSummary,
}) => {
    const columns = useColumns(onActionClick);
    return (
        <Card.Grid className="full-width" hoverable={false}>
            <AlectifyTable
                selectRows={true}
                columns={columns}
                defaultPageSize={10}
                loading={vendorQuotes.fetching}
                multipleDeleteOption={true} // disabled multiple delete option
                onDataFetch={getVendorslist}
                dataSource={vendorQuotes.data || []}
                total={vendorQuotes.total}
                onMultipleDelete={(ids) => deleteEstimations(ids)}
            />
            {action.detail && (
                <VendorQuoteDetail
                    data={action.data}
                    visible={action.detail}
                    onCancel={() => setAction({ ...action, detail: false, data: null })}
                />
            )}
            {action.edit && (
                <VendorQuoteEdit
                    data={action.data}
                    visible={action.edit}
                    getVendorslist={getVendorslist}
                    onCancel={() => setAction({ ...action, edit: false, data: null })}
                    getVendorsSummary={getVendorsSummary}
                />
            )}
        </Card.Grid>
    );
};

export default memo(VendorQuotesList);
