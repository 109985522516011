import { combineReducers } from 'redux';
import { adminReducer } from './admin';
import { commonReducer } from './common';
import { dashboardReducer } from './dashboard';
import { singlelineReducer } from './singleline';
import { tagReducer } from './tags';
import { packageReducer } from './package';
import { approvalReducer } from './approval';
import { permissionReducer } from './permission';
import { projectsReducer } from './projects';
import { authReducer } from './auth';
import { equipmentDocsReducer } from './equipment-docs';
import { documentReducer } from './documents';
import { chatReducer } from './chat';
import { tasksReducer } from './tasks';
import { financialReducer } from './financials';
import { billingReducer } from './billing';
import { biddingProjectsReducer } from './bidding-management';

export default combineReducers({
    admin: adminReducer,
    auth: authReducer,
    common: commonReducer,
    dashboard: dashboardReducer,
    singleLine: singlelineReducer,
    tags: tagReducer,
    tasks: tasksReducer,
    chat: chatReducer,
    packages: packageReducer,
    projects: projectsReducer,
    approval: approvalReducer,
    permission: permissionReducer,
    equipments: equipmentDocsReducer,
    documents: documentReducer,
    financials: financialReducer,
    billings: billingReducer,
    bidding: biddingProjectsReducer,
});
