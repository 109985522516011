import React from 'react';
import { Row, Col } from 'antd';
import UserStatus from '../../components/users-status-cards';
import './user-info.scss';
import UserCard from '../../components/user-card';
import UserRightData from '../../components/user-right-data';

function UserInfo() {
  return (
    <div className="main-body">
      <div className="user-status-card">
        <UserStatus />
      </div>
      <div className="list-user-container">
        <Row className="user-row">
          <Col span={5}>
            <div className="left-side-container custom-scroll-dark">
              <UserCard />
            </div>
          </Col>
          <Col span={18}>
            <div className="right-side-container">
              <UserRightData />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default UserInfo;
