import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Layout, notification, Spin } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { PROJECT_API_ENUM } from '../../../utils';
import { fetchMasterProjectDetail } from '../../../services';
import BasicInfo from './BasicInfo';

const { Content } = Layout;

const steps = [
  {
    title: 'Project Information',
    id: 1,
  },
];

function CreateEditMasterProject({ history }) {
  const { search } = useLocation();
  const { projectId } = useParams();
  const params = new URLSearchParams(search);
  const [loader, setLoader] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [current, setCurrent] = useState(+params.get('activeTab') || 1);

  const getMasterProjectDetail = async () => {
    try {
      setLoader(true);
      const response = await fetchMasterProjectDetail(PROJECT_API_ENUM.BASIC_INFO, projectId);
      setFormValues({ ...response?.data[0] }); // Check to data field
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notification.error({ message: "Project doesn't exist", duration: 5 });
    }
  };

  useEffect(() => {
    if (projectId) {
      getMasterProjectDetail();
    }
  }, [projectId]);

  const onFinish = (values) => {
    setFormValues({ ...formValues, ...values });
    if (projectId) {
      history.push({
        pathname: `/projects/${projectId}`,
      });
    }
  };

  const onTabClick = (item) => {
    if (projectId) {
      setCurrent(item.id);

      history.push({
        pathname: `/projects/${projectId}`,
        search: `?activeTab=${item.id}`,
      });
    }
  };

  return (
    <Content>
      <div style={{ width: '95%', margin: 'auto' }}>
        <div className="page-content-container">
          <div className="project-header">
            <div className="project-steps">
              <div className="project-step first-step">
                {!projectId ? (
                  <p>
                    <span>Create New Master Project V2</span>
                  </p>
                ) : (
                  <p>
                    <span>{formValues?.name}</span>
                  </p>
                )}
              </div>
              {steps.map((item) => (
                <div
                  className={`project-step ${
                    item.id === current ? 'active' : ''
                  }`}
                  key={item.title}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          <div className="steps-content mt-30">
            <Spin spinning={loader}>
              <BasicInfo
                basicInfo={formValues}
                onFinish={onFinish}
                projectId={projectId}
                history={history}
                setLoader={setLoader}
              />
            </Spin>
          </div>
        </div>
      </div>
    </Content>
  );
}

export default CreateEditMasterProject;
