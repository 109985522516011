import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { memo, useEffect } from 'react';
import { Card, Col, Modal, Row, Spin } from 'antd';
import { DISPLAY_DATE_FORMAT, renderAmount } from '../../../../../../../../utils';
import { AlectifyTable } from '../../../../../../../../components';
import useDocumentColumns from './effects/useDocumentColumns';
import { getBiddingVendorEstimationsById } from '../../../../../../../../store/bidding-management';
import { useDispatch, useSelector } from 'react-redux';

const VendorAssociateDetail = memo((props) => {
    const columns = useDocumentColumns();
    const { projectId } = useParams();
    const { vendorItemDetail } = useSelector(({ bidding }) => bidding.financials);

    const dispatch = useDispatch();
    const getVendorEstimations = (pagination) => {
        const params = {
            page: pagination?.page || 1,
            per_page: pagination?.per_page || 10,
        };
        dispatch(getBiddingVendorEstimationsById(projectId, props.data.id, params));
    };

    useEffect(() => {
        dispatch(getBiddingVendorEstimationsById(projectId, props.data.id));
    }, [props.visible]);

    return (
        <Modal
            centered
            width={900}
            footer={false}
            open={props.visible}
            onCancel={props.onCancel}
            title="Price Summary Detail"
            className="equipment-detail-modal"
        >
            <Card title="Information" className="mb-20">
                <Row className="details-view" gutter={30}>
                    <Col span={8}>
                        <label>Date:</label>
                        <span>
                            {moment(vendorItemDetail.data?.entry_date).format(
                                DISPLAY_DATE_FORMAT,
                            ) || '-'}
                        </span>
                    </Col>
                    <Col span={8}>
                        <label>Quote #:</label>
                        <span>{vendorItemDetail.data?.vendor_quote_no || '-'}</span>
                    </Col>
                    <Col span={8}>
                        <label>Total Sell:</label>
                        <span>{renderAmount(vendorItemDetail.data?.total_sell)}</span>
                    </Col>
                    <Col span={8}>
                        <label>Total Cost:</label>
                        <span>{renderAmount(vendorItemDetail.data?.total_cost)}</span>
                    </Col>
                    <Col span={8}>
                        <label>Avg. Margin:</label>
                        <span>{vendorItemDetail.data?.margin_percent || 0} %</span>
                    </Col>
                </Row>
            </Card>
            <Card title="Attachment(s)">
                <Spin spinning={vendorItemDetail.fetching}>
                    <AlectifyTable
                        size="small"
                        columns={columns}
                        loading={false}
                        selectRows={false}
                        // total={documents.total}
                        dataSource={vendorItemDetail.data?.documents || []}
                        onDataFetch={getVendorEstimations}
                    />
                </Spin>
            </Card>
        </Modal>
    );
});

VendorAssociateDetail.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
};

VendorAssociateDetail.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default VendorAssociateDetail;
