import React from 'react';
import { Progress } from 'antd';

export default () => React.useMemo(() => [
  {
    key: '1',
    title: 'Project Name',
    dataIndex: 'master_project_name',
  },
  {
    key: '2',
    title: 'Postive',
    dataIndex: 'positive',
    render: (value) => (
      <div>
        <Progress
          percent={Math.round(value) || 0}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
    ),
  },
  {
    key: '3',
    title: 'Negative',
    dataIndex: 'negative',
    render: (value) => (
      <div>
        <Progress
          percent={Math.round(value) || 0}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
    ),
  },
  {
    key: '4',
    title: 'Neutral',
    dataIndex: 'neutral',
    render: (value) => (
      <div>
        <Progress
          percent={Math.round(value) || 0}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
    ),
  },
  {
    key: '5',
    title: 'Mixed',
    dataIndex: 'mixed',
    render: (value) => (
      <div>
        <Progress
          percent={Math.round(value) || 0}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
    ),
  },
]);
