import { RESET_STATE } from '../common';
import * as ActionTypes from './types';

const initialState = {
  taskList: [],
  taskMeta: {},
  loading: false,
  taskListLoading: false,
  error: null,
};

export const tasksReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_TASKS_LIST:
      return { ...state, taskListLoading: true };
    case ActionTypes.GET_TASKS_LIST_SUCCESS:
      return {
        ...state,
        taskList: payload?.data,
        taskMeta: payload?.meta,
        taskListLoading: false,
      };

    case ActionTypes.GET_TASKS_LIST_FAILURE:
      return { ...state, masterTags: payload, taskListLoading: false };

    case ActionTypes.UPDATE_TASK:
      return { ...state, taskListLoading: true };

    case ActionTypes.UPDATE_TASK_SUCCESS:
      return { ...state, taskListLoading: false };

    case ActionTypes.UPDATE_TASK_FAILURE:
      return { ...state, taskListLoading: false };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
