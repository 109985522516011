import ReactApexChart from 'react-apexcharts';

export function PieChart({ options, series = [0, 0] }) {
  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height={450}
      />
    </div>
  );
}
