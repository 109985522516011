export const DASHBOARD = '/dashboard';
export const PROJECTS = '/projects';
export const SLINES = '/slines';
export const TAGS = '/tags';
export const PACKAGE = '/package';
export const CONTACTS = '/contacts';
export const SHARED_WITH_ME = '/shared';
export const SETTING = '/setting';
export const PROFILE = '/profile';
export const PROFILE_SETTINGS = '/profile-settings';
export const HELP = '/help';
export const USERINFO = '/userinfo';
export const BIDDING_MANAGEMENT = '/bidding-management';
export const CONTRACTORS = '/contractors';
