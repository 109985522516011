import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Col, Modal, Row, Spin,
} from 'antd';
import { memo, useState, useEffect } from 'react';
import {
  CUSTOMER_PO_REASONS, DEFAULT_PAGE_SIZE, DEFAULT_START_PAGE, DISPLAY_DATE_FORMAT, renderAmount,
} from '../../../../../../utils';
import { fetchCustomerPurchaseOrderInvoice } from '../../../../../../services';
import { AlectifyTable } from '../../../../../../components';
import useInvoiceColumns from './effects/useInvoiceColumns';

const CustomerPurchaseOrderDetail = memo((props) => {
  const columns = useInvoiceColumns();
  const [invoice, setInvoice] = useState({ data: null, fetching: false, total: 0 });

  const getInvoiceDetails = async (pagination) => {
    try {
      const params = {
        page: pagination?.page || DEFAULT_START_PAGE,
        per_page: pagination?.pageSize || DEFAULT_PAGE_SIZE,
      };
      const { id, master_project } = props.data;
      setInvoice({ ...invoice, fetching: true });
      const response = await fetchCustomerPurchaseOrderInvoice(master_project, id, params);
      setInvoice({
        ...invoice,
        fetching: false,
        data: response?.data,
        total: response.meta.total_count,
      });
    } catch (error) {
      setInvoice({ ...invoice, fetching: false });
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, [props.visible]);

    return (
        <Modal
            width={700}
            footer={false}
            open={props.visible}
            onCancel={props.onCancel}
            title="Customer Invoice Detail"
        >
            <Row className="details-view" gutter={30}>
                <Col span={12}>
                    <label>Date:</label>
                    <span>{moment(props.data?.po_date).format(DISPLAY_DATE_FORMAT)}</span>
                </Col>
                <Col span={12}>
                    <label>Amount:</label>
                    <span>{renderAmount(props.data?.amount)}</span>
                </Col>
                <Col span={12}>
                    <label>Billed so far:</label>
                    <span>{renderAmount(props.data?.cumm_billed_so_far)}</span>
                </Col>
                <Col span={12}>
                    <label>Remaining to bill:</label>
                    <span>{renderAmount(props.data?.remaining_to_be_billed)}</span>
                </Col>
                {/* <Col span={24}>
                    <label>Comments:</label>
                    <span>{props.data?.comments || '-'}</span>
                </Col> */}
            </Row>
                <Spin spinning={invoice.fetching}>
                    <h3 className='po-summary-heading'>Invoice Details:</h3>
                    <Row gutter={30} className="details-view">
                        <Col span={12}>
                            <label>Invoice Date:</label>
                            <span>{invoice.data?.[0]?.invoice_date ? moment(invoice.data?.[0]?.invoice_date).format(DISPLAY_DATE_FORMAT) : '-'}</span>
                        </Col>
                        <Col span={12}>
                            <label>Invoice Number:</label>
                            <span>{invoice.data?.[0]?.invoice_number || '-'}</span>
                        </Col>
                    </Row>
                    <AlectifyTable
                        size='small'
                        columns={columns}
                        loading={false}
                        selectRows={false}
                        total={invoice.total}
                        dataSource={invoice.data}
                        onDataFetch={getInvoiceDetails}
                    />
                </Spin>
        </Modal>
    );
});

CustomerPurchaseOrderDetail.defaultProps = {
  data: null,
  visible: false,
  onCancel: () => {},
};

CustomerPurchaseOrderDetail.propTypes = {
  data: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default CustomerPurchaseOrderDetail;
