import { Card } from 'antd';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlectifyTable } from '../../../../../../components';
import { getCustomerPurchaseOrders } from '../../../../../../store/billing';
import useColumns from '../customer-purchase-order/effects/useColumns';

const CustomerInvoices = memo(() => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { customerPurchaseOrders } = useSelector(({ billings }) => billings);

    const getAllCustomerInvoices = (page = 1, per_page = 10) => {
        const params = {
            page,
            per_page,
        };
        dispatch(getCustomerPurchaseOrders(projectId, params));
    };

    useEffect(() => {
        getAllCustomerInvoices();
    }, [projectId]);

    const columns = useColumns(null, false);

    return (
        <Card title="Customer Invoices">
            <AlectifyTable
                columns={columns}
                defaultPageSize={10}
                total={customerPurchaseOrders.total}
                dataSource={customerPurchaseOrders.data}
                loading={customerPurchaseOrders.fetching}
                onDataFetch={({ page, per_page }) => getAllCustomerInvoices(page, per_page)}
                selectRows={false}
            />
        </Card>
    );
});

export default CustomerInvoices;
