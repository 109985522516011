import moment from 'moment';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { isEmpty } from 'lodash';
import { Button, Empty } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const ActivityDetail = (props) => {
    return (
        <>
            {props.activeTag ? (
                <div className="page-content-container have-nested-containers notifications-content">
                    <h4 className="card-heading step-heading">
                        {props.activeTag?.name} Notifications
                        <Button onClick={props.goToPackage}>
                            <RightOutlined />
                            To Equipment Detail
                        </Button>
                    </h4>
                    <div className="notifications-body custom-scroll-dark">
                        {!isEmpty(props.notifications) ? (
                            props.notifications.map((notification) => (
                                <div className="notification" key={notification.id}>
                                    <p dangerouslySetInnerHTML={{ __html: notification.message }} />
                                    <span className="date">
                                        Created at:{' '}
                                        {moment(notification.created_at).format(
                                            'DD MMM, YYYY h:mm a',
                                        )}
                                    </span>
                                </div>
                            ))
                        ) : (
                            <div className="placeholder-container custom-description">
                                <Empty
                                    description={`Notifications not found for ${props.activeTag?.name}`}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : !isEmpty(props.tags) ? (
                <div className="placeholder-container custom-description">
                    <Empty description="Select package to see notifications" />
                </div>
            ) : !props.tagsLoaders && isEmpty(props.tags) ? (
                <div className="placeholder-container custom-description">
                    <Empty description="Notifications not found" />
                </div>
            ) : null}
        </>
    );
};

ActivityDetail.defaultProps = {
    tags: [],
    activeTag: {},
    tagsLoaders: false,
    notifications: [],
    goToPackage: () => {},
};

ActivityDetail.propTypes = {
    tags: PropTypes.array,
    activeTag: PropTypes.object,
    tagsLoaders: PropTypes.bool,
    notifications: PropTypes.array,
    goToPackage: PropTypes.func,
};

export default memo(ActivityDetail);
