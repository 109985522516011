import React from 'react';
import Slide from 'react-reveal/Slide';
import {
  NavHeader,
  Content,
  Projects,
  Digital,
  Construction,
  Professionals,
  FeatureNeed,
  BecomeDigital,
  WhatYouCan,
  GetToKnow,
  Footer,
  FooterBottom,
} from '../../components';

function Home() {
  return (
    <>
      <NavHeader />
      <Content />
      <Slide left>
        <Projects />
      </Slide>
      <Digital />
      <Construction />
      <Professionals />
      <FeatureNeed />
      <BecomeDigital />
      <WhatYouCan />
      <GetToKnow />
      <Footer />
      <FooterBottom />
    </>
  );
}

export default Home;
