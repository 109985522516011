import { http } from '../utils/http';

export const updateUser = async (data) => {
  try {
    const response = await http.patch(`/user/${data?.userId}`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchLicenseGroupList = async (data) => {
  try {
    const response = await http.post('/license-group/list/?page=1&per_page=50', data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const postCreateRoleTemplate = async (data) => {
  try {
    const response = await http.post('/role-template/create/', data?.payload);
    return response?.data;
  } catch (ex) {
    throw ex?.response?.data;
  }
};

export const postBranchGroup = async (data) => {
  try {
    const response = await http.post(`/branch/${data?.branchId}/update/`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const postUserGroup = async (data) => {
  try {
    const response = await http.post('/user/update-role-template/', data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const patchPermissionToGroup = async (data) => {
  try {
    const response = await http.patch(`/groups/${data?.groupId}/add-permissions/`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deletePermissionFromGroup = async (data) => {
  try {
    const response = await http.delete(`/groups/${data?.groupId}/remove-permissions/`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const patchPermissionToRole = async (data) => {
  try {
    const response = await http.patch(`/groups/${data?.groupId}/add-permissions/`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const deletePermissionFromRole = async (data) => {
  try {
    const response = await http.delete(`/groups/${data?.groupId}/remove-permissions/`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchPermissionsList = async (data) => {
  try {
    const response = await http.post('/permissions/fulllist/?page=1&per_page=50', data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchBranchPermissions = async (data) => {
  try {
    const response = await http.post(`/branches/list/?page=${data?.page}&per_page=${data.pageSize}`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchRoleTemplates = async (data) => {
  try {
    const response = await http.get('/role-templates/user-dropdown/');
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchProjectsbyAdmin = async (data) => {
  const { searchedColumn, orderingColumn, searchText } = data;
  let url = `/paginatedprojects/?page=${data?.page}&per_page=${data.pageSize}`;
  if (searchedColumn && searchText) {
    url += `&${searchedColumn}=${searchText}`;
  } else if (orderingColumn) {
    if (orderingColumn.includes('-')) {
      url += `&ordering=-`;
    } else {
      url += `&ordering=`;
    }

    if (orderingColumn.includes('organization')) {
      url += `organization__name`;
    } else if (orderingColumn.includes('branch')) {
      url += `branch__name`;
    } else if (orderingColumn.includes('master_project')) {
      url += `master_project__name`;
    } else {
      url += `${orderingColumn}`;
    }
  }

  try {
    const response = await http.get(url);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchUsersByProject = async (data) => {
  try {
    const response = await http.get(`/projects/${data?.id}/paginatedusers/?page=${data?.page}&per_page=${data.pageSize}`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const addUserPermissionToProject = async (data) => {
  try {
    const response = await http.patch(`/projects/${data?.projectId}/projectpermissions/${data.userId}/add/`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const removeUserPermissionFromProject = async (data) => {
  try {
    const response = await http.delete(`/projects/${data?.projectId}/projectpermissions/${data.userId}/delete/`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const toggleUserStatus = async (data) => {
  try {
    const response = await http.patch(`/user/${data?.userId}`, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchUserRoles = async (data) => {
  const { searchedColumn, orderingColumn, searchText } = data;
  let url = `/userlist/?page=${data?.page}&per_page=${data.pageSize}`;
  if (searchedColumn && searchText) {
    url += `&${searchedColumn}=${searchText}`;
  } else if (orderingColumn) {
    if (orderingColumn.includes('-')) {
      url += `&ordering=-`;
    } else {
      url += `&ordering=`;
    }
    if (orderingColumn.includes('organization')) {
      url += `organization__name`;
    } else if (orderingColumn.includes('branch')) {
      url += `branch__name`;
    } else if (orderingColumn.includes('email')) {
      url += `email`;
    } else if (orderingColumn.includes('role')) {
      url += `role__name`;
    } else if (orderingColumn.includes('permission_group')) {
      url += `permission_group__name`;
    } else if (orderingColumn === 'name' || orderingColumn === '-name') {
      url += `first_name`;
    } else {
      url += `${orderingColumn.replace(/^-/, '')}`;
    }
  }

  try {
    const response = await http.post(url, data?.payload);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};
