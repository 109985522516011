import _ from 'lodash';
import moment from 'moment';
import {
  Tooltip, Avatar, Space, Button, Popconfirm,
} from 'antd';
import { DeleteTwoTone, DownloadOutlined, ContainerOutlined } from '@ant-design/icons';
import { DISPLAY_DATETIME_FORMAT, makeAvatar } from '../../utils';

export const reviewColumns = ({
  type = '',
  downloadDocument = () => {},
  setRecord = () => {},
}) => [
  {
    title: 'Master Project',
    dataIndex: 'master_project_name',
    isSearchable: false,
  },
  {
    title: 'Sub Project',
    dataIndex: 'project_name',
    isSearchable: true,
  },
  {
    title: 'Equipment',
    dataIndex: 'package_name',
    isSearchable: true,
  },
  {
    title: 'Submittal Status',
    dataIndex: 'submittal_status',
  },
  {
    title: 'Approval File',
    dataIndex: 'approval_file_name',
    isSearchable: true,
    render: (_, record) => (
      <Space>
        <Tooltip title="Download">
          <Button
            style={{
              border: 'none',
            }}
            data-key="download"
            type="ghost"
            onClick={() => downloadDocument({ file_path: record?.approval_file_path, file_name: record?.approval_file_name })}
          >
            {record?.approval_file_name}
            {' '}
            <DownloadOutlined style={{ fontSize: 18, cursor: 'pointer' }} />
          </Button>
        </Tooltip>
      </Space>
    ),
  },
  {
    title: 'Approval Due Date',
    dataIndex: 'approval_due_time',
    sorter: true,
    sortDirections: ['ascend', 'descend'],
    render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
  },
  // {
  //   title: 'Approval by',
  //   align: 'center',
  //   dataIndex: 'account',
  //   render: (account) => (!_.isEmpty(account) ? (
  //     <Tooltip title={`${account?.first_name} ${account?.last_name}`}>
  //       <Avatar
  //         size="small"
  //         src={makeAvatar(account?.first_name?.[0], account?.last_name?.[0])}
  //       />
  //     </Tooltip>
  //   ) : (
  //     ''
  //   )),
  // },
  // {
  //   title: 'Mark up File',
  //   dataIndex: 'markup_file_name',
  //   isSearchable: true,
  //   render: (_, record) => (
  //     record?.markup_file_name && (
  //       <Space>
  //         <Tooltip title="Download">
  //           <Button
  //             style={{
  //               border: 'none',
  //             }}
  //             data-key="download"
  //             type="ghost"
  //             onClick={() => downloadDocument({ file_path: record?.markup_file_path, file_name: record?.markup_file_name })}
  //           >
  //             {record?.markup_file_name}
  //             {' '}
  //             <DownloadOutlined style={{ fontSize: 18, cursor: 'pointer' }} />
  //           </Button>
  //         </Tooltip>
  //       </Space>
  //     )
  //   ),
  // },
  // {
  //   title: 'Comments',
  //   dataIndex: 'markup_status',
  // },
  // {
  //   title: 'Mark up by',
  //   align: 'center',
  //   dataIndex: 'markup_uploaded_by',
  //   render: (markup_uploaded_by) => (!_.isEmpty(markup_uploaded_by) ? (
  //     <Tooltip title={`${markup_uploaded_by?.first_name} ${markup_uploaded_by?.last_name}`}>
  //       <Avatar
  //         size="small"
  //         src={makeAvatar(markup_uploaded_by?.first_name?.[0], markup_uploaded_by?.last_name?.[0])}
  //       />
  //     </Tooltip>
  //   ) : (
  //     ''
  //   )),
  // },
  {
    title: 'Shared By',
    dataIndex: 'shared_history',
    render: (_, record) => (
      <div
        key={record?.id}
      >
        <Tooltip
          key={record?.id}
          title="View History"
        >
          <Button
            key={record?.id}
            type="text"
            onClick={() => {
              record.type = type;
              setRecord(record);
            }}
            icon={<ContainerOutlined />}
          />
        </Tooltip>
      </div>
    ),
  },
];
