import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import { Col, Form, Input, InputNumber, message, Modal, Row, Spin } from 'antd';
import { calculateSell } from '../../../../../../utils';
import { updateContractorsVendorQuotes } from '../../../../../../services/bidding-management';

const VendorQuoteEdit = (props) => {
    const [form] = Form.useForm();
    const { contractorId } = useParams();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        form.setFieldsValue({ ...props.data });
    }, [props.visible]);

    const onChangeAvgMargin = (value) => {
        if (value > 99) {
            form.setFields([
                {
                    name: 'avgMargin',
                    errors: ['Average margin cannot be more than 99%'],
                },
            ]);
            return;
        }

        form.setFieldsValue({
            avgMargin: value,
        });
        const sell = calculateSell(props.data.total_cost, value);
        form.setFieldsValue({ total_sell: sell.toFixed(2) });
    };

    const formatInput = (value) => {
        return `${value}%`;
    };

    const parseInput = (value) => {
        return parseFloat(value.replace('%', '')) || 0;
    };

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            await updateContractorsVendorQuotes(contractorId, props.data.id, values);
            setSubmitting(false);
            message.success('Updated Successfully');
            props.onCancel();
            props.getVendorslist();
            props.getVendorsSummary();
        } catch (error) {
            message.error(error.response?.data?.message || 'Something unexpected happened');
            setSubmitting(false);
        }
    };

    return (
        <Modal
            centered
            width={900}
            okText="Update"
            onOk={form.submit}
            open={props.visible}
            onCancel={props.onCancel}
            title="Edit Vendor Quote"
            confirmLoading={submitting}
        >
            <Spin spinning={submitting}>
                <Form form={form} layout="vertical" onFinish={onSubmit}>
                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item
                                label="Avg. Margin"
                                name="margin_percent"
                                initialValue={{ avgMargin: 0 }}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value <= 99) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Average margin cannot be more than 100%',
                                                ),
                                            );
                                        },
                                    },
                                ]}
                                help="Margin cannot be more than 99%"
                            >
                                <InputNumber
                                    suffix="%"
                                    precision={2}
                                    min={0}
                                    max={99}
                                    maxLength={3}
                                    step={0.1}
                                    onChange={onChangeAvgMargin}
                                    formatter={formatInput}
                                    parser={parseInput}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Sell" name="total_sell">
                                <Input prefix="$" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Cost" name="total_cost">
                                <Input prefix="$" disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
};

VendorQuoteEdit.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
    getVendorslist: () => {},
    getVendorsSummary: () => {},
};

VendorQuoteEdit.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    getVendorslist: PropTypes.func,
    getVendorsSummary: PropTypes.func,
};

export default memo(VendorQuoteEdit);
