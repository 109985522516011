export const UPLOAD_DOCUMENTS_FROM_LOCAL = 'UPLOAD_DOCUMENTS_FROM_LOCAL';
export const SWITCH_TO_UPLOAD_DOCUMENTS_MODE = 'SWITCH_TO_UPLOAD_DOCUMENTS_MODE';
export const RESET_STATE = 'RESET_STATE';
export const CLEAR_DOCUMENT_STATE = 'CLEAR_DOCUMENT_STATE';

export const FETCH_PROJECT_DOCUMENTS = 'FETCH_PROJECT_DOCUMENTS';
export const FETCH_PROJECT_DOCUMENTS_V2 = 'FETCH_PROJECT_DOCUMENTS_V2';
export const FETCH_PROJECT_DOCUMENTS_V2_INIT = 'FETCH_PROJECT_DOCUMENTS_V2_INIT';
export const FETCH_EQUIPMENT_DOCUMENTS = 'FETCH_EQUIPMENT_DOCUMENTS';
export const FETCH_EQUIPMENT_DOCUMENTS_INIT = 'FETCH_EQUIPMENT_DOCUMENTS_INIT';
export const FETCH_REVIEW_DOCUMENTS = 'FETCH_REVIEW_DOCUMENTS';
export const FETCH_REVIEW_DOCUMENTS_INIT = 'FETCH_REVIEW_DOCUMENTS_INIT';
export const FETCH_SHARED_DOCUMENTS = 'FETCH_SHARED_DOCUMENTS';
export const FETCH_SHARED_DOCUMENTS_INIT = 'FETCH_SHARED_DOCUMENTS_INIT';

export const FETCH_DOCUMENT_HISTORY_INIT = 'FETCH_DOCUMENT_HISTORY_INIT';
export const FETCH_DOCUMENT_HISTORY_SUCCESS = 'FETCH_DOCUMENT_HISTORY_SUCCESS';
export const FETCH_DOCUMENT_HISTORY_FAILED = 'FETCH_DOCUMENT_HISTORY_FAILED';
