import { memo, useState } from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Layout, Tooltip } from 'antd';
import ProjectsSidebar from './ProjectsSidebar';

const { Content } = Layout;

const ProjectDetails = (props) => {
    const [hideSidebar, setHideSidebar] = useState(false);
    return (
        <Spin spinning={false}>
            <Content
                className={
                    hideSidebar ? 'position-relative mb-0 mt-0 ml-20' : 'content-width-sidebar'
                }
            >
                {hideSidebar && (
                    <Tooltip title="Open Projects" placement="right">
                        <div
                            className="sidebar-toggler open"
                            onClick={() => setHideSidebar(!hideSidebar)}
                        >
                            <DoubleRightOutlined />
                        </div>
                    </Tooltip>
                )}
                <Row gutter={30}>
                    <Col span={hideSidebar ? 0 : 5}>
                        <ProjectsSidebar setHideSidebar={setHideSidebar} hideSidebar={hideSidebar} />
                    </Col>
                    <Col span={hideSidebar ? 24 : 19} className="fixed-right">
                        {props.children}
                    </Col>
                </Row>
            </Content>
        </Spin>
    );
};

export default memo(ProjectDetails);
