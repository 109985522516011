import { memo } from 'react';
import { useSelector } from 'react-redux';
import ProjectListItems from './ProjectsListItems';
import * as Permissions from '../../utils/permissions';

const ProjectList = memo((props) => {
    const { user } = useSelector(({ auth }) => auth);

    return (
        <>
            {Permissions.can_view_projects_sidebar(user?.platform_permissions) && (
                <ProjectListItems projects={props.projects} totalCount={props.projectsMeta?.total_count} />
            )}
        </>
    );
});

export default ProjectList;
