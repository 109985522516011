import { http, fileHttp } from '../utils/http';

export const fetchApprovals = async (pkgId) => {
  try {
    const response = await http.get(`/packages/${pkgId}/send-for-approval/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const uploadDocuments = async (projectId, pkgId, data) => {
  try {
    const response = await fileHttp.post(
      `/projects/${projectId}/package-rooms/${pkgId}/send-for-approval/upload/`,
      data,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const sendForApproval = async (pkgId, data) => {
  try {
    const response = await http.post(`/packages/${pkgId}/send-for-approval/`, data);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchMarkups = async (pkgId) => {
  try {
    const response = await http.get(`/packages/${pkgId}/release-approval/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const releaseMarkup = async (pkgId, data) => {
  try {
    const response = await http.patch(`/packages/${pkgId}/release-approval/`, data);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const getSignedUrl = async (key) => {
  try {
    const response = await http.post('/document-link/', { key });
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};
