import _ from 'lodash';
import { Tooltip, Space, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const userColumns = ({
  setModalType = () => { },
  setAdminRecord = () => { },
  setShowModal = () => { },
  onChange = () => { },
}) => [
    {
      title: 'Organization',
      dataIndex: ['organization', 'name'],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Company',
      dataIndex: ['branch', 'company', 'name'],
    },
    {
      title: 'Branch Name',
      dataIndex: ['branch', 'name'],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Name',
      dataIndex: ['name'],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_, record) => (
        <>
          {record?.first_name}
          {' '}
          {record?.last_name}
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Permission',
      dataIndex: ['permission_group', 'name'],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Is Active',
      render: (_, record) => (
        <Switch
          checked={record?.is_active}
          onChange={() => {
              onChange({
                userId: record?.id,
                payload: {
                  is_active: !record?.is_active,
                },
              });
          }} />
      ),
    },
    {
      title: 'Action',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit">
            <EditOutlined
              onClick={() => {
                setModalType('update-user');
                setAdminRecord(record);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
