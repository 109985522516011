import _, { orderBy, uniqBy } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import {
  Alert,
  Form,
  Button,
  Collapse,
  DatePicker,
  Row,
  Tooltip,
  Col,
  Input,
  Spin,
  Empty,
  Avatar,
  Upload,
  Table,
  Space,
  Select,
} from 'antd';
import { DeleteOutlined, DownloadOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  APPROVAL_STATUSES,
  COMMENTS_VALUE,
  DISPLAY_DATETIME_FORMAT,
  makeAvatar,
  MARKUP_COMMENTS,
  MARKUP_TYPES,
} from '../../../../utils';
import { getApprovals } from '../../../../store/approval';
import {
  uploadDocuments,
  getSignedUrl,
  createNotification,
  sendForApproval,
  fetchMarkup,
} from '../../../../services';

import './approval.scss';
import { AlectifyTable, UploadNote } from '../../../../components';

const { Option } = Select;
const { Panel } = Collapse;

function Approval({
  pkgId, projectId, isReleased = false, setMarkupDetail,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { approvals, approvalsLoading } = useSelector(({ approval }) => approval);
  const [isDuplicate, setDuplicate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [approvalValues, setApprovalsValues] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editDoc, setEditDoc] = useState('');
  const [s3Key, setS3Key] = useState('');
  const [uploadFileList, setUploadFileList] = useState([]);
  const { activeProject } = useSelector(({ common }) => common);
  const [emailSet, setEmailSet] = useState([]);
  const { user } = useSelector(({ auth }) => auth);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [finalFiles, setFinalFiles] = useState([]);
  const [isFetchingFile, setFetchingFile] = useState(false);

  const filterApprovals = (status) => approvals.filter((approval) => approval.approval_status === status);

  const submittedApprovals = orderBy(
    filterApprovals(APPROVAL_STATUSES.SEND_FOR_APPROVAL),
    ['created_at'],
    ['desc'],
  );

  const handleFileChange = async (event) => {
    setUploadFileList(event.fileList);

    const newFilesRow = event.fileList.map((file) => {
      const formObj = {
        path: '',
        fileName: '',
        dueDate: '',
        notes: '',
        approval_due_time: null,
        document_type: MARKUP_TYPES.APPROVAL,
        package_room: pkgId,
      };

      return {
        ...formObj,
        file: file.originFileObj,
        fileName: file.name,
      };
    });

    await setApprovalsValues([...newFilesRow]);
  };

  const disabledPreviousDates = (current) => current && current < moment().endOf('day');

  const handleDocumentTypeChange = (value, i) => {
    const tempAppovals = [...approvalValues];
    tempAppovals[i].document_type = value.trim();
    if (value === 'Information') tempAppovals[i].approval_due_time = null;
    setApprovalsValues(tempAppovals);
  };

  const handleDateChange = (date, i) => {
    console.log("modifiedDate: ", date);
   if (date) {
    const modifiedDate = `${date.format('YYYY-MM-DD')} 23:59:00`;
    console.log("modifiedDate: ", modifiedDate);
    const tempAppovals = [...approvalValues].map((item) => ({
      ...item,
      approval_due_time: moment(modifiedDate).format('YYYY-MM-DD HH:mm:ssZ'),
    }));
    console.log("tempAppovals: ", tempAppovals);
    setApprovalsValues([...tempAppovals]);
   }
  };

  const handleDeleteRow = (i, file) => {
    let tempForm = [...approvalValues];
    if (tempForm.length === 1) {
      tempForm = [];
    }

    tempForm.splice(i, 1);

    setUploadFileList(uploadFileList.filter((item) => item.uid !== file.file.uid));
    setApprovalsValues([...tempForm]);
    // form.resetFields();
  };

  const uploadDocumentsAndSendForApproval = async () => {
    const formData = new FormData();

    approvalValues.map(({ file }) => {
      formData.append('files', file);
      formData.append('project_id', projectId.toString());
    });

    await handleUploadDocuments(formData);
  };

  useEffect(() => {
    if (selectedFiles.length) {
      selectedFiles.map((record) => {
        getDocUrl(record);
      });
    } else {
      setFinalFiles([]);
    }
  }, [selectedFiles]);

  useEffect(() => {
    const selectedFilesPaths = selectedFiles.map((file) => file.approval_file_path);
    if (finalFiles.length) {
      setEmailSet(
        uniqBy(
          finalFiles.filter((file) => selectedFilesPaths.includes(file.approval_file_path)),
          'approval_file_path',
        ),
      );
    } else {
      setEmailSet([]);
    }
  }, [finalFiles]);

  function Mailto({ email, subject = '', children }) {
    const body = `Hi,%0D%0DAttaching files from the Project ${
      activeProject?.name
    }.%0D%0DFiles:%0D${emailSet
      .map((file) => `${encodeURIComponent(file?.url)}%0D`)
      .join('.%0D')}.%0D%0D%0D%0DRegards,%0D${user?.first_name}%0D%0D.`;

    return <a href={`mailto:?subject=${subject}&body=${body}`}>{children}</a>;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedFiles(selectedRows);
    },
    type: 'checkbox',
    selectedRowKeys: selectedFiles.map((m) => m.key),
  };

  const getDocUrl = async (rec) => {
    setFetchingFile(true);
    const res = await getSignedUrl(rec.approval_file_path);
    setFinalFiles((state) => [
      ...state,
      {
        ...rec,
        url: res.data,
      },
    ]);

    setFetchingFile(false);

    return res.data;
  };
  const sendNotificationForApproval = async (approvalValues) => {
    let approvalMessage = 'The Review process has started and {} have been sent for approval';
    const uploadedDocuments = approvalValues.map((doc) => doc.fileName);
    const files = uploadedDocuments.length > 1 ? uploadedDocuments.join(', ') : uploadedDocuments[0];
    if (approvals.length === 0) {
      approvalMessage = approvalMessage.replace('{}', files);
    } else {
      approvalMessage = `${files} have been sent for approval`;
    }
    const message = {
      package: pkgId,
      message: approvalMessage,
    };
    await createNotification(projectId, message);
  };

  const handleSubmit = async (values) => {
    let duplicate = false;
    const uploadedDocuments = approvals.map((doc) => doc.file_name);

    approvalValues.map((file) => {
      if (uploadedDocuments.includes(file.fileName)) {
        setDuplicate(true);
        duplicate = true;
      }
    });
    if (!duplicate) {
      setLoader(true);
      uploadDocumentsAndSendForApproval(values);
      sendNotificationForApproval(approvalValues);
    }
  };

  const handleUploadDocuments = async (data) => {
    try {
      const approvalDocuments = await uploadDocuments(projectId, pkgId, data);
      const mapFileDetails = approvalValues.map((item, index) => ({
        ...item,
        approval_file_name: approvalDocuments.data[index].fileName,
        approval_file_path: approvalDocuments.data[index].path,
      }));
      await submitForApproval(mapFileDetails);
      setLoader(false);
    } catch (ex) {
      throw (ex);
      setLoader(false);
    }
  };

  const submitForApproval = async (data) => {
    try {
      await sendForApproval(pkgId, data);
      dispatch(getApprovals(pkgId));
      const markups = await fetchMarkup(projectId, pkgId);

      setMarkupDetail(markups.data);
      resetState();
    } catch (ex) {
      throw (ex);
      resetState();
    }
  };

  const resetState = () => {
    setLoader(false);
    setUploadFileList([]);
    setApprovalsValues([]);
    form.resetFields();
  };

  const handleDownload = async (filePath) => {
    if (!_.isEmpty(filePath)) {
      const key = `${filePath}`;
      const url = await getSignedUrl(key);
      window.open(url.data);
    }
  };

  const handleView = async (filePath) => {
    if (!_.isEmpty(filePath)) {
      const key = `${filePath}`;
      const url = await getSignedUrl(key);
      window.open(url.data, '_blank');
    }
  };

  const APPROVAL_COLUMNS = [
    {
      title: 'File Name',
      dataIndex: 'approval_file_name',
      render: (_, record) => (
        <p className="approval-file-name" title={record.approval_file_name}>
          {record.approval_file_name}
        </p>
      ),
    },
    {
      title: 'Files',
      dataIndex: 'approval_file_name',
      render: (_, record) => (
        <Space>
          <Tooltip title="Download">
            <Button
              className="buttonView"
              type="default"
              onClick={() => handleDownload(record.approval_file_path)}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'document_type',
      render: (_, record) => {
        if (record.document_type) {
          return <p>{record.document_type}</p>;
        }

        return <p>-</p>;
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'approval_due_time',
      render: (_, record) => {
        if (record.approval_due_time !== null) {
          return moment(record.approval_due_time).format(DISPLAY_DATETIME_FORMAT);
        }

        return <p>-</p>;
      },
    },
    {
      title: 'Submitted at',
      dataIndex: 'created_at',
      render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
    },
    {
      title: 'Submitted by',
      dataIndex: 'account',
      render: (_, record) => (
        <Tooltip title={`${record.account.first_name} ${record.account.last_name}`}>
          <Avatar
            className="circle-icon center-icon"
            src={
                            record.account.image
                            || makeAvatar(record.account.first_name[0], record.account.last_name[0])
                        }
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['2']}
      className="site-collapse-custom-collapse"
    >
      {/* {!isReleased && (

            )} */}
      <Panel
        key="2"
        header={(
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                margin: '0 10px 0 0',
              }}
            >
              Submit Approval Documents
            </span>
            <UploadNote />
          </div>
                  )}
        className="site-collapse-custom-panel"
      >
        <Spin
          spinning={loader || approvalsLoading}
          tip={loader || approvalsLoading ? 'Please wait, it will take a while' : ''}
        >
          <div>
            <div className="card">
              <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 8 }}
                onFinish={(values) => handleSubmit(values)}
              >
                <Row gutter={15}>
                  <Col span={24}>
                    <Form.Item>
                      <div className="file_upload_wrapper">
                        <Upload
                          beforeUpload={() => false}
                          showUploadList={false}
                          onChange={handleFileChange}
                                                // maxCount={1}
                          fileList={uploadFileList}
                          multiple
                          className="full-width"
                        >
                          <Button className="full-width" type="primary">
                            Upload Files
                          </Button>
                        </Upload>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row gutter={16}> */}
                  <div className="uploaded_file">
                    {isDuplicate && (
                    <Alert
                      message="Duplicate file detected"
                      showIcon
                      description="It seems that, You have selected file which is already uploaded under this package."
                      type="error"
                      className="mt-15"
                      action={(
                        <Button
                          size="small"
                          danger
                          onClick={(e) => setDuplicate(false)}
                        >
                          Close
                        </Button>
                      )}
                    />
                    )}
                    </div>
                    <AlectifyTable
                      columns={[
                        {
                          key: "file_name",
                          dataIndex: "file_name",
                          title: "File Name",
                          width: "90%",
                          render: (value, record) => value || record?.fileName,
                        },
                        {
                          key: "action",
                          dataIndex: 'Actipn',
                          title: 'Actipn',
                          render: (value, item, index) => (
                            <Tooltip title={`Delete ${item.fileName}`}>
                                  <Button
                                      type="ghost"
                                      onClick={(e) =>
                                          handleDeleteRow(index, item)
                                      }
                                  >
                                      <DeleteOutlined />
                                  </Button>
                              </Tooltip>
                          ),
                        },
                      ]}
                      loading={false}
                      selectRows={false}
                      defaultPageSize={20}
                      dataSource={approvalValues}
                      total={approvalValues?.length}
                      showPagination={false}
                    />
                    {/* {!_.isEmpty([])
                                            && approvalValues.map((item, index) => (
                                              <div className='child-upload' key={index}>
                                                <Col span={5}>
                                                  <p className="approval-form-title">
                                                    File Name
                                                  </p>
                                                  <Tooltip
                                                    title={item.file_name || item.fileName}
                                                  >
                                                    <p className="approval-file-name">
                                                      {item.file_name || item.fileName}
                                                    </p>
                                                  </Tooltip>
                                                </Col>
                                                <Col span={5} />
                                                <Col span={5}>
                                                  <Form.Item
                                                    name={`type${index}`}
                                                    hasFeedback
                                                    rules={[
                                                      {
                                                        required:
                                                                        item.document_type === null,
                                                        message: '',
                                                      },
                                                    ]}
                                                    key={`type${index}`}
                                                  >
                                                    <div>
                                                      <p
                                                        className="approval-form-title"
                                                        data-value={item.document_type}
                                                      >
                                                        Type
                                                      </p>
                                                      <Select
                                                        value={MARKUP_TYPES.APPROVAL}
                                                        placeholder="Select type *"
                                                        onChange={(val) => handleDocumentTypeChange(
                                                          val,
                                                          index,
                                                        )}
                                                      >
                                                        {Object.values(
                                                          MARKUP_TYPES,
                                                        ).map((item, index) => (
                                                          <Option
                                                            key={index}
                                                            value={item}
                                                          >
                                                            {item}
                                                          </Option>
                                                        ))}
                                                      </Select>
                                                    </div>
                                                  </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                  <p
                                                    className="approval-form-title"
                                                    data-value={item?.approval_due_time}
                                                  >
                                                    Approval Due Date
                                                  </p>
                                                  <Form.Item
                                                    name={`dueDate${index}`}
                                                    hasFeedback={(item?.document_type === 'Approval')}
                                                    rules={[
                                                      {
                                                        required:
                                                                        item.document_type
                                                                        === 'Approval',
                                                        message: '',
                                                      },
                                                    ]}
                                                  >
                                                    <DatePicker
                                                      style={{
                                                        width: '100%',
                                                      }}
                                                      defaultValue={
                                                                    item?.approval_due_time && (item?.document_type
                                                                    === 'Approval' || item?.document_type === 'Information')
                                                                      ? moment(
                                                                        item?.approval_due_time,
                                                                      )
                                                                      : null
                                                                }
                                                      disabled={
                                                                    item?.document_type === null
                                                                    || item?.document_type
                                                                        === 'Information'
                                                                }
                                                      className="mt-0 buttonView"
                                                      onChange={(e) => {
                                                        handleDateChange(e, index);
                                                      }}
                                                      placeholder="Select Due Date"
                                                      disabledDate={disabledPreviousDates}
                                                    />
                                                  </Form.Item>
                                                </Col>

                                                <Col span={1}>
                                                  <p
                                                    className="approval-form-title"
                                                    style={{
                                                      opacity: 0,
                                                    }}
                                                  >
                                                    Action
                                                  </p>
                                                  <Tooltip title={`Delete ${item.fileName}`}>
                                                    <Button
                                                      type="ghost"
                                                      onClick={(e) => handleDeleteRow(index, item)}
                                                    >
                                                      <DeleteOutlined />
                                                    </Button>
                                                  </Tooltip>
                                                </Col>
                                              </div>
                                            ))}
                  </div> */}
                {/* </Row> */}
                {!_.isEmpty(approvalValues) && (
                  <div className='text-right mt-30'>
                    <Space align="center">
                      <Form.Item
                        name={`due_date`}
                        hasFeedback={true}
                        rules={[
                          {
                            required: true,
                            message: 'Required',
                          },
                        ]}
                      >
                        <DatePicker
                          style={{
                            width: '200px',
                          }}
                          className="mt-0 buttonView"
                          onChange={(e) => {
                            handleDateChange(e);
                          }}
                          placeholder="Select Due Date"
                          disabledDate={disabledPreviousDates}
                        />
                      </Form.Item>
                      <div className="bottom-btn">
                      <Form.Item>
                        <div>
                          <Button
                            onClick={() => {
                            }}
                            className="btn-lg normal-btn ml-0 text-white"
                            type="primary"
                            htmlType="submit"
                          >
                            Submit for review
                          </Button>
                        </div>
                      </Form.Item>
                    </div>
                    </Space>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </Spin>
      </Panel>
      <Panel
        header={`Submitted Documents (${submittedApprovals?.length})`}
        key="3"
        className="site-collapse-custom-panel-grey"
      >
        {/* <div className="mb-15 text-right">
                    <Button disabled={emailSet.length < 1 || isFetchingFile} type="link">
                        <Mailto email="" subject={`Files from`}>
                            <ShareAltOutlined /> Email
                        </Mailto>
                    </Button>
                </div> */}
        <Table
          columns={APPROVAL_COLUMNS}
          pagination={submittedApprovals.length > 10}
          dataSource={submittedApprovals.map(
            (data, index) => ({
              ...data,
              key: `${data.approval_file_path}+${index}`,
            }),
          )}
        />
      </Panel>
    </Collapse>
  // /* <Modal
  //     width="100%"
  //     className="edit-doc-modal"
  //     style={{ top: 0, bottom: 0 }}
  //     title="Edit Document"
  //     visible={editModal}
  //     onOk={() => setEditModal(false)}
  //     onCancel={() => setEditModal(false)}
  // >
  //     <PdfEditor
  //         pdf={editDoc}
  //         s3Key={s3Key}
  //         key={s3Key}
  //         setEditModal={setEditModal}
  //     />
  // </Modal> */
  );
}

export default Approval;
