import {
  Modal,
} from 'antd';
import '../../assets/styles/modal.scss';
import EquipmentDetails from './EquipmentDetails';

function ViewModal({
  visible = false, record, onOk, onCancel,
}) {

  return (
    <Modal
      title="View Equipment Details"
      centered
      width={1200}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Close"
      maskClosable={false}
      footer={null}
      className="equipment-detail-modal"
    >
      <EquipmentDetails record={record} />
    </Modal>
  );
}

export default ViewModal;
