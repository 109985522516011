import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  AutoComplete,
  Switch,
  notification,
  Spin,
  Col,
  Row,
  Button,
  Space,
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import _ from 'lodash';
import { isEmpty } from '@firebase/util';
import {
  INPUT_DATE_FORMAT, DATE_FIELDS, SERVER_DATE_FORMAT, isDatePast, disabledDate, DATES_KEYS,
} from '../../../utils';
import { getOrganizations } from '../../../services';

export function AddEquipmentModal({
  dates,
  tags,
  projectId,
  visible = false,
  fetchTags,
  setModalType,
  setDates,
  setTags,
  newUpdateProject,
}) {
  const [form] = Form.useForm();
  const [organizations, setOrganizations] = useState({
    data: [],
    value: '',
    fetching: false,
  });
  const [addApproval, setAddApproval] = useState(false);

  const handleAddApprovalChange = (val) => {
    setAddApproval(val);
  };

  const addManualTagHandler = async (values) => {
    try {
      values.approval_dwg = true;
      values.is_manual_tracking = true;
      Object.keys(values).forEach((key) => {
        if (DATES_KEYS.includes(key)) {
          values[key] = values[key]
            ? moment(values[key], INPUT_DATE_FORMAT).format(SERVER_DATE_FORMAT)
            : null;
        }
      });
      if (values?.lead_time_weeks) {
        values.lead_time_weeks = parseInt(values?.lead_time_weeks);
      }
      const response = await newUpdateProject('tag', projectId, [values]);
      values.id = response?.data[0].id;
      setTags([values, ...tags]);
      notification.success({
        message: `${values.name} added`,
        description: 'The tag has been added successfully',
      });
      form.resetFields();
      setModalType(null);
      fetchTags();
    } catch (error) {
      notification.error({
        message: `${values.name} cannot be added`,
        description: 'Please check if the Tag is not a duplicate one.',
      });
    }
  };

  const onSearchOrganization = _.debounce(async (value) => {
    try {
      setOrganizations({ ...organizations, fetching: true });
      const response = await getOrganizations({ search: value });
      setOrganizations({
        ...organizations, fetching: false, data: response?.data, value,
      });
    } catch (error) {
      setOrganizations({ ...organizations, fetching: false, data: [] });
    }
  }, 500);

  const resetForm = () => {
    form.resetFields();
    setOrganizations({ ...organizations, data: [] });
  };

  return (
    <Form form={form} layout="vertical" onFinish={addManualTagHandler}>
      {/* <h1>Main Information</h1> */}
      <Row gutter={15}>
        <Col span={4}>
          <Form.Item name="reference_number" label="Ref #">
            <Input placeholder="Enter Reference Number" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="name"
            label="Tag Name"
            rules={[{ required: true, message: 'Tag name is required' }]}
          >
            <Input placeholder="Enter Tag Name" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="manufacture"
            label="Manufacture Name"
            rules={[
              {
                required: true,
                message: 'Manufacture name is required',
              },
            ]}
          >
            <AutoComplete
              showSearch
              placeholder="Search or Select a Manufacturer"
              options={_.map(organizations.data, (org) => ({
                name: org?.name,
                value: org?.name,
              }))}
              onSearch={onSearchOrganization}
              notFoundContent={
                  organizations.fetching ? (
                    <div className="text-center">
                      <Spin />
                    </div>
                  ) : organizations.value && organizations.data.length === 0 ? (
                    'Not Found'
                  ) : null
              }
            />
          </Form.Item>
        </Col>

        {/* <Col span={4}>
          <Form.Item name="quote_number" label="Quote #">
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="marks" label="Marks">
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="catalog_number" label="Catalog Number">
            <Input />
          </Form.Item>
        </Col> */}
        <Col span={4}>
          <Form.Item
            name="price"
            label="Price ($)"
            rules={[
              {
                message: 'Price should be a number',
                validator: (_, value) => {
                  if (Number.isInteger(Number(value)) || isEmpty(value)) {
                    return Promise.resolve();
                  } if (value.toString().split('.')[1].length <= 3) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ]}
          >
            <Input type='number' prefix="$" placeholder="0.00" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[
              {
                message: 'Quantity should be a number',
                validator: (_, value) => {
                  if (
                    (Number.isInteger(Number(value))
                                            && value.toString().split('.').length === 1)
                                        || isEmpty(value)
                  ) {
                    return Promise.resolve();
                  } if (value.toString().split('.')[1].length > 0) {
                    return Promise.reject();
                  }
                  return Promise.reject();
                },
              },
            ]}
          >
            <Input placeholder="Enter Quantity" />
          </Form.Item>
        </Col>
        {/* <Col span={4}>
          <Row>
            <Col span={12}>
              <Form.Item name="approval_dwg" label="For Approval">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={handleAddApprovalChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="is_manual_tracking" label="Manual Schedule">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col> */}
        <Col span={4} />
        <Col span={4}>
          <Form.Item name="order_date" label="Order Entry">
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, order_date: val })}
            />
          </Form.Item>
        </Col>
        {/* <Col span={4}>
          <Form.Item
            name="actual_order_date"
            label="Order Entry Manufacturer"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, approval_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="lead_time_weeks"
            label="Lead Time (Weeks)"
          >
           <Input type='number' />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="approval_date"
            label="Expected Approval Dwg."
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="actual_approval_date"
            label="Actual Approval Dwg."
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="actual_approved_date"
            label="Approved Dwg. Returned"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="resubmittal_date"
            label="Resubmittal Date"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="release_date"
            label="Expected Release"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="actual_release_date"
            label="Actual Release"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col> */}
        <Col span={4}>
          <Form.Item
            name="ship_date"
            label="Planned Ship"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="description" label="Description" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
        {/* <Col span={4}>
          <Form.Item
            name="test_date"
            label="Witness Testing"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="actual_ship_date"
            label="Actual Ship"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="expected_ship_date_by_customer"
            label="Customer OnSite Request"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="actual_final_date"
            label="Record Dwgs."
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="comments" label="Comments">
            <Input />
          </Form.Item>
        </Col> */}
        {/* <Col span={4}>
          <Form.Item
            name=""
            label=""
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name=""
            label=""
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col> */}
        {/* <Col span={4}>
          <Form.Item
            name="ship_date"
            label="Ship Date"
            rules={[
              {
                message: 'Ship date should be greater than or equal to test date',
                validator: (_, value) => {
                  if (isDatePast(value, dates.test_date)) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) => disabledDate(current, dates?.test_date)}
              format={INPUT_DATE_FORMAT}
              style={{ width: '100%' }}
              onChange={(val) => setDates({ ...dates, ship_date: val })}
            />
          </Form.Item>
        </Col> */}
      </Row>
      {/* <h1>Other Dates</h1> */}
      <Row gutter={15}>
      <Col span={4}>
          <Form.Item
            name="actual_order_date"
            label="Order Entry Manufacturer"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, approval_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="lead_time_weeks"
            label="Lead Time (Weeks)"
          >
           <Input type='number' />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="approval_date"
            label="Expected Approval Dwg."
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="release_date"
            label="Expected Release"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="test_date"
            label="Witness Testing"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="expected_ship_date_by_customer"
            label="Customer OnSite Request"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={INPUT_DATE_FORMAT}
              onChange={(val) => setDates({ ...dates, test_date: val })}
            />
          </Form.Item>
        </Col>
      </Row>
    <Space>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
      <Button type="default" htmlType="button" onClick={resetForm}>
        Clear
      </Button>
    </Space>
    </Form>
  );
}

export default AddEquipmentModal;
