import axios from 'axios';
import { notification } from 'antd';
import { isString } from 'lodash';
import { getFromLocal, saveToLocal, removeFromLocal } from '.';
import { store } from '../store';
import { RESET_STATE } from '../store/common';
import { refreshAuth } from '../services';
import { UPDATE_AUTH_USER } from '../store/auth';

const BASE_URL = process.env.REACT_APP_BASE_URL;

class HttpService {
  constructor(baseURL, headers = { 'X-Custom-Header': 'foobar' }) {
    this._axios = axios.create({
      baseURL,
      headers,
    });
  }

  get(endpoint = '', params = {}) {
    return this._axios.get(endpoint, { params }).catch(this.errorHandling);
  }

  post(endpoint, body) {
    return this._axios.post(endpoint, body).catch(this.errorHandling);
  }

  put(endpoint, data) {
    return this._axios({
      method: 'put',
      url: endpoint,
      data,
    }).catch(this.errorHandling);
  }

  patch(endpoint, data) {
    return this._axios({
      method: 'patch',
      url: endpoint,
      data,
    }).catch(this.errorHandling);
  }

  delete(endpoint, data) {
    return this._axios({
      method: 'delete',
      url: endpoint,
      data,
    }).catch(this.errorHandling);
  }

  errorHandling(err) {
    // if (err?.response?.status === 403 || err?.response?.data?.detail === 'Token is expired') {
    //     store.dispatch({ type: RESET_STATE });
    //     removeFromLocal('token');
    //     notification.info({
    //         message: 'Session expired',
    //         description: 'Your session has been expired please login again',
    //     });
    //     setTimeout(() => {
    //         window.location.href = '/login';
    //     }, 2000);
    // }

    if (err?.response?.status === 400) {
      if (isString(err?.response?.data?.message)) {
        notification.error({
          message: 'Error',
          description: err?.response?.data?.message,
        });
      }
    }

    if (err?.response?.status === 401 && err?.response?.data?.message !== "Invalid credentials or inactive user") {
      const refToken = JSON.parse(localStorage.getItem('refresh_token'));

      refreshAuth({
        refresh: refToken,
      }).then((res) => {
        const newToken = res.access;
        store.dispatch({
          type: UPDATE_AUTH_USER,
          payload: {
            token: newToken,
          },
        });
        saveToLocal('token', res.access);
        window.location.reload();
      })
        .catch((err) => {
          store.dispatch({ type: RESET_STATE });
          removeFromLocal('token');
          removeFromLocal('refresh_token');
          notification.info({
            message: 'Session expired',
            description: 'Your session has been expired please login again',
          });

          setTimeout(() => {
            window.location.href = '/login';
          }, 6000);
        });
    }

    throw err;
  }
}

export const http = new HttpService(BASE_URL, {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getFromLocal('token')}`,
});

export const openHttp = new HttpService(BASE_URL, {
  'Content-Type': 'application/json',
});

export const fileHttp = new HttpService(BASE_URL, {
  'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
  Authorization: `Bearer ${getFromLocal('token')}`,
});

export const detectHttp = new HttpService('https://cardboard-detect.alectify.com', {
  'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
  Authorization: `Bearer ${getFromLocal('token')}`,
});

export const videoPlayerHttp = new HttpService('https://video-punch-list.alectify.com', {
  'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
  Authorization: `Bearer ${getFromLocal('token')}`,
});
