import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse, notification, Upload } from 'antd';
import { downloadZip, shareFiles } from '../../../../../services';
import { DEFAULT_PAGE_SIZE, DEFAULT_START_PAGE } from '../../../../../utils';
import { getEquipDocuments, getProjectDocumentsV2, getReviewDocuments } from '../../../../../store/documents';
import { AlectifyTable, UploadDocumentModal, UploadNote } from '../../../../../components';
import useReviewColumns from './effects/useReviewColumns';
import useProjectDocumentColumns from './effects/useProjectDocumentColumns';
import useEquipmentDocumentColumns from './effects/useEquipmentDocumentColumns';

const { Panel } = Collapse;

const Documents = () => {
    const dispatch = useDispatch();
    const { subProjectId } = useParams();
    const {
        review_documents,
        project_documents,
        equipment_documents,
        review_documents_loading,
        project_documents_loading,
        equipment_documents_loading,
    } = useSelector(({ documents }) => documents);
    const [uploadModal, setUploadModal] = useState(false);
    const [documentsToUpload, setDocumentsToUpload] = useState([]);

    const reviewColumns = useReviewColumns();
    const projectColumns = useProjectDocumentColumns();
    const equipmentColumns = useEquipmentDocumentColumns();

    const getAllDocuments = () => {
        const params = {
            id: subProjectId,
            page: DEFAULT_START_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
        };
        dispatch(getEquipDocuments(params));
        dispatch(getReviewDocuments(params));
        dispatch(getProjectDocumentsV2(params));
    };

    useEffect(() => {
        getAllDocuments();
    }, []);

    const downloadDocumentsInZip = async (s3Keys, type) => {
        const response = await downloadZip({
            projectId: subProjectId,
            payload: {
                docs_group: `${type}-docs`,
                s3_keys: s3Keys,
            },
        });

        if (response?.success) {
            notification.success({
                title: 'Received Download Zip Request',
                message: 'Zip link will be send to you mail id',
            });
        }
    };

    const onDownloadZip = async (data, type) => {
        const s3_keys = data.map((a) => a.file_path);
        await downloadDocumentsInZip(s3_keys, type);
    };

    const onEmailShare = async (emails, data, type) => {
        const file_ids = data.map((a) => a?.id);
        const response = await shareFiles({
            projectId: subProjectId,
            payload: {
                to_users: emails,
                document_type: `${type}`,
                file_ids,
            },
        });

        if (response?.success) {
            notification.success({
                title: 'Received Download Zip Request',
                message: 'Shared files',
            });
        }
    };

    const onUploadDocuments = (ev) => {
        setDocumentsToUpload([...documentsToUpload, ...ev.fileList]);
        setUploadModal(true);
    };

    return (
        <>
            <div className="text-right">
                <Upload
                    beforeUpload={() => false}
                    onChange={debounce(onUploadDocuments, 100)}
                    showUploadList={false}
                    multiple
                    fileList={[]}
                >
                    <Button type="default" shape="round" className="main-upload-btn">
                        <span
                            style={{
                                margin: '0 8px 0 0',
                            }}
                        >
                            <PlusOutlined /> Add Documents
                        </span>
                        <UploadNote />
                    </Button>
                </Upload>
            </div>
            <Collapse className="alectify-collapse mb-15" defaultActiveKey={['project-files']}>
                <Panel
                    header={`Project Files (${project_documents?.meta?.total_count || 0})`}
                    key="project-files"
                >
                    <AlectifyTable
                        size="small"
                        onDataFetch={(data) => dispatch(getProjectDocumentsV2(data))}
                        columns={projectColumns}
                        isDownloadZip={true}
                        isEmailSharing={true}
                        onDownloadZip={(data) => onDownloadZip(data, 'project')}
                        onEmailShare={(emails, data) => onEmailShare(emails, data, 'project')}
                        id={subProjectId}
                        total={project_documents?.meta?.total_count}
                        dataSource={project_documents?.data}
                        loading={project_documents_loading}
                        key={`${subProjectId}-project-files`}
                    />
                </Panel>
                <Panel
                    header={`Equipment Files (${equipment_documents?.meta?.total_count || 0})`}
                    key="equipment-fies"
                >
                    <AlectifyTable
                        size="small"
                        onDataFetch={(data) => dispatch(getEquipDocuments(data))}
                        columns={equipmentColumns}
                        isDownloadZip={true}
                        isEmailSharing={true}
                        onDownloadZip={(data) => onDownloadZip(data, 'equipment')}
                        onEmailShare={(emails, data) => onEmailShare(emails, data, 'equipment')}
                        id={subProjectId}
                        dataSource={equipment_documents?.data}
                        loading={equipment_documents_loading}
                        rowSelection={false}
                        total={equipment_documents?.meta?.total_count}
                        key={`${subProjectId}-eqp-files`}
                    />
                </Panel>
                <Panel
                    header={`Submittal Files (${
                        review_documents?.meta?.total_count
                            ? review_documents?.meta?.total_count
                            : 0
                    })`}
                    key="submittals"
                >
                    <AlectifyTable
                        size="small"
                        onDataFetch={(data) => dispatch(getReviewDocuments(data))}
                        columns={reviewColumns}
                        isDownloadZip={true}
                        isEmailSharing={true}
                        onDownloadZip={(data) => onDownloadSubmitalsZip(data, 'review')}
                        onEmailShare={(emails, data) =>
                            onEmailShare(emails, data, 'review')
                        }
                        id={subProjectId}
                        dataSource={review_documents?.data}
                        loading={review_documents_loading}
                        rowSelection={false}
                        onRowSelectionAction={(data) =>
                            onRowSelectionAction(data, 'review')
                        }
                        total={review_documents?.meta?.total_count}
                        key={`${subProjectId}-review-files`}
                    />
                </Panel>
            </Collapse>
            <UploadDocumentModal
                visible={uploadModal}
                projectId={subProjectId}
                onCancel={() => setUploadModal(false)}
                documentsToUpload={documentsToUpload}
                getAllDocuments={getAllDocuments}
                setDocumentsToUpload={setDocumentsToUpload}
            />
        </>
    );
};

export default memo(Documents);
